define('ui/components/modal-welcome/component', ['exports', 'ember', 'ui/utils/constants', 'lacsso/components/modal-base'], function (exports, _ember, _uiUtilsConstants, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'span-6', 'offset-3', 'modal-welcome'],
    settings: _ember['default'].inject.service(),
    optIn: null,

    init: function init() {
      this._super.apply(this, arguments);
      var cur = this.get('settings').get(_uiUtilsConstants['default'].SETTING.TELEMETRY);
      var optIn = undefined;
      var version = this.get('settings.rancherVersion');
      if (!version) {
        // For master, default to opt out
        optIn = cur === 'in';
      } else {
        // For releases, default to opt in
        optIn = cur !== 'out';
      }

      this.set('optIn', optIn);
    },

    actions: {
      cancel: function cancel() {
        this.get('settings').set(_uiUtilsConstants['default'].SETTING.TELEMETRY, this.get('optIn') ? 'in' : 'out');
        this.get('modalService').toggleModal();
      }
    }
  });
});