define('ui/components/new-select/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'select',
    // possible passed-in values with their defaults:
    content: null,
    prompt: null,
    optionValuePath: 'value',
    optionLabelPath: 'label',
    optionGroupPath: 'group',
    optionDisabledPath: 'disabled',
    action: _ember['default'].K, // action to fire on change
    value: null,
    localizedLabel: false,
    disabled: false,
    attributeBindings: ['disabled'],

    ungroupedContent: null,
    groupedContent: null,

    // shadow the passed-in `selection` to avoid
    // leaking changes to it via a 2-way binding
    _selection: _ember['default'].computed.reads('selection'),

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      if (!this.get('content')) {
        this.set('content', []);
      }

      this.set('ungroupedContent', _ember['default'].computed('content.@each.' + this.get('optionGroupPath'), function () {
        var groupPath = _this.get('optionGroupPath');
        var out = [];
        (_this.get('content') || []).forEach(function (opt) {
          var key = _ember['default'].get(opt, groupPath);
          if (!key) {
            out.push(opt);
          }
        });

        return out;
      }));

      this.set('groupedContent', _ember['default'].computed('content.@each.' + this.get('optionGroupPath'), function () {
        var groupPath = _this.get('optionGroupPath');
        var out = [];

        (_this.get('content') || []).forEach(function (opt) {
          var key = _ember['default'].get(opt, groupPath);
          if (key) {
            var group = out.filterBy('group', key)[0];
            if (!group) {
              group = { group: key, options: [] };
              out.push(group);
            }

            group.options.push(opt);
          }
        });

        return out.sortBy(groupPath);
      }));

      this.on('change', this, this._change);
    },

    didRender: function didRender() {
      var selectEl = this.$()[0];
      var value = this.get('value');
      for (var i = 0; i < selectEl.options.length; i++) {
        if (selectEl.options[i].value === value) {
          selectEl.value = value;
          break;
        }
      }

      if (value !== selectEl.value) {
        this._change();
      }
    },

    willDestroyElement: function willDestroyElement() {
      this.off('change', this, this._change);
    },

    _change: function _change() {
      var selectEl = this.$()[0];
      var selectedIndex = selectEl.selectedIndex;
      if (selectedIndex === -1) {
        return;
      }

      var selectedValue = selectEl.options[selectedIndex].value;
      var content = this.get('content') || [];

      var selection = content.filterBy(this.get('optionValuePath'), selectedValue)[0];

      if (selection) {
        // set the local, shadowed selection to avoid leaking
        // changes to `selection` out via 2-way binding
        this.set('_selection', selection);

        var changeCallback = this.get('action');
        if (changeCallback) {
          changeCallback(selection);
        }

        this.set('value', _ember['default'].get(selection, this.get('optionValuePath')));
      } else {
        this.set('value', null);
      }
    }
  });
});