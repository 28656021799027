define('ui/models/host', ['exports', 'ember', 'ui/utils/util', 'ember-api-store/models/resource', 'ui/utils/constants', 'ember-api-store/utils/denormalize', 'ui/utils/parse-version'], function (exports, _ember, _uiUtilsUtil, _emberApiStoreModelsResource, _uiUtilsConstants, _emberApiStoreUtilsDenormalize, _uiUtilsParseVersion) {

  var Host = _emberApiStoreModelsResource['default'].extend({
    type: 'host',
    modalService: _ember['default'].inject.service('modal'),
    settings: _ember['default'].inject.service(),

    instances: (0, _emberApiStoreUtilsDenormalize.denormalizeIdArray)('instanceIds'),
    arrangedInstances: (function () {
      return this.get('instances').sortBy('isSystem', 'displayName');
    }).property('instances.@each.{isSystem,displayName}'),

    actions: {
      activate: function activate() {
        return this.doAction('activate');
      },

      deactivate: function deactivate() {
        return this.doAction('deactivate');
      },

      promptEvacuate: function promptEvacuate() {
        this.get('modalService').toggleModal('modal-host-evacuate', {
          model: [this]
        });
      },

      evacuate: function evacuate() {
        return this.doAction('evacuate');
      },

      purge: function purge() {
        return this.doAction('purge');
      },

      newContainer: function newContainer() {
        this.get('application').transitionToRoute('containers.new', { queryParams: { hostId: this.get('model.id') } });
      },

      clone: function clone() {
        this.get('application').transitionToRoute('hosts.new', { queryParams: { hostId: this.get('id'), driver: this.get('driver') } });
      },

      edit: function edit() {
        this.get('modalService').toggleModal('edit-host', this);
      },

      machineConfig: function machineConfig() {
        var url = this.linkFor('config');
        if (url) {
          _uiUtilsUtil['default'].download(url);
        }
      }
    },

    availableActions: (function () {
      var a = this.get('actionLinks');

      var out = [{ label: 'action.activate', icon: 'icon icon-play', action: 'activate', enabled: !!a.activate }, { label: 'action.deactivate', icon: 'icon icon-pause', action: 'deactivate', enabled: !!a.deactivate }, { label: 'action.evacuate', icon: 'icon icon-snapshot', action: 'promptEvacuate', enabled: !!a.evacuate, altAction: 'evacuate' }, { label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: !!a.remove, altAction: 'delete' }, { label: 'action.purge', icon: '', action: 'purge', enabled: !!a.purge }, { divider: true }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }];

      if (this.get('links.config')) {
        out.push({ label: 'action.machineConfig', icon: 'icon icon-download', action: 'machineConfig', enabled: true });
      }

      out.push({ label: 'action.clone', icon: 'icon icon-copy', action: 'clone', enabled: !!this.get('driver') });
      out.push({ label: 'action.edit', icon: 'icon icon-edit', action: 'edit', enabled: !!a.update });

      return out;
    }).property('actionLinks.{activate,deactivate,evacuate,remove,purge,update}', 'links.config', 'driver'),

    displayIp: _ember['default'].computed.alias('agentIpAddress'),

    displayName: (function () {
      return this.get('name') || this.get('hostname') || '(' + this.get('id') + ')';
    }).property('name', 'hostname', 'id'),

    osBlurb: (function () {
      var out = this.get('info.osInfo.operatingSystem') || '';

      out = out.replace(/\s+\(.*?\)/, ''); // Remove details in parens
      out = out.replace(/;.*$/, ''); // Or after semicolons
      out = out.replace('Red Hat Enterprise Linux Server', 'RHEL'); // That's kinda long

      var hasKvm = (this.get('labels') || {})[_uiUtilsConstants['default'].LABEL.KVM] === 'true';
      if (hasKvm && out) {
        out += ' (with KVM)';
      }

      return out;
    }).property('info.osInfo.operatingSystem', 'labels'),

    osDetail: _ember['default'].computed.alias('info.osInfo.operatingSystem'),

    dockerEngineVersion: (function () {
      if (this.get('info.osInfo')) {
        return (this.get('info.osInfo.dockerVersion') || '').replace(/^Docker version\s*/i, '').replace(/,.*/, '');
      }
    }).property('info.osInfo.dockerVersion'),

    supportState: (function () {
      var my = this.get('dockerEngineVersion') || '';
      my = my.replace(/-(cs|ce|ee)[0-9.-]*$/, '');

      var supported = this.get('settings.' + _uiUtilsConstants['default'].SETTING.SUPPORTED_DOCKER);
      var newest = this.get('settings.' + _uiUtilsConstants['default'].SETTING.NEWEST_DOCKER);

      if (!my || !supported || !newest) {
        return 'unknown';
      } else if ((0, _uiUtilsParseVersion.satisfies)(my, supported)) {
        return 'supported';
      } else if ((0, _uiUtilsParseVersion.compare)(my, newest) > 0) {
        return 'untested';
      } else {
        return 'unsupported';
      }
    }).property('dockerEngineVersion', 'settings.' + _uiUtilsConstants['default'].SETTING.SUPPORTED_DOCKER, 'settings.' + _uiUtilsConstants['default'].SETTING.NEWEST_DOCKER),

    dockerDetail: _ember['default'].computed.alias('info.osInfo.operatingSystem'),

    kernelBlurb: (function () {
      if (this.get('info.osInfo')) {
        return this.get('info.osInfo.kernelVersion') || '';
      }
    }).property('info.osInfo.kernelVersion'),

    cpuBlurb: (function () {
      if (this.get('info.cpuInfo.count')) {
        var ghz = Math.round(this.get('info.cpuInfo.mhz') / 10) / 100;

        if (this.get('info.cpuInfo.count') > 1) {
          return this.get('info.cpuInfo.count') + 'x' + ghz + ' GHz';
        } else {
          return ghz + ' GHz';
        }
      }
    }).property('info.cpuInfo.{count,mhz}'),

    cpuTooltip: _ember['default'].computed.alias('info.cpuInfo.modelName'),

    memoryBlurb: (function () {
      if (this.get('info.memoryInfo')) {
        return (0, _uiUtilsUtil.formatMib)(this.get('info.memoryInfo.memTotal'));
      }
    }).property('info.memoryInfo.memTotal'),

    memoryLimitBlurb: _ember['default'].computed('memory', function () {
      if (this.get('memory')) {
        return (0, _uiUtilsUtil.formatSi)(this.get('memory'), 1024, 'iB', 'B');
      }
    }),

    localStorageBlurb: _ember['default'].computed('localStorageMb', function () {
      if (this.get('localStorageMb')) {
        return (0, _uiUtilsUtil.formatSi)(this.get('localStorageMb'), 1024, 'iB', 'B', 2 /*start at 1024^2==MB */);
      }
    }),

    diskBlurb: (function () {
      var totalMb = 0;

      // New hotness
      if (this.get('info.diskInfo.fileSystems')) {
        var fses = this.get('info.diskInfo.fileSystems') || [];
        Object.keys(fses).forEach(function (fs) {
          totalMb += fses[fs].capacity;
        });

        return (0, _uiUtilsUtil.formatMib)(totalMb);
      } else if (this.get('info.diskInfo.mountPoints')) {
        // Old & busted
        var mounts = this.get('info.diskInfo.mountPoints') || [];
        Object.keys(mounts).forEach(function (mountPoint) {
          totalMb += mounts[mountPoint].total;
        });

        return (0, _uiUtilsUtil.formatMib)(totalMb);
      }
    }).property('info.diskInfo.mountPoints.@each.total', 'info.diskInfo.fileSystems.@each.capacity'),

    diskDetail: (function () {
      // New hotness
      if (this.get('info.diskInfo.fileSystems')) {
        var out = [];
        var fses = this.get('info.diskInfo.fileSystems') || [];
        Object.keys(fses).forEach(function (fs) {
          out.pushObject(_ember['default'].Object.create({ label: fs, value: (0, _uiUtilsUtil.formatMib)(fses[fs].capacity) }));
        });

        return out;
      }
    }).property('info.diskInfo.fileSystems.@each.capacity'),

    // If you use this you must ensure that services and containers are already in the store
    //  or they will not be pulled in correctly.
    displayEndpoints: (function () {
      var store = this.get('store');
      return (this.get('publicEndpoints') || []).map(function (endpoint) {
        if (!endpoint.service) {
          endpoint.service = store.getById('service', endpoint.serviceId);
        }

        endpoint.instance = store.getById('instance', endpoint.instanceId);
        return endpoint;
      });
    }).property('publicEndpoints.@each.{ipAddress,port,serviceId,instanceId}'),

    requireAnyLabels: (function () {
      return ((this.get('labels') || {})[_uiUtilsConstants['default'].LABEL.REQUIRE_ANY] || '').split(/\s*,\s*/).filter(function (x) {
        return x.length > 0;
      });
    }).property('labels.' + _uiUtilsConstants['default'].LABEL.REQUIRE_ANY)
  });

  Host.reopenClass({
    defaultSortBy: 'name,hostname',
    stateMap: {
      'active': { icon: 'icon icon-host', color: 'text-success' },
      'provisioning': { icon: 'icon icon-host', color: 'text-info' },
      'reconnecting': { icon: 'icon icon-help', color: 'text-danger' }
    }
  });

  exports['default'] = Host;
});