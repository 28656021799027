define('ui/components/upgrade-dropdown/component', ['exports', 'ember', 'ui/mixins/upgrade-component', 'ui/utils/parse-externalid'], function (exports, _ember, _uiMixinsUpgradeComponent, _uiUtilsParseExternalid) {

  var CURRENT = 'current',
      AVAILABLE = 'available';

  exports['default'] = _ember['default'].Component.extend(_uiMixinsUpgradeComponent['default'], {
    // See mixin for other inputs
    currentId: null,

    tagName: 'div',
    classNames: ['btn-group'],

    actions: {
      doUpgrade: function doUpgrade() {
        this.doUpgrade();
      },

      changeVersion: function changeVersion(version, url) {
        this.sendAction('changeVersion', version, url);
      }
    },

    // @TODO hacky hacky mchackerson...
    currentVersion: _ember['default'].computed('allVersions', 'currentId', function () {
      var parsed = (0, _uiUtilsParseExternalid.parseExternalId)(this.get('currentId'));

      var versions = this.get('allVersions');
      var keys = Object.keys(versions);
      for (var i = 0; i < keys.length; i++) {
        var key = keys[i];
        if (versions[key].indexOf(parsed.id) >= 0) {
          return key;
        }
      }
    }),

    currentVersionChanged: (function () {
      _ember['default'].run.once(this, 'updateStatus');
    }).observes('currentId'),

    showDropdown: _ember['default'].computed('upgradeStatus', function () {
      return [AVAILABLE, CURRENT].indexOf(this.get('upgradeStatus')) >= 0;
    })
  });
});