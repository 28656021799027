define('ui/mixins/cattle-polled-resource', ['exports', 'ember', 'ui/utils/util', 'ui/utils/constants'], function (exports, _ember, _uiUtilsUtil, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Mixin.create({
    reservedKeys: ['delayTimer', 'pollTimer'],

    replaceWith: function replaceWith() {
      this._super.apply(this, arguments);
      this.transitioningChanged();
    },

    // ember-api-store hook
    wasAdded: function wasAdded() {
      this.transitioningChanged();
    },

    // ember-api-store hook
    wasRemoved: function wasRemoved() {
      this.transitioningChanged();
    },

    delayTimer: null,
    clearDelay: function clearDelay() {
      clearTimeout(this.get('delayTimer'));
      this.set('delayTimer', null);
    },

    pollTimer: null,
    clearPoll: function clearPoll() {
      clearTimeout(this.get('pollTimer'));
      this.set('pollTimer', null);
    },

    needsPolling: (function () {
      return this.get('transitioning') === 'yes' || this.get('state') === 'requested';
    }).property('transitioning', 'state'),

    remove: function remove() {
      var _this = this;

      return this._super()['finally'](function () {
        _this.reload();
      });
    },

    transitioningChanged: (function () {
      var delay = this.constructor.pollTransitioningDelay;
      var interval = this.constructor.pollTransitioningInterval;

      // This resource doesn't want polling
      if (!delay || !interval) {
        return;
      }

      // This resource isn't transitioning or isn't in the store
      if (!this.get('needsPolling') || !this.isInStore()) {
        this.clearPoll();
        this.clearDelay();
        return;
      }

      // We're already polling or waiting, just let that one finish
      if (this.get('delayTimer')) {
        return;
      }

      this.set('delayTimer', setTimeout((function () {
        this.transitioningPoll();
      }).bind(this), _uiUtilsUtil['default'].timerFuzz(delay)));
    }).observes('transitioning'),

    reloadOpts: (function () {
      return null;
    }).property(),

    transitioningPoll: function transitioningPoll() {
      var _this2 = this;

      this.clearPoll();

      if (!this.get('needsPolling') || !this.isInStore()) {
        return;
      }

      //console.log('Polling', this.toString());
      this.reload(this.get('reloadOpts')).then(function () {
        //console.log('Poll Finished', this.toString());
        if (_this2.get('needsPolling')) {
          var interval = _this2.constructor.pollTransitioningInterval;
          var factor = _this2.constructor.pollTransitioningIntervalFactor;
          if (factor) {
            interval *= factor;
          }

          var max = _this2.constructor.pollTransitioningIntervalMax;
          if (max) {
            interval = Math.min(max, interval);
          }

          //console.log('Rescheduling', this.toString());
          _this2.set('pollTimer', setTimeout((function () {
            //console.log('2 expired', this.toString());
            this.transitioningPoll();
          }).bind(_this2), _uiUtilsUtil['default'].timerFuzz(interval)));
        } else {
          // If not transitioning anymore, stop polling
          _this2.clearPoll();
          _this2.clearDelay();
        }
      })['catch'](function () {
        // If reloading fails, stop polling
        _this2.clearPoll();
        // but leave delay set so that it doesn't restart, (don't clearDelay())
      });
    },

    stateChanged: (function () {
      // Get rid of things that are removed
      if (_uiUtilsConstants['default'].REMOVEDISH_STATES.includes(this.state)) {
        try {
          this.clearPoll();
          this.clearDelay();
          this.get('store')._remove(this.get('type'), this);
        } catch (e) {}
      }
    }).observes('state')
  });
});