define('ui/utils/util', ['exports'], function (exports) {
  exports.arrayDiff = arrayDiff;
  exports.arrayIntersect = arrayIntersect;
  exports.filterByValues = filterByValues;
  exports.download = download;
  exports.popupWindowOptions = popupWindowOptions;
  exports.escapeHtml = escapeHtml;
  exports.addQueryParam = addQueryParam;
  exports.addQueryParams = addQueryParams;
  exports.parseUrl = parseUrl;
  exports.absoluteUrl = absoluteUrl;
  exports.addAuthorization = addAuthorization;
  exports.ucFirst = ucFirst;
  exports.lcFirst = lcFirst;
  exports.strPad = strPad;
  exports.timerFuzz = timerFuzz;
  exports.random32 = random32;
  exports.randomStr = randomStr;
  exports.formatPercent = formatPercent;
  exports.formatMib = formatMib;
  exports.formatKbps = formatKbps;
  exports.formatSi = formatSi;
  exports.constructUrl = constructUrl;
  exports.pluralize = pluralize;
  exports.uniqKeys = uniqKeys;
  exports.camelToTitle = camelToTitle;
  // Note: nothing Ember specific should go in here...

  function arrayDiff(a, b) {
    return a.filter(function (i) {
      return !b.includes(i);
    });
  }

  function arrayIntersect(a, b) {
    return a.filter(function (i) {
      return b.includes(i);
    });
  }

  function filterByValues(ary, field, values) {
    return ary.filter(function (obj) {
      return values.includes(obj.get(field));
    });
  }

  function download(url) {
    var id = arguments.length <= 1 || arguments[1] === undefined ? '__downloadIframe' : arguments[1];

    var iframe = document.getElementById(id);
    if (!iframe) {
      iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.id = id;
      document.body.appendChild(iframe);
    }

    iframe.src = url;
  }

  function popupWindowOptions(width, height) {
    var s = window.screen;
    var opt = {
      width: Math.min(s.width, width || 1040),
      height: Math.min(s.height, height || 768),
      resizable: 1,
      scrollbars: 1
    };

    opt.left = Math.max(0, (s.width - opt.width) / 2);
    opt.top = Math.max(0, (s.height - opt.height) / 2);

    var optStr = Object.keys(opt).map(function (k) {
      return k + '=' + opt[k];
    }).join(',');

    return optStr;
  }

  var entityMap = {
    "&": "&amp;",
    "<": "&lt;",
    ">": "&gt;",
    '"': '&quot;',
    "'": '&#39;',
    "/": '&#x2F;'
  };

  function escapeHtml(html) {
    return String(html).replace(/[&<>"'\/]/g, function (s) {
      return entityMap[s];
    });
  }

  function escapeRegex(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
  }

  function addQueryParam(url, key, val) {
    var out = url + (url.indexOf('?') >= 0 ? '&' : '?');

    // val can be a string or an array of strings
    if (!Array.isArray(val)) {
      val = [val];
    }
    out += val.map(function (v) {
      return encodeURIComponent(key) + '=' + encodeURIComponent(v);
    }).join('&');

    return out;
  }

  function addQueryParams(url, params) {
    if (params && typeof params === 'object') {
      Object.keys(params).forEach(function (key) {
        url = addQueryParam(url, key, params[key]);
      });
    }

    return url;
  }

  function parseUrl(url) {
    var a = document.createElement('a');
    a.href = url;
    return a.cloneNode(false);
  }

  function absoluteUrl(url) {
    return parseUrl(url).href;
  }

  function addAuthorization(url, user, pass) {
    url = absoluteUrl(url);
    var pos = url.indexOf('//');
    if (pos >= 0) {
      url = url.substr(0, pos + 2) + (user ? encodeURIComponent(user) : '') + (pass ? ':' + encodeURIComponent(pass) : '') + '@' + url.substr(pos + 2);
    } else {
      throw new Error("That doesn't look like a URL: " + url);
    }

    return url;
  }

  function ucFirst(str) {
    str = str || '';
    return str.substr(0, 1).toUpperCase() + str.substr(1);
  }

  function lcFirst(str) {
    str = str || '';
    return str.substr(0, 1).toLowerCase() + str.substr(1);
  }

  function strPad(str, toLength, padChars, right) {
    str = str + '';
    padChars = padChars || ' ';

    if (str.length >= toLength) {
      return str;
    }

    var neededLen = toLength - str.length + 1;
    var padStr = new Array(neededLen).join(padChars);
    padStr = padStr.substr(0, neededLen);

    if (right) {
      return str + padStr;
    } else {
      return padStr + str;
    }
  }

  function timerFuzz(ms) {
    var maxFuzz = arguments.length <= 1 || arguments[1] === undefined ? 0.1 : arguments[1];

    var factor = Math.random() * 2 * maxFuzz + (1 - maxFuzz);
    return Math.max(1, ms * factor);
  }

  function random32(count) {
    count = Math.max(0, count || 1);
    var out = [];
    var i;
    if (window.crypto && window.crypto.getRandomValues) {
      var tmp = new Uint32Array(count);
      window.crypto.getRandomValues(tmp);
      for (i = 0; i < tmp.length; i++) {
        out[i] = tmp[i];
      }
    } else {
      for (i = 0; i < count; i++) {
        out[i] = Math.random() * 4294967296; // Math.pow(2,32);
      }
    }

    if (count === 1) {
      return out[0];
    } else {
      return out;
    }
  }

  var alpha = 'abcdefghijklmnopqrstuvwxyz';
  var num = '0123456789';
  var sym = '!@#$%^&*()_+-=[]{};:,./<>?|';
  var randomCharsets = {
    numeric: num,
    loweralpha: alpha,
    upperalpha: alpha.toUpperCase(),
    hex: num + 'ABCDEF',
    alpha: alpha + alpha.toUpperCase(),
    alphanum: alpha + alpha.toUpperCase() + num,
    password: alpha + alpha.toUpperCase() + num + sym
  };

  function randomStr() {
    var length = arguments.length <= 0 || arguments[0] === undefined ? 16 : arguments[0];
    var charset = arguments.length <= 1 || arguments[1] === undefined ? 'alphanum' : arguments[1];

    var chars = randomCharsets[charset];
    if (!chars) {
      return null;
    }

    var charCount = chars.length;
    return random32(length).map(function (val) {
      return chars[val % charCount];
    }).join('');
  }

  function formatPercent(value) {
    if (value < 1) {
      return Math.round(value * 100) / 100 + '%';
    } else if (value < 10) {
      return Math.round(value * 10) / 10 + '%';
    } else {
      return Math.round(value) + '%';
    }
  }

  function formatMib(value) {
    return formatSi(value * 1024 * 1024, 1024, "iB", "B");
  }

  function formatKbps(value) {
    return formatSi(value * 1000, 1000, "bps", "Bps");
  }

  function formatSi(inValue) {
    var increment = arguments.length <= 1 || arguments[1] === undefined ? 1000 : arguments[1];
    var suffix = arguments.length <= 2 || arguments[2] === undefined ? "" : arguments[2];
    var firstSuffix = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];
    var startingExponent = arguments.length <= 4 || arguments[4] === undefined ? 0 : arguments[4];

    var units = ['B', 'K', 'M', 'G', 'T', 'P'];
    var val = inValue;
    var exp = startingExponent;
    while (val >= increment && exp + 1 < units.length) {
      val = val / increment;
      exp++;
    }

    var out = '';
    if (val < 10 && exp > 0) {
      out = Math.round(val * 100) / 100;
    } else if (val < 100 && exp > 0) {
      out = Math.round(val * 10) / 10;
    } else {
      out = Math.round(val);
    }

    if (exp === 0 && firstSuffix) {
      out += " " + firstSuffix;
    } else {
      out += " " + units[exp] + suffix;
    }

    return out;
  }

  function constructUrl(ssl, host, port) {
    var out = 'http' + (ssl ? 's' : '') + '://' + host;
    if (ssl && port !== 443 || !ssl && port !== 80) {
      out += ':' + port;
    }

    return out;
  }

  function pluralize(count, singular, plural) {
    if (!plural) {
      if (singular.substr(-1, 1) === 's') {
        plural = singular + 'es';
      } else {
        plural = singular + 's';
      }
    }

    if (count === 1) {
      return count + ' ' + singular;
    } else {
      return count + ' ' + plural;
    }
  }

  function uniqKeys(data) {
    var field = arguments.length <= 1 || arguments[1] === undefined ? undefined : arguments[1];

    // Make a map of all the unique category names.
    // If multiple casings of the same name are present, first wins.
    var cased = {};
    data.map(function (obj) {
      return field ? obj[field] : obj;
    }).filter(function (str) {
      return str && str.length;
    }).forEach(function (str) {
      var lc = str.toLowerCase();
      if (!cased[lc]) {
        cased[lc] = str;
      }
    });

    return Object.keys(cased).uniq().sort().map(function (str) {
      return cased[str];
    });
  }

  function camelToTitle(str) {
    return (str || '').dasherize().split('-').map(function (str) {
      return ucFirst(str);
    }).join(' ');
  }

  var Util = {
    arrayDiff: arrayDiff,
    arrayIntersect: arrayIntersect,
    constructUrl: constructUrl,
    download: download,
    popupWindowOptions: popupWindowOptions,
    escapeHtml: escapeHtml,
    escapeRegex: escapeRegex,
    filterByValues: filterByValues,
    addQueryParam: addQueryParam,
    addQueryParams: addQueryParams,
    parseUrl: parseUrl,
    absoluteUrl: absoluteUrl,
    addAuthorization: addAuthorization,
    ucFirst: ucFirst,
    lcFirst: lcFirst,
    strPad: strPad,
    timerFuzz: timerFuzz,
    random32: random32,
    randomStr: randomStr,
    formatPercent: formatPercent,
    formatMib: formatMib,
    formatKbps: formatKbps,
    formatSi: formatSi,
    pluralize: pluralize,
    camelToTitle: camelToTitle,
    uniqKeys: uniqKeys
  };

  window.Util = Util;
  exports['default'] = Util;
});