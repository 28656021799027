define('ui/components/copy-to-clipboard/component', ['exports', 'ember', 'ui/utils/platform'], function (exports, _ember, _uiUtilsPlatform) {

  var DELAY = 1000;
  var DEFAULT_TEXT = 'copyToClipboard.tooltip';

  exports['default'] = _ember['default'].Component.extend({
    tagName: 'div',
    classNames: ['copy-button-container', 'inline-block'],

    model: null,

    /*Component Params*/
    buttonText: null,
    tooltipText: null,
    status: null,
    size: null,
    target: null,
    clipboardText: null,
    textChangedEvent: null,

    mouseEnter: function mouseEnter() {
      this.set('model', new Object({ tooltipText: DEFAULT_TEXT }));
    },

    click: function click(evt) {
      this.set('textChangedEvent', _ember['default'].$(evt.currentTarget));
    },

    isSupported: (function () {
      return this.get('clipboardText.length') && (!_uiUtilsPlatform.isSafari || document.queryCommandSupported('copy'));
    }).property('clipboardText'),

    actions: {
      alertSuccess: function alertSuccess() {
        var _this = this;

        this.set('status', 'success');
        var orig = this.get('model.tooltipText');
        this.set('model', new Object({ tooltipText: 'copyToClipboard.copied' }));

        _ember['default'].run.later(function () {
          _this.set('status', null);
          _this.set('model', new Object({ tooltipText: orig }));
        }, DELAY);
      }
    },

    buttonClasses: _ember['default'].computed('status', function () {
      var status = this.get('status');
      var out = '';

      if (status) {
        out = 'btn btn-success';
      } else {
        out = 'btn btn-primary';
      }

      if (this.get('size')) {
        out = out + ' small btn-link';
      }

      return out;
    })
  });
});