define('ui/mixins/driver', ['exports', 'ember', 'ui/utils/util', 'ui/mixins/new-or-edit', 'ui/mixins/manage-labels', 'ui/utils/add-view-action'], function (exports, _ember, _uiUtilsUtil, _uiMixinsNewOrEdit, _uiMixinsManageLabels, _uiUtilsAddViewAction) {
  exports['default'] = _ember['default'].Mixin.create(_uiMixinsNewOrEdit['default'], _uiMixinsManageLabels['default'], {
    intl: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    createDelayMs: 0,
    showEngineUrl: true,

    queryParams: ['hostId'],
    hostId: null,
    error: null,

    count: null,
    prefix: null,
    multiTemplate: null,
    clonedModel: null,
    useHost: true,

    actions: {
      addLabel: (0, _uiUtilsAddViewAction.addAction)('addLabel', '.key'),
      cancel: function cancel() {
        this.attrs.cancel();
      },
      goBack: function goBack() {
        this.attrs.goBack();
      },
      setLabels: function setLabels(labels) {
        var out = {};
        labels.forEach(function (row) {
          out[row.key] = row.value;
        });

        this.set('primaryResource.labels', out);
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      this.set('error', null);
      this.set('editing', false);

      if (this.get('clonedModel')) {
        this.set('model', this.get('clonedModel'));
        this.set('prefix', '');
      } else if (typeof this.get('bootstrap') === 'function') {
        this.bootstrap();
      }
    },

    nameParts: (function () {
      var input = this.get('prefix') || '';
      var count = this.get('count');
      var match = input.match(/^(.*?)([0-9]+)$/);

      if (count <= 1) {
        return {
          name: input
        };
      }

      var prefix = undefined,
          minLength = undefined,
          start = undefined;
      if (match && match.length) {
        prefix = match[1];
        minLength = (match[2] + '').length;
        start = parseInt(match[2], 10);
      } else {
        prefix = input;
        minLength = 1;
        start = 1;
      }

      // app98 and count = 3 will go to 101, so the minLength should be 3
      var end = start + count - 1;
      minLength = Math.max(minLength, (end + '').length);

      return {
        prefix: prefix,
        minLength: minLength,
        start: start,
        end: end
      };
    }).property('prefix', 'count'),

    nameCountLabel: (function () {
      var parts = this.get('nameParts');
      if (typeof parts.name !== 'undefined' || !parts.prefix) {
        // qty=1 or no input yet, nothing to see here...
        return '';
      }

      var first = parts.prefix + _uiUtilsUtil['default'].strPad(parts.start, parts.minLength, '0');
      var last = parts.prefix + _uiUtilsUtil['default'].strPad(parts.end, parts.minLength, '0');
      return this.get('intl').tHtml('driver.multiHostNames', { first: first, last: last });
    }).property('nameParts', 'intl._locale'),

    nameDidChange: (function () {
      var parts = this.get('nameParts');
      var nameField = 'hostname';
      if (this.get('primaryResource.type') === 'machine') {
        nameField = 'name';
      }
      if (typeof parts.name !== 'undefined' || !parts.prefix) {
        this.set('primaryResource.' + nameField, parts.name || '');
      } else {
        var first = parts.prefix + _uiUtilsUtil['default'].strPad(parts.start, parts.minLength, '0');
        this.set('primaryResource.' + nameField, first);
      }
    }).observes('nameParts'),

    willSave: function willSave() {
      this.set('multiTemplate', this.get('primaryResource').clone());
      return this._super();
    },

    validate: function validate() {
      var errors = [];

      if (!this.get('nameParts.prefix') && !this.get('nameParts.name')) {
        errors.push('Name is required');
      }

      this.set('errors', errors);
      return errors.length === 0;
    },

    didSave: function didSave() {
      var _this = this;

      if (this.get('count') > 1) {
        var promise;

        var _ret = (function () {
          var parts = _this.get('nameParts');
          var tpl = _this.get('multiTemplate');
          var delay = _this.get('createDelayMs');
          promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
            var hosts = [];
            for (var i = parts.start + 1; i <= parts.end; i++) {
              var host = tpl.clone();
              host.set('hostname', parts.prefix + _uiUtilsUtil['default'].strPad(i, parts.minLength, '0'));
              hosts.push(host);
            }

            async.eachSeries(hosts, function (host, cb) {
              host.save().then(function () {
                setTimeout(cb, delay);
              })['catch'](function (err) {
                cb(err);
              });
            }, function (err) {
              if (err) {
                reject(err);
              } else {
                resolve();
              }
            });
          });

          return {
            v: promise
          };
        })();

        if (typeof _ret === 'object') return _ret.v;
      }
    },

    doneSaving: function doneSaving() {
      var out = this._super();
      this.send('goBack');
      return out;
    },

    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super();
      _ember['default'].run.next(function () {
        try {
          var input = _this2.$('INPUT')[0];
          if (input) {
            input.focus();
          }
        } catch (e) {}
      });
    }
  });
});