define('ui/components/machine/driver-other/component', ['exports', 'ember', 'ui/mixins/driver'], function (exports, _ember, _uiMixinsDriver) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsDriver['default'], {
    // Set by Driver
    driverName: 'other',
    driver: null,

    otherDriver: null,
    availableDrivers: null,
    typeDocumentations: null,
    schemas: null,
    driverOpts: null,

    init: function init() {
      this._super.apply(this, arguments);

      this._super();
      this.driverChanged();
    },

    bootstrap: function bootstrap() {
      var model = this.get('store').createRecord({
        type: 'host'
      });

      this.setProperties({
        otherDriver: this.get('otherChoices.firstObject.value'),
        model: model
      });
    },

    willDestroyElement: function willDestroyElement() {
      this.setProperties({
        otherDriver: null,
        driverOpts: null
      });
    },

    fieldNames: (function () {
      var driver = this.get('otherDriver');

      if (driver) {
        return Object.keys(this.get('userStore').getById('schema', driver.toLowerCase()).get('resourceFields'));
      }
    }).property('otherDriver', 'model'),

    driverChanged: (function () {
      var driver = this.get('otherDriver');
      var host = this.get('model');

      if (driver && host) {
        if (!host.get(driver)) {
          host.set(driver, this.get('store').createRecord({ type: driver }));
        }

        this.set('driverOpts', host.get(driver));
      } else {
        this.set('otherDriver', this.get('otherChoices.firstObject.value'));
      }
    }).observes('otherDriver', 'model'),

    otherChoices: (function () {
      var out = [];
      this.get('availableDrivers').filterBy('hasUi', false).forEach(function (driver) {
        out.push({ label: driver.name, value: driver.name + 'Config' });
      });

      return out;
    }).property('availableDrivers.@each.{hasUi,name}'),

    willSave: function willSave() {
      // Null out all the drivers that aren't the active one, because the API only accepts one.
      var activeDriver = this.get('otherDriver');
      var host = this.get('model');
      this.get('otherChoices').forEach(function (choice) {
        var cur = choice.value;
        if (choice.value !== activeDriver) {
          host.set(cur, null);
        }
      });

      return this._super();
    }
  });
});