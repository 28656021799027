define("ui/components/modal-feedback/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 13,
            "column": 0
          }
        },
        "moduleName": "ui/components/modal-feedback/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "r-pt10 r-pb40 r-pl40 r-pr40 welcome-copy");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        dom.setAttribute(el2, "class", "header");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("p");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "feedback-form");
        dom.setAttribute(el2, "class", "clearfix");
        dom.setAttribute(el2, "style", "min-height: 100px;");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "text-center r-p20");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "icon icon-spinner icon-spin");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "footer-actions r-pt20");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "btn btn-primary");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [7, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 1]), 2, 2);
        morphs[3] = dom.createAttrMorph(element1, 'disabled');
        morphs[4] = dom.createElementMorph(element1);
        morphs[5] = dom.createMorphAt(element1, 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["modalFeedback.header"], ["appName", ["subexpr", "@mut", [["get", "settings.appName", ["loc", [null, [2, 56], [2, 72]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 21], [2, 74]]], 0, 0], ["inline", "t", ["modalFeedback.subtitle"], [], ["loc", [null, [3, 5], [3, 35]]], 0, 0], ["inline", "t", ["generic.loading"], [], ["loc", [null, [6, 76], [6, 99]]], 0, 0], ["attribute", "disabled", ["get", "loading", ["loc", [null, [10, 67], [10, 74]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["submit"], [], ["loc", [null, [10, 12], [10, 31]]], 0, 0], ["inline", "t", ["modalFeedback.send"], [], ["loc", [null, [10, 77], [10, 103]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});