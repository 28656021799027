define('ui/helpers/format-ip', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Helper.extend({
    intl: _ember['default'].inject.service(),

    compute: function compute(params, options) {
      var noIp = options.noIp || 'formatIp.noIp';
      var ip = params[0];

      if (ip === '0:0:0:0:0:0:0:1') {
        ip = '::1';
      }

      if (ip) {
        return ip;
      } else {
        return ('<span class="text-muted">' + this.get('intl').t(noIp) + '</span>').htmlSafe();
      }
    }
  });
});