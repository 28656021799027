define('ui/k8s-tab/dashboard/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    k8s: _ember['default'].inject.service(),

    model: function model() {
      return _ember['default'].Object.create({
        dashboardUrl: this.get('k8s.kubernetesDashboard')
      });
    }
  });
});