define('ui/registries/new/controller', ['exports', 'ember', 'ui/mixins/new-or-edit'], function (exports, _ember, _uiMixinsNewOrEdit) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsNewOrEdit['default'], {
    editing: false,
    primaryResource: _ember['default'].computed.alias('model.registry'),

    activeDriver: null,
    isCustom: _ember['default'].computed.equal('activeDriver', 'custom'),

    actions: {
      selectDriver: function selectDriver(name) {
        var driver = this.get('drivers').filterBy('name', name)[0];
        this.set('activeDriver', driver.name);
        this.set('model.registry.serverAddress', driver.value);
      },

      cancel: function cancel() {
        this.transitionToRoute('registries');
      }
    },

    drivers: (function () {
      var drivers = [{ name: 'dockerhub', label: 'DockerHub', css: 'dockerhub', value: 'index.docker.io' }, { name: 'quay', label: 'Quay.io', css: 'quay', value: 'quay.io' }, { name: 'custom', label: 'Custom', css: 'custom', value: '' }];

      var active = this.get('activeDriver');
      drivers.forEach(function (driver) {
        driver.active = active === driver.name;
      });

      return drivers;
    }).property('activeDriver'),

    cleanAddress: function cleanAddress() {
      var cur = this.get('model.registry.serverAddress') || '';
      var neu = cur.replace(/^http(s)?:\/\/(.*)$/, '$2');
      neu = neu.replace(/\/.*$/, '');

      if (cur !== neu) {
        this.set('model.registry.serverAddress', neu);
      }
    },

    validate: function validate() {
      this.cleanAddress();

      this._super();

      var errors = this.get('errors') || [];

      var registry = this.get('model.registry');
      var existing = this.get('model.allRegistries').filterBy('serverAddress', registry.get('serverAddress'))[0];
      if (existing) {
        errors.push('There is already a registry defined for ' + existing.get('displayAddress'));
      }

      var cred = this.get('model.credential');
      cred.set('registryId', 'tbd');

      errors.pushObjects(cred.validationErrors());

      if (errors.get('length') > 0) {
        this.set('errors', errors.uniq());
        return false;
      }

      return true;
    },

    didSave: function didSave() {
      var registry = this.get('model.registry');
      var cred = this.get('model.credential');

      var existing = registry.get('credentials.lastObject');
      if (existing) {
        existing.merge(cred);
        return existing.save();
      } else {
        cred.set('registryId', registry.get('id'));
        return cred.save();
      }
    },

    doneSaving: function doneSaving() {
      this.transitionToRoute('registries');
    }
  });
});