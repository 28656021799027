define('ui/components/form-secrets/component', ['exports', 'ember'], function (exports, _ember) {

  var DEFAULT_UID = '0';
  var DEFAULT_GID = '0';
  var DEFAULT_MODE = '444';

  exports['default'] = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),
    secrets: null,
    showPermissions: false,

    init: function init() {
      this._super.apply(this, arguments);
      var secrets = this.get('secrets');
      if (!secrets) {
        secrets = [];
        this.set('secrets', secrets);
      }

      for (var i = 0; i < secrets.get('length'); i++) {
        var secret = secrets.objectAt(i);
        var uid = secret.get('uid');
        var gid = secret.get('gid');
        var mode = secret.get('mode');
        if (uid && uid !== DEFAULT_UID || gid && gid !== DEFAULT_GID || mode && mode !== DEFAULT_MODE) {
          this.set('showPermissions', true);
          break;
        }
      }
    },

    actions: {
      addSecret: function addSecret() {
        this.get('secrets').pushObject(this.get('store').createRecord({
          type: 'secretReference',
          uid: this.get('secrets.lastObject.uid') || DEFAULT_UID,
          gid: this.get('secrets.lastObject.gid') || DEFAULT_GID,
          mode: this.get('secrets.lastObject.mode') || DEFAULT_MODE,
          name: '',
          secretId: null
        }));
      },

      removeSecret: function removeSecret(secret) {
        this.get('secrets').removeObject(secret);
      },

      showPermissions: function showPermissions() {
        this.set('showPermissions', true);
      }
    }
  });
});