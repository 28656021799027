define('ui/helpers/date-str', ['exports', 'ember'], function (exports, _ember) {
  exports.dateStr = dateStr;

  function dateStr(params, options) {
    var format = 'MMM DD, YYYY hh:mm:ss A';
    if (options && options.format) {
      format = options.format;
    }

    return moment(params[0]).format(format);
  }

  exports['default'] = _ember['default'].Helper.helper(dateStr);
});