define('ui/utils/platform', ['exports'], function (exports) {
  exports.isAlternate = isAlternate;
  exports.isMore = isMore;
  exports.isRange = isRange;
  exports.version = version;
  var platform = (navigator.platform || '').toLowerCase();
  exports.platform = platform;
  var isLinuxy = platform.indexOf('linux') >= 0 || platform.indexOf('unix') >= 0;
  exports.isLinuxy = isLinuxy;
  var isMac = platform.indexOf('mac') >= 0;
  exports.isMac = isMac;
  var isWin = platform.indexOf('win') >= 0;

  exports.isWin = isWin;
  var alternateKey = 'ctrlKey';
  exports.alternateKey = alternateKey;
  var alternateLabel = 'Control';

  exports.alternateLabel = alternateLabel;
  var moreKey = 'ctrlKey';
  exports.moreKey = moreKey;
  var moreLabel = 'Control';

  exports.moreLabel = moreLabel;
  var rangeKey = 'shiftKey';
  exports.rangeKey = rangeKey;
  var rangeLabel = 'Shift';

  exports.rangeLabel = rangeLabel;
  if (isMac) {
    exports.alternateKey = alternateKey = 'metaKey';
    exports.alternateLabel = alternateLabel = 'Command';
    exports.moreKey = moreKey = 'metaKey';
    exports.moreLabel = moreLabel = 'Command';
  }

  function isAlternate(event) {
    return !!event[alternateKey];
  }

  function isMore(event) {
    return !!event[moreKey];
  }

  function isRange(event) {
    return !!event[rangeKey];
  }

  // Only intended to work for Mobile Safari at the moment...

  function version() {
    var match = userAgent.match(/\s+Version\/([0-9.]+)/);
    if (match) {
      return parseFloat(match[1]);
    }

    return null;
  }

  var userAgent = navigator.userAgent;
  exports.userAgent = userAgent;
  var isGecko = userAgent.indexOf('Gecko/') >= 0;
  exports.isGecko = isGecko;
  var isBlink = userAgent.indexOf('Chrome/') >= 0;
  exports.isBlink = isBlink;
  var isWebKit = !isBlink && userAgent.indexOf('AppleWebKit/') >= 0;
  exports.isWebKit = isWebKit;
  var isSafari = !isBlink && userAgent.indexOf('Safari/') >= 0;
  exports.isSafari = isSafari;
  var isMobile = /Android|webOS|iPhone|iPad|iPod|IEMobile/i.test(userAgent);

  exports.isMobile = isMobile;
  var xhrConcur = 99;
  exports.xhrConcur = xhrConcur;
  if (isSafari) {
    if (version() && version() < 10) {
      // Safari for iOS9 has problems with multiple simultaneous requests
      exports.xhrConcur = xhrConcur = 1;
    }
  }
});