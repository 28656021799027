define('ui/applications-tab/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      if (this.controllerFor('authenticated').get('hasKubernetes')) {
        this.transitionTo('k8s-tab');
      } else if (this.controllerFor('authenticated').get('hasSwarm')) {
        this.transitionTo('swarm-tab.projects');
      } else {
        this.transitionTo('stacks');
      }
    }
  });
});