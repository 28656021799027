define('ui/models/serviceupgrade', ['exports', 'ember-api-store/models/resource'], function (exports, _emberApiStoreModelsResource) {
  exports['default'] = _emberApiStoreModelsResource['default'].extend({
    serviceSelectorStr: (function () {
      var all = this.get('serviceSelector') || [];
      return Object.keys(all).map(function (key) {
        var val = all[key];
        return key + (val ? '=' + val : '');
      }).join(', ');
    }).property('serviceSelector')
  });
});