define("ui/components/orchestration/wait-steps/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 7,
                "column": 8
              },
              "end": {
                "line": 9,
                "column": 8
              }
            },
            "moduleName": "ui/components/orchestration/wait-steps/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.setAttribute(el1, "class", "icon icon-spinner icon-spin");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 10
                },
                "end": {
                  "line": 12,
                  "column": 10
                }
              },
              "moduleName": "ui/components/orchestration/wait-steps/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon icon-check text-success");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 12,
                  "column": 10
                },
                "end": {
                  "line": 14,
                  "column": 10
                }
              },
              "moduleName": "ui/components/orchestration/wait-steps/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "class", "icon icon-circle-o");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 8
              },
              "end": {
                "line": 15,
                "column": 8
              }
            },
            "moduleName": "ui/components/orchestration/wait-steps/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "gt", [["get", "currentStep", ["loc", [null, [10, 20], [10, 31]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [10, 32], [10, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 16], [10, 38]]], 0, 0]], [], 0, 1, ["loc", [null, [10, 10], [14, 17]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 20
              },
              "end": {
                "line": 18,
                "column": 132
              }
            },
            "moduleName": "ui/components/orchestration/wait-steps/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["waitOrchestration.count"], ["sub", ["subexpr", "@mut", [["get", "subStep", ["loc", [null, [18, 108], [18, 115]]], 0, 0, 0, 0]], [], [], 0, 0], "count", ["subexpr", "@mut", [["get", "subCount", ["loc", [null, [18, 122], [18, 130]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [18, 74], [18, 132]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 21,
              "column": 4
            }
          },
          "moduleName": "ui/components/orchestration/wait-steps/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("span");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(element0, 1, 1);
          morphs[2] = dom.createMorphAt(element1, 1, 1);
          morphs[3] = dom.createMorphAt(element1, 2, 2);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "lt", [["get", "currentStep", ["loc", [null, [6, 26], [6, 37]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [6, 38], [6, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 22], [6, 44]]], 0, 0], "text-muted"], [], ["loc", [null, [6, 17], [6, 59]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "currentStep", ["loc", [null, [7, 18], [7, 29]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [7, 30], [7, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [7, 14], [7, 36]]], 0, 0]], [], 0, 1, ["loc", [null, [7, 8], [15, 15]]]], ["inline", "t", [["get", "step", ["loc", [null, [18, 14], [18, 18]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 10], [18, 20]]], 0, 0], ["block", "if", [["subexpr", "and", [["get", "subStep", ["loc", [null, [18, 31], [18, 38]]], 0, 0, 0, 0], ["get", "subCount", ["loc", [null, [18, 39], [18, 47]]], 0, 0, 0, 0], ["subexpr", "eq", [["get", "currentStep", ["loc", [null, [18, 52], [18, 63]]], 0, 0, 0, 0], ["get", "index", ["loc", [null, [18, 64], [18, 69]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 48], [18, 70]]], 0, 0]], [], ["loc", [null, [18, 26], [18, 71]]], 0, 0]], [], 2, null, ["loc", [null, [18, 20], [18, 139]]]]],
        locals: ["step", "index"],
        templates: [child0, child1, child2]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "ui/components/orchestration/wait-steps/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "well r-mt40 text");
        dom.setAttribute(el1, "style", "width: 50%; margin-left: 25%");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        dom.setAttribute(el2, "class", "loading");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "class", "list-unstyled r-mt20");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
        return morphs;
      },
      statements: [["inline", "t", [["get", "label", ["loc", [null, [2, 26], [2, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 22], [2, 33]]], 0, 0], ["block", "each", [["get", "steps", ["loc", [null, [5, 12], [5, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 4], [21, 13]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});