define('ui/components/container-logs/component', ['exports', 'ember', 'ui/mixins/throttled-resize', 'ui/utils/util', 'ui/utils/platform', 'npm:ansi_up'], function (exports, _ember, _uiMixinsThrottledResize, _uiUtilsUtil, _uiUtilsPlatform, _npmAnsi_up) {

  var typeClass = {
    0: 'log-combined',
    1: 'log-stdout',
    2: 'log-stderr'
  };

  exports['default'] = _ember['default'].Component.extend(_uiMixinsThrottledResize['default'], {
    instance: null,
    alternateLabel: _uiUtilsPlatform.alternateLabel,
    showProtip: true,

    status: 'connecting',
    socket: null,

    logHeight: 300,

    onlyCombinedLog: _ember['default'].computed.alias('instance.tty'),
    which: 'combined',
    isCombined: _ember['default'].computed.equal('which', 'combined'),
    isStdOut: _ember['default'].computed.equal('which', 'stdout'),
    isStdErr: _ember['default'].computed.equal('which', 'stderr'),

    stdErrVisible: true,
    stdOutVisible: true,

    actions: {

      cancel: function cancel() {
        this.disconnect();
        this.sendAction('dismiss');
      },

      clear: function clear() {
        var body = this.$('.log-body')[0];
        body.innerHTML = '';
        body.scrollTop = 0;
      },

      scrollToTop: function scrollToTop() {
        this.$('.log-body').animate({ scrollTop: '0px' });
      },

      scrollToBottom: function scrollToBottom() {
        var body = this.$('.log-body');
        body.stop().animate({ scrollTop: body[0].scrollHeight + 1000 + 'px' });
      },

      changeShow: function changeShow(which) {
        this.set('which', which);
        this.set('stdErrVisible', which === 'combined' || which === 'stderr');
        this.set('stdOutVisible', which === 'combined' || which === 'stdout');
        _ember['default'].run.next(this, function () {
          this.send('scrollToBottom');
        });
      }
    },

    didInsertElement: function didInsertElement() {
      this._super();
      _ember['default'].run.next(this, 'exec');
    },

    exec: function exec() {
      var _this = this;

      var instance = this.get('instance');
      var opt = {
        follow: true,
        lines: 500
      };

      instance.doAction('logs', opt).then(function (logs) {
        logs.set('instance', instance);
        _this.connect(logs);
      });
    },

    connect: function connect(logs) {
      var _this2 = this;

      var url = logs.get('url') + '?token=' + encodeURIComponent(logs.get('token'));
      var socket = new WebSocket(url);
      this.set('socket', socket);

      var body = this.$('.log-body')[0];
      var $body = $(body);

      this.set('status', 'initializing');

      socket.onopen = function () {
        _this2.set('status', 'connected');
      };

      socket.onmessage = function (message) {
        _this2.set('status', 'connected');

        var isFollow = $body.scrollTop() + $body.outerHeight() + 10 >= body.scrollHeight;

        //var framingVersion = message.data.substr(0,1); -- Always 0
        var type = parseInt(message.data.substr(1, 1), 10); // 0 = combined, 1 = stdout, 2 = stderr

        message.data.substr(2).trim().split(/\n/).forEach(function (line) {
          var match = line.match(/^\[?([^ \]]+)\]?\s?/);
          var dateStr, msg;
          if (match) {
            msg = line.substr(match[0].length);
            var date = new Date(match[1]);
            dateStr = '<span class="log-date">' + _uiUtilsUtil['default'].escapeHtml(date.toLocaleDateString()) + ' ' + _uiUtilsUtil['default'].escapeHtml(date.toLocaleTimeString()) + '</span>';
          } else {
            msg = line;
            dateStr = '<span class="log-date">Unknown Date</span>';
          }

          body.insertAdjacentHTML('beforeend', '<div class="log-msg ' + typeClass[type] + '">' + dateStr + _npmAnsi_up['default'].ansi_to_html(_uiUtilsUtil['default'].escapeHtml(msg)) + '</div>');
        });

        if (isFollow) {
          _ember['default'].run.next(function () {
            _this2.send('scrollToBottom');
          });
        }
      };

      socket.onclose = function () {
        if (_this2.isDestroyed || _this2.isDestroying) {
          return;
        }

        _this2.set('status', 'disconnected');
      };
    },

    disconnect: function disconnect() {
      this.set('status', 'closed');

      var socket = this.get('socket');
      if (socket) {
        socket.close();
        this.set('socket', null);
      }
    },

    onResize: function onResize() {
      this.$('.log-body').css('height', Math.max(200, $(window).height() - this.get('logHeight')) + 'px');
    },

    willDestroyElement: function willDestroyElement() {
      this.disconnect();
      this._super();
    }
  });
});