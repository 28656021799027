define("ui/components/orchestration/wait-mesos/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "ui/components/orchestration/wait-mesos/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "orchestration/wait-steps", [], ["steps", ["subexpr", "@mut", [["get", "steps", ["loc", [null, [2, 8], [2, 13]]], 0, 0, 0, 0]], [], [], 0, 0], "currentStep", ["subexpr", "@mut", [["get", "currentStep", ["loc", [null, [3, 14], [3, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "subStep", ["subexpr", "@mut", [["get", "subStep", ["loc", [null, [4, 10], [4, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "subCount", ["subexpr", "@mut", [["get", "subCount", ["loc", [null, [5, 11], [5, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "label", "waitingPage.hosts.setup.mesos"], ["loc", [null, [1, 0], [6, 41]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});