define("ui/components/form-disks/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 22,
                "column": 4
              }
            },
            "moduleName": "ui/components/form-disks/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "disk-row", [], ["disk", ["subexpr", "@mut", [["get", "disk", ["loc", [null, [21, 22], [21, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "driverChoices", ["subexpr", "@mut", [["get", "driverChoices", ["loc", [null, [21, 41], [21, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "remove", ["subexpr", "action", ["removeDisk", ["get", "disk", ["loc", [null, [21, 83], [21, 87]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 62], [21, 88]]], 0, 0]], ["loc", [null, [21, 6], [21, 90]]], 0, 0]],
          locals: ["disk"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 6,
              "column": 0
            },
            "end": {
              "line": 24,
              "column": 0
            }
          },
          "moduleName": "ui/components/form-disks/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1, "class", "table fixed no-lines no-top-padding tight");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tr");
          dom.setAttribute(el2, "class", "text-muted");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("*");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "width", "30");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "width", "30");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "width", "100");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "width", "30");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "width", "100");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "width", "30");
          var el4 = dom.createTextNode(" ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "width", "130");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("th");
          dom.setAttribute(el3, "width", "50");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element1, [5]), 0, 0);
          morphs[2] = dom.createMorphAt(dom.childAt(element1, [9]), 0, 0);
          morphs[3] = dom.createMorphAt(dom.childAt(element1, [13]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [17]), 0, 0);
          morphs[5] = dom.createMorphAt(element0, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["formDisks.name.label"], [], ["loc", [null, [9, 10], [9, 38]]], 0, 0], ["inline", "t", ["formDisks.size.label"], [], ["loc", [null, [11, 10], [11, 38]]], 0, 0], ["inline", "t", ["formDisks.readIops.label"], [], ["loc", [null, [13, 22], [13, 54]]], 0, 0], ["inline", "t", ["formDisks.writeIops.label"], [], ["loc", [null, [15, 22], [15, 55]]], 0, 0], ["inline", "t", ["formDisks.driver.label"], [], ["loc", [null, [17, 22], [17, 52]]], 0, 0], ["block", "each", [["get", "disksArray", ["loc", [null, [20, 12], [20, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [20, 4], [22, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 25,
            "column": 0
          }
        },
        "moduleName": "ui/components/form-disks/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-control-static");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn-circle-text");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "icon icon-plus-circle");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn-circle-text r-ml20");
        var el3 = dom.createElement("i");
        dom.setAttribute(el3, "class", "icon icon-plus-circle");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("span");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [0]);
        var element3 = dom.childAt(element2, [1]);
        var element4 = dom.childAt(element2, [3]);
        var morphs = new Array(6);
        morphs[0] = dom.createElementMorph(element3);
        morphs[1] = dom.createMorphAt(dom.childAt(element3, [2]), 0, 0);
        morphs[2] = dom.createAttrMorph(element4, 'disabled');
        morphs[3] = dom.createElementMorph(element4);
        morphs[4] = dom.createMorphAt(dom.childAt(element4, [2]), 0, 0);
        morphs[5] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["element", "action", ["addDisk"], [], ["loc", [null, [2, 34], [2, 54]]], 0, 0], ["inline", "t", ["formDisks.addDiskLabel"], [], ["loc", [null, [2, 96], [2, 126]]], 0, 0], ["attribute", "disabled", ["get", "hasRoot", ["loc", [null, [3, 77], [3, 84]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["addRootDisk"], [], ["loc", [null, [3, 41], [3, 65]]], 0, 0], ["inline", "t", ["formDisks.addRootDiskLabel"], [], ["loc", [null, [3, 128], [3, 162]]], 0, 0], ["block", "if", [["get", "disksArray.length", ["loc", [null, [6, 6], [6, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [6, 0], [24, 7]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});