define('ui/components/common-mark/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({

    markdown: null,

    cmReader: new commonmark.Parser(),
    cmWriter: new commonmark.HtmlRenderer(),

    parsedMarkdown: (function () {

      var parsed = this.cmReader.parse(this.get('markdown'));

      return this.cmWriter.render(parsed);
    }).property('markdown')
  });
});