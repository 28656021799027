define('ui/models/apikey', ['exports', 'ember', 'ember-api-store/models/resource', 'ui/mixins/cattle-polled-resource', 'ui/utils/constants'], function (exports, _ember, _emberApiStoreModelsResource, _uiMixinsCattlePolledResource, _uiUtilsConstants) {

  var ApiKey = _emberApiStoreModelsResource['default'].extend(_uiMixinsCattlePolledResource['default'], {

    type: 'apiKey',
    publicValue: null,
    secretValue: null,
    modalService: _ember['default'].inject.service('modal'),

    actions: {
      deactivate: function deactivate() {
        return this.doAction('deactivate');
      },

      activate: function activate() {
        return this.doAction('activate');
      },

      edit: function edit() {
        this.get('modalService').toggleModal('edit-apikey', this);
      }
    },

    isForAccount: (function () {
      return this.get('accountId') === this.get('session.' + _uiUtilsConstants['default'].SESSION.ACCOUNT_ID);
    }).property('accountId', 'session.' + _uiUtilsConstants['default'].SESSION.ACCOUNT_ID),

    displayName: (function () {
      return this.get('name') || this.get('publicValue') || '(' + this.get('id') + ')';
    }).property('name', 'publicValue', 'id'),

    availableActions: (function () {
      var a = this.get('actionLinks');

      return [{ label: 'action.activate', icon: 'icon icon-play', action: 'activate', enabled: !!a.activate }, { label: 'action.deactivate', icon: 'icon icon-pause', action: 'deactivate', enabled: !!a.deactivate }, { label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: !!a.remove, altAction: 'delete' }, { divider: true }, { label: 'action.purge', icon: '', action: 'purge', enabled: !!a.purge }, { label: 'action.restore', icon: '', action: 'restore', enabled: !!a.restore }, { divider: true }, { label: 'action.edit', icon: 'icon icon-edit', action: 'edit', enabled: !!a.update }];
    }).property('actionLinks.{update,activate,deactivate,restore,remove,purge}')
  });

  ApiKey.reopenClass({
    pollTransitioningDelay: 1000,
    pollTransitioningInterval: 5000
  });

  exports['default'] = ApiKey;
});