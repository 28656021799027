define('ui/components/container-row/component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend({
    projects: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),

    model: null,
    showCommand: 'column', // 'no', 'column', or 'inline'
    showStats: false,
    cpuMax: null,
    memoryMax: null,
    storageMax: null,
    networkMax: null,
    showPrimaryActions: true,
    tagName: '',

    statsAvailable: (function () {
      return _uiUtilsConstants['default'].ACTIVEISH_STATES.indexOf(this.get('model.state')) >= 0 && this.get('model.healthState') !== 'started-once';
    }).property('model.{state,healthState}'),

    detailRoute: (function () {
      if (this.get('model.isVm')) {
        return 'virtualmachine';
      } else {
        return 'container';
      }
    }).property('model.isVm')
  });
});