define('ui/authenticated/project/api/hooks/edit-receiver/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    webhookStore: _ember['default'].inject.service(),

    model: function model(params) {
      return this.get('webhookStore').find('receiver', params.receiver_id).then(function (receiver) {
        return _ember['default'].Object.create({
          receiver: receiver
        });
      });
    },

    resetController: function resetController(controller, isExisting /*, transition*/) {
      if (isExisting) {
        controller.set('errors', null);
      }
    }
  });
});