define('ui/initializers/extend-jquery', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() /*application */{
    // Define some more easings and inject into jQuery
    jQuery.extend(jQuery.easing, {
      easeOutBack: function easeOutBack(x, t, b, c, d, s) {
        if (s === undefined) {
          s = 1.70158;
        }
        return c * ((t = t / d - 1) * t * ((s + 1) * t + s) + 1) + b;
      },

      easeOutCubic: function easeOutCubic(x, t, b, c, d) {
        return c * ((t = t / d - 1) * t * t + 1) + b;
      }
    });
  }

  exports['default'] = {
    name: 'extend-jquery',
    initialize: initialize
  };
});