define('ui/admin-tab/audit-logs/controller', ['exports', 'ember', 'ui/mixins/sortable', 'ui/utils/constants'], function (exports, _ember, _uiMixinsSortable, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], {
    application: _ember['default'].inject.controller(),
    queryParams: ['sortBy', 'sortOrder', 'eventType', 'resourceType', 'resourceId', 'clientIp', 'authType'],

    sortableContent: _ember['default'].computed.alias('model.auditLog'),
    resourceTypeAndId: null,
    modalService: _ember['default'].inject.service('modal'),

    actions: {
      updateResourceType: function updateResourceType(type) {
        this.set('filters.resourceType', type);
      },

      updateAuthType: function updateAuthType(type) {
        this.set('filters.authType', type.name);
        this.set('authTypeReadable', type.value);
      },

      changeSort: function changeSort(name) {
        this._super(name);
      },

      search: function search() {
        this.setProperties({
          eventType: this.get('filters.eventType'),
          resourceType: this.get('filters.resourceType'),
          resourceId: this.get('filters.resourceId'),
          clientIp: this.get('filters.clientIp'),
          authType: this.get('filters.authType')
        });
        this.send('filterLogs');
      },

      showResponseObjects: function showResponseObjects(request, response) {
        this.get('modalService').toggleModal('modal-auditlog-info', {
          requestObject: request,
          responseObject: response
        });
      },

      clearAll: function clearAll() {
        this.set('filters', {
          accountId: null,
          authType: null,
          authenticatedAsAccountId: null,
          authenticatedAsIdentityId: null,
          created: null,
          clientIp: null,
          description: null,
          eventType: null,
          id: null,
          kind: null,
          resourceId: null,
          resourceType: null,
          runtime: null
        });

        this.setProperties({
          eventType: null,
          resourceType: null,
          resourceId: null,
          clientIp: null,
          authType: null
        });

        this.setProperties({
          sortBy: 'id',
          sortOrder: 'desc'
        });
        this.set('authTypeReadable', null);
        this.send('filterLogs');
      }
    },

    sortBy: 'id',
    sortOrder: 'desc',
    descending: true,
    limit: 100,
    eventType: null,
    resourceType: null,
    resourceId: null,
    clientIp: null,
    authType: null,
    authTypes: null,
    authTypeReadable: null,
    filters: {
      accountId: null,
      authType: null,
      authenticatedAsAccountId: null,
      authenticatedAsIdentityId: null,
      created: null,
      description: null,
      eventType: null,
      clientIp: null,
      id: null,
      kind: null,
      resourceId: null,
      resourceType: null,
      runtime: null
    },

    setup: (function () {
      var out = [];

      Object.keys(_uiUtilsConstants['default'].AUTH_TYPES).forEach(function (key) {
        var val = _uiUtilsConstants['default'].AUTH_TYPES[key];
        if (val !== _uiUtilsConstants['default'].AUTH_TYPES.HeaderAuth && val !== _uiUtilsConstants['default'].AUTH_TYPES.TokenAccount) {
          out.push({ name: key, value: val });
        }
      });

      this.set('authTypes', out);
    }).on('init'),

    setSortOrderObserver: (function () {
      var out = 'asc';

      if (this.get('descending')) {
        out = 'desc';
      }

      this.set('sortOrder', out);
      this.send('logsSorted');
    }).observes('descending'),

    resourceIdReady: (function () {
      if (this.get('filters.resourceType')) {
        return false;
      } else {
        return true;
      }
    }).property('filters.resourceType'),

    showPagination: (function () {
      var pagination = this.get('model.auditLog.pagination');

      if (pagination.next) {
        return true;
      } else {
        return false;
      }
    }).property('model.auditLog.pagination'),

    // implemented here cause we're using sortable kinda but not really. Basically want the
    // actions but not the implmentation
    arranged: function arranged() {}

  });
});