define('ui/mixins/manage-labels', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/debounce'], function (exports, _ember, _uiUtilsConstants, _uiUtilsDebounce) {
  exports.flattenLabelArrays = flattenLabelArrays;

  var USER = 'user';
  var SYSTEM = 'system';
  var AFFINITY = 'affinity';

  function flattenLabelArrays() {
    var out = {};

    function flatten(row) {
      if (row.value === undefined) {
        delete out[row.key];
      } else {
        out[row.key] = row.value;
      }
    }

    for (var _len = arguments.length, lists = Array(_len), _key = 0; _key < _len; _key++) {
      lists[_key] = arguments[_key];
    }

    for (var i = 0; i < lists.length; i++) {
      (lists[i] || []).forEach(flatten);
    }

    return out;
  }

  function isSoftUser(type, key) {
    // Include actual user labels
    if (type === USER) {
      return true;
    }

    // Don't include any affinity labels
    if (type === AFFINITY) {
      return false;
    }

    // Don't include any system labels that are blacklisted (because they have their own controls, like global)
    if (_uiUtilsConstants['default'].SYSTEM_LABELS_WITH_CONTROL.indexOf(key) >= 0) {
      return false;
    }

    // Include anything else
    return true;
  }

  exports['default'] = _ember['default'].Mixin.create({
    labelArray: null,

    actions: {
      addUserLabel: function addUserLabel() {
        this.get('labelArray').pushObject(_ember['default'].Object.create({
          type: USER,
          key: '',
          value: ''
        }));
      },

      addSystemLabel: function addSystemLabel() {
        this.get('labelArray').pushObject(_ember['default'].Object.create({
          type: SYSTEM,
          key: '',
          value: ''
        }));
      },

      addAffinityLabel: function addAffinityLabel() {
        this.get('labelArray').pushObject(_ember['default'].Object.create({
          type: AFFINITY,
          key: _uiUtilsConstants['default'].LABEL.SCHED_HOST_LABEL,
          value: ''
        }));
      },

      removeLabel: function removeLabel(obj) {
        this.get('labelArray').removeObject(obj);
      },

      pastedLabels: function pastedLabels(str, target) {
        var ary = this.get('labelArray');
        str = str.trim();
        if (str.indexOf('=') === -1 && str.indexOf(':') === -1) {
          // Just pasting a key
          $(target).val(str);
          return;
        }

        var lines = str.split(/\r?\n/);
        lines.forEach(function (line) {
          line = line.trim();
          if (!line) {
            return;
          }

          var idx = line.indexOf('=');
          if (idx === -1) {
            idx = line.indexOf(':');
          }

          var key = '';
          var val = '';
          if (idx > 0) {
            key = line.substr(0, idx).trim();
            val = line.substr(idx + 1).trim();
          } else {
            key = line.trim();
            val = '';
          }

          var existing = ary.filterBy('key', key)[0];
          if (existing) {
            _ember['default'].set(existing, 'value', val);
          } else {
            ary.pushObject(_ember['default'].Object.create({ key: key, value: val, type: USER }));
          }
        });

        // Clean up empty user entries
        var toRemove = [];
        ary.filterBy('type', USER).forEach(function (item) {
          if (!item.get('key') && !item.get('value')) {
            toRemove.push(item);
          }
        });
        ary.removeObjects(toRemove);
      }
    },

    // User labels are actual user ones, plus system ones that have no controls in the UI so they are manually entered.
    userLabelArray: (function () {
      return (this.get('labelArray') || []).filter(function (item) {
        return isSoftUser(item.get('type'), item.get('key'));
      });
    }).property('labelArray.@each.type'),

    strictUserLabelArray: (function () {
      return (this.get('labelArray') || []).filterBy('type', USER);
    }).property('labelArray.@each.type'),

    systemLabelArray: (function () {
      return (this.get('labelArray') || []).filterBy('type', SYSTEM);
    }).property('labelArray.@each.type'),

    affinityLabelArray: (function () {
      return (this.get('labelArray') || []).filterBy('type', AFFINITY);
    }).property('labelArray.@each.type'),

    getLabelObj: function getLabelObj(key) {
      var lcKey = (key || '').toLowerCase();
      var ary = this.get('labelArray');
      var item = undefined;

      // Try specific case first
      for (var _i = 0; _i < ary.get('length'); _i++) {
        item = ary.objectAt(_i);
        if (item.get('key') === key) {
          return item;
        }
      }

      // Then case-insensitive
      for (var i = 0; i < ary.get('length'); i++) {
        item = ary.objectAt(i);
        if (item.get('key').toLowerCase() === lcKey) {
          return item;
        }
      }

      return null;
    },

    getLabel: function getLabel(key) {
      var obj = this.getLabelObj(key);
      if (obj) {
        return obj.get('value');
      }

      return null;
    },

    setLabel: function setLabel(key, value) {
      var lcKey = (key || '').toLowerCase();
      var type = 'user';

      // Rancher keys are always lowercase
      if (lcKey.indexOf(_uiUtilsConstants['default'].LABEL.AFFINITY_PREFIX) === 0) {
        type = 'affinity';
        key = lcKey;
      } else if (lcKey.indexOf(_uiUtilsConstants['default'].LABEL.SYSTEM_PREFIX) === 0) {
        type = 'system';
        key = lcKey;
      }

      var existing = this.getLabelObj(key);
      if (existing) {
        _ember['default'].setProperties(existing, {
          value: value,
          type: type
        });
      } else {
        existing = this.get('labelArray').pushObject(_ember['default'].Object.create({
          key: key,
          value: value,
          type: type
        }));
      }

      return existing;
    },

    removeLabel: function removeLabel(key) {
      var soft = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      if (soft) {
        this.setLabel(key, undefined);
      } else {
        var existing = this.getLabelObj(key);
        if (existing) {
          this.get('labelArray').removeObject(existing);
        }
      }
    },

    initLabels: function initLabels(obj, onlyOfType, onlyKeys, readonlyKeys) {
      var out = [];

      if (onlyKeys && !_ember['default'].isArray(onlyKeys)) {
        onlyKeys = [onlyKeys];
      }

      if (readonlyKeys && !_ember['default'].isArray(readonlyKeys)) {
        readonlyKeys = [readonlyKeys];
      }

      Object.keys(obj || {}).forEach(function (key) {
        var type = 'user';
        if (key.indexOf(_uiUtilsConstants['default'].LABEL.AFFINITY_PREFIX) === 0) {
          type = 'affinity';
        } else if (key.indexOf(_uiUtilsConstants['default'].LABEL.SYSTEM_PREFIX) === 0) {
          type = 'system';
        }

        if (_uiUtilsConstants['default'].LABELS_TO_IGNORE.indexOf(key) >= 0) {
          // Skip ignored labels
          return;
        }

        if (onlyOfType) {
          // Strict User, only those with type actually == user
          if (onlyOfType === 'strictUser' && type !== 'user') {
            return;
          }

          if (onlyOfType === 'user') {
            // Soft user := user + system things that don't have UI controls
            if (!isSoftUser(type, key)) {
              return;
            }
          } else if (onlyOfType !== type) {
            // Generally the wrong type, for system or affinity
            return;
          }
        }

        if (onlyKeys && onlyKeys.indexOf(key) === -1) {
          // Skip labels of keys we don't care about
          return;
        }

        if (type === AFFINITY && obj[key] && obj[key].indexOf(',') > -1) {
          obj[key].split(',').forEach(function (s) {
            out.push(_ember['default'].Object.create({
              key: key,
              value: s || '',
              type: type,
              readonly: readonlyKeys && readonlyKeys.indexOf(key) >= 0
            }));
          });
        } else {
          out.push(_ember['default'].Object.create({
            key: key,
            value: obj[key] || '',
            type: type,
            readonly: readonlyKeys && readonlyKeys.indexOf(key) >= 0
          }));
        }
      });

      this.set('labelArray', out);
      this.labelsChanged();
    },

    labelsChanged: (0, _uiUtilsDebounce.debouncedObserver)('labelArray.@each.{type,key,value}', function () {
      // Make a map of the keys we care about, and combine multiple values together
      var map = {};
      (this.get('labelArray') || []).forEach(function (row) {
        var key = row.get('key') || '';
        var type = row.get('type') || '';

        // System and Affinity labels are always lowercase.
        if (type !== USER) {
          key = key.toLowerCase();
        }

        // Pass undefined through, for soft-delete
        if (row.get('value') === undefined) {
          map[key] = undefined;
          return;
        }

        var value = row.get('value') || '';

        // Skip empty keys, and system/affinity labels with no value
        if (!key || type !== USER && value === '') {
          return;
        }

        // System and Affinity values used to be always lowercase.
        //if ( type !== USER )
        //{
        //  value = value.toLowerCase();
        //}

        // Affinity & System labels can be concatenated, Users just overwrite the previous value.
        if (map[key] && type !== USER) {
          map[key] = map[key] + ',' + value;
        } else {
          map[key] = value;
        }
      });

      // Then turn them back into an array because Ember hates maps.
      var out = [];
      Object.keys(map).forEach(function (key) {
        out.push({ key: key, value: map[key] });
      });

      this.updateLabels(out);
    }),

    updateLabels: function updateLabels() /*labels*/{
      // Override me to do something
    }
  });
});