define('ui/host/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    application: _ember['default'].inject.controller(),
    host: _ember['default'].computed.alias('model.host'),

    actions: {
      changeHost: function changeHost(host) {
        this.get('application').transitionToRoute('host', host.get('id'));
      }
    }
  });
});