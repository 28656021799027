define('ui/components/container-dot/component', ['exports', 'ember', 'ui/utils/platform'], function (exports, _ember, _uiUtilsPlatform) {
  exports['default'] = _ember['default'].Component.extend({
    resourceActions: _ember['default'].inject.service('resource-actions'),
    tooltipService: _ember['default'].inject.service('tooltip'),
    model: null,
    tagName: 'span',
    type: 'tooltip-action-menu',
    template: null,

    click: function click(event) {
      this.details(event);
      this.get('tooltipService').hide();
    },

    details: function details() /*event*/{

      var route = 'container';
      if (this.get('model.isVm')) {
        route = 'virtualmachine';
      }

      this.get('router').transitionTo(route, this.get('model.id'));
    },

    contextMenu: function contextMenu(event) {
      if ((0, _uiUtilsPlatform.isMore)(event)) {
        return;
      }

      event.preventDefault();

      if (this.get('type') === 'tooltip-action-menu') {

        this.get('resourceActions').set('open', true);
        this.get('tooltipService').set('openedViaContextClick', true);
        _ember['default'].$('.container-tooltip .more-actions').trigger('click');
      } else {

        this.get('resourceActions').show(this.get('model'), this.$());
      }
    },

    resourceActionsObserver: _ember['default'].observer('resourceActions.open', function () {

      if (this.get('tooltipService.openedViaContextClick')) {

        this.get('tooltipService').set('openedViaContextClick', false);
      }
    }).on('init')
  });
});