define('ui/settings/projects/detail/route', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/platform', 'ui/mixins/promise-to-cb'], function (exports, _ember, _uiUtilsConstants, _uiUtilsPlatform, _uiMixinsPromiseToCb) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _ember['default'].Route.extend(_uiMixinsPromiseToCb['default'], {
    queryParams: {
      editing: {
        refreshModel: true
      }
    },

    model: function model(params /* , transition*/) {
      var _this = this;

      var userStore = this.get('userStore');

      var policyManagerOpt = {
        headers: _defineProperty({}, _uiUtilsConstants['default'].HEADER.PROJECT_ID, params.project_id),
        filter: {
          name: _uiUtilsConstants['default'].CAPABILITY.NETWORK_POLICIES
        }
      };

      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var tasks = {
          allProjects: _this.toCb(function () {
            return userStore.findAll('project');
          }),
          project: ['allProjects', _this.toCb(function () {
            return userStore.find('project', params.project_id);
          })],
          importMembers: ['project', _this.toCb(function (results) {
            return results.project.importLink('projectMembers');
          })],
          networks: _this.toCb(function () {
            return userStore.find('network', null, { filter: { accountId: params.project_id } });
          }),
          policyManagers: _this.toCb(function () {
            return userStore.find('stack', null, policyManagerOpt);
          })
        };

        async.auto(tasks, _uiUtilsPlatform.xhrConcur, function (err, res) {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      }, 'Load all the things');

      return promise.then(function (hash) {
        var network = hash.networks.find(function (x) {
          return _uiUtilsConstants['default'].PROJECT.SUPPORTS_NETWORK_POLICY.includes(x.get('name'));
        });
        if (network) {
          (function () {
            network = network.clone();

            if (!network.get('defaultPolicyAction')) {
              network.set('defaultPolicyAction', 'allow');
            }

            var policy = network.get('policy');
            if (!policy) {
              policy = [];
              network.set('policy', policy);
            }

            // Create default allow policies
            var fields = ['linked', 'service', 'stack'];
            fields.forEach(function (field) {
              var rule = policy.findBy('within', field);
              if (!rule) {
                policy.pushObject(userStore.createRecord({
                  type: 'networkPolicyRule',
                  within: field,
                  action: network.get('defaultPolicyAction')
                }));
              }
            });
          })();
        }

        var out = _ember['default'].Object.create({
          all: hash.allProjects,
          network: network,
          policyManager: hash.policyManagers.objectAt(0)
        });

        if (params.editing) {
          out.setProperties({
            originalProject: hash.project,
            project: hash.project.clone()
          });
        } else {
          out.setProperties({
            originalProject: null,
            project: hash.project
          });
        }

        return out;
      });
    }
  });
});