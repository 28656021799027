define('ui/models/virtualmachine', ['exports', 'ember', 'ui/models/container'], function (exports, _ember, _uiModelsContainer) {

  var VirtualMachine = _uiModelsContainer['default'].extend({
    modalService: _ember['default'].inject.service('modal'),
    actions: {
      console: function console() {
        this.get('modalService').toggleModal('modal-console', this);
      },

      clone: function clone() {
        this.get('router').transitionTo('virtualmachines.new', { queryParams: { virtualMachineId: this.get('id') } });
      },

      popoutShellVm: function popoutShellVm() {
        var proj = this.get('projects.current.id');
        var id = this.get('id');
        _ember['default'].run.later(function () {
          window.open('//' + window.location.host + '/env/' + proj + '/infra/console-vm?instanceId=' + id + '&isPopup=true', '_blank', "toolbars=0,width=845,height=585,left=200,top=200");
        });
      },

      popoutLogs: function popoutLogs() {
        var proj = this.get('projects.current.id');
        var id = this.get('id');
        _ember['default'].run.later(function () {
          window.open('//' + window.location.host + '/env/' + proj + '/infra/vm-log?instanceId=' + id + '&isPopup=true', '_blank', "toolbars=0,width=700,height=715,left=200,top=200");
        });
      }
    }
  });

  VirtualMachine.reopenClass({
    mangleIn: function mangleIn(data) {
      // VM's baseType is container, but store doesn't handle
      // virtualMachine -> container -> instance
      data.baseType = 'instance';
      return data;
    }
  });

  exports['default'] = VirtualMachine;
});