define("ui/components/svg-edge/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "ui/components/svg-edge/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("path");
        dom.setAttribute(el1, "class", "edge-path");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createAttrMorph(element0, 'd');
        morphs[1] = dom.createAttrMorph(element0, 'style');
        return morphs;
      },
      statements: [["attribute", "d", ["concat", [["get", "draw", ["loc", [null, [1, 11], [1, 15]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "style", ["concat", ["marker-start: url(", ["get", "markerPath", ["loc", [null, [1, 64], [1, 74]]], 0, 0, 0, 0], "#markerSquare);marker-end: url(", ["get", "markerPath", ["loc", [null, [1, 109], [1, 119]]], 0, 0, 0, 0], "#markerArrow);"], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  })());
});