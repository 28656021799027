define('ui/virtualmachine/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var store = this.get('store');

      return store.find('virtualmachine', params.virtualmachine_id).then(function (vm) {
        return _ember['default'].Object.create({
          vm: vm
        });
      });
    }
  });
});