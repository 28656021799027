define('ui/host/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var _this = this;

      return this.get('store').findAll('host').then(function (all) {
        return _this.get('store').find('host', params.host_id).then(function (host) {
          return _ember['default'].Object.create({
            all: all,
            host: host
          });
        });
      });
    }
  });
});