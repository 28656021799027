define('ui/backuptargets/new-target/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.get('store').createRecord({
        type: 'backupTarget',
        name: '',
        description: null,
        nfsConfig: {
          server: null,
          share: null,
          mountOptions: null
        }
      });
    }
  });
});