define('ui/helpers/date-recent', ['exports', 'ember'], function (exports, _ember) {
  exports.dateCalendar = dateCalendar;

  function dateCalendar(params) {
    var date = moment(params[0]);
    var now = moment();
    var diff = now.diff(date);
    if (Math.abs(diff) > 44 * 60 * 1000) {
      return date.calendar();
    } else if (diff > 0) {
      return date.fromNow();
    } else {
      return date.toNow();
    }
  }

  exports['default'] = _ember['default'].Helper.helper(dateCalendar);
});