define('ui/host/storage/controller', ['exports', 'ember', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], {
    nonRootVolumes: (function () {
      return this.get('model').filter(function (volume) {
        return !volume.get('instanceId') && volume.get('state') !== 'purged';
      });
    }).property('model.@each.{instanceId,state}'),

    sortableContent: _ember['default'].computed.alias('nonRootVolumes'),
    sortBy: 'name',
    sorts: {
      state: ['stateSort', 'displayUri', 'id'],
      hostPath: ['displayUri', 'id']
    }
  });
});