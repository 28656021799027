define('ui/initializers/extend-ember-input', ['exports', 'ember', 'ui/mixins/safe-style'], function (exports, _ember, _uiMixinsSafeStyle) {
  exports.initialize = initialize;

  function initialize() /*application */{
    // Allow style to be bound on inputs
    _ember['default'].TextField.reopen(_uiMixinsSafeStyle['default']);
    _ember['default'].TextArea.reopen(_uiMixinsSafeStyle['default']);
    _ember['default'].Checkbox.reopen(_uiMixinsSafeStyle['default']);

    // Disable iOS auto-capitalization
    _ember['default'].TextField.reopen({
      attributeBindings: ['autocapitalize'],
      autocapitalize: 'none'
    });
  }

  exports['default'] = {
    name: 'extend-ember-input',
    initialize: initialize
  };
});