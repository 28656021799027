define('ui/stacks/new/route', ['exports', 'ember', 'ember-api-store/utils/ajax-promise'], function (exports, _ember, _emberApiStoreUtilsAjaxPromise) {

  var DOCKER = 'docker-compose.yml';
  var RANCHER = 'rancher-compose.yml';

  function encodeRepo(str) {
    return str.split('/').map(function (substr) {
      return encodeURIComponent(substr);
    }).join('/');
  }

  function githubUrl(repo, branch, file) {
    return 'https://raw.githubusercontent.com/' + encodeRepo(repo) + '/' + encodeURIComponent(branch || 'master') + '/' + encodeURIComponent(file);
  }

  exports['default'] = _ember['default'].Route.extend({
    model: function model(params /*, transition*/) {
      var stack = this.get('store').createRecord({
        type: 'stack',
        startOnCreate: true
      });

      var dockerUrl = null;
      var rancherUrl = null;

      if (params.githubRepo) {
        // Load compose files from GitHub
        dockerUrl = githubUrl(params.githubRepo, params.githubBranch, DOCKER);
        rancherUrl = githubUrl(params.githubRepo, params.githubBranch, RANCHER);
      } else if (params.composeFiles) {
        // Load compose files from arbitrary base URL
        var base = params.composeFiles.replace(/\/+$/, '');
        dockerUrl = base + '/' + DOCKER;
        rancherUrl = base + '/' + RANCHER;
      }

      if (dockerUrl && rancherUrl) {
        return _ember['default'].RSVP.hashSettled({
          docker: (0, _emberApiStoreUtilsAjaxPromise.ajaxPromise)({ url: dockerUrl, dataType: 'text' }, true),
          rancher: (0, _emberApiStoreUtilsAjaxPromise.ajaxPromise)({ url: rancherUrl, dataType: 'text' }, true)
        }).then(function (hash) {
          if (hash.docker.state === 'fulfilled') {
            stack.set('dockerCompose', hash.docker.value);
          }

          if (hash.rancher.state === 'fulfilled') {
            stack.set('rancherCompose', hash.rancher.value);
          }

          return stack;
        });
      } else {
        return stack;
      }
    },

    setupController: function setupController(controller, model) {
      controller.set('originalModel', null);
      controller.set('model', model);
      controller.initFields();
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.setProperties({
          githubRepo: null,
          githubBranch: null,
          composeFiles: null,
          system: false
        });
      }
    },

    actions: {
      cancel: function cancel() {
        this.transitionTo('stacks');
      }
    }
  });
});