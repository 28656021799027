define('ui/components/stack-section/component', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/parse-externalid'], function (exports, _ember, _uiUtilsConstants, _uiUtilsParseExternalid) {
  exports['default'] = _ember['default'].Component.extend({
    prefs: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    hasVm: _ember['default'].computed.alias('projects.current.virtualMachine'),

    model: null,
    single: false,
    showAddService: true,

    collapsed: true,
    classNames: ['stack-section'],

    sortedServices: _ember['default'].computed.sort('model.services', 'sortBy'),
    sortBy: ['name', 'id'],

    actions: {
      toggleCollapse: function toggleCollapse() {
        var collapsed = this.toggleProperty('collapsed');
        var list = this.get('prefs.' + _uiUtilsConstants['default'].PREFS.EXPANDED_STACKS) || [];
        var id = this.get('model.id');
        if (collapsed) {
          list.removeObject(id);
        } else if (!list.includes(id)) {
          // Add at the front
          list.unshift(id);
        }

        // Cut off the back to keep the list reasonable
        if (list.length > 100) {
          list.length = 100;
        }

        this.get('prefs').set(_uiUtilsConstants['default'].PREFS.EXPANDED_STACKS, list);
      },

      addtlInfo: function addtlInfo(service) {
        this.sendAction('showAddtlInfo', service);
      },

      upgradeImage: function upgradeImage(service) {
        service.send('upgrade', 'true');
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      var list = this.get('prefs.' + _uiUtilsConstants['default'].PREFS.EXPANDED_STACKS) || [];
      if (list.indexOf(this.get('model.id')) >= 0) {
        this.set('collapsed', false);
      }
    },

    isKubernetes: (function () {
      var parts = (0, _uiUtilsParseExternalid.parseExternalId)(this.get('model.externalId'));
      return parts && parts.kind === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_KUBERNETES;
    }).property('model.externalId'),

    instanceCount: (function () {
      var count = 0;
      (this.get('model.services') || []).forEach(function (service) {
        count += service.get('instanceCount') || 0;
      });

      return count;
    }).property('model.services.@each.instanceCount'),

    outputs: (function () {
      var out = [];
      var map = this.get('model.outputs') || {};
      Object.keys(map).forEach(function (key) {
        out.push(_ember['default'].Object.create({
          key: key,
          value: map[key]
        }));
      });

      return out;
    }).property('model.outputs', 'model.id')
  });
});