define("ui/settings/projects/edit-template/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 7,
            "column": 0
          }
        },
        "moduleName": "ui/settings/projects/edit-template/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "edit-projecttemplate", [], ["originalProjectTemplate", ["subexpr", "@mut", [["get", "model.originalProjectTemplate", ["loc", [null, [2, 26], [2, 55]]], 0, 0, 0, 0]], [], [], 0, 0], "projectTemplate", ["subexpr", "@mut", [["get", "model.projectTemplate", ["loc", [null, [3, 18], [3, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "catalogInfo", ["subexpr", "@mut", [["get", "model.catalogInfo", ["loc", [null, [4, 14], [4, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "serviceChoices", ["subexpr", "@mut", [["get", "model.serviceChoices", ["loc", [null, [5, 17], [5, 37]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 0], [6, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});