define('ui/components/form-user-labels/component', ['exports', 'ember', 'ui/mixins/manage-labels'], function (exports, _ember, _uiMixinsManageLabels) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsManageLabels['default'], {
    // Inputs
    initialLabels: null,
    readonlyLabels: null,

    actions: {
      addUserLabel: function addUserLabel() {
        var _this = this;

        this._super();
        _ember['default'].run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.$('INPUT.key').last()[0].focus();
        });
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      this.initLabels(this.get('initialLabels'), 'user', null, this.get('readonlyLabels'));
      this.labelsChanged();
    },

    updateLabels: function updateLabels(labels) {
      this.sendAction('setLabels', labels);
    }
  });
});