define('ui/settings/projects/detail/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    model: null,
    editing: false,
    tab: 'access',

    queryParams: ['editing', 'tab'],

    actions: {
      done: function done() {
        this.transitionToRoute('settings.projects');
      },

      cancel: function cancel() {
        this.transitionToRoute('settings.projects');
      }
    }
  });
});