define('ui/certificates/new/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() /*params, transition*/{
      return this.get('store').createRecord({
        type: 'certificate'
      });
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('errors', null);
      }
    }
  });
});