define('ui/components/action-menu/component', ['exports', 'ember', 'ui/utils/platform'], function (exports, _ember, _uiUtilsPlatform) {
  exports['default'] = _ember['default'].Component.extend({
    model: null,
    size: 'xs',
    showPrimary: true,
    inTooltip: false,

    resourceActions: _ember['default'].inject.service('resource-actions'),

    tagName: 'div',
    classNames: ['btn-group', 'resource-actions', 'action-menu'],
    tooltipService: _ember['default'].inject.service('tooltip'),

    primaryAction: _ember['default'].computed.alias('model.primaryAction'),

    click: function click(e) {
      var tgt = _ember['default'].$(e.target);
      var more = tgt.closest('.more-actions');
      if (more && more.length) {
        e.preventDefault();
        e.stopPropagation();

        if (this.get('inTooltip')) {
          this.get('resourceActions').set('tooltipActions', true);
        } else {
          this.get('resourceActions').set('tooltipActions', false);
        }

        this.get('resourceActions').show(this.get('model'), more, this.$());
      } else {
        var idx = parseInt(tgt.closest('BUTTON').data('primary'), 10);
        if (!isNaN(idx)) {
          var action = this.get('model.primaryAction');
          if (action) {
            e.preventDefault();
            e.stopPropagation();

            if ((0, _uiUtilsPlatform.isAlternate)(e) && _ember['default'].get(action, 'altAction')) {
              this.sendToModel(_ember['default'].get(action, 'altAction'));
            } else {
              this.sendToModel(_ember['default'].get(action, 'action'));
            }
          }
        }
      }
    },

    sendToModel: function sendToModel(action) {
      this.get('tooltipService').leave();
      this.get('model').send(action);
    }
  });
});