define('ui/components/process-executions/component', ['exports', 'ember', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsSortable['default'], {
    expandAll: false,

    actions: {
      expandChildren: function expandChildren() {
        var _this = this;

        _ember['default'].run.next(function () {
          _this.toggleProperty('expandAll');
        });
      }
    }
  });
});