define('ui/stacks/new/controller', ['exports', 'ember', 'ui/mixins/new-or-edit', 'ui/utils/constants', 'ui/models/stack'], function (exports, _ember, _uiMixinsNewOrEdit, _uiUtilsConstants, _uiModelsStack) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsNewOrEdit['default'], {
    queryParams: ['githubRepo', 'githubBranch', 'composeFiles', 'system'],
    githubRepo: null,
    githubBranch: null,
    composeFiles: null,
    system: false,

    error: null,
    editing: false,

    allStacks: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('allStacks', this.get('store').all('stack'));
    },

    actions: {
      addTag: function addTag(tag) {
        var neu = (0, _uiModelsStack.tagsToArray)(this.get('model.group'));
        neu.addObject(tag);
        this.set('model.group', neu.join(', '));
      }
    },

    groupChoices: (function () {
      return (0, _uiModelsStack.tagChoices)(this.get('allStacks')).sort();
    }).property('allStacks.@each.grouping'),

    willSave: function willSave() {
      var out = this._super.apply(this, arguments);
      var externalId = '';
      if (this.get('system')) {
        externalId = _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SYSTEM + _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SEPARATOR + 'user';
      }

      this.set('primaryResource.externalId', externalId);
      return out;
    },

    doneSaving: function doneSaving() {
      return this.transitionToRoute('stack', this.get('primaryResource.id'));
    }
  });
});