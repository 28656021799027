define('ui/initializers/extend-ember-link', ['exports', 'ember'], function (exports, _ember) {
  exports.initialize = initialize;

  function initialize() /*application */{
    _ember['default'].LinkComponent.reopen({
      attributeBindings: ['tooltip', 'data-placement'],

      // Set activeParent=true on a {{link-to}} to automatically propagate the active
      // class to the parent element (like <li>{{link-to}}</li>)
      activeParent: false,

      addActiveObserver: (function () {
        if (this.get('activeParent')) {
          this.addObserver('active', this, 'activeChanged');
          this.activeChanged();
        }
      }).on('didInsertElement'),

      activeChanged: function activeChanged() {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        this.$().parent().toggleClass('active', this.get('active'));
      }
    });
  }

  exports['default'] = {
    name: 'extend-ember-link',
    initialize: initialize
  };
});