define('ui/components/new-catalog/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'npm:shell-quote', 'ui/utils/constants', 'ui/utils/util', 'ui/utils/parse-version'], function (exports, _ember, _uiMixinsNewOrEdit, _npmShellQuote, _uiUtilsConstants, _uiUtilsUtil, _uiUtilsParseVersion) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], {
    intl: _ember['default'].inject.service(),
    catalog: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    allTemplates: null,
    templateResource: null,
    stackResource: null,
    versionsArray: null,
    versionsLinks: null,
    actuallySave: true,
    showHeader: true,
    showPreview: true,
    showName: true,
    titleAdd: 'newCatalog.titleAdd',
    titleUpgrade: 'newCatalog.titleUpgrade',
    selectVersionAdd: 'newCatalog.selectVersionAdd',
    selectVersionUpgrade: 'newCatalog.selectVersionUpgrade',
    saveUpgrade: 'newCatalog.saveUpgrade',
    saveNew: 'newCatalog.saveNew',
    sectionClass: 'well',
    showDefaultVersionOption: false,

    classNames: ['launch-catalog'],

    primaryResource: _ember['default'].computed.alias('stackResource'),
    templateBase: _ember['default'].computed.alias('templateResource.templateBase'),
    editing: _ember['default'].computed.notEmpty('stackResource.id'),

    previewOpen: false,
    previewTab: null,
    questionsArray: null,
    selectedTemplateUrl: null,
    selectedTemplateModel: null,
    readmeContent: null,

    actions: {
      cancel: function cancel() {
        this.sendAction('cancel');
      },

      togglePreview: function togglePreview() {
        this.toggleProperty('previewOpen');
      },

      selectPreviewTab: function selectPreviewTab(tab) {
        this.set('previewTab', tab);
      },

      changeTemplate: function changeTemplate(tpl) {
        this.set('selectedTemplateUrl', null);
        this.get('application').transitionToRoute('catalog-tab.launch', tpl.id);
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('selectedTemplateModel', null);

      _ember['default'].run.scheduleOnce('afterRender', function () {
        if (_this.get('selectedTemplateUrl')) {
          _this.templateChanged();
        } else {
          var def = _this.get('templateResource.defaultVersion');
          var links = _this.get('versionLinks');
          if (links[def]) {
            _this.set('selectedTemplateUrl', links[def]);
          } else {
            _this.set('selectedTemplateUrl', null);
          }
        }
      });
    },

    updateReadme: function updateReadme() {
      var _this2 = this;

      var model = this.get('selectedTemplateModel');
      this.set('readmeContent', null);
      if (model && model.hasLink('readme')) {
        this.set('loadingReadme', true);
        model.followLink('readme').then(function (response) {
          _this2.set('readmeContent', response);
        })['finally'](function () {
          _this2.set('loadingReadme', false);
        });
      }
    },

    showDescription: _ember['default'].computed('loading', 'loadingReadme', function () {
      return !this.get('loading') && !this.get('loadingReadme');
    }),

    sortedVersions: (function () {
      var out = this.get('versionsArray').sort(function (a, b) {
        return (0, _uiUtilsParseVersion.compare)(a.version, b.version);
      });

      var def = this.get('templateResource.defaultVersion');
      if (this.get('showDefaultVersionOption') && this.get('defaultUrl')) {
        out.unshift({ version: this.get('intl').t('newCatalog.version.default', { version: def }), link: 'default' });
      }

      return out;
    }).property('versionsArray', 'templateResource.defaultVersion'),

    defaultUrl: (function () {
      var defaultVersion = this.get('templateResource.defaultVersion');
      var versionLinks = this.get('versionLinks');

      if (defaultVersion && versionLinks && versionLinks[defaultVersion]) {
        return versionLinks[defaultVersion];
      }

      return null;
    }).property('templateResource.defaultVersion', 'versionLinks'),

    templateChanged: (function () {
      var _this3 = this;

      var url = this.get('selectedTemplateUrl');

      if (url === 'default') {
        var defaultUrl = this.get('defaultUrl');
        if (defaultUrl) {
          url = defaultUrl;
        } else {
          url = null;
        }
      }

      if (url) {
        this.set('loading', true);

        var version = this.get('settings.rancherVersion');
        if (version) {
          url = _uiUtilsUtil['default'].addQueryParam(url, 'rancherVersion', version);
        }

        var current = this.get('stackResource.environment');
        if (!current) {
          current = {};
          this.set('stackResource.environment', current);
        }

        this.get('catalog').fetchByUrl(url).then(function (response) {
          if (response.questions) {
            response.questions.forEach(function (item) {
              // This will be the component that is rendered to edit this answer
              item.inputComponent = 'schema/input-' + item.type;

              // Only types marked supported will show the component, Ember will explode if the component doesn't exist
              item.supported = _uiUtilsConstants['default'].SUPPORTED_SCHEMA_INPUTS.indexOf(item.type) >= 0;

              if (typeof current[item.variable] !== 'undefined') {
                // If there's an existing value, use it (for upgrade)
                item.answer = current[item.variable];
              } else if (item.type === 'service' || item.type === 'certificate') {
                // Loaded async and then the component picks the default
              } else if (item.type === 'boolean') {
                  // Coerce booleans
                  item.answer = item['default'] === 'true' || item['default'] === true;
                } else {
                  // Everything else
                  item.answer = item['default'];
                }
            });
          }

          _this3.set('selectedTemplateModel', response);
          _this3.set('previewTab', Object.keys(response.get('files') || [])[0]);
          _this3.updateReadme();
          _this3.set('loading', false);
        }, function () /*error*/{});
      } else {
        this.set('selectedTemplateModel', null);
        this.updateReadme();
        this.set('readmeContent', null);
      }
    }).observes('selectedTemplateUrl', 'templateResource.defaultVersion'),

    answers: (function () {
      var out = {};
      (this.get('selectedTemplateModel.questions') || []).forEach(function (item) {
        out[item.variable] = item.answer;
      });

      return out;
    }).property('selectedTemplateModel.questions.@each.{variable,answer}'),

    answersArray: _ember['default'].computed.alias('selectedTemplateModel.questions'),

    answersString: (function () {
      return (this.get('answersArray') || []).map(function (obj) {
        if (obj.answer === null || obj.answer === undefined) {
          return obj.variable + '=';
        } else {
          return obj.variable + '=' + _npmShellQuote['default'].quote([obj.answer]);
        }
      }).join("\n");
    }).property('answersArray.@each.{variable,answer}'),

    validate: function validate() {
      var errors = [];

      if (!this.get('editing') && !this.get('stackResource.name')) {
        errors.push('Name is required');
      }

      if (this.get('selectedTemplateModel.questions')) {
        this.get('selectedTemplateModel.questions').forEach(function (item) {
          if (item.required && item.type !== 'boolean' && !item.answer) {
            errors.push(item.label + ' is required');
          }
        });
      }

      if (errors.length) {
        this.set('errors', errors.uniq());
        return false;
      }

      return true;
    },

    newExternalId: (function () {
      return _uiUtilsConstants['default'].EXTERNAL_ID.KIND_CATALOG + _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SEPARATOR + this.get('selectedTemplateModel.id');
    }).property('selectedTemplateModel.id'),

    willSave: function willSave() {
      this.set('errors', null);
      var ok = this.validate();
      if (!ok) {
        // Validation failed
        return false;
      }

      var files = this.get('selectedTemplateModel.files');
      var stack = this.get('stackResource');

      if (this.get('actuallySave')) {
        stack.setProperties({
          dockerCompose: files['docker-compose.yml.tpl'] || files['docker-compose.yml'],
          rancherCompose: files['rancher-compose.yml.tpl'] || files['rancher-compose.yml'],
          environment: this.get('answers'),
          externalId: this.get('newExternalId')
        });

        return true;
      } else {
        var versionId = null;
        if (this.get('selectedTemplateUrl') !== 'default' && this.get('selectedTemplateModel')) {
          versionId = this.get('selectedTemplateModel.id');
        }

        this.sendAction('doSave', {
          templateId: this.get('templateResource.id'),
          templateVersionId: versionId,
          answers: this.get('answers')
        });
        return false;
      }
    },

    doSave: function doSave() {
      var stack = this.get('stackResource');
      if (this.get('editing')) {
        return stack.doAction('upgrade', {
          dockerCompose: stack.get('dockerCompose'),
          rancherCompose: stack.get('rancherCompose'),
          environment: stack.get('environment'),
          externalId: this.get('newExternalId')
        });
      } else {
        return this._super.apply(this, arguments);
      }
    },

    doneSaving: function doneSaving() {
      var projectId = this.get('projects.current.id');
      if (this.get('stackResource.system')) {
        return this.get('router').transitionTo('stack', projectId, this.get('primaryResource.id'), { queryParams: { which: 'infra' } });
      } else {
        return this.get('router').transitionTo('stack', projectId, this.get('primaryResource.id'));
      }
    }
  });
});