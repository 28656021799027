define('ui/authenticated/project/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    access: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),

    model: function model(params /*, transition*/) {
      var project = this.get('projects.current');

      if (!project) {
        this.replaceWith('settings.projects');
        return;
      }

      // If the project ID in the URL is out of sync somehow, bail & try again
      if (project.get('id') !== params.project_id) {
        this.replaceWith('authenticated');
        return;
      }

      return _ember['default'].Object.create({
        project: project
      });
    },

    loadingError: function loadingError(err, transition, ret) {
      if (err && err.status && [401, 403].indexOf(err.status) >= 0) {
        this.send('logout', transition, true);
        return;
      }

      this.transitionTo('authenticated');
      return ret;
    }
  });
});