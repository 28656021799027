define('ui/services/store-reset', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),
    userStore: _ember['default'].inject.service('user-store'),
    webhookStore: _ember['default'].inject.service('webhook-store'),
    catalog: _ember['default'].inject.service(),

    reset: function reset() {
      // Forget all the things
      console.log('Store Reset');
      this.get('userStore').reset();
      this.get('store').reset();
      this.get('catalog').reset();
      this.get('webhookStore').reset();
    }
  });
});