define("ui/service/new/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "ui/service/new/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "new-container", [], ["editing", false, "isService", true, "isUpgrade", ["subexpr", "@mut", [["get", "upgrade", ["loc", [null, [4, 12], [4, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "isSidekick", false, "launchConfig", ["subexpr", "@mut", [["get", "model.service.launchConfig", ["loc", [null, [6, 15], [6, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "service", ["subexpr", "@mut", [["get", "model.service", ["loc", [null, [7, 10], [7, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "primaryResource", ["subexpr", "@mut", [["get", "model.service", ["loc", [null, [8, 18], [8, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "primaryService", ["subexpr", "@mut", [["get", "model.service", ["loc", [null, [9, 17], [9, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "allHosts", ["subexpr", "@mut", [["get", "model.allHosts", ["loc", [null, [10, 11], [10, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "done", ["subexpr", "action", ["done"], [], ["loc", [null, [11, 7], [11, 22]]], 0, 0], "cancel", ["subexpr", "action", ["cancel"], [], ["loc", [null, [12, 9], [12, 26]]], 0, 0]], ["loc", [null, [1, 0], [13, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});