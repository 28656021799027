define('ui/utils/parse-catalog-setting', ['exports', 'ui/utils/constants'], function (exports, _uiUtilsConstants) {
  exports.parseCatalogSetting = parseCatalogSetting;
  exports.getCatalogNames = getCatalogNames;
  exports.getCatalogSubtree = getCatalogSubtree;

  // New JSON format: {"catalogs": {"foo": {"url":"...", "branch": "master"}, ...}}

  function parseCatalogSetting(str) {
    var out = {
      catalogs: {}
    };

    str = (str || '').trim();
    if (!str) {
      return out;
    }

    if (str.substr(0, 1) === '{') {
      // New JSON format
      try {
        var _ret = (function () {
          var json = JSON.parse(str);
          if (json && json.catalogs) {
            Object.keys(json.catalogs).forEach(function (k) {
              var entry = json.catalogs[k];
              if (!entry.branch) {
                entry.branch = _uiUtilsConstants['default'].CATALOG.DEFAULT_BRANCH;
              }
            });

            return {
              v: json
            };
          }
        })();

        if (typeof _ret === 'object') return _ret.v;
      } catch (e) {}

      // If parsing fails, return empty object
      return out;
    }

    // Old string format
    str.split(',').forEach(function (item) {
      var key = undefined,
          val = undefined;
      var idx = item.indexOf('=');
      if (idx > 0) {
        //Less old key=val,key2=val2 format
        key = item.substr(0, idx);
        val = item.substr(idx + 1);
      } else {
        // Even older single URL format
        key = _uiUtilsConstants['default'].EXTERNAL_ID.CATALOG_DEFAULT_GROUP;
        val = item;
      }

      key = key.trim();
      val = val.trim();
      if (key && val) {
        out.catalogs[key] = { url: val, branch: _uiUtilsConstants['default'].CATALOG.DEFAULT_BRANCH };
      }
    });

    return out;
  }

  function getCatalogNames(str) {
    return Object.keys(parseCatalogSetting(str).catalogs).sort();
  }

  function getCatalogSubtree(str, projId) {
    var repos = getCatalogNames(str);
    var showAll = repos.length > 1;

    var out = [];
    if (showAll) {
      out.push({
        id: 'catalog-all',
        localizedLabel: 'nav.catalog.all',
        icon: 'icon icon-globe',
        route: 'catalog-tab',
        ctx: [projId],
        queryParams: { catalogId: 'all' }
      });

      // out.push({divider: true});
    }

    if (repos.indexOf(_uiUtilsConstants['default'].CATALOG.LIBRARY_KEY) >= 0) {
      repos.removeObject(_uiUtilsConstants['default'].CATALOG.LIBRARY_KEY);
      out.push({
        id: 'catalog-library',
        localizedLabel: 'nav.catalog.library',
        icon: 'icon icon-catalog',
        route: 'catalog-tab',
        ctx: [projId],
        queryParams: { catalogId: 'library' }
      });
    }

    if (repos.indexOf(_uiUtilsConstants['default'].CATALOG.COMMUNITY_KEY) >= 0) {
      repos.removeObject(_uiUtilsConstants['default'].CATALOG.COMMUNITY_KEY);
      out.push({
        id: 'catalog-community',
        localizedLabel: 'nav.catalog.community',
        icon: 'icon icon-users',
        route: 'catalog-tab',
        ctx: [projId],
        queryParams: { catalogId: 'community' }
      });
    }

    repos.forEach(function (repo) {
      out.push({
        id: 'catalog-' + repo,
        label: repo,
        icon: 'icon icon-user',
        route: 'catalog-tab',
        ctx: [projId],
        queryParams: { catalogId: repo }
      });
    });

    if (out.length === 1) {
      return [];
    } else {
      return out;
    }
  }
});