define('ui/hosts/index/controller', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    prefs: _ember['default'].inject.service(),

    mode: 'grouped',
    queryParams: ['mode'],

    actions: {
      newContainer: function newContainer(hostId) {
        this.transitionToRoute('containers.new', { queryParams: { hostId: hostId } });
      }

    },

    showSystem: _ember['default'].computed('prefs.' + _uiUtilsConstants['default'].PREFS.SHOW_SYSTEM, {
      get: function get() {
        return this.get('prefs.' + _uiUtilsConstants['default'].PREFS.SHOW_SYSTEM) !== false;
      },

      set: function set(key, value) {
        this.set('prefs.' + _uiUtilsConstants['default'].PREFS.SHOW_SYSTEM, value);
        return value;
      }
    }),

    show: _ember['default'].computed('showSystem', function () {
      return this.get('showSystem') === false ? 'standard' : 'all';
    }),

    listLinkOptions: {
      route: 'hosts',
      options: {
        mode: 'dot'
      }
    },

    groupLinkOptions: {
      route: 'hosts',
      options: {
        mode: 'grouped'
      }
    }
  });
});