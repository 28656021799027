define('ui/not-found/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    language: _ember['default'].inject.service('user-language'),

    beforeModel: function beforeModel() {
      return this.get('language').initLanguage();
    },

    redirect: function redirect() {
      var url = this.router.location.formatURL('/not-found');
      if (window.location.pathname !== url) {
        this.transitionTo('not-found');
      }
    }
  });
});