define('ui/catalog-tab/launch/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    catalog: _ember['default'].inject.service(),

    parentRoute: 'catalog-tab',

    model: function model(params /*, transition*/) {
      var _this = this;

      var store = this.get('store');

      var dependencies = {
        tpl: this.get('catalog').fetchTemplate(params.template)
      };

      if (params.upgrade) {
        dependencies.upgrade = this.get('catalog').fetchTemplate(params.upgrade, true);
      }

      if (params.stackId) {
        dependencies.stack = store.find('stack', params.stackId);
      }

      return _ember['default'].RSVP.hash(dependencies, 'Load dependencies').then(function (results) {
        if (!results.stack) {
          results.stack = store.createRecord({
            type: 'stack',
            name: results.tpl.get('defaultName'),
            startOnCreate: true,
            system: results.tpl.get('templateBase') === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_INFRA,
            environment: {} });
        }

        // Question answers
        var links;
        if (results.upgrade) {
          links = results.upgrade.upgradeVersionLinks;
        } else {
          links = results.tpl.versionLinks;
        }

        var verArr = Object.keys(links).filter(function (key) {
          // Filter out empty values for rancher/rancher#5494
          return !!links[key];
        }).map(function (key) {
          return { version: key, link: links[key] };
        });

        if (results.upgrade) {
          verArr.unshift({
            version: results.upgrade.version + ' (current)',
            link: results.upgrade.links.self
          });
        }

        return _ember['default'].Object.create({
          stack: results.stack,
          tpl: results.tpl,
          upgrade: results.upgrade,
          versionLinks: links,
          versionsArray: verArr,
          allTemplates: _this.modelFor(_this.get('parentRoute')).get('catalog'),
          templateBase: _this.modelFor(_this.get('parentRoute')).get('templateBase')
        });
      });
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('stackId', null);
        controller.set('upgrade', null);
      }
    }
  });
});