define("ui/components/edit-container/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "ui/components/edit-container/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "text-center");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "icon icon-spinner icon-spin");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["generic.loading"], [], ["loc", [null, [4, 50], [4, 73]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "ui/components/edit-container/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1, "class", "horizontal-form container-fluid");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element0, 3, 3);
          morphs[2] = dom.createMorphAt(element0, 5, 5);
          morphs[3] = dom.createMorphAt(element0, 7, 7);
          morphs[4] = dom.createMorphAt(element0, 9, 9);
          morphs[5] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", [["subexpr", "if", [["get", "model.instance.isVm", ["loc", [null, [9, 16], [9, 35]]], 0, 0, 0, 0], "editContainer.title.vm", "editContainer.title.container"], [], ["loc", [null, [9, 12], [9, 93]]], 0, 0]], [], ["loc", [null, [9, 8], [9, 95]]], 0, 0], ["inline", "top-errors", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [11, 24], [11, 30]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [11, 4], [11, 32]]], 0, 0], ["inline", "form-name-description", [], ["model", ["subexpr", "@mut", [["get", "model.instance", ["loc", [null, [14, 12], [14, 26]]], 0, 0, 0, 0]], [], [], 0, 0], "namePlaceholder", "editContainer.name.placeholder", "descriptionPlaceholder", "editContainer.description.placeholder"], ["loc", [null, [13, 4], [17, 6]]], 0, 0], ["inline", "form-ports", [], ["initialPorts", ["subexpr", "@mut", [["get", "model.ports", ["loc", [null, [19, 30], [19, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "editing", true, "changed", ["subexpr", "action", ["setPorts"], [], ["loc", [null, [19, 63], [19, 82]]], 0, 0]], ["loc", [null, [19, 4], [19, 84]]], 0, 0], ["inline", "form-container-links", [], ["initialLinks", ["subexpr", "@mut", [["get", "model.instanceLinks", ["loc", [null, [21, 40], [21, 59]]], 0, 0, 0, 0]], [], [], 0, 0], "instance", ["subexpr", "@mut", [["get", "model.instance", ["loc", [null, [21, 69], [21, 83]]], 0, 0, 0, 0]], [], [], 0, 0], "allHosts", ["subexpr", "@mut", [["get", "model.allHosts", ["loc", [null, [21, 93], [21, 107]]], 0, 0, 0, 0]], [], [], 0, 0], "editing", true, "changed", ["subexpr", "action", ["setLinks"], [], ["loc", [null, [21, 129], [21, 148]]], 0, 0]], ["loc", [null, [21, 4], [21, 150]]], 0, 0], ["inline", "save-cancel", [], ["editing", ["subexpr", "@mut", [["get", "editing", ["loc", [null, [24, 24], [24, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "save", "save", "cancel", "cancel"], ["loc", [null, [24, 2], [24, 61]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 26,
            "column": 0
          }
        },
        "moduleName": "ui/components/edit-container/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "liquid-if", [["get", "loading", ["loc", [null, [1, 13], [1, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [25, 14]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});