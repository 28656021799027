define('ui/certificates/detail/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params /*, transition*/) {
      var _this = this;

      return this.get('store').find('certificate', params.certificate_id).then(function (cert) {
        return _ember['default'].Object.create({
          certificate: cert,
          allCertificates: _this.modelFor('certificates')
        });
      });
    }
  });
});