define('ui/registries/index/controller', ['exports', 'ember', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], {
    sortBy: 'address',
    sorts: {
      state: ['stateSort', 'displayAddress', 'id'],
      address: ['displayAddress', 'id'],
      username: ['credential.publicValue', 'displayAddress', 'id'],
      created: ['created', 'id']
    }
  });
});