define("ui/components/storagepool-section/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "ui/components/storagepool-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "pull-left");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "badge-state", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [6, 49], [6, 54]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 29], [6, 56]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 6
              },
              "end": {
                "line": 21,
                "column": 161
              }
            },
            "moduleName": "ui/components/storagepool-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["storagePoolSection.volumes.add"], [], ["loc", [null, [21, 123], [21, 161]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 4
            },
            "end": {
              "line": 22,
              "column": 4
            }
          },
          "moduleName": "ui/components/storagepool-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["block", "link-to", ["storagepools.new-volume", ["subexpr", "query-params", [], ["driverName", ["get", "model.driverName", ["loc", [null, [21, 68], [21, 84]]], 0, 0, 0, 0]], ["loc", [null, [21, 43], [21, 85]]], 0, 0]], ["classNames", "btn btn-primary btn-sm"], 0, null, ["loc", [null, [21, 6], [21, 173]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 92
              },
              "end": {
                "line": 33,
                "column": 139
              }
            },
            "moduleName": "ui/components/storagepool-section/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["content", "host.displayName", ["loc", [null, [33, 119], [33, 139]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 6
            },
            "end": {
              "line": 34,
              "column": 6
            }
          },
          "moduleName": "ui/components/storagepool-section/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "r-m10");
          var el2 = dom.createElement("i");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [0]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element8, 'class');
          morphs[1] = dom.createMorphAt(element7, 2, 2);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["icon ", ["get", "host.stateIcon", ["loc", [null, [33, 44], [33, 58]]], 0, 0, 0, 0], " ", ["get", "host.stateBackground", ["loc", [null, [33, 63], [33, 83]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "link-to", ["host", ["get", "host.id", ["loc", [null, [33, 110], [33, 117]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [33, 92], [33, 151]]]]],
        locals: ["host"],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 34,
              "column": 6
            },
            "end": {
              "line": 36,
              "column": 6
            }
          },
          "moduleName": "ui/components/storagepool-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "text-center text-muted");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["storagePoolSection.hosts.none"], [], ["loc", [null, [35, 44], [35, 81]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 16
                },
                "end": {
                  "line": 60,
                  "column": 16
                }
              },
              "moduleName": "ui/components/storagepool-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "hand force-wrap clip r-m0");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("i");
              dom.setAttribute(el1, "classs", "icon icon-info");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["content", "vol.displayName", ["loc", [null, [59, 55], [59, 74]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 67,
                        "column": 24
                      },
                      "end": {
                        "line": 67,
                        "column": 100
                      }
                    },
                    "moduleName": "ui/components/storagepool-section/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["content", "mount.instance.displayName", ["loc", [null, [67, 70], [67, 100]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 66,
                      "column": 22
                    },
                    "end": {
                      "line": 68,
                      "column": 22
                    }
                  },
                  "moduleName": "ui/components/storagepool-section/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "link-to", ["virtualmachine", ["get", "mount.instanceId", ["loc", [null, [67, 52], [67, 68]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [67, 24], [67, 112]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 69,
                        "column": 24
                      },
                      "end": {
                        "line": 69,
                        "column": 95
                      }
                    },
                    "moduleName": "ui/components/storagepool-section/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["content", "mount.instance.displayName", ["loc", [null, [69, 65], [69, 95]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 68,
                      "column": 22
                    },
                    "end": {
                      "line": 70,
                      "column": 22
                    }
                  },
                  "moduleName": "ui/components/storagepool-section/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "link-to", ["container", ["get", "mount.instanceId", ["loc", [null, [69, 47], [69, 63]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [69, 24], [69, 107]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            var child2 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 72,
                      "column": 22
                    },
                    "end": {
                      "line": 74,
                      "column": 22
                    }
                  },
                  "moduleName": "ui/components/storagepool-section/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                        ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "text-muted");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["inline", "t", ["storagePoolSection.readOnly"], [], ["loc", [null, [73, 49], [73, 84]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 64,
                    "column": 18
                  },
                  "end": {
                    "line": 76,
                    "column": 18
                  }
                },
                "moduleName": "ui/components/storagepool-section/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(": ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("                    ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createMorphAt(element2, 0, 0);
                morphs[1] = dom.createMorphAt(element2, 2, 2);
                morphs[2] = dom.createMorphAt(element2, 4, 4);
                return morphs;
              },
              statements: [["block", "if", [["get", "mount.instance.isVm", ["loc", [null, [66, 29], [66, 48]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [66, 22], [70, 31]]]], ["content", "mount.path", ["loc", [null, [71, 24], [71, 38]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "mount.permissions", ["loc", [null, [72, 32], [72, 49]]], 0, 0, 0, 0], "ro"], [], ["loc", [null, [72, 28], [72, 55]]], 0, 0]], [], 2, null, ["loc", [null, [72, 22], [74, 29]]]]],
              locals: ["mount"],
              templates: [child0, child1, child2]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 63,
                  "column": 16
                },
                "end": {
                  "line": 77,
                  "column": 16
                }
              },
              "moduleName": "ui/components/storagepool-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "each", [["get", "vol.mounts", ["loc", [null, [64, 26], [64, 36]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [64, 18], [76, 27]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 77,
                  "column": 16
                },
                "end": {
                  "line": 79,
                  "column": 16
                }
              },
              "moduleName": "ui/components/storagepool-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "text-muted");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["generic.none"], [], ["loc", [null, [78, 43], [78, 63]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child3 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 97,
                    "column": 22
                  },
                  "end": {
                    "line": 99,
                    "column": 22
                  }
                },
                "moduleName": "ui/components/storagepool-section/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "snapshot-section", [], ["volume", ["subexpr", "@mut", [["get", "vol", ["loc", [null, [98, 50], [98, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "snapshot", ["subexpr", "@mut", [["get", "snapshot", ["loc", [null, [98, 63], [98, 71]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [98, 24], [98, 73]]], 0, 0]],
              locals: ["snapshot"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 85,
                  "column": 12
                },
                "end": {
                  "line": 104,
                  "column": 12
                }
              },
              "moduleName": "ui/components/storagepool-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              dom.setAttribute(el1, "class", "nohover");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "colspan", "4");
              dom.setAttribute(el2, "class", "subtable r-p10");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("table");
              dom.setAttribute(el3, "class", "grid fixed no-lines");
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("thead");
              var el5 = dom.createTextNode("\n                      ");
              dom.appendChild(el4, el5);
              var el5 = dom.createElement("tr");
              var el6 = dom.createTextNode("\n                        ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("th");
              dom.setAttribute(el6, "width", "125");
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                        ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("th");
              var el7 = dom.createComment("");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                        ");
              dom.appendChild(el5, el6);
              var el6 = dom.createElement("th");
              dom.setAttribute(el6, "width", "70");
              var el7 = dom.createTextNode(" ");
              dom.appendChild(el6, el7);
              dom.appendChild(el5, el6);
              var el6 = dom.createTextNode("\n                      ");
              dom.appendChild(el5, el6);
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("\n                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                    ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("tbody");
              var el5 = dom.createTextNode("\n");
              dom.appendChild(el4, el5);
              var el5 = dom.createComment("");
              dom.appendChild(el4, el5);
              var el5 = dom.createTextNode("                    ");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                  ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1, 1]);
              var element1 = dom.childAt(element0, [1, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
              morphs[2] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
              return morphs;
            },
            statements: [["inline", "t", ["storagePoolSection.models.table.header.snapshotState"], [], ["loc", [null, [91, 40], [91, 100]]], 0, 0], ["inline", "t", ["storagePoolSection.models.table.header.snapshotName"], [], ["loc", [null, [92, 28], [92, 87]]], 0, 0], ["block", "each", [["get", "vol.snapshots", ["loc", [null, [97, 30], [97, 43]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [97, 22], [99, 31]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 10
              },
              "end": {
                "line": 105,
                "column": 10
              }
            },
            "moduleName": "ui/components/storagepool-section/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "state");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "force-wrap");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "force-wrap");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "actions wide");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element3, [5]), 1, 1);
            morphs[3] = dom.createMorphAt(dom.childAt(element3, [7]), 1, 1);
            morphs[4] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "badge-state", [], ["model", ["subexpr", "@mut", [["get", "vol", ["loc", [null, [55, 36], [55, 39]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [55, 16], [55, 41]]], 0, 0], ["block", "tooltip-element", [], ["type", "tooltip-basic", "model", ["subexpr", "@mut", [["get", "vol", ["loc", [null, [58, 62], [58, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "tooltipTemplate", "tooltip-storage", "tooltipFor", "storagePoolSection"], 0, null, ["loc", [null, [58, 16], [60, 36]]]], ["block", "if", [["get", "vol.mounts.length", ["loc", [null, [63, 22], [63, 39]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [63, 16], [79, 23]]]], ["inline", "action-menu", [], ["model", ["subexpr", "@mut", [["get", "vol", ["loc", [null, [82, 36], [82, 39]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [82, 16], [82, 41]]], 0, 0], ["block", "if", [["get", "vol.snapshots.length", ["loc", [null, [85, 18], [85, 38]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [85, 12], [104, 19]]]]],
          locals: ["vol"],
          templates: [child0, child1, child2, child3]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 39,
              "column": 2
            },
            "end": {
              "line": 109,
              "column": 2
            }
          },
          "moduleName": "ui/components/storagepool-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "subsection gutter col-xs-12 col-md-9 col-full-height");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h6");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("table");
          dom.setAttribute(el2, "class", "grid");
          dom.setAttribute(el2, "style", "margin-bottom: 0");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("thead");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("tr");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("th");
          dom.setAttribute(el5, "width", "75");
          var el6 = dom.createTextNode(" ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tbody");
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element4 = dom.childAt(fragment, [1]);
          var element5 = dom.childAt(element4, [3]);
          var element6 = dom.childAt(element5, [1, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(element6, 1, 1);
          morphs[2] = dom.createMorphAt(element6, 3, 3);
          morphs[3] = dom.createMorphAt(dom.childAt(element6, [5]), 0, 0);
          morphs[4] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["storagePoolSection.volumes.header"], [], ["loc", [null, [41, 10], [41, 51]]], 0, 0], ["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [45, 35], [45, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "state", "width", "115", "label", "generic.state"], ["loc", [null, [45, 12], [45, 108]]], 0, 0], ["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [46, 35], [46, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "name", "width", "350", "label", "storagePoolSection.models.table.header.volumeName"], ["loc", [null, [46, 12], [46, 143]]], 0, 0], ["inline", "t", ["storagePoolSection.models.table.header.mounts"], [], ["loc", [null, [47, 16], [47, 69]]], 0, 0], ["block", "each", [["get", "arranged", ["loc", [null, [52, 18], [52, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [52, 10], [105, 19]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 109,
              "column": 2
            },
            "end": {
              "line": 111,
              "column": 2
            }
          },
          "moduleName": "ui/components/storagepool-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "text-muted text-center r-p10");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["storagePoolSection.volumes.none"], [], ["loc", [null, [110, 46], [110, 85]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 113,
            "column": 0
          }
        },
        "moduleName": "ui/components/storagepool-section/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "header-left collapser");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "header-left");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h4");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "divider clip");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "header-right right-divider stack-actions");
        dom.setAttribute(el2, "style", "width: auto");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "stack-body r-p10 row row-full-height");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "subsection gutter col-xs-12 col-md-3 col-full-height");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h6");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "clearfix r-p10 hosts");
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var element10 = dom.childAt(element9, [3]);
        var element11 = dom.childAt(fragment, [4]);
        var element12 = dom.childAt(element11, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element9, 'class');
        morphs[1] = dom.createMorphAt(element10, 1, 1);
        morphs[2] = dom.createMorphAt(dom.childAt(element10, [3, 1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element9, [5]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element12, [1]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element12, [3]), 1, 1);
        morphs[6] = dom.createMorphAt(element11, 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["clearfix ", ["subexpr", "if", [["get", "single", ["loc", [null, [1, 26], [1, 32]]], 0, 0, 0, 0], ["get", "model.stateBackground", ["loc", [null, [1, 33], [1, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 21], [1, 56]]], 0, 0], " ", ["subexpr", "if", [["get", "single", ["loc", [null, [1, 62], [1, 68]]], 0, 0, 0, 0], "single"], [], ["loc", [null, [1, 57], [1, 79]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "unless", [["get", "single", ["loc", [null, [5, 14], [5, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 4], [7, 15]]]], ["content", "model.name", ["loc", [null, [9, 33], [9, 47]]], 0, 0, 0, 0], ["block", "unless", [["get", "single", ["loc", [null, [20, 14], [20, 20]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [20, 4], [22, 15]]]], ["inline", "t", ["storagePoolSection.hosts.header"], [], ["loc", [null, [30, 8], [30, 47]]], 0, 0], ["block", "each", [["get", "hostsByName", ["loc", [null, [32, 14], [32, 25]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [32, 6], [36, 15]]]], ["block", "if", [["get", "model.volumes.length", ["loc", [null, [39, 8], [39, 28]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [39, 2], [111, 9]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});