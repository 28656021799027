define('ui/service/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var stack = this.modelFor('stack');
      var service = this.get('store').getById('service', params.service_id);
      if (service) {
        return _ember['default'].Object.create({
          service: service,
          stack: stack.get('stack')
        });
      } else {
        return this.get('store').find('service', params.service_id).then(function (service) {
          return _ember['default'].Object.create({
            service: service,
            stack: stack.get('stack')
          });
        });
      }
    }
  });
});