define('ui/components/machine/driver-otc/component', ['exports', 'ember', 'ui/mixins/driver', 'ember-api-store/utils/fetch'], function (exports, _ember, _uiMixinsDriver, _emberApiStoreUtilsFetch) {

  var VOLUME_TYPES = [{
    name: 'SAS'
  }, {
    name: 'SATA'
  }, {
    name: 'SSD'
  }];

  var URLS = {
    ecs: 'https://ecs.eu-de.otc.t-systems.com',
    vpc: 'https://vpc.eu-de.otc.t-systems.com'
  };

  var SERVICE = 'ecs';

  exports['default'] = _ember['default'].Component.extend(_uiMixinsDriver['default'], {
    driverName: 'otcConfig',
    catalogUrls: null,
    step: 1,
    _prevStep: 1,
    errors: null,
    intl: _ember['default'].inject.service(),
    volumeTypes: VOLUME_TYPES,
    itemsLoading: false,
    flavors: null,
    images: null,
    osAvailabilityZone: null,
    subnet: null,

    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('clonedModel')) {
        this.initModel();
      }
    },

    initModel: function initModel() {
      var config = this.get('store').createRecord({
        type: 'otcConfig',
        serviceEndpoint: URLS.ecs,
        region: 'eu-de',
        sshUser: 'linux'
      });

      var type = 'host';

      if (!this.get('useHost')) {
        type = 'machine';
      }

      this.set('model', this.get('store').createRecord({
        type: type,
        'otcConfig': config
      }));
    },

    actions: {
      authorizeCreds: function authorizeCreds() {
        var _this = this;

        this.setProperties({
          _prevStep: 1,
          step: 2,
          errors: null
        });
        this.getZones().then(function () {
          _this.getNetworks().then(function () {});
        });
      },
      goToStep3: function goToStep3() {
        var _this2 = this;

        this.setProperties({
          _prevStep: 2,
          step: 3,
          errors: null
        });
        this.getSubnets().then(function () {
          _this2.getSecurityGroups();
        });
      },
      goToStep4: function goToStep4() {
        var _this3 = this;

        this.setProperties({
          _prevStep: 3,
          step: 4,
          errors: null
        });
        this.getFlavors().then(function () {
          _this3.getImage();
        });
      }

    },

    validate: function validate() {
      this._super.apply(this, arguments);
      var errors = this.get('errors');

      if (!this.get('model.otcConfig.flavorId')) {
        errors.push('Flavor is required');
      }

      if (!this.get('model.otcConfig.imageId')) {
        errors.push('Image is required');
      }

      this.set('errors', errors);
      return errors.length === 0;
    },

    getURL: function getURL(type, version, endpoint, qp) {
      var proxyEndpoint = this.get('app.proxyEndpoint');
      var catalog = URLS;
      var url = catalog[type] + '/' + version + '/' + this.get('model.otcConfig.tenantId');
      var proxyUrl = proxyEndpoint + '/' + url;
      var parsedURL = parseURL(url);
      var host = parsedURL.host;
      var path = parsedURL.pathname;

      if (endpoint) {
        proxyUrl += '/' + endpoint;
        path = path + '/' + endpoint;
      }

      function parseURL(urlIn) {
        var parser = document.createElement('a');
        parser.href = urlIn;
        // parser.hostname; // => "example.com"
        // parser.port;     // => "3000"
        // parser.pathname; // => "/pathname/"
        // parser.search;   // => "?search=test"
        // parser.hash;     // => "#hash"
        // parser.host;     // => "example.com:3000"
        return parser;
      }
      return {
        endpoint: proxyUrl,
        url: path,
        host: host,
        qp: qp
      };
    },

    describeRequest: function describeRequest(url, method, t, body) {
      return {
        url: (url.endpoint.substr(-1) === '/' ? url.endpoint.replace(/\/$/, '') : url.endpoint) + '?' + url.qp,
        urlToEncode: url.url.substr(-1) === '/' ? url.url : url.url + '/',
        method: method,
        headers: {
          'x-sdk-date': t, // long date
          'host': url.host,
          'content-type': ''
        },
        queryString: url.qp,
        body: body,
        params: {}
      };
    },

    signAuth: function signAuth(credentials, req, time) {
      var serviceEndpoint = arguments.length <= 3 || arguments[3] === undefined ? null : arguments[3];

      var self = this;
      var request = req;
      var addAuthorization = function addAuthorization(credentials) {
        var r = request;

        r.params.Timestamp = time.format('YYYYMMDDTHHmmss') + 'Z';
        r.params.ShortTimestamp = time.format('YYYYMMDD');
        r.params.SignatureMethod = 'SDK-HMAC-SHA256';
        r.params.accessKeyId = credentials.accessKeyId;
        r.params.Signature = signature(credentials, serviceEndpoint);
        r['Content-Length'] = r.body.length;
        r.headers['content-type'] += ' rancher:';
        r.headers['x-api-auth-header'] = buildAuth(r.params, serviceEndpoint);

        return r;
      };

      return addAuthorization(credentials);

      function signature(credentials) {
        var serviceEndpoint = arguments.length <= 1 || arguments[1] === undefined ? null : arguments[1];

        var canonicalRequest = buildCanonicalRequest(request);
        var hashedSigingString = AWS.util.crypto.sha256(canonicalRequest, 'hex');
        var credScope = credentialScope(request.params.ShortTimestamp, self.get('model.otcConfig.region'), serviceEndpoint || SERVICE);
        var sts = stringToSign(hashedSigingString, credScope, request.params.Timestamp);
        var key = generateSigningKey(credentials.secretAccessKey, self.get('model.otcConfig.region'), serviceEndpoint || SERVICE, request.params.ShortTimestamp);

        return AWS.util.crypto.hmac(key, sts, 'hex');
      }

      function generateSigningKey(sk, rn, sn, t) {
        var kDate = AWS.util.crypto.hmac('SDK' + sk, t, 'buffer');
        var kRegion = AWS.util.crypto.hmac(kDate, rn, 'buffer');
        var kService = AWS.util.crypto.hmac(kRegion, sn, 'buffer');
        var kSigning = AWS.util.crypto.hmac(kService, 'sdk_request', 'buffer');
        return kSigning;
      }

      function credentialScope(time, rname, sname) {
        return time + '/' + rname + '/' + sname + '/sdk_request';
      }

      function buildCanonicalHeaders(requestHeaders) {
        var headers = [];
        var parts = [];

        Object.keys(requestHeaders).forEach(function (key) {
          headers.push([key, requestHeaders[key]]);
        });

        headers.sort(function (a, b) {
          return a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1;
        });

        headers.forEach(function (item) {
          var key = item[0].toLowerCase();
          parts.push(key + ':' + item[1].toString().replace(/\s+/g, ' ').replace(/^\s+|\s+$/g, ''));
        });

        return parts.join('\n') + '\n';
      }

      function buildCanonicalQS(qs) {
        var qsParts = qs.split('&');

        qsParts.sort(function (a, b) {
          return a[0].toLowerCase() < b[0].toLowerCase() ? -1 : 1;
        });

        qsParts.forEach(function (qString) {
          qString.replace(/\+/g, '%20');
        });

        return qsParts.join('&');
      }

      function buildCanonicalRequest(request) {
        var parts = [];

        parts.push(request.method);
        parts.push(encodeURI(request.urlToEncode));
        parts.push(buildCanonicalQS(request.queryString));
        parts.push(buildCanonicalHeaders(request.headers));
        parts.push('content-type;host;x-sdk-date');
        parts.push(AWS.util.crypto.sha256(request.body, 'hex'));

        return parts.join('\n');
      }

      function stringToSign(canonicalRequest, credentialScope, t) {
        var parts = [];

        parts.push('SDK-HMAC-SHA256');
        parts.push(t);
        parts.push(credentialScope);
        parts.push(canonicalRequest);

        return parts.join('\n');
      }

      function buildAuth(params, serviceEndpoint) {
        var out = [];

        out.push(params.SignatureMethod + ' Credential=' + params.accessKeyId + '/' + params.ShortTimestamp + '/' + self.get('model.otcConfig.region') + '/' + (serviceEndpoint || SERVICE) + '/sdk_request');
        out.push('SignedHeaders=content-type;host;x-sdk-date');
        out.push('Signature=' + params.Signature);

        return out.join(', ');
      }
    },

    apiRequest: function apiRequest(params) {
      var _this4 = this;

      var time = moment.utc();
      var signature = this.signAuth({
        accessKeyId: this.get('model.otcConfig.accessKeyId'),
        secretAccessKey: this.get('model.otcConfig.accessKeySecret')
      }, this.describeRequest(this.getURL(params.serviceEndpoint || SERVICE, params.version, params.endpoint, params.queryParams), params.method, time.format('YYYYMMDDTHHmmss') + 'Z', ''), time);

      this.set('itemsLoading', true);

      delete signature.headers.host;

      return (0, _emberApiStoreUtilsFetch['default'])(signature.url, {
        method: params.method,
        headers: signature.headers
      }).then(function (xhr) {
        if (xhr.status >= 200 && xhr.status < 300) {

          _this4.set('itemsLoading', false);

          return xhr.body;
        } else {

          _this4.set('itemsLoading', false);
          _this4.set('step', _this4.get('_prevStep'));

          return _ember['default'].RSVP.reject();
        }
      })['catch'](function (err) {
        var errors = [];
        var errMsg = err.body.message || err.body.badRequest.message; // werid case where we get an odd bad response from the otc driver

        errors.push(errMsg);

        _this4.setProperties({
          itemsLoading: false,
          step: _this4.get('_prevStep'),
          errors: errors.uniq()
        });

        return _ember['default'].RSVP.reject(err);
      });
    },

    getSecurityGroups: function getSecurityGroups() {
      var _this5 = this;

      return this.apiRequest({
        method: 'GET',
        endpoint: 'security-groups',
        version: 'v1',
        serviceEndpoint: 'vpc',
        queryParams: ''
      }).then(function (resp) {
        return _this5.set('securityGroups', resp.security_groups.sortBy('name'));
      });
    },

    getSubnets: function getSubnets() {
      var _this6 = this;

      return this.apiRequest({
        method: 'GET',
        endpoint: 'subnets',
        serviceEndpoint: 'vpc',
        version: 'v1',
        queryParams: 'vpc_id=' + this.get('model.otcConfig.vpcId')
      }).then(function (resp) {
        var subnets = resp.subnets.filterBy('availability_zone', _this6.get('model.otcConfig.availableZone'));
        if (subnets.length === 0) {
          subnets = resp.subnets;
        }
        return _this6.set('subnets', subnets.sortBy('name'));
      });
    },

    getNetworks: function getNetworks() {
      var _this7 = this;

      return this.apiRequest({
        method: 'GET',
        endpoint: 'vpcs',
        serviceEndpoint: 'vpc',
        version: 'v1',
        queryParams: ''
      }).then(function (resp) {
        return _this7.set('networks', resp.vpcs);
      });
    },

    getZones: function getZones() {
      var _this8 = this;

      return this.apiRequest({
        method: 'GET',
        endpoint: 'os-availability-zone',
        version: 'v2',
        queryParams: ''
      }).then(function (resp) {
        return _this8.set('osAvailabilityZone', resp.availabilityZoneInfo.filterBy('zoneState.available', true).sortBy('zoneName'));
      });
    },

    getFlavors: function getFlavors() {
      var _this9 = this;

      return this.apiRequest({
        method: 'GET',
        endpoint: 'cloudservers/flavors',
        version: 'v1',
        queryParams: ''
      }).then(function (resp) {
        return _this9.set('flavors', resp.flavors.sortBy('name'));
      });
    },

    getImage: function getImage() {
      var _this10 = this;

      return this.apiRequest({
        method: 'GET',
        endpoint: 'images',
        version: 'v2',
        queryParams: ''
      }).then(function (resp) {
        return _this10.set('images', resp.images.sortBy('name'));
      });
    }
  });
});