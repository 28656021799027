define('ui/host/storage/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var host = this.modelFor('host').get('host');
      var out = [];
      return host.followLink('storagePools').then(function (pools) {
        var promises = pools.map(function (pool) {
          return pool.followLink('volumes', { include: ['mounts'] }).then(function (volumes) {
            out.pushObjects((volumes || []).toArray());
          });
        });

        return _ember['default'].RSVP.all(promises).then(function () {
          return out;
        });
      });
    }
  });
});