define('ui/components/machine/driver-azure/component', ['exports', 'ember', 'ui/utils/azure-choices', 'ui/utils/azure-vm-choices', 'ui/mixins/driver'], function (exports, _ember, _uiUtilsAzureChoices, _uiUtilsAzureVmChoices, _uiMixinsDriver) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsDriver['default'], {
    azureConfig: _ember['default'].computed.alias('model.azureConfig'),
    environments: _uiUtilsAzureChoices.environments.sortBy('value'),
    vmTypeChoices: _uiUtilsAzureVmChoices.sizes,
    driverName: 'azure',
    model: null,
    openPorts: null,
    publicIpChoice: null,
    selectedVmType: null,
    publicIpChoices: [{
      'name': 'Static',
      'value': 'staticPublicIp=true,noPublicIp=false'
    }, {
      'name': 'Dynamic',
      'value': 'staticPublicIp=false,noPublicIp=false'
    }, {
      'name': 'None',
      'value': 'staticPublicIp=true,noPublicIp=true'
    }],
    storageTypeChoices: _uiUtilsAzureChoices.storageTypes.sortBy('name'),

    bootstrap: function bootstrap() {
      var config = this.get('store').createRecord({
        type: 'azureConfig',
        subscriptionId: '',
        clientId: '',
        clientSecret: '',
        openPort: ['500/udp', '4500/udp']
      });

      this.set('model', this.get('store').createRecord({
        type: 'host',
        azureConfig: config
      }));

      this.set('editing', false);
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      _ember['default'].run.scheduleOnce('afterRender', function () {
        _this.set('publicIpChoice', _this.initPublicIpChoices(_this.get('azureConfig.staticPublicIp'), _this.get('azureConfig.noPublicIp')));
        _this.set('openPorts', _this.initOpenPorts(_this.get('azureConfig.openPort')));
      });
    },

    initOpenPorts: function initOpenPorts(ports) {
      return ports ? ports.join(',') : '';
    },

    initPublicIpChoices: function initPublicIpChoices(staticPublicIp, noPublicIp) {
      if (staticPublicIp && noPublicIp) {
        return this.get('publicIpChoices').findBy('name', 'None').value;
      } else if (staticPublicIp && !noPublicIp) {
        return this.get('publicIpChoices').findBy('name', 'Static').value;
      } else {
        return this.get('publicIpChoices').findBy('name', 'Dynamic').value;
      }
    },

    vmSizeChoices: _ember['default'].computed('azureConfig.location', 'selectedVmType', function () {
      var location = this.get('azureConfig.location');
      var selectedVmType = this.get('selectedVmType');
      var found = _uiUtilsAzureVmChoices.sizes.findBy('label', selectedVmType);
      var out = [];
      if (found && found.series) {
        found.series.filter(function (ss) {
          return !ss.invalidRegions || ss.invalidRegions.indexOf(location) === -1;
        }).forEach(function (s) {
          s.sizes.forEach(function (item) {
            out.push({
              group: s.label,
              label: item.size + ' ( ' + item.cpu + ' ' + (item.cpu > 1 ? 'Cores' : 'Core') + ' ' + item.memory + 'GB RAM )',
              value: item.size
            });
          });
        });
      }
      if (out.length === 0) {
        this.set('azureConfig.size', null);
      }
      return out;
    }),

    regionChoices: _ember['default'].computed('azureConfig.environment', function () {
      var environment = this.get('azureConfig.environment');
      return _uiUtilsAzureChoices.regions[environment];
    }),

    evironmentChoiceObserver: _ember['default'].observer('azureConfig.environment', function () {
      var environment = this.get('azureConfig.environment');
      this.set('azureConfig.location', _uiUtilsAzureChoices.regions[environment][0].name);
    }),

    privateSet: _ember['default'].computed('publicIpChoice', function () {
      var publicIpChoice = this.get('publicIpChoice');
      if (publicIpChoice && this.get('publicIpChoices').findBy('value', publicIpChoice).name === 'None') {
        return true;
      }
      return false;
    }),

    ipChoiceObserver: _ember['default'].observer('publicIpChoice', function () {
      var publicIpChoice = this.get('publicIpChoice');
      if (this.get('publicIpChoices').findBy('value', publicIpChoice).name === 'None') {
        this.set('azureConfig.usePrivateIp', true);
      } else {
        this.set('azureConfig.usePrivateIp', false);
      }
    }),

    setUsePrivateIp: _ember['default'].computed('publicIpChoice', function () {
      var publicIpChoice = this.get('publicIpChoice');
      if (publicIpChoice && this.get('publicIpChoices').findBy('value', publicIpChoice).name === 'None') {
        return this.set('azureConfig.usePrivateIp', true);
      }
      return false;
    }),

    publicIpObserver: _ember['default'].observer('publicIpChoice', function () {
      var _this2 = this;

      var elChoice = this.get('publicIpChoice');
      var choice = this.get('publicIpChoices').findBy('value', elChoice);

      choice = choice.value.split(',');

      choice.forEach(function (val) {
        var tmp = val.split('=');
        _this2.set('azureConfig.' + tmp[0], tmp[1] === 'true' ? true : false);
      });
    }),

    openPort: _ember['default'].observer('openPorts', function () {
      var str = (this.get('openPorts') || '').trim();
      var ary = [];
      if (str.length) {
        ary = str.split(/\s*,\s*/);
      }

      this.set('azureConfig.openPort', ary);
    }),

    validate: function validate() {
      this._super();
      var errors = this.get('errors') || [];

      this.set('prefix', (this.get('prefix') || '').toLowerCase());
      var name = this.get('model.hostname');
      if (name.length < 4 || name.length > 62) {
        errors.push('Name must be between 4 and 62 characters long');
      }

      if (name.match(/[^a-z0-9-]/)) {
        errors.push('Name can only contain a-z, 0-9, and hyphen');
      }

      if (!this.get('azureConfig.subscriptionId')) {
        errors.push('Subscription ID is required');
      }

      if (!this.get('azureConfig.clientId')) {
        errors.push('Client ID is requried');
      }

      if (!this.get('azureConfig.clientSecret')) {
        errors.push('Client Secret is requried');
      }

      if (errors.length) {
        this.set('errors', errors.uniq());
        return false;
      }

      return true;
    }

  });
});