define('ui/utils/parse-healthcheck', ['exports', 'ember', 'ui/utils/util'], function (exports, _ember, _uiUtilsUtil) {
  exports.parseRequestLine = parseRequestLine;
  exports.canonicalHeader = canonicalHeader;
  exports.parseHeaders = parseHeaders;

  function parseRequestLine(str) {
    str = (str || '').trim();
    var out = null;
    var match;

    if (str.indexOf('"') > 0) {
      //haproxy 1.6+ with quoted request
      //                 METHOD     "path"   "HTTP/1.x\\r\\nHost: Blah"
      match = str.match(/([^\s]+)\s+"?([^"]+)"?\s+"?(HTTP\/[0-9\.]+)([^"]*)"?/);
      if (match) {
        out = {
          method: match[1],
          path: match[2],
          version: match[3],
          headers: parseHeaders(match[4].replace(/(\\r)?\\n/g, '\r\n').trim())
        };
      }
    } else {
      //haproxy <= 1.5
      var lines = str.split(/[\r\n]+/);
      match = lines[0].match(/^([^\s]+)\s+(.*)\s+(HTTP\/[0-9\.]+)/);
      if (match) {
        out = {
          method: match[1],
          path: match[2],
          version: match[3],
          headers: parseHeaders(lines.slice(1))
        };
      }
    }

    return out;
  }

  function canonicalHeader(str) {
    return (str || '').toLowerCase().split('-').map(_uiUtilsUtil['default'].ucFirst).join('-');
  }

  function parseHeaders(strOrArray) {
    var out = {};

    var ary;
    if (_ember['default'].isArray(strOrArray)) {
      ary = strOrArray;
    } else {
      ary = (strOrArray || '').split(/(\r?\n)+/);
    }

    ary.forEach(function (line) {
      var pos = line.indexOf(':');
      if (pos > 0) {
        var key = canonicalHeader(line.substr(0, pos)).trim();
        var val = line.substr(pos + 1).trim();
        if (val) {
          if (out[key]) {
            out[key] = out[key] + ',' + val;
          } else {
            out[key] = val;
          }
        }
      }
    });

    return out;
  }

  exports['default'] = {
    parseRequestLine: parseRequestLine,
    parseHeaders: parseHeaders,
    canonicalHeader: canonicalHeader
  };
});