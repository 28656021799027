define('ui/authenticated/project/api/hooks/index/controller', ['exports', 'ember', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], {
    settings: _ember['default'].inject.service(),

    sortableContent: (function () {
      var receivers = this.get('model.receivers');
      return receivers.filter(function (ele) {
        return ele.driver !== 'forwardPost';
      });
    }).property('model.receivers.@each.driver'),
    sortBy: 'name',
    sorts: {
      state: ['stateSort', 'name', 'id'],
      name: ['name', 'id'],
      kind: ['displayKind', 'id']
    }

  });
});