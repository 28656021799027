define('ui/stack/graph/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    stack: _ember['default'].computed.alias('model.stack'),

    showServiceInfo: null,
    selectedService: null,
    noServices: false,
    actions: {
      dismiss: function dismiss() {
        this.set('showServiceInfo', false);
      },
      setNoServices: function setNoServices(val) {
        this.set('noServices', val);
      }
    }
  });
});