define('ui/admin-tab/auth/github/controller', ['exports', 'ember', 'ui/utils/constants', 'ui/services/settings'], function (exports, _ember, _uiUtilsConstants, _uiServicesSettings) {
  exports['default'] = _ember['default'].Controller.extend({
    github: _ember['default'].inject.service(),
    endpoint: _ember['default'].inject.service(),
    access: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    githubConfig: _ember['default'].computed.alias('model.githubConfig'),

    confirmDisable: false,
    errors: null,
    testing: false,
    error: null,
    saved: false,
    saving: false,
    haveToken: false,

    organizations: null,
    scheme: _ember['default'].computed.alias('githubConfig.scheme'),
    isEnterprise: false,
    secure: true,

    createDisabled: (function () {
      if (!this.get('haveToken')) {
        return true;
      }
      if (this.get('isEnterprise') && !this.get('githubConfig.hostname')) {
        return true;
      }

      if (this.get('testing')) {
        return true;
      }
    }).property('githubConfig.{clientId,clientSecret,hostname}', 'testing', 'isEnterprise', 'haveToken'),

    providerName: (function () {
      if (!!this.get('githubConfig.hostname')) {
        return 'authPage.github.enterprise';
      } else {
        return 'authPage.github.standard';
      }
    }).property('githubConfig.hostname'),

    numUsers: (function () {
      return this.get('model.allowedIdentities').filterBy('externalIdType', _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_USER).get('length');
    }).property('model.allowedIdentities.@each.externalIdType', 'wasRestricted'),

    numOrgs: (function () {
      return this.get('model.allowedIdentities').filterBy('externalIdType', _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_ORG).get('length');
    }).property('model.allowedIdentities.@each.externalIdType', 'wasRestricted'),

    destinationUrl: (function () {
      return window.location.origin + '/';
    }).property(),

    updateEnterprise: function updateEnterprise() {
      if (this.get('isEnterprise')) {
        var match;
        var hostname = this.get('githubConfig.hostname') || '';

        if (match = hostname.match(/^http(s)?:\/\//i)) {
          this.set('secure', (match[1] || '').toLowerCase() === 's');
          hostname = hostname.substr(match[0].length).replace(/\/.*$/, '');
          this.set('githubConfig.hostname', hostname);
        }
      } else {
        this.set('githubConfig.hostname', null);
        this.set('secure', true);
      }

      this.set('scheme', this.get('secure') ? 'https://' : 'http://');
    },

    enterpriseDidChange: (function () {
      _ember['default'].run.once(this, 'updateEnterprise');
    }).observes('isEnterprise', 'githubConfig.hostname', 'secure'),

    protocolChoices: [{ label: 'https:// -- Requires a cert from a public CA', value: 'https://' }, { label: 'http://', value: 'http://' }],

    actions: {
      save: function save() {
        var _this = this;

        this.send('clearError');
        this.set('saving', true);

        var githubConfig = _ember['default'].Object.create(this.get('githubConfig'));
        githubConfig.setProperties({
          'clientId': (githubConfig.get('clientId') || '').trim(),
          'clientSecret': (githubConfig.get('clientSecret') || '').trim()
        });

        this.get('model').setProperties({
          'provider': 'githubconfig',
          'enabled': false, // It should already be, but just in case..
          'accessMode': 'unrestricted',
          'allowedIdentities': []
        });

        this.get('github').setProperties({
          hostname: githubConfig.get('hostname'),
          scheme: githubConfig.get('scheme'),
          clientId: githubConfig.get('clientId')
        });

        this.get('model').save().then(function () /*resp*/{
          // we need to go get he new token before we open the popup
          // if you've authed with any other services in v1-auth
          // the redirect token will be stale and representitive
          // of the old auth method
          _this.get('github').getToken().then(function (resp) {
            _this.get('access').set('token', resp);
            _this.setProperties({
              saving: false,
              saved: true,
              haveToken: true
            });
          })['catch'](function (err) {
            _this.setProperties({
              saving: false,
              saved: false,
              haveToken: false
            });
            _this.send('gotError', err);
          });
        })['catch'](function (err) {
          _this.setProperties({
            saving: false,
            saved: false,
            haveToken: false
          });
          _this.send('gotError', err);
        });
      },

      authenticate: function authenticate() {
        var _this2 = this;

        this.send('clearError');
        this.set('testing', true);
        this.get('github').authorizeTest(function (err, code) {
          if (err) {
            _this2.send('gotError', err);
            _this2.set('testing', false);
          } else {
            _this2.send('gotCode', code);
            _this2.set('testing', false);
          }
        });
      },

      gotCode: function gotCode(code) {
        var _this3 = this;

        this.get('access').login(code).then(function (res) {
          _this3.send('authenticationSucceeded', res.body);
        })['catch'](function (res) {
          // Github auth succeeded but didn't get back a token
          _this3.send('gotError', res.body);
        });
      },

      authenticationSucceeded: function authenticationSucceeded(auth) {
        var _this4 = this;

        this.send('clearError');
        this.set('organizations', auth.orgs);

        var model = this.get('model').clone();
        model.setProperties({
          'enabled': true,
          'accessMode': 'restricted',
          'allowedIdentities': [auth.userIdentity]
        });

        var url = window.location.href;

        model.save().then(function () {
          // Set this to true so the token will be sent with the request
          _this4.set('access.enabled', true);

          return _this4.get('userStore').find('setting', (0, _uiServicesSettings.denormalizeName)(_uiUtilsConstants['default'].SETTING.API_HOST)).then(function (setting) {
            if (setting.get('value')) {
              _this4.send('waitAndRefresh', url);
            } else {
              // Default the api.host so the user won't have to set it in most cases
              if (window.location.hostname === 'localhost') {
                _this4.send('waitAndRefresh', url);
              } else {
                setting.set('value', window.location.origin);
                return setting.save().then(function () {
                  _this4.send('waitAndRefresh', url);
                });
              }
            }
          });
        })['catch'](function (err) {
          _this4.set('access.enabled', false);
          _this4.send('gotError', err);
        });
      },

      waitAndRefresh: function waitAndRefresh(url) {
        $('#loading-underlay, #loading-overlay').removeClass('hide').show();
        setTimeout(function () {
          window.location.href = url || window.location.href;
        }, 1000);
      },

      promptDisable: function promptDisable() {
        this.set('confirmDisable', true);
        _ember['default'].run.later(this, function () {
          this.set('confirmDisable', false);
        }, 10000);
      },

      gotError: function gotError(err) {
        if (err.message) {
          this.send('showError', err.message + (err.detail ? '(' + err.detail + ')' : ''));
        } else {
          this.send('showError', 'Error (' + err.status + ' - ' + err.code + ')');
        }

        this.set('testing', false);
      },

      showError: function showError(msg) {
        this.set('errors', [msg]);
        window.scrollY = 10000;
      },

      clearError: function clearError() {
        this.set('errors', null);
      },

      disable: function disable() {
        var _this5 = this;

        this.send('clearError');

        var model = this.get('model').clone();
        model.setProperties({
          'allowedIdentities': [],
          'accessMode': 'unrestricted',
          'enabled': false,
          'githubConfig': {
            'hostname': null,
            'clientSecret': ''
          }
        });

        model.save().then(function () {
          _this5.get('access').clearSessionKeys();
          _this5.set('access.enabled', false);
          _this5.send('waitAndRefresh');
        })['catch'](function (err) {
          _this5.send('gotError', err);
        })['finally'](function () {
          _this5.set('confirmDisable', false);
        });
      }
    }
  });
});