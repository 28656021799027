define('ui/components/schema/input-password/component', ['exports', 'ember', 'ui/utils/util'], function (exports, _ember, _uiUtilsUtil) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['input-group'],
    value: '',

    actions: {
      generate: function generate() {
        this.set('value', _uiUtilsUtil['default'].randomStr(16, 'password'));

        var $field = this.$('INPUT');
        $field.attr('type', 'text');
        setTimeout(function () {
          $field[0].focus();
          $field[0].select();
        }, 50);

        this.sendAction('generated');
      }
    }

  });
});