define('ui/components/edit-container/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'lacsso/components/modal-base'], function (exports, _ember, _uiMixinsNewOrEdit, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewOrEdit['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    editing: true,
    isService: false,
    isSidekick: false,
    loading: true,

    model: null,

    primaryResource: _ember['default'].computed.alias('model.instance'),
    launchConfig: _ember['default'].computed.alias('model.instance'),
    portsArray: null,

    linksArray: null,

    actions: {
      setPorts: function setPorts(ports) {
        this.set('portsArray', ports);
      },

      setLinks: function setLinks(links) {
        this.set('linksArray', links);
      },

      save: function save() {
        this._super.apply(this, arguments);
        this.send('cancel');
      }
    },

    didInsertElement: function didInsertElement() {
      _ember['default'].run.next(this, 'loadDependencies');
    },

    loadDependencies: function loadDependencies() {
      var _this = this;

      var instance = this.get('originalModel');

      return _ember['default'].RSVP.all([instance.followLink('ports'), instance.followLink('instanceLinks'), this.get('store').findAll('host')]). // Need inactive ones in case a link points to an inactive host
      then(function (results) {
        var model = _ember['default'].Object.create({
          instance: instance.clone(),
          ports: results[0],
          instanceLinks: results[1],
          allHosts: results[2]
        });

        _this.setProperties({
          originalModel: instance,
          model: model,
          loading: false
        });
      });
    },

    didSave: function didSave() {
      return _ember['default'].RSVP.all([this.savePorts(), this.saveLinks()]);
    },

    savePorts: function savePorts() {
      var promises = [];
      this.get('portsArray').forEach(function (port) {
        var neu = parseInt(port['public'], 10);
        if (isNaN(neu)) {
          neu = null;
        }

        var obj = port.obj;
        if (neu !== _ember['default'].get(obj, 'publicPort')) {
          //console.log('Changing port',obj.serialize(),'to',neu);
          obj.set('publicPort', neu);
          promises.push(obj.save());
        }
      });

      return _ember['default'].RSVP.all(promises);
    },

    saveLinks: function saveLinks() {
      var promises = [];
      this.get('linksArray').forEach(function (link) {
        var neu = link.targetInstanceId;
        var obj = link.obj;
        if (neu !== _ember['default'].get(obj, 'targetInstanceId')) {
          //console.log('Changing link',obj.serialize(),'to',neu);
          obj.set('targetInstanceId', neu);
          promises.push(obj.save());
        }
      });

      return _ember['default'].RSVP.all(promises);
    },

    doneSaving: function doneSaving() {
      this.sendAction('dismiss');
    }
  });
});