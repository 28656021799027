define('ui/components/input-paste/component', ['exports', 'ember', 'ui/mixins/intl-placeholder'], function (exports, _ember, _uiMixinsIntlPlaceholder) {
  exports['default'] = _ember['default'].TextField.extend(_uiMixinsIntlPlaceholder['default'], {
    _onPaste: null,
    didInsertElement: function didInsertElement() {
      this._super();

      this.set('_onPaste', this.handlePaste.bind(this));
      this.$().on('paste', this.get('_onPaste'));
    },

    willDestroyElement: function willDestroyElement() {
      this.$().off('paste', this.get('_onPaste'));
      this._super();
    },

    handlePaste: function handlePaste(event) {
      var e = event.originalEvent;
      if (e && e.clipboardData && e.clipboardData.getData && e.clipboardData.types) {
        if (e.clipboardData.types.contains('text/plain')) {
          var text = e.clipboardData.getData('text/plain');
          if (text) {
            e.stopPropagation();
            e.preventDefault();
            this.sendAction('pasted', text, event.target);
            return false;
          }
        }

        return true;
      }
    }
  });
});