define("ui/hosts/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 2
            },
            "end": {
              "line": 4,
              "column": 93
            }
          },
          "moduleName": "ui/hosts/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["hostsPage.index.addHost"], [], ["loc", [null, [4, 62], [4, 93]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 191
            }
          },
          "moduleName": "ui/hosts/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "tooltip-link", [], ["icon", "icon-ellipsis", "text", ["subexpr", "t", ["hostsPage.index.tooltip.listView"], [], ["loc", [null, [7, 127], [7, 165]]], 0, 0], "options", ["subexpr", "@mut", [["get", "listLinkOptions", ["loc", [null, [7, 174], [7, 189]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [7, 86], [7, 191]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 4
            },
            "end": {
              "line": 8,
              "column": 203
            }
          },
          "moduleName": "ui/hosts/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "tooltip-link", [], ["icon", "icon-layeredgroup", "text", ["subexpr", "t", ["hostsPage.index.tooltip.groupedView"], [], ["loc", [null, [8, 135], [8, 176]]], 0, 0], "options", ["subexpr", "@mut", [["get", "groupLinkOptions", ["loc", [null, [8, 185], [8, 201]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 90], [8, 203]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 0
            },
            "end": {
              "line": 20,
              "column": 0
            }
          },
          "moduleName": "ui/hosts/index/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "host-pod", [], ["model", ["subexpr", "@mut", [["get", "item", ["loc", [null, [19, 19], [19, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "mode", ["subexpr", "@mut", [["get", "mode", ["loc", [null, [19, 29], [19, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "newContainer", "newContainer", "show", ["subexpr", "@mut", [["get", "show", ["loc", [null, [19, 67], [19, 71]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 2], [19, 73]]], 0, 0]],
        locals: ["item"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "ui/hosts/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "btn-group pull-right");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "btn-group pull-right r-mr10 r-mt5");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "style", "font-weight: normal;");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(element0, [7, 1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(element1, 1, 1);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        morphs[4] = dom.createMorphAt(element2, 0, 0);
        morphs[5] = dom.createMorphAt(element2, 2, 2);
        morphs[6] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["inline", "t", ["hostsPage.index.header"], [], ["loc", [null, [2, 6], [2, 36]]], 0, 0], ["block", "link-to", ["hosts.new"], ["classNames", "btn btn-sm btn-primary"], 0, null, ["loc", [null, [4, 2], [4, 105]]]], ["block", "link-to", ["hosts", ["subexpr", "query-params", [], ["mode", "dot"], ["loc", [null, [7, 23], [7, 48]]], 0, 0]], ["classNames", "btn btn-sm btn-default"], 1, null, ["loc", [null, [7, 4], [7, 203]]]], ["block", "link-to", ["hosts", ["subexpr", "query-params", [], ["mode", "grouped"], ["loc", [null, [8, 23], [8, 52]]], 0, 0]], ["classNames", "btn btn-sm btn-default"], 2, null, ["loc", [null, [8, 4], [8, 215]]]], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "showSystem", ["loc", [null, [14, 72], [14, 82]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 40], [14, 84]]], 0, 0], ["inline", "t", ["hostsPage.index.showSystem"], [], ["loc", [null, [14, 85], [14, 119]]], 0, 0], ["block", "columns-section", [], ["pods", ["subexpr", "@mut", [["get", "model.hosts", ["loc", [null, [18, 24], [18, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "emptyMessage", ["subexpr", "t", ["hostsPage.index.columns.emptyMessage"], [], ["loc", [null, [18, 49], [18, 91]]], 0, 0]], 3, null, ["loc", [null, [18, 0], [20, 20]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});