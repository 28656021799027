define('ui/initializers/viewport', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize() {
    if (screen && screen.width <= 570) {
      document.getElementById('viewport').setAttribute('content', 'width=570');
    }
  }

  exports['default'] = {
    name: 'viewport',
    initialize: initialize
  };
});