define('ui/components/action-menu-item/component', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/platform'], function (exports, _ember, _uiUtilsConstants, _uiUtilsPlatform) {
  exports['default'] = _ember['default'].Component.extend({
    resourceActions: _ember['default'].inject.service('resource-actions'),
    icon: 'icon-help',
    label: '',
    prefix: null,
    enabled: true,
    actionArg: null,
    altActionArg: null,

    tagName: 'a',
    classNameBindings: ['enabled::hide'],
    attributeBindings: ['tabindex'],
    tabindex: 0,

    click: function click(event) {
      if ((0, _uiUtilsPlatform.isAlternate)(event) && this.get('altActionArg')) {
        this.sendAction('action', this.get('altActionArg'));
      } else {
        this.sendAction('action', this.get('actionArg'));
      }
    },

    keyPress: function keyPress(event) {
      if ([_uiUtilsConstants['default'].KEY.CR, _uiUtilsConstants['default'].KEY.LF].indexOf(event.which) >= 0) {
        this.click(event);
        this.get('resourceActions').hide();
      }
    },

    willRender: function willRender() {
      var icon = this.get('icon');

      if (icon.indexOf('icon-') === -1) {
        this.set('prefix', 'icon icon-fw');
      }
    },

    iconChanged: (function () {
      this.rerender();
    }).observes('icon')
  });
});