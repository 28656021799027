define("ui/components/apikey-row/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 2
            },
            "end": {
              "line": 5,
              "column": 41
            }
          },
          "moduleName": "ui/components/apikey-row/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["content", "model.displayName", ["loc", [null, [5, 20], [5, 41]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 41
            },
            "end": {
              "line": 5,
              "column": 109
            }
          },
          "moduleName": "ui/components/apikey-row/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "text-muted");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["apiPage.table.noName"], [], ["loc", [null, [5, 74], [5, 102]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 8,
              "column": 48
            }
          },
          "moduleName": "ui/components/apikey-row/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["content", "model.description", ["loc", [null, [8, 27], [8, 48]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 48
            },
            "end": {
              "line": 8,
              "column": 123
            }
          },
          "moduleName": "ui/components/apikey-row/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "text-muted");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["apiPage.table.noDescription"], [], ["loc", [null, [8, 81], [8, 116]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 2
            },
            "end": {
              "line": 11,
              "column": 48
            }
          },
          "moduleName": "ui/components/apikey-row/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["content", "model.publicValue", ["loc", [null, [11, 27], [11, 48]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 48
            },
            "end": {
              "line": 11,
              "column": 123
            }
          },
          "moduleName": "ui/components/apikey-row/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "text-muted");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["apiPage.table.noPublicValue"], [], ["loc", [null, [11, 81], [11, 116]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 19,
            "column": 0
          }
        },
        "moduleName": "ui/components/apikey-row/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "text-muted");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "actions");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        var element3 = dom.childAt(fragment, [6]);
        var element4 = dom.childAt(fragment, [8]);
        var element5 = dom.childAt(fragment, [10]);
        var morphs = new Array(12);
        morphs[0] = dom.createAttrMorph(element0, 'data-title');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createAttrMorph(element1, 'data-title');
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        morphs[4] = dom.createAttrMorph(element2, 'data-title');
        morphs[5] = dom.createMorphAt(element2, 1, 1);
        morphs[6] = dom.createAttrMorph(element3, 'data-title');
        morphs[7] = dom.createMorphAt(element3, 1, 1);
        morphs[8] = dom.createAttrMorph(element4, 'data-title');
        morphs[9] = dom.createMorphAt(dom.childAt(element4, [1]), 0, 0);
        morphs[10] = dom.createAttrMorph(element5, 'data-title');
        morphs[11] = dom.createMorphAt(element5, 1, 1);
        return morphs;
      },
      statements: [["attribute", "data-title", ["concat", [["subexpr", "t", ["apiPage.table.state"], [], ["loc", [null, [1, 16], [1, 43]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "badge-state", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [2, 22], [2, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [2, 29]]], 0, 0], ["attribute", "data-title", ["concat", [["subexpr", "t", ["apiPage.table.name"], [], ["loc", [null, [4, 16], [4, 42]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "model.name", ["loc", [null, [5, 8], [5, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [5, 2], [5, 116]]]], ["attribute", "data-title", ["concat", [["subexpr", "t", ["apiPage.table.description"], [], ["loc", [null, [7, 16], [7, 49]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "model.description", ["loc", [null, [8, 8], [8, 25]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [8, 2], [8, 130]]]], ["attribute", "data-title", ["concat", [["subexpr", "t", ["apiPage.table.publicValue"], [], ["loc", [null, [10, 16], [10, 49]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "model.publicValue", ["loc", [null, [11, 8], [11, 25]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [11, 2], [11, 130]]]], ["attribute", "data-title", ["concat", [["subexpr", "t", ["apiPage.table.created"], [], ["loc", [null, [13, 16], [13, 45]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "date-calendar", [["get", "model.created", ["loc", [null, [14, 42], [14, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [14, 26], [14, 57]]], 0, 0], ["attribute", "data-title", ["concat", [["subexpr", "t", ["generic.actions"], [], ["loc", [null, [16, 16], [16, 39]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "action-menu", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [17, 22], [17, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 2], [17, 29]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});