define('ui/components/environment-name/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    environmentId: null,
    loading: true,
    env: null,
    onlyType: null,

    tagName: '',

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      var id = this.get('environmentId');
      var onlyType = this.get('onlyType');

      this.setProperties({
        loading: true,
        env: null
      });

      if (id) {
        this.get('userStore').find('project', id).then(function (env) {
          if (!onlyType || env.get('type').toLowerCase() === onlyType.toLowerCase()) {
            _this.set('env', env);
          }
        })['catch'](function () {
          _this.set('env', null);
        })['finally'](function () {
          _this.set('loading', false);
        });
      } else {
        this.set('loading', false);
      }
    }
  });
});