define('ui/utils/parse-externalid', ['exports', 'ui/utils/constants'], function (exports, _uiUtilsConstants) {
  exports.parseExternalId = parseExternalId;

  // Parses externalIds on services into
  // {
  //  kind: what kind of id this is supposed to be
  //  group: for catalog, what group it's in
  //  id: the actual external id

  function parseExternalId(externalId) {
    var CE = _uiUtilsConstants['default'].EXTERNAL_ID;

    var nameVersion;
    var out = {
      kind: null,
      group: null,
      base: null,
      id: null,
      name: null,
      version: null
    };

    if (!externalId) {
      return out;
    }

    var idx = externalId.indexOf(CE.KIND_SEPARATOR);
    if (idx >= 0) {
      // New style kind://[group:]id
      out.kind = externalId.substr(0, idx);

      var rest = externalId.substr(idx + CE.KIND_SEPARATOR.length);
      idx = rest.indexOf(CE.GROUP_SEPARATOR);
      out.id = rest;
      if (idx >= 0) {
        // With group kind://group/id
        out.group = rest.substr(0, idx);
        nameVersion = rest.substr(idx + 1);
      } else {
        // Without group kind://id
        if (out.kind === CE.KIND_CATALOG) {
          // For catalog kinds, we have a default group
          out.group = CE.CATALOG_DEFAULT_GROUP;
        }

        nameVersion = rest;
      }
    } else {

      var dashedIdx = externalId.lastIndexOf('-');

      // Old style just an ID
      out.kind = CE.KIND_CATALOG;
      var group = CE.CATALOG_DEFAULT_GROUP;
      var _name = externalId.substr(0, dashedIdx);
      var version = externalId.substr(dashedIdx + 1);
      nameVersion = '' + _name + CE.ID_SEPARATOR + version;
      // defaultgroup:extid:version
      out.id = '' + group + CE.GROUP_SEPARATOR + nameVersion;
      out.group = group;
    }

    if (nameVersion) {
      idx = nameVersion.lastIndexOf(CE.ID_SEPARATOR);
      var nameBase = undefined;
      if (idx > 0) {
        out.version = nameVersion.substr(idx + 1);
        nameBase = nameVersion.substr(0, idx);
      } else {
        nameBase = nameVersion;
      }

      out.templateId = '' + out.group + CE.GROUP_SEPARATOR + nameBase;

      idx = nameBase.lastIndexOf(CE.BASE_SEPARATOR);
      if (idx > 0) {
        out.base = nameBase.substr(0, idx);
        out.name = nameBase.substr(idx + 1);
      } else {
        out.name = nameBase;
      }
    }

    return out;
  }
});