define('ui/components/form-container-links/component', ['exports', 'ember', 'ui/mixins/container-choices'], function (exports, _ember, _uiMixinsContainerChoices) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsContainerChoices['default'], {
    // Inputs
    editing: null,
    instance: null,
    allHosts: null,
    initialLinks: null,
    linksArray: null,

    tagName: '',
    errors: null,

    actions: {
      addLink: function addLink() {
        this.get('linksArray').pushObject({ name: '', targetInstanceId: null });
      },
      removeLink: function removeLink(obj) {
        this.get('linksArray').removeObject(obj);
      }
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      var out = [];
      var links = this.get('initialLinks') || [];

      if (_ember['default'].isArray(links)) {
        links.forEach(function (value) {
          // Objects, from edit
          if (typeof value === 'object') {
            out.push({
              existing: value.id ? true : false,
              obj: value,
              name: value.linkName || value.name,
              targetInstanceId: value.targetInstanceId
            });
          } else {
            // Strings, from create maybe
            var match = value.match(/^([^:]+):(.*)$/);
            if (match) {
              out.push({ name: match[1], targetInstanceId: match[2], existing: false });
            }
          }
        });
      }

      _ember['default'].run.scheduleOnce('afterRender', function () {
        _this.set('linksArray', out);
        _this.linksDidChange();
      });
    },

    linksDidChange: (function () {
      var _this2 = this;

      var errors = [];
      var linksAsMap = {};

      this.get('linksArray').forEach(function (row) {
        if (row.targetInstanceId) {
          var name = row.name;
          // Lookup the container name if an "as name" wasn't given
          if (!name) {
            var container = _this2.get('store').getById('container', row.targetInstanceId);
            if (container) {
              name = container.name;
            }
          }

          if (name) {
            linksAsMap[name] = row.targetInstanceId;
          } else {
            errors.push('Link to container ' + row.targetInstanceId + '  must have an "as name".');
          }
        }
      });

      this.set('instance.instanceLinks', linksAsMap);
      this.set('errors', errors);
      this.sendAction('changed', this.get('linksArray'));
    }).observes('linksArray.@each.{targetInstanceId,name}')
  });
});