define("ui/components/machine/driver-other/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 4
            },
            "end": {
              "line": 36,
              "column": 4
            }
          },
          "moduleName": "ui/components/machine/driver-other/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "api-field", [], ["resource", ["subexpr", "@mut", [["get", "driverOpts", ["loc", [null, [29, 17], [29, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "resourceType", ["subexpr", "@mut", [["get", "otherDriver", ["loc", [null, [30, 21], [30, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "field", ["subexpr", "@mut", [["get", "fieldName", ["loc", [null, [31, 14], [31, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "get", [["get", "driverOpts", ["loc", [null, [32, 19], [32, 29]]], 0, 0, 0, 0], ["get", "fieldName", ["loc", [null, [32, 30], [32, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [32, 14], [32, 40]]], 0, 0], "schemas", ["subexpr", "@mut", [["get", "schemas", ["loc", [null, [33, 16], [33, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "typeDocs", ["subexpr", "@mut", [["get", "typeDocumentations", ["loc", [null, [34, 17], [34, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [28, 6], [35, 8]]], 0, 0]],
        locals: ["fieldName"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 44,
            "column": 0
          }
        },
        "moduleName": "ui/components/machine/driver-other/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "horizontal-form");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "container-fluid");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "over-hr r-mt20 r-mb20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "row form-group");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-2 form-label");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("label");
        dom.setAttribute(el5, "class", "form-control-static");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        var el6 = dom.createTextNode("*");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "col-md-8");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "over-hr r-mt20 r-mb20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [1]);
        var element1 = dom.childAt(element0, [1]);
        var element2 = dom.childAt(element1, [5]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [3, 1]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element2, [1, 1]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [7, 1]), 0, 0);
        morphs[5] = dom.createMorphAt(element1, 9, 9);
        morphs[6] = dom.createMorphAt(element1, 11, 11);
        morphs[7] = dom.createMorphAt(element0, 3, 3);
        morphs[8] = dom.createMorphAt(element0, 5, 5);
        return morphs;
      },
      statements: [["inline", "partial", ["host/add-common"], [], ["loc", [null, [4, 4], [4, 33]]], 0, 0], ["inline", "t", ["machine.driverOther.driverSection"], [], ["loc", [null, [7, 12], [7, 53]]], 0, 0], ["inline", "t", ["machine.driverOther.driver.label"], [], ["loc", [null, [12, 43], [12, 83]]], 0, 0], ["inline", "new-select", [], ["classNames", "form-control", "content", ["subexpr", "@mut", [["get", "otherChoices", ["loc", [null, [17, 18], [17, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "otherDriver", ["loc", [null, [18, 16], [18, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [15, 8], [19, 10]]], 0, 0], ["inline", "t", ["machine.driverOther.optionsSection"], ["driverName", ["subexpr", "str-replace", [["get", "driver", ["loc", [null, [24, 77], [24, 83]]], 0, 0, 0, 0]], ["match", "Config", "with", ""], ["loc", [null, [24, 64], [24, 107]]], 0, 0]], ["loc", [null, [24, 12], [24, 109]]], 0, 0], ["block", "each", [["get", "fieldNames", ["loc", [null, [27, 12], [27, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [27, 4], [36, 13]]]], ["inline", "partial", ["host/add-options"], [], ["loc", [null, [38, 4], [38, 34]]], 0, 0], ["inline", "top-errors", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [41, 22], [41, 28]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [41, 2], [41, 30]]], 0, 0], ["inline", "save-cancel", [], ["save", "save", "cancel", "cancel"], ["loc", [null, [42, 2], [42, 45]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});