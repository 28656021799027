define('ui/components/labels-section/component', ['exports', 'ember', 'ui/mixins/manage-labels', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsManageLabels, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsManageLabels['default'], _uiMixinsSortable['default'], {
    model: null,

    labelSource: _ember['default'].computed.alias('model.labels'),
    sortableContent: _ember['default'].computed.alias('labelArray'),
    sortBy: 'kind',
    showKind: true,
    descending: true,

    sorts: {
      kind: ['type', 'key'],
      key: ['key'],
      value: ['value', 'key']
    },

    labelsObserver: _ember['default'].observer('model.labels', function () {
      this.initLabels(this.get('labelSource'));
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      this.initLabels(this.get('labelSource'));
    }
  });
});