define('ui/helpers/date-calendar', ['exports', 'ember'], function (exports, _ember) {
  exports.dateCalendar = dateCalendar;

  function dateCalendar(params, options) {
    var out = moment(params[0]).calendar();
    if (options && options.withToday !== true) {
      out = out.replace('Today at ', '');
    }

    return out;
  }

  exports['default'] = _ember['default'].Helper.helper(dateCalendar);
});