define('ui/models/typedocumentation', ['exports', 'ember-api-store/utils/normalize', 'ember-api-store/models/resource'], function (exports, _emberApiStoreUtilsNormalize, _emberApiStoreModelsResource) {

  var TypeDocumentation = _emberApiStoreModelsResource['default'].extend();

  TypeDocumentation.reopenClass({
    mangleIn: function mangleIn(data) {
      // Pass IDs through the type normalizer so they will match the case in other places like store.find('schema',normalizeType('thing'))
      data.id = (0, _emberApiStoreUtilsNormalize.normalizeType)(data.id);
      return data;
    }
  });

  exports['default'] = TypeDocumentation;
});