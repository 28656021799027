define('ui/container/networking/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      return this.get('store').findAll('host').then(function (hosts) {
        return {
          hosts: hosts,
          container: _this.modelFor('container')
        };
      });
    }
  });
});