define('ui/instance-initializers/user-store', ['exports', 'ui/mixins/store-tweaks'], function (exports, _uiMixinsStoreTweaks) {
  exports.initialize = initialize;

  function initialize(instance) {
    var application = instance.lookup('application:main');
    var store = instance.lookup('service:user-store');
    var cookies = instance.lookup('service:cookies');

    store.reopen(_uiMixinsStoreTweaks['default']);
    store.baseUrl = application.apiEndpoint;

    var timeout = cookies.get('timeout');
    if (timeout) {
      store.defaultTimeout = timeout;
    }
  }

  exports['default'] = {
    name: 'user-store',
    initialize: initialize
  };
});