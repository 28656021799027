define('ui/admin-tab/audit-logs/route', ['exports', 'ember'], function (exports, _ember) {

  var INTERVALCOUNT = 15000;

  exports['default'] = _ember['default'].Route.extend({
    queryParams: {
      sortBy: {
        refreshModel: true
      },
      sortOrder: {
        refreshModel: true
      },
      eventType: {
        refreshModel: true
      },
      resourceType: {
        refreshModel: true
      },
      resourceId: {
        refreshModel: true
      },
      clientIp: {
        refreshModel: true
      },
      authType: {
        refreshModel: true
      }
    },

    timer: null,
    userHasPaged: null,

    actions: {
      filterLogs: function filterLogs() {
        this.cancelLogUpdate();
      },

      logsSorted: function logsSorted() {
        this.cancelLogUpdate();
      },

      next: function next() {
        var _this = this;

        this.cancelLogUpdate();
        this.set('userHasPaged', true);

        this.controller.model.auditLog.followPagination('next').then(function (response) {
          _this.controller.set('model.auditLog', response);
        });
      },

      first: function first() {
        this.set('userHasPaged', false);
        this.refresh();
        this.scheduleLogUpdate();
      }
    },

    deactivate: function deactivate() {
      this.cancelLogUpdate();
      this.set('userHasPaged', false);
    },

    model: function model(params) {
      var _this2 = this;

      this.cancelLogUpdate();

      return this.get('userStore').find('auditLog', null, this.parseFilters(params)).then(function (response) {
        var resourceTypes = _this2.get('userStore').all('schema').filterBy('links.collection').map(function (x) {
          return x.get('_id');
        });

        return _ember['default'].Object.create({
          auditLog: response,
          resourceTypes: resourceTypes
        });
      });
    },

    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.scheduleLogUpdate();
    },

    scheduleLogUpdate: function scheduleLogUpdate() {
      var _this3 = this;

      _ember['default'].run.cancel(this.get('timer'));

      this.set('timer', _ember['default'].run.later(function () {
        var params = _this3.paramsFor('admin-tab.audit-logs');

        _this3.get('userStore').find('auditLog', null, _this3.parseFilters(params)).then(function (response) {
          // We can get into a state where the user paged but we have an unresolved promise from the previous
          // run. If thats the case we dont want to replace the page with this unresolved promise.
          if (!_this3.get('userHasPaged')) {

            _this3.controller.set('model.auditLog', response);
            if (_this3.get('timer')) {
              _this3.scheduleLogUpdate();
            }
          }
        }, function () /* error */{});
      }, INTERVALCOUNT));
    },

    cancelLogUpdate: function cancelLogUpdate() {
      _ember['default'].run.cancel(this.get('timer'));
      this.set('timer', null);
    },

    parseFilters: function parseFilters(params) {
      var returnValue = {
        filter: {},
        limit: 100,
        depaginate: false,
        forceReload: true
      };
      if (params) {
        _.forEach(params, function (item, key) {
          if (['sortBy', 'sortOrder', 'forceReload'].indexOf(key) >= 0) {
            returnValue[key] = item;
          } else {
            if (item) {
              returnValue.filter[key] = item;
            } else {
              delete returnValue.filter[key];
            }
          }
        });
      }
      return returnValue;
    }

  });
});