define("ui/components/form-ssl-termination/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 16
                },
                "end": {
                  "line": 30,
                  "column": 16
                }
              },
              "moduleName": "ui/components/form-ssl-termination/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "btn-circle-text");
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "icon icon-plus-circle");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("span");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element2 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element2);
              morphs[1] = dom.createMorphAt(dom.childAt(element2, [2]), 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["addAlternate"], [], ["loc", [null, [29, 50], [29, 75]]], 0, 0], ["inline", "t", ["formSslTermination.addAlternate"], [], ["loc", [null, [29, 117], [29, 156]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 30,
                  "column": 16
                },
                "end": {
                  "line": 32,
                  "column": 16
                }
              },
              "moduleName": "ui/components/form-ssl-termination/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("span");
              dom.setAttribute(el1, "class", "text-muted form-control-static");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["formSslTermination.noAlternateCertificates"], [], ["loc", [null, [31, 63], [31, 113]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 18
                  },
                  "end": {
                    "line": 53,
                    "column": 18
                  }
                },
                "moduleName": "ui/components/form-ssl-termination/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn btn-primary btn-sm");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "icon icon-minus");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element0);
                return morphs;
              },
              statements: [["element", "action", ["removeAlternate", ["get", "alt", ["loc", [null, [52, 86], [52, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 59], [52, 91]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 38,
                  "column": 12
                },
                "end": {
                  "line": 56,
                  "column": 12
                }
              },
              "moduleName": "ui/components/form-ssl-termination/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                  ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2, "class", "text-right");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element1, [1]), 1, 1);
              morphs[1] = dom.createMorphAt(dom.childAt(element1, [3]), 1, 1);
              return morphs;
            },
            statements: [["inline", "new-select", [], ["classNames", "form-control", "prompt", "formSslTermination.alternateCertificate.prompt", "content", ["subexpr", "@mut", [["get", "alternateCertificates", ["loc", [null, [44, 28], [44, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "optionLabelPath", "displayDetailedName", "optionValuePath", "id", "value", ["subexpr", "@mut", [["get", "alt.value", ["loc", [null, [47, 26], [47, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [41, 18], [48, 20]]], 0, 0], ["block", "unless", [["get", "link.existing", ["loc", [null, [51, 28], [51, 41]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [51, 18], [53, 29]]]]],
            locals: ["alt"],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 67,
                "column": 2
              }
            },
            "moduleName": "ui/components/form-ssl-termination/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row form-group");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-12 col-md-2 form-label");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3, "class", "form-control-static");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-12 col-md-8");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row form-group");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-12 col-md-2 form-label");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("label");
            dom.setAttribute(el3, "class", "form-control-static");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-12 col-md-8");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("table");
            dom.setAttribute(el3, "class", "table fixed no-lines no-top-padding tight small");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("thead");
            var el5 = dom.createTextNode("\n            ");
            dom.appendChild(el4, el5);
            var el5 = dom.createElement("tr");
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("td");
            var el7 = dom.createTextNode("\n");
            dom.appendChild(el6, el7);
            var el7 = dom.createComment("");
            dom.appendChild(el6, el7);
            var el7 = dom.createTextNode("              ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n              ");
            dom.appendChild(el5, el6);
            var el6 = dom.createElement("th");
            dom.setAttribute(el6, "width", "30");
            var el7 = dom.createTextNode(" ");
            dom.appendChild(el6, el7);
            dom.appendChild(el5, el6);
            var el6 = dom.createTextNode("\n            ");
            dom.appendChild(el5, el6);
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("\n          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("tbody");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row form-group");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-sm-12 col-md-offset-2 col-md-8");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3, "class", "help-block");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element3 = dom.childAt(fragment, [1]);
            var element4 = dom.childAt(fragment, [3]);
            var element5 = dom.childAt(element4, [3, 1]);
            var morphs = new Array(6);
            morphs[0] = dom.createMorphAt(dom.childAt(element3, [1, 1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element3, [3]), 1, 1);
            morphs[2] = dom.createMorphAt(dom.childAt(element4, [1, 1]), 0, 0);
            morphs[3] = dom.createMorphAt(dom.childAt(element5, [1, 1, 1]), 1, 1);
            morphs[4] = dom.createMorphAt(dom.childAt(element5, [3]), 1, 1);
            morphs[5] = dom.createMorphAt(dom.childAt(fragment, [5, 1, 1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["formSslTermination.certificate"], [], ["loc", [null, [5, 43], [5, 81]]], 0, 0], ["inline", "new-select", [], ["classNames", "form-control", "prompt", "formSslTermination.defaultCertificate.prompt", "content", ["subexpr", "@mut", [["get", "allCertificates", ["loc", [null, [11, 18], [11, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "optionLabelPath", "displayDetailedName", "optionValuePath", "id", "value", ["subexpr", "@mut", [["get", "lbConfig.defaultCertificateId", ["loc", [null, [14, 16], [14, 45]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 8], [15, 10]]], 0, 0], ["inline", "t", ["formSslTermination.alternateCerts"], [], ["loc", [null, [21, 43], [21, 84]]], 0, 0], ["block", "if", [["get", "alternateCertificates.length", ["loc", [null, [28, 22], [28, 50]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [28, 16], [32, 23]]]], ["block", "each", [["get", "alternates", ["loc", [null, [38, 20], [38, 30]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [38, 12], [56, 21]]]], ["inline", "format-html-message", ["formSslTermination.helpBlock"], [], ["loc", [null, [64, 30], [64, 84]]], 0, 0]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 67,
                "column": 2
              },
              "end": {
                "line": 69,
                "column": 2
              }
            },
            "moduleName": "ui/components/form-ssl-termination/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("span");
            dom.setAttribute(el1, "class", "text-muted");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["formSslTermination.noCertificates"], [], ["loc", [null, [68, 29], [68, 70]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 70,
              "column": 0
            }
          },
          "moduleName": "ui/components/form-ssl-termination/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "allCertificates.length", ["loc", [null, [2, 8], [2, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [69, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 70,
              "column": 0
            },
            "end": {
              "line": 74,
              "column": 0
            }
          },
          "moduleName": "ui/components/form-ssl-termination/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "text-muted");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["formSslTermination.notNeeded"], [], ["loc", [null, [72, 4], [72, 40]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 75,
            "column": 0
          }
        },
        "moduleName": "ui/components/form-ssl-termination/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "liquid-if", [["get", "lbConfig.needsCertificate", ["loc", [null, [1, 13], [1, 38]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [74, 14]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});