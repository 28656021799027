define("ui/components/edit-certificate/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "ui/components/edit-certificate/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "horizontal-form container-fluid");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h2");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "alert alert-info");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element0, [5, 1]), 0, 0);
        morphs[3] = dom.createMorphAt(element0, 7, 7);
        morphs[4] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[5] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        return morphs;
      },
      statements: [["inline", "t", ["editCertificate.title"], [], ["loc", [null, [2, 6], [2, 35]]], 0, 0], ["inline", "form-name-description", [], ["model", ["subexpr", "@mut", [["get", "primaryResource", ["loc", [null, [5, 10], [5, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "nameRequired", true, "namePlaceholder", "editCertificate.name.placeholder", "descriptionPlaceholder", "editCertificate.description.placeholder"], ["loc", [null, [4, 2], [9, 4]]], 0, 0], ["inline", "t", ["editCertificate.noteKeyWriteOnly"], [], ["loc", [null, [12, 34], [12, 74]]], 0, 0], ["inline", "input-certificate", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [15, 28], [15, 33]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [15, 2], [15, 35]]], 0, 0], ["inline", "top-errors", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [18, 20], [18, 26]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [18, 0], [18, 28]]], 0, 0], ["inline", "save-cancel", [], ["editing", true, "save", "save", "cancel", "cancel"], ["loc", [null, [19, 0], [19, 56]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});