define('ui/components/advanced-section/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    advanced: false,

    tagName: null,

    actions: {
      toggle: function toggle() {
        this.set('advanced', !this.get('advanced'));
      }
    }
  });
});