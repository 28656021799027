define('ui/admin-tab/auth/openldap/controller', ['exports', 'ember', 'ui/admin-tab/auth/activedirectory/controller', 'ui/utils/constants'], function (exports, _ember, _uiAdminTabAuthActivedirectoryController, _uiUtilsConstants) {
  exports['default'] = _uiAdminTabAuthActivedirectoryController['default'].extend({
    providerName: 'ldap.providerName.openldap',
    isOpenLdap: true,
    userType: _uiUtilsConstants['default'].PROJECT.TYPE_OPENLDAP_USER,
    groupType: _uiUtilsConstants['default'].PROJECT.TYPE_OPENLDAP_GROUP,
    ldapConfig: _ember['default'].computed.alias('model'),
    actions: {
      test: function test() {
        var _this = this;

        this.send('clearError');

        var model = this.get('model');
        model.setProperties({
          enabled: false,
          'accessMode': 'unrestricted'
        });

        var errors = model.validationErrors();
        if (errors.get('length')) {
          this.set('errors', errors);
        } else {
          this.set('testing', true);
          model.save().then(function () {
            _this.send('authenticate');
          })['catch'](function (err) {
            _this.send('gotError', err);
          });
        }
      },

      authenticate: function authenticate() {
        var _this2 = this;

        this.send('clearError');
        var code = this.get('username') + ':' + this.get('password');

        this.get('access').login(code).then(function (res) {
          _this2.send('authenticationSucceeded', res.body);
        })['catch'](function (err) {
          _this2.send('gotError', err);
        });
      },

      authenticationSucceeded: function authenticationSucceeded(auth) {
        var _this3 = this;

        this.send('clearError');
        this.set('organizations', auth.orgs);

        // Set this to true so the token will be sent with the request
        this.set('access.enabled', true);

        var model = this.get('model');
        model.setProperties({
          'enabled': true,
          'accessMode': 'unrestricted',
          'allowedIdentities': [auth.userIdentity]
        });

        model.save().then(function () {
          _this3.send('waitAndRefresh');
        })['catch'](function (err) {
          _this3.set('access.enabled', false);
          _this3.send('gotError', err);
        });
      }
    }
  });
});