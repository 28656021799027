define('ui/catalog-tab/launch/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['stackId', 'upgrade'],
    stackId: null,
    upgrade: null,

    parentRoute: 'catalog-tab',

    actions: {
      cancel: function cancel() {
        this.transitionToRoute(this.get('parentRoute'));
      }
    }
  });
});