define('ui/utils/parse-port', ['exports', 'ember'], function (exports, _ember) {
  exports.parsePortSpec = parsePortSpec;
  exports.stringifyPortSpec = stringifyPortSpec;
  exports.parseIpPort = parseIpPort;
  exports.portToInt = portToInt;

  // hostIp:hostPort:containerPort
  // hostIp::containerPort
  // hostPort:containerPort
  // containerPort

  function parsePortSpec(str, defaultProtocol) {
    str = str.trim();

    var match,
        parts,
        hostIp = '',
        hostPort,
        containerPort,
        protocol;

    // Protocol
    if (match = str.match(/\/([a-z]+)$/i)) {
      protocol = match[1].toLowerCase();
      str = str.substr(0, str.length - match[0].length);
    } else {
      protocol = defaultProtocol;
    }

    // IPv6
    if (str.indexOf('[') >= 0 && (match = str.match(/^(\[[^]+\]):/))) {
      parts = str.substr(match[0].length).split(':');
      parts.unshift(match[1]);
    } else {
      parts = str.split(':');
    }

    if (parts.length >= 3) {
      hostIp = parts[0];
      hostPort = parts[1];
      containerPort = parts[2];
    } else if (parts.length === 2) {
      hostIp = null;
      hostPort = parts[0];
      containerPort = parts[1];
    } else {
      hostIp = null;
      hostPort = "";
      containerPort = parts[0];
    }

    return _ember['default'].Object.create({
      host: (hostIp ? hostIp + ':' : '') + hostPort,
      hostIp: hostIp,
      hostPort: parseInt(hostPort, 10) || null,
      container: parseInt(containerPort, 10) || null,
      protocol: protocol
    });
  }

  function stringifyPortSpec(port) {
    var defaultProtocol = arguments.length <= 1 || arguments[1] === undefined ? 'http' : arguments[1];

    var hostStr = _ember['default'].get(port, 'host') || '';
    var match, hostIp, hostPort;
    if (match = hostStr.match(/^((.*):)?([^:]+)$/)) {
      hostIp = match[2];
      hostPort = match[3];
    } else {
      hostIp = null;
      hostPort = hostStr;
    }

    var container = _ember['default'].get(port, 'container');
    var protocol = _ember['default'].get(port, 'protocol');

    var out = '';
    if (hostPort) {
      out = (hostIp ? hostIp + ':' : '') + hostPort + ':';
    }

    out += container;

    if (protocol && (!defaultProtocol || protocol !== defaultProtocol)) {
      out += '/' + protocol;
    }

    return out;
  }

  // port
  // 1.2.3.4
  // 1.2.3.4:port
  // long:ip:v6::str
  // [long:ip:v6::str]
  // [long:ip:v6::str]:port

  function parseIpPort(str) {
    str = str.trim();
    var colons = str.replace(/[^:]/g, '').length;
    var index = undefined;

    // IPv6, IPv6+port
    index = str.indexOf(']');
    if (colons > 1) {
      var _index = str.indexOf(']');
      if (_index > 0) {
        var ip = str.substr(0, _index + 1);
        var _port = null;
        if (str.substr(_index + 1, 1) === ':') {
          _port = portToInt(str.substr(_index + 2));
        }

        return ret(ip, _port);
      } else {
        return ret('[' + str + ']', null);
      }
    }

    // IPv4+port
    index = str.indexOf(':');
    if (index >= 0) {
      return ret(str.substr(0, index), str.substr(index + 1));
    }

    // IPv4
    if (str.match(/[^\d]/)) {
      return ret(str, null);
    }

    // Port
    var port = portToInt(str);
    if (port) {
      return ret(null, port);
    }

    return null;

    function ret(ip, port) {
      return {
        ip: ip || null,
        port: portToInt(port)
      };
    }
  }

  function portToInt(str) {
    str = (str + '').trim();
    if (str.match(/^\d+$/)) {
      return parseInt(str, 10) || null;
    }

    return null;
  }

  exports['default'] = {
    parsePortSpec: parsePortSpec,
    stringifyPortSpec: stringifyPortSpec,
    parseIpPort: parseIpPort,
    portToInt: portToInt
  };
});