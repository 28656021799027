define('ui/components/modal-edit-setting/component', ['exports', 'ember', 'lacsso/components/modal-base', 'ui/services/settings'], function (exports, _ember, _lacssoComponentsModalBase, _uiServicesSettings) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    settings: _ember['default'].inject.service(),
    growl: _ember['default'].inject.service(),

    classNames: ['lacsso', 'modal-container', 'span-8', 'offset-2'],
    model: _ember['default'].computed.alias('modalService.modalOpts'),

    value: null,
    removing: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('value', this.get('model.obj.value') || '');
    },

    actions: {
      save: function save(btnCb) {
        var _this = this;

        this.get('settings').set((0, _uiServicesSettings.normalizeName)(this.get('model.key')), this.get('value'));
        this.get('settings').one('settingsPromisesResolved', function () {
          btnCb(true);
          _this.send('done');
        });
      },

      remove: function remove() {
        var _this2 = this;

        this.set('removing', true);
        var key = this.get('model.key');
        this.get('model.obj')['delete']({ forceRemove: true }).then(function () {
          _this2.get('settings').load([key]).then(function () {
            _this2.send('done');
          });
        })['catch'](function (err) {
          _this2.get('growl').fromError(err);
        })['finally'](function () {
          _this2.set('removing', false);
        });
      },

      done: function done() {
        this.send('cancel');
      }
    }
  });
});