define('ui/components/stack-header/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    settings: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    hasVm: _ember['default'].computed.alias('projects.current.virtualMachine'),

    actions: {
      changeStack: function changeStack(stack) {
        var app = this.get('application');
        app.transitionToRoute(app.get('currentRouteName'), stack.get('id'));
        this.sendAction('hideAddtlInfo');
      }
    },

    outputs: (function () {
      var out = [];
      var map = this.get('model.outputs') || {};
      Object.keys(map).forEach(function (key) {
        out.push(_ember['default'].Object.create({
          key: key,
          value: map[key]
        }));
      });

      return out;
    }).property('model.outputs', 'model.id'),

    listLinkOptions: {
      route: 'stack.index'
    },

    graphLinkOptions: {
      route: 'stack.graph'
    },

    yamlLinkOptions: {
      route: 'stack.code'
    }
  });
});