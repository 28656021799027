define('ui/components/container-shell/component', ['exports', 'ember', 'ui/utils/platform', 'ui/mixins/throttled-resize', 'npm:xterm', 'ui/utils/xterm-fit-addon'], function (exports, _ember, _uiUtilsPlatform, _uiMixinsThrottledResize, _npmXterm, _uiUtilsXtermFitAddon) {

  var DEFAULT_COMMAND = ["/bin/sh", "-c", 'TERM=xterm-256color; export TERM; [ -x /bin/bash ] && ([ -x /usr/bin/script ] && /usr/bin/script -q -c "/bin/bash" /dev/null || exec /bin/bash) || exec /bin/sh'];

  exports['default'] = _ember['default'].Component.extend(_uiMixinsThrottledResize['default'], {
    instance: null,
    command: null,
    cols: 80,
    rows: 24,
    alternateLabel: _uiUtilsPlatform.alternateLabel,
    showProtip: true,
    contenteditable: false,

    status: 'connecting',
    error: null,
    socket: null,
    term: null,

    actions: {
      contextMenuHandler: function contextMenuHandler() {
        var _this = this;

        // fix for no paste button in firefox context menu on Windows
        this.set('contenteditable', true);
        setTimeout(function () {
          _this.set('contenteditable', false);
        }, 20);
      }
    },

    fit: function fit() {
      var term = this.get('term');
      var socket = this.get('socket');
      if (term && socket) {
        var geometry = (0, _uiUtilsXtermFitAddon.proposeGeometry)(term);
        socket.send(':resizeTTY:' + geometry.cols + ',' + geometry.rows);
        term.resize(geometry.cols, geometry.rows);
      }
    },

    onResize: function onResize() {
      this.fit();
    },

    didInsertElement: function didInsertElement() {
      this._super();
      _ember['default'].run.next(this, 'exec');
    },

    exec: function exec() {
      var _this2 = this;

      var instance = this.get('instance');
      var opt = {
        attachStdin: true,
        attachStdout: true,
        tty: true,
        command: this.get('command') || DEFAULT_COMMAND
      };

      if (instance.hasAction('execute')) {
        instance.doAction('execute', opt).then(function (exec) {
          exec.set('instance', instance);
          _this2.connect(exec);
        })['catch'](function (err) {
          _this2.setProperties({
            status: 'error',
            error: err
          });
        });
      }
    },

    connect: function connect(exec) {
      var _this3 = this;

      var url = exec.get('url') + '?token=' + encodeURIComponent(exec.get('token'));
      var socket = new WebSocket(url);
      this.set('socket', socket);

      socket.onopen = function () {
        _this3.set('status', 'initializing');

        var term = new _npmXterm['default']({
          useStyle: true,
          screenKeys: true,
          cursorBlink: false
        });
        _this3.set('term', term);

        term.on('data', function (data) {
          //console.log('To Server:',data);
          socket.send(btoa(unescape(encodeURIComponent(data)))); // jshint ignore:line
        });

        term.open(_this3.$('.shell-body')[0], true);
        _this3.fit();
        socket.onmessage = function (message) {
          _this3.set('status', 'connected');
          _this3.sendAction('connected');
          //console.log('From Server:',message.data);
          term.write(decodeURIComponent(escape(atob(message.data)))); // jshint ignore:line
        };

        socket.onclose = function () {
          try {
            _this3.set('status', 'closed');
            term.destroy();
            if (!_this3.get('userClosed')) {
              _this3.sendAction('dismiss');
            }
          } catch (e) {}
        };
      };
    },

    disconnect: function disconnect() {
      this.set('status', 'closed');
      this.set('userClosed', true);

      var term = this.get('term');
      if (term) {
        term.destroy();
        this.set('term', null);
      }

      var socket = this.get('socket');
      if (socket) {
        socket.close();
        this.set('socket', null);
      }

      this.sendAction('disconnected');
    },

    willDestroyElement: function willDestroyElement() {
      this.disconnect();
      this._super();
    }
  });
});