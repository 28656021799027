define('ui/admin-tab/processes/pools/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortBy: 'name',
    headers: [{
      translationKey: 'generic.name',
      name: 'name',
      sort: ['name'],
      width: '150px'
    }, {
      translationKey: 'processesPage.pools.table.activeTasks',
      name: 'activeTasks',
      sort: ['activeTasks', 'name']
    }, {
      translationKey: 'processesPage.pools.table.poolSize',
      name: 'poolSize',
      sort: ['poolSize', 'name']
    }, {
      translationKey: 'processesPage.pools.table.rejectedTasks',
      name: 'rejectedTasks',
      sort: ['rejectedTasks', 'name']
    }, {
      translationKey: 'processesPage.pools.table.completedTasks',
      name: 'completedTasks',
      sort: ['completedTasks', 'name']
    }, {
      translationKey: 'processesPage.pools.table.queueSize',
      name: 'queueSize',
      sort: ['queueSize', 'name']
    }, {
      translationKey: 'processesPage.pools.table.queueRemainingCapacity',
      name: 'queueRemainingCapacity',
      sort: ['queueRemainingCapacity', 'name']
    }]
  });
});