define('ui/components/form-targets/component', ['exports', 'ember', 'ui/utils/parse-target'], function (exports, _ember, _uiUtilsParseTarget) {
  exports['default'] = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),

    existing: null,
    exclude: null,
    isBalancer: null,
    editing: false,

    classNames: ['form-group'],

    actions: {
      addTargetService: function addTargetService() {
        this.get('targetsArray').pushObject(_ember['default'].Object.create({ isService: true, value: null }));
      },
      removeTarget: function removeTarget(obj) {
        this.get('targetsArray').removeObject(obj);
      },

      setAdvanced: function setAdvanced() {
        this.set('isAdvanced', true);
      }
    },

    isAdvanced: false,
    targetsArray: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('isAdvanced', this.get('editing'));

      var out = [];

      var existing = this.get('existing');
      if (existing && existing.get('linkedServices') !== null) {
        (function () {
          var links = existing.get('linkedServices');
          Object.keys(links).forEach(function (key) {
            out.pushObject(_ember['default'].Object.create({
              isService: true,
              value: links[key]
            }));
          });
        })();
      } else {
        out.pushObject(_ember['default'].Object.create({
          isService: true,
          value: null
        }));
      }

      _ember['default'].run.scheduleOnce('afterRender', function () {
        _this.set('targetsArray', out);
        _this.targetsChanged();
      });
    },

    targetResources: (function () {
      var out = [];
      var array = this.get('targetsArray');
      array.filterBy('isService', true).filterBy('value').map(function (choice) {
        var serviceId = _ember['default'].get(choice, 'value');

        var entry = out.filterBy('serviceId', serviceId)[0];
        if (!entry) {
          entry = _ember['default'].Object.create({
            serviceId: serviceId,
            ports: []
          });
          out.pushObject(entry);
        }

        var str = (0, _uiUtilsParseTarget.stringifyTarget)(choice);
        if (str) {
          entry.get('ports').pushObject(str);
        }
      });

      return out;
    }).property('targetsArray.@each.{isService,value,hostname,path,srcPort,dstPort}'),

    targetsChanged: (function () {
      this.sendAction('changed', this.get('targetsArray'), this.get('targetResources'));
    }).observes('targetResources', 'targetResources.@each.{serviceId,ports}'),

    hasAdvancedSourcePorts: (function () {
      return this.get('targetsArray').filterBy('isService', true).filter(function (target) {
        return parseInt(target.get('srcPort'), 10) > 0;
      }).get('length') > 0;
    }).property('targetsArray.@each.{isService,srcPort}')
  });
});