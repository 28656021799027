define('ui/components/identity-avatar/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    identity: null,
    link: true,
    size: 35,

    classNames: ['gh-avatar'],
    attributeBindings: ['aria-label:identity.name'],

    avatarSrc: _ember['default'].computed.alias('identity.avatarSrc'),
    url: _ember['default'].computed.alias('identity.profileUrl')
  });
});