define('ui/components/textarea-autogrow/component', ['exports', 'ember', 'ui/utils/platform', 'ui/mixins/intl-placeholder'], function (exports, _ember, _uiUtilsPlatform, _uiMixinsIntlPlaceholder) {
  exports['default'] = _ember['default'].TextArea.extend(_uiMixinsIntlPlaceholder['default'], {
    intl: _ember['default'].inject.service(),

    minHeight: 0,
    maxHeight: 200,

    tagName: 'textarea',
    classNames: ['no-resize'],

    didInsertElement: function didInsertElement() {
      _ember['default'].run.scheduleOnce('afterRender', this, 'initHeights');
    },

    initHeights: function initHeights() {
      var _this = this;

      if (this.get('minHeight') === 0) {
        this.set('minHeight', this.get('isSmall') ? 31 : 43);
      }

      this.autoSize();

      this.$().on('paste', function () {
        _ember['default'].run.later(_this, 'autoSize', 100);
      });
    },

    changed: (function () {
      _ember['default'].run.debounce(this, 'autoSize', 100);
    }).observes('value'),

    isSmall: (function () {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      return this.$().hasClass('input-sm');
    }).property(),

    autoSize: function autoSize() {
      var _this2 = this;

      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var el = this.element;
      var $el = $(el);

      _ember['default'].run(function () {
        $el.css('height', '1px');

        var border = parseInt($el.css('borderTopWidth'), 10) || 0 + parseInt($el.css('borderBottomWidth'), 10) || 0;
        var magic = (_this2.get('isSmall') ? -2 : 0) + (_uiUtilsPlatform.isGecko ? 1 : 2); // Sigh, but it's wrong without magic fudge
        var neu = Math.max(_this2.get('minHeight'), Math.min(el.scrollHeight + border + magic, _this2.get('maxHeight')));

        $el.css('overflowY', el.scrollHeight > neu ? 'auto' : 'hidden');
        $el.css('height', neu + 'px');
      });
    }
  });
});