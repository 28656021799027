define('ui/authenticated/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    projects: _ember['default'].inject.service(),

    redirect: function redirect() {
      var project = this.get('projects.current');
      if (project) {
        this.replaceWith('authenticated.project', project.get('id'));
      } else {
        this.replaceWith('settings.projects');
      }
    }
  });
});