define('ui/services/mesos', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Service.extend({
    'tab-session': _ember['default'].inject.service('tab-session'),
    store: _ember['default'].inject.service(),

    publicUrl: function publicUrl() {
      return this.get('store').find('service').then(function (services) {
        var master = services.filterBy('name', _uiUtilsConstants['default'].MESOS.MASTER_SERVICE)[0];
        if (master) {
          var ips = master.get('endpointsMap')[_uiUtilsConstants['default'].MESOS.MASTER_PORT];
          if (ips && ips.length) {
            return 'http://' + ips[0] + ':' + _uiUtilsConstants['default'].MESOS.MASTER_PORT;
          }
        }

        return _ember['default'].RSVP.reject('No mesos-master endpoint found');
      });
    },

    masterUrl: (function () {
      var projectId = this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT);
      return this.get('app.mesosEndpoint').replace('%PROJECTID%', projectId);
    }).property('app.mesosEndpoint', 'tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT),

    isReady: function isReady() {
      var _this = this;

      return this.get('store').find('stack').then(function (stacks) {
        var stack = _this.filterSystemStack(stacks);
        if (stack) {
          return _this.get('store').rawRequest({
            url: _this.get('masterUrl') + '/' + _uiUtilsConstants['default'].MESOS.HEALTH
          }).then(function () {
            return true;
          })['catch'](function () {
            return _ember['default'].RSVP.resolve(false);
          });
        }

        return false;
      })['catch'](function () {
        return _ember['default'].RSVP.resolve(false);
      });
    },

    filterSystemStack: function filterSystemStack(stacks) {
      return (stacks || []).find(function (stack) {
        var info = stack.get('externalIdInfo');
        return (info.kind === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_CATALOG || info.kind === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SYSTEM_CATALOG) && info.base === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_INFRA && info.name === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_MESOS;
      });
    }
  });
});