define('ui/utils/debounce', ['exports', 'ember'], function (exports, _ember) {
  exports.debouncedObserver = debouncedObserver;
  exports.throttledObserver = throttledObserver;

  // debouncedObserver('observeKey1','...','observerKeyN', function() {} [, delay] [,leadingEdge])

  function debouncedObserver() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    var argsLength = args.length;
    var funcIndex, keys, opt;

    if (typeof args[argsLength - 1] === 'function') {
      funcIndex = argsLength - 1;
    } else if (typeof args[argsLength - 2] === 'function') {
      funcIndex = argsLength - 2;
    } else if (typeof args[argsLength - 3] === 'function') {
      funcIndex = argsLength - 3;
    } else {
      throw Error('Invalid arguments');
    }

    opt = args.slice(funcIndex);
    keys = args.slice(0, funcIndex);

    var fn = function fn() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      opt[0].apply(this);
    };

    return _ember['default'].observer.apply(_ember['default'], keys.concat(function () {
      _ember['default'].run.debounce(this, fn, opt[1] || 250, opt[2] || false);
    }));
  }

  function throttledObserver() {
    for (var _len2 = arguments.length, args = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
      args[_key2] = arguments[_key2];
    }

    var argsLength = args.length;
    var funcIndex, keys, opt;

    if (typeof args[argsLength - 1] === 'function') {
      funcIndex = argsLength - 1;
    } else if (typeof args[argsLength - 2] === 'function') {
      funcIndex = argsLength - 2;
    } else if (typeof args[argsLength - 3] === 'function') {
      funcIndex = argsLength - 3;
    } else {
      throw Error('Invalid arguments');
    }

    opt = args.slice(funcIndex);
    keys = args.slice(0, funcIndex);

    return _ember['default'].observer.apply(_ember['default'], keys.concat(function () {
      _ember['default'].run.throttle(this, opt[0], opt[1] || 250, opt[2] || false);
    }));
  }
});