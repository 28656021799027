define("ui/stack/graph/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "ui/stack/graph/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "stack-section r-mt20");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "text-muted text-center r-p10");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["stackSection.none"], [], ["loc", [null, [5, 46], [5, 71]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "ui/stack/graph/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "stack-header", [], ["model", ["subexpr", "@mut", [["get", "model.stack", ["loc", [null, [1, 21], [1, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "all", ["subexpr", "@mut", [["get", "model.all.stacks", ["loc", [null, [1, 37], [1, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "hideAddtlInfo", "dismiss"], ["loc", [null, [1, 0], [1, 79]]], 0, 0], ["block", "if", [["get", "noServices", ["loc", [null, [3, 6], [3, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 0], [7, 7]]]], ["inline", "stack-graph", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [8, 20], [8, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "setNoServices", "setNoServices"], ["loc", [null, [8, 0], [8, 57]]], 0, 0], ["inline", "service-addtl-info", [], ["service", ["subexpr", "@mut", [["get", "selectedService", ["loc", [null, [11, 29], [11, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "show", ["subexpr", "@mut", [["get", "showServiceInfo", ["loc", [null, [11, 50], [11, 65]]], 0, 0, 0, 0]], [], [], 0, 0], "dismiss", "dismiss"], ["loc", [null, [11, 0], [11, 85]]], 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});