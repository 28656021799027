define('ui/k8s-tab/dashboard/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    projects: _ember['default'].inject.service(),

    actions: {
      openDashboard: function openDashboard() {
        window.open(this.get('model.dashboardUrl'), '_blank');
      }
    }
  });
});