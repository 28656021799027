define('ui/services/shibboleth-auth', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/util', 'ui/services/settings'], function (exports, _ember, _uiUtilsConstants, _uiUtilsUtil, _uiServicesSettings) {
  exports['default'] = _ember['default'].Service.extend({
    userStore: _ember['default'].inject.service('user-store'),
    session: _ember['default'].inject.service(),
    access: _ember['default'].inject.service(),
    hasToken: null,
    parseIdentity: _ember['default'].observer('hasToken', function () {
      var locToken = this.get('hasToken');
      if (locToken) {
        var userIdent = locToken.userIdentity;
        this.set('session.' + _uiUtilsConstants['default'].SESSION.IDENTITY, userIdent);
      }
    }),
    getToken: function getToken() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.get('userStore').rawRequest({
          url: 'token'
        }).then(function (xhr) {
          resolve(xhr.body.data[0]);
          return;
        })['catch'](function (err) {
          reject(err);
        });
      });
    },
    buildRedirectUrl: function buildRedirectUrl(url) {
      var test = arguments.length <= 1 || arguments[1] === undefined ? false : arguments[1];

      var redirect = url;
      var qp = {
        redirectBackBase: window.location.origin,
        redirectBackPath: '/login/shibboleth-auth/'
      };

      if (test) {
        qp.redirectBackPath = qp.redirectBackPath + '?shibbolethTest=1';
      }

      return _uiUtilsUtil['default'].addQueryParams(redirect, qp);
    },
    waitAndRefresh: function waitAndRefresh(url) {
      $('#loading-underlay, #loading-overlay').removeClass('hide').show();
      setTimeout(function () {
        window.location.href = url || window.location.href;
      }, 1000);
    },
    authorizeRedirect: function authorizeRedirect(url) {
      window.location.href = url;
    },
    authenticationSucceeded: function authenticationSucceeded(model) {
      var _this2 = this;

      var url = window.location.href;

      model = model.clone();
      model.setProperties({
        'enabled': true,
        'accessMode': 'restricted',
        'allowedIdentities': []
      });

      model.save().then(function () {

        // for some reason we can't get past here because we've set auth true?
        return _this2.get('userStore').find('setting', (0, _uiServicesSettings.denormalizeName)(_uiUtilsConstants['default'].SETTING.API_HOST)).then(function (setting) {
          if (setting.get('value')) {
            _this2.waitAndRefresh(url);
          } else {
            // Default the api.host so the user won't have to set it in most cases
            if (window.location.hostname === 'localhost') {
              _this2.waitAndRefresh(url);
            } else {
              setting.set('value', window.location.origin);
              return setting.save().then(function () {
                _this2.waitAndRefresh(url);
              });
            }
          }
        });
      })['catch'](function () {
        _this2.set('access.enabled', false);
      });
    }
  });
});