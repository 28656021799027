define('ui/components/modal-host-evacuate/component', ['exports', 'ember', 'lacsso/components/modal-base', 'ui/utils/platform'], function (exports, _ember, _lacssoComponentsModalBase, _uiUtilsPlatform) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'medium-modal'],
    resources: _ember['default'].computed.alias('modalService.modalOpts.model'),
    alternateLabel: _uiUtilsPlatform.alternateLabel,

    actions: {
      evacuate: function evacuate() {
        var _this = this;

        this.get('resources').forEach(function (resource) {
          resource.doAction('evacuate');
        });

        _ember['default'].run.next(function () {
          _this.send('cancel');
        });
      }
    }
  });
});