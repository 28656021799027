define("ui/components/catalog-configure/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "ui/components/catalog-configure/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "new-catalog", [], ["serviceChoices", ["subexpr", "@mut", [["get", "serviceChoices", ["loc", [null, [2, 17], [2, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "stackResource", ["subexpr", "@mut", [["get", "model.stack", ["loc", [null, [3, 16], [3, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "templateResource", ["subexpr", "@mut", [["get", "model.tpl", ["loc", [null, [4, 19], [4, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "selectedTemplateUrl", ["subexpr", "@mut", [["get", "selectedTemplateUrl", ["loc", [null, [5, 22], [5, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "versionLinks", ["subexpr", "@mut", [["get", "model.versionLinks", ["loc", [null, [6, 15], [6, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "versionsArray", ["subexpr", "@mut", [["get", "model.versionsArray", ["loc", [null, [7, 16], [7, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "cancel", ["subexpr", "action", ["cancel"], [], ["loc", [null, [8, 9], [8, 26]]], 0, 0], "actuallySave", false, "editing", true, "showHeader", false, "showPreview", false, "showDefaultVersionOption", true, "titleUpgrade", "newCatalog.titleConfigure", "selectVersionUpgrade", "newCatalog.selectVersionConfigure", "saveUpgrade", "newCatalog.saveConfigure", "sectionClass", "", "doSave", ["subexpr", "action", ["doSave"], [], ["loc", [null, [18, 9], [18, 26]]], 0, 0]], ["loc", [null, [1, 0], [19, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});