define('ui/models/project', ['exports', 'ember-api-store/models/resource', 'ui/mixins/cattle-polled-resource', 'ember', 'ui/utils/util', 'ui/utils/constants', 'ember-api-store/utils/denormalize'], function (exports, _emberApiStoreModelsResource, _uiMixinsCattlePolledResource, _ember, _uiUtilsUtil, _uiUtilsConstants, _emberApiStoreUtilsDenormalize) {

  var Project = _emberApiStoreModelsResource['default'].extend(_uiMixinsCattlePolledResource['default'], {
    access: _ember['default'].inject.service(),
    prefs: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    modalService: _ember['default'].inject.service('modal'),

    projectTemplate: (0, _emberApiStoreUtilsDenormalize.denormalizeId)('projectTemplateId'),

    type: 'project',
    name: null,
    description: null,

    actions: {
      edit: function edit() {
        this.get('router').transitionTo('settings.projects.detail', this.get('id'), { queryParams: { editing: true } });
      },

      'delete': function _delete() {
        var _this = this;

        return this['delete']().then(function () {
          // If you're in the project that was deleted, go back to the default project
          if (_this.get('active')) {
            window.location.href = window.location.href;
          }
        });
      },

      activate: function activate() {
        var _this2 = this;

        return this.doAction('activate').then(function () {
          return _this2.waitForState('active').then(function () {
            _this2.get('projects').refreshAll();
          });
        });
      },

      deactivate: function deactivate() {
        var _this3 = this;

        return this.doAction('deactivate').then(function () {
          if (_this3.get('active')) {
            window.location.href = window.location.href;
          }
        });
      },

      setAsDefault: function setAsDefault() {
        this.get('prefs').set(_uiUtilsConstants['default'].PREFS.PROJECT_DEFAULT, this.get('id'));
      },

      switchTo: function switchTo() {
        // @TODO bad
        window.lc('authenticated').send('switchProject', this.get('id'));
      },

      promptStop: function promptStop() {
        this.get('modalService').toggleModal('modal-confirm-deactivate', {
          originalModel: this,
          action: 'deactivate'
        });
      }

    },

    availableActions: (function () {
      var a = this.get('actionLinks');

      var choices = [{ label: 'action.switchEnvironment', icon: 'icon icon-folder-open', action: 'switchTo', enabled: this.get('canSwitchTo') }, { label: 'action.setDefault', icon: 'icon icon-home', action: 'setAsDefault', enabled: this.get('canSetDefault') }, { divider: true }, { label: 'action.edit', icon: 'icon icon-edit', action: 'edit', enabled: !!a.update || !!a.setmembers }, { label: 'action.activate', icon: 'icon icon-play', action: 'activate', enabled: !!a.activate }, { label: 'action.deactivate', icon: 'icon icon-pause', action: 'promptStop', enabled: !!a.deactivate, altAction: 'deactivate' }, { divider: true }, { label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: this.get('canRemove'), altAction: 'delete' }, { label: 'action.restore', icon: '', action: 'restore', enabled: !!a.restore }, { label: 'action.purge', icon: '', action: 'purge', enabled: !!a.purge }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }];

      return choices;
    }).property('actionLinks.{activate,deactivate,update,restore,purge,setmembers}', 'state', 'canRemove', 'canSetDefault', 'canSwitchTo'),

    icon: (function () {
      if (this.get('isDefault')) {
        return 'icon icon-home';
      } else if (this.get('active')) {
        return 'icon icon-folder-open';
      } else {
        return 'icon icon-folder';
      }
    }).property('active', 'isDefault'),

    isDefault: (function () {
      return this.get('prefs.' + _uiUtilsConstants['default'].PREFS.PROJECT_DEFAULT) === this.get('id');
    }).property('prefs.' + _uiUtilsConstants['default'].PREFS.PROJECT_DEFAULT, 'id'),

    active: (function () {
      return this.get('id') === this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT);
    }).property('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT, 'id'),

    canRemove: (function () {
      return !!this.get('actionLinks.remove') && ['removing', 'removed', 'purging', 'purged'].indexOf(this.get('state')) === -1;
    }).property('state', 'actionLinks.remove'),

    canSwitchTo: (function () {
      return this.get('state') === 'active' && this.get('id') !== this.get('projects.current.id');
    }).property('id', 'projects.current.id', 'state'),

    canSetDefault: (function () {
      return this.get('state') === 'active' && !this.get('isDefault');
    }).property('state', 'isDefault'),

    displayOrchestration: (function () {
      return _uiUtilsUtil['default'].ucFirst(this.get('orchestration'));
    }).property('orchestration'),

    combinedState: (function () {
      var project = this.get('state');
      var health = this.get('healthState');
      if (['active', 'updating-active'].indexOf(project) === -1) {
        // If the project isn't active, return its state
        return project;
      }

      if (health === 'healthy') {
        return project;
      } else {
        return health;
      }
    }).property('state', 'healthState'),

    isUpgrading: _ember['default'].computed.equal('state', 'upgrading'),

    needsUpgrade: (function () {
      return this.get('isActive') && this.get('version') !== this.get('settings.' + _uiUtilsConstants['default'].SETTING.PROJECT_VERSION);
    }).property('isActive', 'version', 'settings.' + _uiUtilsConstants['default'].SETTING.PROJECT_VERSION),

    isWindows: _ember['default'].computed.equal('orchestration', 'windows')
  });

  // Projects don't get pushed by /subscribe WS, so refresh more often
  Project.reopenClass({
    pollTransitioningDelay: 1000,
    pollTransitioningInterval: 5000
  });

  exports['default'] = Project;
});