define('ui/components/project-upgrade/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['project-upgrade'],

    access: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    canUpgrade: (function () {
      return this.get('access').isOwner();
    }).property('projects.current.id'),

    actions: {
      upgrade: function upgrade() {
        this.get('projects.current').doAction('upgrade');
      }
    }
  });
});