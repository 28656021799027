define('ui/virtualmachines/new/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['stackId', 'virtualMachineId', 'hostId'],
    hostId: null,
    stackId: null,
    virtualMachineId: null,
    editing: false,

    actions: {
      done: function done() {
        this.send('goToPrevious');
      },

      cancel: function cancel() {
        this.send('goToPrevious');
      }
    }
  });
});