define("ui/container/volumes/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 4
            },
            "end": {
              "line": 26,
              "column": 4
            }
          },
          "moduleName": "ui/container/volumes/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode(" ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [3]);
          var element4 = dom.childAt(element1, [5]);
          var element5 = dom.childAt(element1, [7]);
          var morphs = new Array(10);
          morphs[0] = dom.createAttrMorph(element2, 'data-title');
          morphs[1] = dom.createMorphAt(element2, 1, 1);
          morphs[2] = dom.createAttrMorph(element3, 'data-title');
          morphs[3] = dom.createMorphAt(element3, 1, 1);
          morphs[4] = dom.createMorphAt(element3, 3, 3);
          morphs[5] = dom.createMorphAt(element3, 5, 5);
          morphs[6] = dom.createAttrMorph(element4, 'data-title');
          morphs[7] = dom.createMorphAt(element4, 1, 1);
          morphs[8] = dom.createAttrMorph(element5, 'data-title');
          morphs[9] = dom.createMorphAt(element5, 1, 1);
          return morphs;
        },
        statements: [["attribute", "data-title", ["concat", [["subexpr", "t", ["containersPage.containerPage.volumesTab.table.header.name"], [], ["loc", [null, [13, 24], [13, 89]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "mount.volumeName", ["loc", [null, [14, 10], [14, 30]]], 0, 0, 0, 0], ["attribute", "data-title", ["concat", [["subexpr", "t", ["containersPage.containerPage.volumesTab.table.header.mount"], [], ["loc", [null, [16, 24], [16, 90]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "mount.path", ["loc", [null, [17, 10], [17, 24]]], 0, 0, 0, 0], ["inline", "if", [["get", "mount.isReadWrite", ["loc", [null, [17, 30], [17, 47]]], 0, 0, 0, 0], "", "(ro)"], [], ["loc", [null, [17, 25], [17, 59]]], 0, 0], ["inline", "copy-to-clipboard", [], ["size", "small", "clipboardText", ["subexpr", "@mut", [["get", "mount.path", ["loc", [null, [17, 107], [17, 117]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 60], [17, 119]]], 0, 0], ["attribute", "data-title", ["concat", [["subexpr", "t", ["containersPage.containerPage.volumesTab.table.header.snapshot"], [], ["loc", [null, [19, 24], [19, 93]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "snapshot-timeline", [], ["model", ["subexpr", "@mut", [["get", "mount.volume.snapshots", ["loc", [null, [20, 36], [20, 58]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [20, 10], [20, 60]]], 0, 0], ["attribute", "data-title", ["concat", [["subexpr", "t", ["generic.actions"], [], ["loc", [null, [22, 24], [22, 47]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "action-menu", [], ["model", ["subexpr", "@mut", [["get", "mount.volume", ["loc", [null, [23, 30], [23, 42]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [23, 10], [23, 44]]], 0, 0]],
        locals: ["mount"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 26,
              "column": 4
            },
            "end": {
              "line": 32,
              "column": 4
            }
          },
          "moduleName": "ui/container/volumes/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "colspan", "4");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "text-muted text-center");
          dom.setAttribute(el3, "style", "display:inline-block;");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'data-title');
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
          return morphs;
        },
        statements: [["attribute", "data-title", ["subexpr", "t", ["containersPage.containerPage.volumesTab.table.body.error.data"], [], ["loc", [null, [null, null], [28, 92]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["containersPage.containerPage.volumesTab.table.body.error.noData"], [], ["loc", [null, [29, 76], [29, 147]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 35,
            "column": 0
          }
        },
        "moduleName": "ui/container/volumes/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("table");
        dom.setAttribute(el1, "class", "grid fixed");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("thead");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "width", "80");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "width", "175");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "width", "80");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("tbody");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var element7 = dom.childAt(element6, [1, 1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element7, [3]), 0, 0);
        morphs[2] = dom.createMorphAt(dom.childAt(element7, [5]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(element7, [7]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element6, [3]), 1, 1);
        return morphs;
      },
      statements: [["inline", "t", ["containersPage.containerPage.volumesTab.table.header.name"], [], ["loc", [null, [4, 21], [4, 86]]], 0, 0], ["inline", "t", ["containersPage.containerPage.volumesTab.table.header.mount"], [], ["loc", [null, [5, 22], [5, 88]]], 0, 0], ["inline", "t", ["containersPage.containerPage.volumesTab.table.header.snapshot"], [], ["loc", [null, [6, 10], [6, 79]]], 0, 0], ["inline", "t", ["generic.actions"], [], ["loc", [null, [7, 21], [7, 44]]], 0, 0], ["block", "each", [["get", "model.mounts", ["loc", [null, [11, 12], [11, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [11, 4], [32, 13]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});