define("ui/not-found/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 8
            },
            "end": {
              "line": 7,
              "column": 63
            }
          },
          "moduleName": "ui/not-found/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["notFoundPage.linkTo"], [], ["loc", [null, [7, 36], [7, 63]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 2
            },
            "end": {
              "line": 26,
              "column": 2
            }
          },
          "moduleName": "ui/not-found/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "failplane");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "width", "484");
          dom.setAttribute(el2, "height", "269");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "class", "failprop");
          dom.setAttribute(el2, "width", "123");
          dom.setAttribute(el2, "height", "246");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "failcow");
          dom.setAttribute(el1, "width", "178");
          dom.setAttribute(el1, "height", "255");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "cloud");
          dom.setAttribute(el1, "width", "254");
          dom.setAttribute(el1, "height", "121");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "cloud");
          dom.setAttribute(el1, "width", "151");
          dom.setAttribute(el1, "height", "91");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "cloud");
          dom.setAttribute(el1, "width", "175");
          dom.setAttribute(el1, "height", "102");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "cloud");
          dom.setAttribute(el1, "width", "300");
          dom.setAttribute(el1, "height", "142");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "cloud");
          dom.setAttribute(el1, "width", "320");
          dom.setAttribute(el1, "height", "152");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "wave");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(fragment, [3]);
          var element4 = dom.childAt(fragment, [5]);
          var element5 = dom.childAt(fragment, [7]);
          var element6 = dom.childAt(fragment, [9]);
          var element7 = dom.childAt(fragment, [11]);
          var element8 = dom.childAt(fragment, [13]);
          var morphs = new Array(8);
          morphs[0] = dom.createAttrMorph(element1, 'src');
          morphs[1] = dom.createAttrMorph(element2, 'src');
          morphs[2] = dom.createAttrMorph(element3, 'src');
          morphs[3] = dom.createAttrMorph(element4, 'src');
          morphs[4] = dom.createAttrMorph(element5, 'src');
          morphs[5] = dom.createAttrMorph(element6, 'src');
          morphs[6] = dom.createAttrMorph(element7, 'src');
          morphs[7] = dom.createAttrMorph(element8, 'src');
          return morphs;
        },
        statements: [["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [14, 18], [14, 32]]], 0, 0, 0, 0], "assets/images/logos/fail-plane.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [15, 35], [15, 49]]], 0, 0, 0, 0], "assets/images/logos/fail-prop.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [18, 32], [18, 46]]], 0, 0, 0, 0], "assets/images/logos/fail-cowparachute.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [19, 30], [19, 44]]], 0, 0, 0, 0], "assets/images/logos/fail-cloud1.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [20, 30], [20, 44]]], 0, 0, 0, 0], "assets/images/logos/fail-cloud2.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [21, 30], [21, 44]]], 0, 0, 0, 0], "assets/images/logos/fail-cloud3.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [22, 30], [22, 44]]], 0, 0, 0, 0], "assets/images/logos/fail-cloud4.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [23, 30], [23, 44]]], 0, 0, 0, 0], "assets/images/logos/fail-cloud5.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 28,
            "column": 0
          }
        },
        "moduleName": "ui/not-found/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "fail-whale");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "error");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        dom.setAttribute(el3, "class", "bg-danger");
        var el4 = dom.createTextNode("404");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "r-p20 text-center");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var element10 = dom.childAt(element9, [1, 3]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element10, [3]), 1, 1);
        morphs[2] = dom.createMorphAt(element9, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", ["notFoundPage.header"], [], ["loc", [null, [5, 9], [5, 36]]], 0, 0], ["block", "link-to", ["authenticated"], [], 0, null, ["loc", [null, [7, 8], [7, 75]]]], ["block", "if", [["get", "settings.isRancher", ["loc", [null, [12, 8], [12, 26]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [12, 2], [26, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});