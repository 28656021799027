define('ui/components/webhook/service-upgrade-config/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    payloadFormatChoices: null,
    addressTypeChoices: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('payloadFormatChoices', this.getChoices('payloadFormat'));
      this.set('addressTypeChoices', this.getChoices('addressType'));
    },

    getChoices: function getChoices(field) {
      var serviceUpgradeSchema = this.get('webhookStore').getById('schema', 'serviceupgrade');
      var schema = serviceUpgradeSchema.resourceFields[field];
      var choices = [];
      if (schema) {
        choices = schema.options.map(function (option) {
          return { label: 'newReceiver.' + field + '.' + option, value: option };
        });
      }
      return choices;
    },

    actions: {
      optionsChanged: function optionsChanged(opt) {
        this.get('model').setProperties(opt);
      }
    }
  });
});