define('ui/models/scaleservice', ['exports', 'ember-api-store/models/resource'], function (exports, _emberApiStoreModelsResource) {
  exports['default'] = _emberApiStoreModelsResource['default'].extend({
    validationErrors: function validationErrors() {
      var errors = this._super.apply(this, arguments);
      var min = parseInt(this.get('min'), 10);
      var max = parseInt(this.get('max'), 10);
      if (min && max && min > max) {
        errors.push('"Minimum Scale" cannot be greater than "Maximum Scale"');
      }

      return errors;
    }
  });
});