define('ui/settings/projects/edit-template/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    catalog: _ember['default'].inject.service(),

    model: function model(params) {
      return _ember['default'].RSVP.hash({
        catalogInfo: this.get('catalog').fetchTemplates({ templateBase: 'infra', category: 'all' }),
        originalProjectTemplate: this.get('userStore').find('projecttemplate', params.template_id)
      }).then(function (hash) {
        hash.projectTemplate = hash.originalProjectTemplate.clone();
        return hash;
      });
    }
  });
});