define('ui/components/confirm-delete/component', ['exports', 'ember', 'ui/utils/platform', 'lacsso/components/modal-base'], function (exports, _ember, _uiUtilsPlatform, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'medium-modal'],
    resources: _ember['default'].computed.alias('modalService.modalOpts'),
    alternateLabel: _uiUtilsPlatform.alternateLabel,
    settings: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    actions: {
      confirm: function confirm() {
        this.get('resources').forEach(function (resource) {
          resource['delete']();
        });

        this.send('cancel');
      }

    },

    isEnvironment: _ember['default'].computed('resources', function () {
      var resources = this.get('resources');
      var out = false;

      resources.forEach(function (resource) {
        if (resource.type === 'project') {
          out = true;
        }
      });

      return out;
    }),

    largeDeleteText: _ember['default'].computed(function () {
      var resources = this.get('resources');
      return this.get('intl').t('confirmDelete.largeDeleteText', {
        key: resources[0].get('displayName'),
        othersCount: resources.length
      });
    }),

    didRender: function didRender() {
      var _this = this;

      setTimeout(function () {
        try {
          _this.$('BUTTON')[0].focus();
        } catch (e) {}
      }, 500);
    }
  });
});