define('ui/instance-initializers/webhook-store', ['exports', 'ui/mixins/store-tweaks', 'ui/utils/util'], function (exports, _uiMixinsStoreTweaks, _uiUtilsUtil) {
  exports.initialize = initialize;

  function initialize(instance) {
    var application = instance.lookup('application:main');
    var store = instance.lookup('service:webhook-store');
    var projects = instance.lookup('service:projects');

    store.reopen(_uiMixinsStoreTweaks['default']);
    store.reopen({
      removeAfterDelete: true,
      baseUrl: application.webhookEndpoint,

      normalizeUrl: function normalizeUrl() {
        var out = this._super.apply(this, arguments);
        if (out.indexOf('projectId=') === -1) {
          out = _uiUtilsUtil['default'].addQueryParam(out, 'projectId', projects.get('current.id'));
        }
        return out;
      }
    });
  }

  exports['default'] = {
    name: 'webhook-store',
    initialize: initialize
  };
});