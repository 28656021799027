define('ui/registries/new/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() /*params, transition*/{
      var store = this.get('store');
      var registry = store.createRecord({
        type: 'registry',
        serverAddress: ''
      });

      var credential = store.createRecord({
        type: 'registryCredential',
        registryId: 'tbd',
        email: "not-really@required.anymore"
      });

      return store.find('registry').then(function (registries) {
        return _ember['default'].Object.create({
          allRegistries: registries,
          registry: registry,
          credential: credential
        });
      });
    },

    setupController: function setupController(controller, model) {
      controller.set('model', model);
      controller.send('selectDriver', 'dockerhub');
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('errors', null);
      }
    }
  });
});