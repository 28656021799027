define('ui/swarm-tab/console/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.get('store').findAll('container').then(function (containers) {
        var inst = containers.filter(function (c) {
          return (c.get('labels') || {})[_uiUtilsConstants['default'].LABEL.SERVICE_NAME] === 'swarm/swarmkit-mon';
        }).sortBy('createIndex').objectAt(0);

        if (inst) {
          return _ember['default'].Object.create({
            command: ['/bin/bash', '-l', '-c', 'echo "# Run docker commands inside here\n# e.g. docker service ls\n"; TERM=xterm-256color /bin/bash'],
            instance: inst
          });
        }
      });
    }
  });
});