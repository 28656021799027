define('ui/certificates/index/controller', ['exports', 'ember', 'ui/mixins/sortable', 'ui/mixins/filter-state'], function (exports, _ember, _uiMixinsSortable, _uiMixinsFilterState) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsFilterState['default'], _uiMixinsSortable['default'], {
    sortBy: 'name',
    sorts: {
      state: ['stateSort', 'name', 'id'],
      name: ['name', 'id'],
      cn: ['CN', 'id'],
      expires: ['expiresDate', 'id']
    }
  });
});