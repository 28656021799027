define('ui/components/language-dropdown/component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend({
    // Set to true on login to savesession value instead of user-pref
    login: false,

    tagName: 'div',
    classNames: ['dropdown', 'language-dropdown', 'inline-block'],
    classNameBindings: ['hideSingle:hide'],

    language: _ember['default'].inject.service('user-language'),
    intl: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    locales: _ember['default'].computed.alias('language.locales'),

    hideSingle: (function () {
      return Object.keys(this.get('locales')).length <= 1;
    }).property('locales'),

    actions: {
      selectLanguage: function selectLanguage(language) {
        var _this = this;

        if (this.get('login')) {
          this.get('session').set(_uiUtilsConstants['default'].SESSION.LOGIN_LANGUAGE, language);
        }

        this.get('language').sideLoadLanguage(language).then(function () {
          if (!_this.get('login')) {
            _this.get('language').setLanguage(language);
          }
        });
      }
    },

    selected: _ember['default'].computed('intl._locale', function () {
      var locale = this.get('intl._locale');
      if (locale) {
        return locale[0];
      }
      return null;
    }),

    selectedLabel: _ember['default'].computed('selected', 'locales', function () {
      var sel = this.get('selected');
      var out = '';
      if (sel) {
        out = this.get('locales')[sel];
      }

      if (!out) {
        out = 'Language';
      }

      // Strip parens for display
      return out.replace(/\s+\(.+\)$/, '');
    })

  });
});