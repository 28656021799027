define('ui/hosts/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var store = this.get('store');
      return _ember['default'].RSVP.hash({
        hosts: store.findAll('host'),
        instances: store.findAll('instance')
      }).then(function (hash) {
        return hash.hosts;
      });
    }
  });
});