define('ui/host/ports/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var host = this.modelFor('host').get('host');
      var store = this.get('store');

      return _ember['default'].RSVP.all([store.findAll('service'), store.findAll('instance')]).then(function () {
        return host;
      });
    }
  });
});