define('ui/models/processinstance', ['exports', 'ember', 'ember-api-store/models/resource'], function (exports, _ember, _emberApiStoreModelsResource) {

  var ProcessInstance = _emberApiStoreModelsResource['default'].extend({
    runTime: _ember['default'].computed('startTime', 'endTime', function () {
      return moment(this.get('endTime')).diff(this.get('startTime'), 'seconds');
    }),

    typeAndId: _ember['default'].computed('resourceType', 'resourceId', function () {
      return this.get('resourceType') + ':' + this.get('resourceId');
    }),

    availableActions: (function () {
      var a = this.get('actionLinks');

      return [{ label: 'action.replay', icon: 'icon icon-refresh', action: 'replay', enabled: !!a.replay }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }];
    }).property('actionLinks.replay')
  });

  exports['default'] = ProcessInstance;
});