define('ui/admin-tab/accounts/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      return this.get('userStore').find('password').then(function () {
        return _this.get('userStore').find('account', null, { filter: { 'kind_ne': ['service', 'agent'] }, forceReload: true });
      });
    }
  });
});