define("ui/components/action-menu/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 6,
                "column": 4
              }
            },
            "moduleName": "ui/components/action-menu/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("i");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createAttrMorph(element0, 'class');
            return morphs;
          },
          statements: [["attribute", "class", ["concat", ["icon-fw ", ["get", "model.primaryAction.icon", ["loc", [null, [5, 26], [5, 50]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "ui/components/action-menu/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("button");
          dom.setAttribute(el1, "data-primary", "0");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createAttrMorph(element1, 'aria-label');
          morphs[2] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["btn btn-default btn-", ["get", "size", ["loc", [null, [3, 39], [3, 43]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "aria-label", ["concat", [["subexpr", "t", [["get", "model.primaryAction.label", ["loc", [null, [3, 80], [3, 105]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 76], [3, 107]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "tooltip-element", [], ["type", "tooltip-basic", "model", ["subexpr", "@mut", [["get", "model.primaryAction", ["loc", [null, [4, 50], [4, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "tooltipFor", "actionMenu"], 0, null, ["loc", [null, [4, 4], [6, 24]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "ui/components/action-menu/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "aria-controls", "resource-actions");
        dom.setAttribute(el1, "data-toggle", "dropdown");
        dom.setAttribute(el1, "data-target", ".resource-actions");
        dom.setAttribute(el1, "aria-expanded", "false");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "icon icon-fw icon-vertical-ellipsis");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element2 = dom.childAt(fragment, [3]);
        var element3 = dom.childAt(element2, [1]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createAttrMorph(element2, 'aria-label');
        morphs[3] = dom.createAttrMorph(element2, 'class');
        morphs[4] = dom.createAttrMorph(element3, 'alt');
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["content", "yield", ["loc", [null, [1, 0], [1, 9]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "and", [["get", "showPrimary", ["loc", [null, [2, 11], [2, 22]]], 0, 0, 0, 0], ["get", "model.primaryAction", ["loc", [null, [2, 23], [2, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 6], [2, 43]]], 0, 0]], [], 0, null, ["loc", [null, [2, 0], [8, 7]]]], ["attribute", "aria-label", ["concat", [["subexpr", "t", ["generic.moreActions"], [], ["loc", [null, [9, 20], [9, 47]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["btn btn-default btn-", ["get", "size", ["loc", [null, [9, 111], [9, 115]]], 0, 0, 0, 0], " more-actions must-propagate"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "alt", ["concat", [["subexpr", "t", ["generic.moreActions"], [], ["loc", [null, [10, 54], [10, 81]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});