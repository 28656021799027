define('ui/mixins/upgrade-component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {

  var NONE = 'none',
      LOADING = 'loading',
      CURRENT = 'current',
      AVAILABLE = 'available',
      REQUIRED = 'required',
      INPROGRESS = 'inprogress',
      UPGRADED = 'upgraded',
      NOTFOUND = 'notfound',
      ERROR = 'error';

  var INPROGRESS_STATES = ['upgrading', 'canceled-upgrade', 'canceling-rollback', 'canceling-upgrade', 'finishing-upgrade', 'rolling-back'];

  var queue = async.queue(getUpgradeInfo, 2);

  function getUpgradeInfo(task, cb) {
    var obj = task.obj;

    var deps = {
      upgradeInfo: obj.get('catalog').fetchTemplate(task.id, true)
    };

    if (task.upgradeOnly === false) {
      deps.fullInfo = obj.get('catalog').fetchTemplate(task.templateId, false);
    }

    _ember['default'].RSVP.hash(deps).then(function (hash) {
      if (obj.isDestroyed || obj.isDestroying) {
        return;
      }

      var upgradeInfo = hash.upgradeInfo;
      var fullInfo = hash.fullInfo;

      if (!upgradeInfo) {
        obj.set('upgradeStatus', CURRENT);
        return;
      }

      upgradeInfo.id = task.id;
      obj.set('upgradeInfo', upgradeInfo);

      var upgradeVersions = {},
          allVersions = {};
      if (upgradeInfo && upgradeInfo.upgradeVersionLinks) {
        (function () {
          var list = upgradeInfo.upgradeVersionLinks;
          Object.keys(list).forEach(function (key) {
            if (list[key]) {
              upgradeVersions[key] = list[key];
            }
          });
        })();
      }

      if (fullInfo && fullInfo.versionLinks) {
        (function () {
          var list = fullInfo.versionLinks;
          Object.keys(list).forEach(function (key) {
            if (list[key]) {
              allVersions[key] = list[key];
            }
          });
        })();
      }

      if (upgradeVersions && obj.get('upgradeStatus') !== UPGRADED) {
        var _status = CURRENT;
        if (Object.keys(upgradeVersions).length) {
          if (upgradeInfo.catalogId === _uiUtilsConstants['default'].CATALOG.LIBRARY_KEY && upgradeInfo.templateBase === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_INFRA) {
            _status = REQUIRED;
          } else {
            _status = AVAILABLE;
          }
        }

        obj.setProperties({
          upgradeStatus: _status,
          upgradeVersions: upgradeVersions,
          allVersions: allVersions
        });
      } else {
        // we're in upgraded status and have not finished but still need a verison for tooltip
        obj.setProperties({
          allVersions: allVersions
        });
      }
    })['catch'](function (err) {
      if (err.status === 404) {
        obj.set('upgradeStatus', NOTFOUND);
      } else {
        obj.set('upgradeStatus', ERROR);
      }
    })['finally'](function () {
      cb();
    });
  }

  exports['default'] = _ember['default'].Mixin.create({
    model: null,
    upgradeOnly: true,

    intl: _ember['default'].inject.service(),
    catalog: _ember['default'].inject.service(),
    userStore: _ember['default'].inject.service('user-store'),

    upgradeInfo: null,
    upgradeStatus: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.updateStatus();
    },

    color: _ember['default'].computed('upgradeStatus', function () {
      switch (this.get('upgradeStatus')) {
        case NONE:
          return 'hide';
        case CURRENT:
          return 'btn-info';
        case LOADING:
        case NOTFOUND:
        case ERROR:
        case INPROGRESS:
          return 'btn-disabled';
        case REQUIRED:
          return 'btn-danger';
        case AVAILABLE:
        case UPGRADED:
          return 'btn-warning';
      }
    }),

    currentVersion: _ember['default'].computed('upgradeInfo', 'model.externalId', function () {
      var text = this.get('intl').findTranslationByKey('upgradeBtn.version.current');
      var version = this.get('upgradeInfo.version');
      if (typeof version === 'string' || typeof version === 'number') {
        return text + ': ' + this.get('upgradeInfo.version');
      } else {
        return null;
      }
    }),

    doUpgrade: function doUpgrade() {
      var status = this.get('upgradeStatus');

      if ([REQUIRED, AVAILABLE, CURRENT].indexOf(status) >= 0) {
        var templateId = this.get('model.externalIdInfo.templateId');
        var versionId = this.get('upgradeInfo.id');
        this.get('application').transitionToRoute('catalog-tab.launch', templateId, { queryParams: {
            stackId: this.get('model.id'),
            upgrade: versionId
          } });
      } else if (status === UPGRADED) {
        this.get('model').send('finishUpgrade');
      }
    },

    updateStatus: function updateStatus() {
      var _this = this;

      var upgradeOnly = this.get('upgradedOnly') === true;
      var state = this.get('model.state');
      var info = this.get('model.externalIdInfo');
      var queueUpgradeStatus = function queueUpgradeStatus() {
        queue.push({
          obj: _this,
          id: info.id,
          templateId: info.templateId,
          upgradeOnly: upgradeOnly
        });
      };

      if (state === 'upgraded') {
        this.set('upgradeStatus', UPGRADED);
        queueUpgradeStatus();
        return;
      }

      if (INPROGRESS_STATES.indexOf(state) >= 0) {
        this.set('upgradeStatus', INPROGRESS);
        return;
      }

      if (info && _uiUtilsConstants['default'].EXTERNAL_ID.UPGRADEABLE.indexOf(info.kind) >= 0) {
        this.set('upgradeStatus', LOADING);
        queueUpgradeStatus();
      } else {
        this.set('upgradeStatus', NONE);
      }
    },

    externalIdChanged: (function () {
      _ember['default'].run.once(this, 'updateStatus');
    }).observes('model.{externalId,state}')
  });
});