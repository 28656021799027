define('ui/mixins/tooltip', ['exports', 'ember', 'ui/mixins/throttled-resize'], function (exports, _ember, _uiMixinsThrottledResize) {
  exports['default'] = _ember['default'].Mixin.create(_uiMixinsThrottledResize['default'], {
    tooltipContent: null,
    originalNode: null,
    router: _ember['default'].inject.service("-routing"),
    currentRoute: null,

    tooltipService: _ember['default'].inject.service('tooltip'),

    mouseEnter: function mouseEnter() {
      this.get('tooltipService').cancelTimer();
    },
    mouseLeave: function mouseLeave() {
      this.destroyTooltip();
    },

    routeObserver: _ember['default'].observer('router.currentRouteName', function () {
      // On init
      if (!this.get('currentRoute')) {
        this.set('currentRoute', this.get('router.currentRouteName'));
      }

      // if we change routes tear down the tooltip
      if (this.get('currentRoute') !== this.get('router.currentRouteName')) {
        this.destroyTooltip();
      }
    }).on('init'),

    tooltipConstructor: (function () {
      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        if (this.get('tooltipService.tooltipOpts')) {
          this.constructTooltip();
        }
      });
    }).observes('tooltipService.tooltipOpts').on('init'),

    constructTooltip: function constructTooltip() {
      var tts = this.get('tooltipService');
      var node = _ember['default'].$(this.element);
      var eventPosition = tts.get('tooltipOpts.eventPosition');
      var position = this.positionTooltip(node, eventPosition);
      var css = { visibility: 'visible' };

      if (tts.get('tooltipOpts.isCopyTo')) {
        css.width = position.width + 1;
      }

      node.offset(position).addClass(position.placement + ' ' + tts.tooltipOpts.baseClass).css(css);
    },

    destroyTooltip: function destroyTooltip() {
      this.get('tooltipService').startTimer();
    },

    positionTooltip: function positionTooltip(node, position) {

      var windowWidth = window.innerWidth;
      var originalNodeWidth = this.get('tooltipService.tooltipOpts.originalNode').outerWidth();
      var originalNodeHeight = this.get('tooltipService.tooltipOpts.originalNode').outerHeight();
      var nodeHeight = node.outerHeight();
      var nodeWidth = node.outerWidth();

      if (nodeWidth >= position.left) {
        position.placement = 'left';
        position.top = position.top + originalNodeHeight / 2 - nodeHeight / 2;
        position.left = position.left + originalNodeWidth + 7;
      } else if (nodeWidth >= windowWidth - position.left) {
        position.placement = 'right';
        position.left = position.left - nodeWidth - 7;
        position.top = position.top + originalNodeHeight / 2 - nodeHeight / 2;
      } else if (nodeHeight >= position.top) {
        position.placement = 'bottom';
        position.top = position.top + originalNodeHeight + 7;
        position.left = position.left + originalNodeWidth / 2 - nodeWidth / 2;
      } else {
        position.placement = 'top';
        position.top = position.top - (nodeHeight + 7);
        position.left = position.left + originalNodeWidth / 2 - nodeWidth / 2;
      }

      position.width = nodeWidth;

      return position;
    }
  });
});