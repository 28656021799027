define('ui/services/swarm', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/util'], function (exports, _ember, _uiUtilsConstants, _uiUtilsUtil) {
  exports['default'] = _ember['default'].Service.extend({
    store: _ember['default'].inject.service(),

    isReady: function isReady() {
      var _this = this;

      return this.get('store').find('stack').then(function (stacks) {
        return _this.get('store').find('service').then(function (services) {
          var stack = _this.filterSystemStack(stacks);
          if (stack) {
            var matching = services.filterBy('stackId', stack.get('id'));
            var expect = matching.get('length');
            var healthy = _uiUtilsUtil['default'].filterByValues(matching, 'healthState', _uiUtilsConstants['default'].READY_STATES).get('length');
            return expect > 0 && expect === healthy;
          }

          return false;
        });
      })['catch'](function () {
        return _ember['default'].RSVP.resolve(false);
      });
    },

    filterSystemStack: function filterSystemStack(stacks) {
      return (stacks || []).find(function (stack) {
        var info = stack.get('externalIdInfo');
        return (info.kind === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_CATALOG || info.kind === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SYSTEM_CATALOG) && info.base === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_INFRA && info.name === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SWARM;
      });
    }
  });
});