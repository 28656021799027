define('ui/components/container-subpod/component', ['exports', 'ember', 'ui/utils/constants', 'ui/mixins/stripped-name'], function (exports, _ember, _uiUtilsConstants, _uiMixinsStrippedName) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsStrippedName['default'], {
    model: null,
    children: null,
    groupHasChildren: false,

    classNames: ['subpod', 'instance'],
    classNameBindings: ['model.isManaged:managed'],

    stateBackground: (function () {
      return 'bg-' + this.get('model.stateColor').substr(5);
    }).property('model.stateColor'),

    isKubernetes: (function () {
      return !!this.get('model.labels')[_uiUtilsConstants['default'].LABEL.K8S_POD_NAME];
    }).property('model.labels')
  });
});