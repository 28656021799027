define("ui/components/registry-row/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 2
            },
            "end": {
              "line": 8,
              "column": 70
            }
          },
          "moduleName": "ui/components/registry-row/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["content", "model.credential.publicValue", ["loc", [null, [8, 38], [8, 70]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 70
            },
            "end": {
              "line": 8,
              "column": 124
            }
          },
          "moduleName": "ui/components/registry-row/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("i");
          dom.setAttribute(el1, "class", "text-muted");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["generic.none"], [], ["loc", [null, [8, 100], [8, 120]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 16,
            "column": 0
          }
        },
        "moduleName": "ui/components/registry-row/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "class", "state");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("td");
        dom.setAttribute(el1, "align", "actions");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        var element3 = dom.childAt(fragment, [6]);
        var element4 = dom.childAt(fragment, [8]);
        var morphs = new Array(10);
        morphs[0] = dom.createAttrMorph(element0, 'data-title');
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createAttrMorph(element1, 'data-title');
        morphs[3] = dom.createMorphAt(element1, 1, 1);
        morphs[4] = dom.createAttrMorph(element2, 'data-title');
        morphs[5] = dom.createMorphAt(element2, 1, 1);
        morphs[6] = dom.createAttrMorph(element3, 'data-title');
        morphs[7] = dom.createMorphAt(element3, 1, 1);
        morphs[8] = dom.createAttrMorph(element4, 'data-title');
        morphs[9] = dom.createMorphAt(element4, 1, 1);
        return morphs;
      },
      statements: [["attribute", "data-title", ["concat", [["subexpr", "t", ["generic.state"], [], ["loc", [null, [1, 16], [1, 37]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "badge-state", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [2, 22], [2, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 2], [2, 29]]], 0, 0], ["attribute", "data-title", ["concat", [["subexpr", "t", ["registryRow.address"], [], ["loc", [null, [4, 16], [4, 43]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "model.displayAddress", ["loc", [null, [5, 2], [5, 26]]], 0, 0, 0, 0], ["attribute", "data-title", ["concat", [["subexpr", "t", ["registryRow.username"], [], ["loc", [null, [7, 16], [7, 44]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "model.credential.publicValue", ["loc", [null, [8, 8], [8, 36]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [8, 2], [8, 131]]]], ["attribute", "data-title", ["concat", [["subexpr", "t", ["registryRow.created"], [], ["loc", [null, [10, 16], [10, 43]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "date-calendar", [["get", "model.created", ["loc", [null, [11, 18], [11, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [11, 2], [11, 33]]], 0, 0], ["attribute", "data-title", ["concat", [["subexpr", "t", ["generic.actions"], [], ["loc", [null, [13, 16], [13, 39]]], 0, 0], ":"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "action-menu", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [14, 22], [14, 27]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 2], [14, 29]]], 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});