define('ui/models/password', ['exports', 'ember-api-store/models/resource', 'ui/mixins/cattle-polled-resource'], function (exports, _emberApiStoreModelsResource, _uiMixinsCattlePolledResource) {

  var Password = _emberApiStoreModelsResource['default'].extend(_uiMixinsCattlePolledResource['default'], {
    type: 'password'
  });

  // Passwords don't get pushed by /subscribe WS, so refresh more often
  Password.reopenClass({
    pollTransitioningDelay: 1000,
    pollTransitioningInterval: 5000
  });

  exports['default'] = Password;
});