define("ui/fail-whale/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 40
            },
            "end": {
              "line": 5,
              "column": 78
            }
          },
          "moduleName": "ui/fail-whale/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("(");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode(")");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["content", "model.status", ["loc", [null, [5, 61], [5, 77]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 14,
              "column": 2
            },
            "end": {
              "line": 28,
              "column": 2
            }
          },
          "moduleName": "ui/fail-whale/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "failplane");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "width", "484");
          dom.setAttribute(el2, "height", "269");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "class", "failprop");
          dom.setAttribute(el2, "width", "123");
          dom.setAttribute(el2, "height", "246");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "failcow");
          dom.setAttribute(el1, "width", "178");
          dom.setAttribute(el1, "height", "255");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "cloud");
          dom.setAttribute(el1, "width", "254");
          dom.setAttribute(el1, "height", "121");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "cloud");
          dom.setAttribute(el1, "width", "151");
          dom.setAttribute(el1, "height", "91");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "cloud");
          dom.setAttribute(el1, "width", "175");
          dom.setAttribute(el1, "height", "102");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "cloud");
          dom.setAttribute(el1, "width", "300");
          dom.setAttribute(el1, "height", "142");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("img");
          dom.setAttribute(el1, "class", "cloud");
          dom.setAttribute(el1, "width", "320");
          dom.setAttribute(el1, "height", "152");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "wave");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var element3 = dom.childAt(fragment, [3]);
          var element4 = dom.childAt(fragment, [5]);
          var element5 = dom.childAt(fragment, [7]);
          var element6 = dom.childAt(fragment, [9]);
          var element7 = dom.childAt(fragment, [11]);
          var element8 = dom.childAt(fragment, [13]);
          var morphs = new Array(8);
          morphs[0] = dom.createAttrMorph(element1, 'src');
          morphs[1] = dom.createAttrMorph(element2, 'src');
          morphs[2] = dom.createAttrMorph(element3, 'src');
          morphs[3] = dom.createAttrMorph(element4, 'src');
          morphs[4] = dom.createAttrMorph(element5, 'src');
          morphs[5] = dom.createAttrMorph(element6, 'src');
          morphs[6] = dom.createAttrMorph(element7, 'src');
          morphs[7] = dom.createAttrMorph(element8, 'src');
          return morphs;
        },
        statements: [["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [16, 18], [16, 32]]], 0, 0, 0, 0], "assets/images/logos/fail-plane.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [17, 35], [17, 49]]], 0, 0, 0, 0], "assets/images/logos/fail-prop.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [20, 32], [20, 46]]], 0, 0, 0, 0], "assets/images/logos/fail-cowparachute.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [21, 30], [21, 44]]], 0, 0, 0, 0], "assets/images/logos/fail-cloud1.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [22, 30], [22, 44]]], 0, 0, 0, 0], "assets/images/logos/fail-cloud2.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [23, 30], [23, 44]]], 0, 0, 0, 0], "assets/images/logos/fail-cloud3.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [24, 30], [24, 44]]], 0, 0, 0, 0], "assets/images/logos/fail-cloud4.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["get", "app.baseAssets", ["loc", [null, [25, 30], [25, 44]]], 0, 0, 0, 0], "assets/images/logos/fail-cloud5.svg"], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 0
          }
        },
        "moduleName": "ui/fail-whale/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "fail-whale");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "error");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("h2");
        dom.setAttribute(el3, "class", "bg-danger");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "r-p20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("h4");
        dom.setAttribute(el4, "class", "r-mt20");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("p");
        dom.setAttribute(el4, "class", "small");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "r-mt20");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("a");
        dom.setAttribute(el5, "class", "hand");
        var el6 = dom.createComment("");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element9 = dom.childAt(fragment, [0]);
        var element10 = dom.childAt(element9, [1]);
        var element11 = dom.childAt(element10, [3]);
        var element12 = dom.childAt(element11, [1]);
        var element13 = dom.childAt(element11, [7]);
        var element14 = dom.childAt(element13, [3]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(element12, 0, 0);
        morphs[2] = dom.createMorphAt(element12, 2, 2);
        morphs[3] = dom.createMorphAt(dom.childAt(element11, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element11, [5]), 0, 0);
        morphs[5] = dom.createMorphAt(element13, 1, 1);
        morphs[6] = dom.createElementMorph(element14);
        morphs[7] = dom.createMorphAt(element14, 0, 0);
        morphs[8] = dom.createMorphAt(element9, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", ["failWhalePage.header"], [], ["loc", [null, [3, 26], [3, 54]]], 0, 0], ["content", "model.code", ["loc", [null, [5, 25], [5, 39]]], 0, 0, 0, 0], ["block", "if", [["get", "model.status", ["loc", [null, [5, 46], [5, 58]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 40], [5, 85]]]], ["content", "model.message", ["loc", [null, [6, 9], [6, 26]]], 0, 0, 0, 0], ["content", "model.detail", ["loc", [null, [7, 23], [7, 39]]], 0, 0, 0, 0], ["inline", "format-html-message", ["failWhalePage.reloadButton"], [], ["loc", [null, [9, 8], [9, 60]]], 0, 0], ["element", "action", ["logout"], [], ["loc", [null, [9, 77], [9, 96]]], 0, 0], ["inline", "t", ["failWhalePage.logoutButton"], [], ["loc", [null, [9, 97], [9, 131]]], 0, 0], ["block", "if", [["get", "settings.isRancher", ["loc", [null, [14, 8], [14, 26]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [14, 2], [28, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});