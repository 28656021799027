define('ui/components/form-launch-config-switch/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    projects: _ember['default'].inject.service(),
    hasVm: _ember['default'].computed.alias('projects.current.virtualMachine'),

    index: null,
    choices: null,
    showAdd: true,
    initialIndex: -1,

    actions: {
      'switch': function _switch(index) {
        this.sendAction('switch', index);
      },

      add: function add(vm) {
        this.sendAction('add', vm);
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.send('switch', this.get('initialIndex'));
    },

    hasSidekicks: (function () {
      return this.get('choices.length') > 1;
    }).property('choices.length'),

    enabledChoices: (function () {
      return this.get('choices').filterBy('enabled', true);
    }).property('choices.@each.enabled')
  });
});