define("ui/components/theme-toggle/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "ui/components/theme-toggle/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "role", "button");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "role", "button");
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "icon icon-history");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "role", "button");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element0, 'class');
        morphs[1] = dom.createElementMorph(element0);
        morphs[2] = dom.createMorphAt(element0, 0, 0);
        morphs[3] = dom.createAttrMorph(element1, 'class');
        morphs[4] = dom.createElementMorph(element1);
        morphs[5] = dom.createMorphAt(element1, 2, 2);
        morphs[6] = dom.createAttrMorph(element2, 'class');
        morphs[7] = dom.createElementMorph(element2);
        morphs[8] = dom.createMorphAt(element2, 0, 0);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", ["btn btn-default ", ["subexpr", "if", [["subexpr", "eq", [["get", "theme", ["loc", [null, [1, 40], [1, 45]]], 0, 0, 0, 0], "ui-light"], [], ["loc", [null, [1, 36], [1, 57]]], 0, 0], "active", ""], [], ["loc", [null, [1, 31], [1, 71]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["changeTheme", "ui-light"], [], ["loc", [null, [1, 73], [1, 108]]], 0, 0], ["inline", "t", ["nav.user.themeLight"], [], ["loc", [null, [1, 123], [1, 150]]], 0, 0], ["attribute", "class", ["concat", ["btn btn-default ", ["subexpr", "if", [["subexpr", "eq", [["get", "theme", ["loc", [null, [3, 40], [3, 45]]], 0, 0, 0, 0], "ui-auto"], [], ["loc", [null, [3, 36], [3, 56]]], 0, 0], "active", ""], [], ["loc", [null, [3, 31], [3, 70]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["changeTheme", "ui-auto"], [], ["loc", [null, [3, 72], [3, 106]]], 0, 0], ["inline", "t", ["nav.user.themeAuto"], [], ["loc", [null, [3, 155], [3, 181]]], 0, 0], ["attribute", "class", ["concat", ["btn btn-default ", ["subexpr", "if", [["subexpr", "eq", [["get", "theme", ["loc", [null, [5, 40], [5, 45]]], 0, 0, 0, 0], "ui-dark"], [], ["loc", [null, [5, 36], [5, 56]]], 0, 0], "active", ""], [], ["loc", [null, [5, 31], [5, 70]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["changeTheme", "ui-dark"], [], ["loc", [null, [5, 72], [5, 106]]], 0, 0], ["inline", "t", ["nav.user.themeDark"], [], ["loc", [null, [5, 121], [5, 147]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});