define('ui/host/containers/route', ['exports', 'ember', 'ui/utils/multi-stats'], function (exports, _ember, _uiUtilsMultiStats) {
  exports['default'] = _ember['default'].Route.extend({
    statsSocket: null,

    model: function model() {
      return this.modelFor('host').get('host');
    },

    afterModel: function afterModel() {
      this.setupSocketConnection();
    },

    setupSocketConnection: function setupSocketConnection() {
      var _this = this;

      if (this.get('statsSocket')) {
        this.deactivate();
      }

      var stats = _uiUtilsMultiStats['default'].create({
        resource: this.modelFor('host').get('host'),
        linkName: 'containerStats'
      });

      this.set('statsSocket', stats);

      stats.on('dataPoint', function (data) {
        var controller = _this.get('controller');

        if (controller) {
          controller.onDataPoint(data);
        }
      });
    },

    deactivate: function deactivate() {
      this.get('statsSocket').close();
    }
  });
});