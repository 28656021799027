define('ui/authenticated/project/api/keys/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var me = this.get('session.' + _uiUtilsConstants['default'].SESSION.ACCOUNT_ID);
      return _ember['default'].RSVP.hash({
        account: this.get('userStore').findAll('apikey', null, { filter: { accountId: me }, url: 'apikeys', forceReload: true }),
        environment: this.get('store').findAll('apikey', null, { forceReload: true })
      });
    }
  });
});