define('ui/components/tooltip-warning/component', ['exports', 'ember', 'ui/mixins/tooltip', 'ui/utils/constants'], function (exports, _ember, _uiMixinsTooltip, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsTooltip['default'], {
    prefs: _ember['default'].inject.service(),
    classNames: ['tooltip-warning-container'],
    actions: {
      hideAccessWarning: function hideAccessWarning() {
        this.set('prefs.' + _uiUtilsConstants['default'].PREFS.ACCESS_WARNING, false);
        this.destroyTooltip();
      }

    }
  });
});