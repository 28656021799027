define('ui/components/input-command/component', ['exports', 'ember', 'npm:shell-quote'], function (exports, _ember, _npmShellQuote) {
  exports.reop = reop;
  exports.unparse = unparse;
  var OPS = ['||', '&&', ';;', '|&', '&', ';', '(', ')', '|', '<', '>'];
  exports.OPS = OPS;

  function reop(xs) {
    return xs.map(function (s) {
      if (OPS.includes(s)) {
        return { op: s };
      } else {
        return s;
      }
    });
  }

  function unparse(xs) {
    return xs.map(function (s) {
      if (s && typeof s === 'object') {
        if (s.hasOwnProperty('pattern')) {
          return '"' + s.pattern + '"';
        } else {
          return s.op;
        }
      } else if (/["\s]/.test(s) && !/'/.test(s)) {
        return "'" + s.replace(/(['\\])/g, '\\$1') + "'";
      } else if (/["'\s]/.test(s)) {
        return '"' + s.replace(/(["\\$`!])/g, '\\$1') + '"';
      } else {
        return String(s).replace(/([\\$`()!#&*|])/g, '\\$1');
      }
    }).join(' ');
  }

  exports['default'] = _ember['default'].TextField.extend({
    type: 'text',

    init: function init() {
      this._super.apply(this, arguments);

      var initial = this.get('initialValue') || '';
      if (_ember['default'].isArray(initial)) {
        this.set('value', unparse(reop(initial)));
      } else {
        this.set('value', initial);
      }
    },

    valueChanged: (function () {
      var out = _npmShellQuote['default'].parse(this.get('value') || '').map(function (piece) {
        if (typeof piece === 'object' && piece) {
          if (piece.pattern) {
            return piece.pattern;
          } else if (piece.op) {
            return piece.op;
          } else {
            return '';
          }
        }

        return piece;
      });

      if (out.length) {
        this.sendAction('changed', out);
      } else {
        this.sendAction('changed', null);
      }
    }).observes('value')
  });
});