define('ui/components/device-permissions/component', ['exports', 'ember'], function (exports, _ember) {
  var computed = /*, set*/_ember['default'].computed;
  var get = _ember['default'].get;
  exports['default'] = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),

    rSelected: false,
    wSelected: false,
    mSelected: false,
    editing: false,

    selection: null,

    init: function init() {
      this._super();
      var sel = get(this, 'initialSelection');
      this.setProperties({
        rSelected: sel.indexOf('r') >= 0,
        wSelected: sel.indexOf('w') >= 0,
        mSelected: sel.indexOf('m') >= 0
      });
    },

    actions: {
      selectChanged: function selectChanged(x, ele) {
        var str = ($(ele.target).val() || []).join('');
        this.sendAction('changed', str);
      }
    },

    didInsertElement: function didInsertElement() {
      if (get(this, 'editing')) {
        var moreClass = get(this, 'buttonClass') || '';
        var opts = {
          buttonClass: 'btn btn-default' + (moreClass ? ' ' + moreClass : ''),
          numberDisplayed: 2,
          nonSelectedText: get(this, 'intl').t('devicePermissions.none'),
          allSelectedText: get(this, 'intl').t('devicePermissions.all'),

          templates: {
            li: '<li><a tabindex="0"><label></label></a></li>'
          }
        };

        this.$('SELECT').multiselect(opts);
      }
    },

    humanReadableList: computed('initialSelection', function () {
      var _this = this;

      var permissions = get(this, 'initialSelection').split('');
      var out = [];

      permissions.forEach(function (perm) {
        switch (perm) {
          case 'r':
            out.push(get(_this, 'intl').tHtml('devicePermissions.read'));
            break;
          case 'w':
            out.push(get(_this, 'intl').tHtml('devicePermissions.write'));
            break;
          case 'm':
            out.push(get(_this, 'intl').tHtml('devicePermissions.mknod'));
            break;
          default:
            break;
        }
      });

      return out.join('/');
    }),

    rebuild: (function () {
      var _this2 = this;

      _ember['default'].run.next(function () {
        if (get(_this2, 'editing')) {
          _this2.$('SELECT').multiselect('setOptions', {
            nonSelectedText: get(_this2, 'intl').t('devicePermissions.none'),
            allSelectedText: get(_this2, 'intl').t('devicePermissions.all')
          });
          _this2.$('SELECT').multiselect('rebuild');
        }
      });
    }).observes('intl._locale')
  });
});