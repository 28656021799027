define('ui/login/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['timedOut', 'errorMsg'],
    access: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    isGithub: _ember['default'].computed.equal('access.provider', 'githubconfig'),
    isActiveDirectory: _ember['default'].computed.equal('access.provider', 'ldapconfig'),
    isOpenLdap: _ember['default'].computed.equal('access.provider', 'openldapconfig'),
    isLocal: _ember['default'].computed.equal('access.provider', 'localauthconfig'),
    isAzureAd: _ember['default'].computed.equal('access.provider', 'azureadconfig'),
    isShibboleth: _ember['default'].computed.equal('access.provider', 'shibbolethconfig'),

    timedOut: false,
    waiting: false,
    errorMsg: null,

    actions: {
      started: function started() {
        this.setProperties({
          'timedOut': false,
          'waiting': true,
          'errorMsg': null
        });
      },

      authenticate: function authenticate(code) {
        var _this = this;

        this.send('started');

        _ember['default'].run.later(function () {
          _this.get('access').login(code).then(function () {
            _this.send('finishLogin');
          })['catch'](function (err) {
            _this.set('waiting', false);

            if (err && err.status === 401) {
              _this.set('errorMsg', _this.get('intl').t('loginPage.error.authFailed'));
            } else {
              _this.set('errorMsg', err ? err.message : "No response received");
            }
          })['finally'](function () {
            _this.set('waiting', false);
          });
        }, 10);
      }
    },

    bootstrap: (function () {
      _ember['default'].run.schedule('afterRender', this, function () {
        var user = _ember['default'].$('.login-user')[0];
        var pass = _ember['default'].$('.login-pass')[0];
        if (user) {
          if (user.value) {
            pass.focus();
          } else {
            user.focus();
          }
        }
      });
    }).on('init'),

    infoMsg: (function () {
      if (this.get('errorMsg')) {
        return this.get('errorMsg');
      } else if (this.get('timedOut')) {
        return this.get('intl').t('loginPage.error.timedOut');
      } else {
        return '';
      }
    }).property('timedOut', 'errorMsg', 'intl._locale')

  });
});