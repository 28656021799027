define('ui/mixins/console', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    application: _ember['default'].inject.controller(),
    queryParams: ['instanceId'],
    instanceId: null,
    model: null,

    bootstrap: (function () {
      if (this.get('application.isPopup')) {
        _ember['default'].$('body').css('overflow', 'hidden');
      }
    }).on('init'),

    actions: {
      cancel: function cancel() {
        window.close();
      }
    }
  });
});