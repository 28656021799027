define('ui/mixins/container-choices', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    intl: _ember['default'].inject.service(),
    // linksArray, allHosts, instance should be set

    isManagedNetwork: _ember['default'].computed.equal('instance.networkMode', 'managed'),

    containerChoices: (function () {
      var _this = this;

      var list = [];
      var id = this.get('id');
      var intl = this.get('intl');

      var expectContainerIds = (this.get('linksArray') || []).map(function (obj) {
        return _ember['default'].get(obj, 'targetInstanceId');
      });

      this.get('allHosts').map(function (host) {
        var containers = (host.get('instances') || []).filter(function (instance) {
          // You can't link to yourself, or to other types of instances, or to system containers
          return instance.get('id') !== id && instance.get('kind') === 'container' && !instance.get('systemContainer');
        });

        var hostLabel = undefined;
        if (host.get('state') === 'active') {
          hostLabel = intl.t('containerChoices.hostGroup', { name: host.get('displayName') });
        } else {
          hostLabel = intl.t('containerChoices.hostGroupWithState', { name: host.get('displayName'), state: host.get('state') });
        }

        list.pushObjects(containers.map(function (container) {
          expectContainerIds.removeObject(container.get('id'));

          var containerLabel = undefined;
          if (container.get('state') === 'running') {
            containerLabel = intl.t('containerChoices.containerOption', { name: container.get('displayName') });
          } else {
            containerLabel = intl.t('containerChoices.containerOption', { name: container.get('displayName'), state: container.get('state') });
          }

          return {
            group: hostLabel,
            hostId: host.get('id'),
            id: container.get('id'),
            name: containerLabel
          };
        }));
      });

      if (expectContainerIds.get('length')) {
        // There are some links to containers which are not in the list somehow..
        expectContainerIds.forEach(function (id) {
          var container = _this.get('store').getById('container', id);
          return {
            group: intl.t('containerChoices.unknownHost'),
            hostId: null,
            id: id,
            name: container && container.get('name') ? container.get('name') : '(' + id + ')'
          };
        });
      }

      return list.sortBy('group', 'name', 'id');
    }).property('allHosts.@each.instances', 'intl._locale'),

    containersOnRequestedHost: (function () {
      var requestedHostId = this.get('instance.requestedHostId');
      var all = this.get('containerChoices');

      if (requestedHostId) {
        return all.filterBy('hostId', requestedHostId);
      } else {
        return all;
      }
    }).property('containerChoices.@each.hostId', 'instance.requestedHostId'),

    containersOnRequestedHostIfUnmanaged: (function () {
      var requestedHostId = this.get('instance.requestedHostId');
      var all = this.get('containerChoices');
      var isManagedNetwork = this.get('isManagedNetwork');

      if (requestedHostId && !isManagedNetwork) {
        return all.filterBy('hostId', requestedHostId);
      } else {
        return all;
      }
    }).property('containerChoices.@each.hostId', 'instance.requestedHostId', 'isManagedNetwork', 'intl._locale')
  });
});