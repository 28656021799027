define('ui/initializers/extend-bootstrap', ['exports', 'ui/utils/bootstrap-fixes'], function (exports, _uiUtilsBootstrapFixes) {
  exports.initialize = initialize;

  function initialize() /*application*/{
    $(document).on('shown.bs.dropdown.position-calculator', function (event, data) {
      _uiUtilsBootstrapFixes['default'].resizeDropdown(event, data);
    });
  }

  exports['default'] = {
    name: 'extend-bootstrap',
    initialize: initialize
  };
});