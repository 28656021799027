define('ui/components/form-value-array/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // Inputs
    initialValues: null,
    addActionLabel: 'formValueArray.addActionLabel',
    valueLabel: 'formValueArray.valueLabel',
    valuePlaceholder: 'formValueArray.valuePlaceholder',
    showProTip: true,

    ary: null,
    asValues: null,

    actions: {
      add: function add() {
        var _this = this;

        this.get('ary').pushObject(_ember['default'].Object.create({ value: '' }));
        _ember['default'].run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.$('INPUT.value').last()[0].focus();
        });
      },

      remove: function remove(obj) {
        this.get('ary').removeObject(obj);
      },

      pastedValues: function pastedValues(str) {
        var ary = this.get('ary');
        str = str.trim();

        var lines = str.split(/\r?\n/);
        lines.forEach(function (line) {
          line = line.trim();
          if (!line) {
            return;
          }

          ary.pushObject(_ember['default'].Object.create({ value: line }));
        });

        // Clean up empty user entries
        var toRemove = [];
        ary.forEach(function (item) {
          if (!item.get('value')) {
            toRemove.push(item);
          }
        });

        ary.removeObjects(toRemove);
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      var ary = [];
      (this.get('initialValues') || []).forEach(function (value) {
        ary.push(_ember['default'].Object.create({ value: value }));
      });

      this.set('ary', ary);
    },

    asValuesObserver: (function () {
      var out = this.get('ary').filterBy('value').map(function (row) {
        return row.get('value');
      });

      this.set('asValues', out);
      this.sendAction('changed', out);
    }).observes('ary.@each.{value}')
  });
});