define('ui/admin-tab/ha/controller', ['exports', 'ember', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], {
    settings: _ember['default'].inject.service(),

    sortBy: 'address',
    sorts: {
      state: ['stateSort', 'address', 'uuid'],
      address: ['address', 'uuid'],
      port: ['port', 'address', 'uuid'],
      heartbeat: ['heartbeat', 'address', 'uuid'],
      clustered: ['clustered', 'address', 'uuid']
    }
  });
});