define('ui/components/input-text-file/component', ['exports', 'ember', 'ui/utils/platform'], function (exports, _ember, _uiUtilsPlatform) {
  exports['default'] = _ember['default'].Component.extend({
    value: null,
    placeholder: "",
    accept: "text/*",
    btnClass: "btn btn-primary",
    encode: false,
    minHeight: 0,
    maxHeight: 200,

    tagName: ['div'],
    classNames: ['input-group'],

    _boundChange: null,

    actions: {
      click: function click() {
        this.$('INPUT[type=file]')[0].click();
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('_boundChange', function (event) {
        _this.change(event);
      });
      this.$('INPUT[type=file]').on('change', this.get('_boundChange'));
    },

    willDestroyElement: function willDestroyElement() {
      this.$('INPUT[type=file]').off('change', this.get('_boundChange'));
    },

    change: function change(event) {
      var _this2 = this;

      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = function (event2) {
          var out = event2.target.result;

          if (_this2.get('encode')) {
            out = btoa(out);
          }

          _this2.set('value', out);
          input.value = '';
        };
        reader.readAsText(input.files[0]);
      }
    },

    actualAccept: (function () {
      if (_uiUtilsPlatform.isSafari) {
        return '';
      } else {
        return this.get('accept');
      }
    }).property('accept')
  });
});