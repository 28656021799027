define('ui/service/containers/controller', ['exports', 'ember', 'ui/mixins/sortable', 'ui/mixins/container-spark-stats'], function (exports, _ember, _uiMixinsSortable, _uiMixinsContainerSparkStats) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], _uiMixinsContainerSparkStats['default'], {
    sparkInstances: _ember['default'].computed.alias('model.instances'),

    sortableContent: _ember['default'].computed.alias('model.instances'),
    sortBy: 'name',
    sorts: {
      state: ['stateSort', 'name', 'id'],
      name: ['name', 'id'],
      ip: ['sortIp', 'name', 'id'],
      host: ['primaryHost.displayName', 'name', 'id'],
      image: ['imageUuid', 'command', 'name', 'id']
    }
  });
});