define('ui/components/form-image/component', ['exports', 'ember', 'ui/utils/constants', 'ui/mixins/manage-labels'], function (exports, _ember, _uiUtilsConstants, _uiMixinsManageLabels) {

  // Remember the last value and use that for new one
  var lastContainer = 'ubuntu:14.04.3';
  var lastVm = 'rancher/vm-ubuntu';
  var lastWindows = 'microsoft/nanoserver';

  exports['default'] = _ember['default'].Component.extend(_uiMixinsManageLabels['default'], {
    settings: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),

    // Inputs
    initialValue: null,
    errors: null,
    isVm: null,

    userInput: null,
    tagName: '',
    pullImage: null,
    value: null,

    actions: {
      setInput: function setInput(str) {
        this.set('userInput', str);
      }
    },

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.initLabels(this.get('initialLabels'), null, _uiUtilsConstants['default'].LABEL.PULL_IMAGE);

      var pull = this.getLabel(_uiUtilsConstants['default'].LABEL.PULL_IMAGE) === _uiUtilsConstants['default'].LABEL.PULL_IMAGE_VALUE;
      this.set('pullImage', pull);

      var initial;
      if (this.get('initialValue')) {
        initial = (this.get('initialValue') || '').replace(/^docker:/, '');
      }

      if (!initial) {
        if (this.get('projects.current.isWindows')) {
          initial = lastWindows;
        } else {
          initial = this.get('isVm') ? lastVm : lastContainer;
        }
      }

      _ember['default'].run.scheduleOnce('afterRender', function () {
        _this.set('userInput', initial);
        _this.userInputDidChange();
      });
    },

    updateLabels: function updateLabels(labels) {
      this.sendAction('setLabels', labels);
    },

    pullImageDidChange: (function () {
      if (this.get('pullImage')) {
        this.setLabel(_uiUtilsConstants['default'].LABEL.PULL_IMAGE, _uiUtilsConstants['default'].LABEL.PULL_IMAGE_VALUE);
      } else {
        this.removeLabel(_uiUtilsConstants['default'].LABEL.PULL_IMAGE, true);
      }
    }).observes('pullImage'),

    userInputDidChange: (function () {
      var input = (this.get('userInput') || '').trim();
      var out = 'docker:';

      // Look for a redundant docker: pasted in
      if (input.indexOf(out) === 0) {
        out = input;
      } else if (input && input.length) {
        if (this.get('projects.current.isWindows')) {
          lastWindows = input;
        } else if (this.get('isVm')) {
          lastVm = input;
        } else {
          lastContainer = input;
        }
        out += input;
      } else {
        out = null;
      }

      this.set('value', out);
      this.sendAction('changed', out);
      this.validate();
    }).observes('userInput'),

    validate: function validate() {
      var errors = [];
      if (!this.get('value')) {
        errors.push('Image is required');
      }

      this.set('errors', errors);
    }
  });
});