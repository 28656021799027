define('ui/components/site-access/component', ['exports', 'ember', 'ui/utils/errors'], function (exports, _ember, _uiUtilsErrors) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'section',
    classNames: ['well'],
    settings: _ember['default'].inject.service(),
    access: _ember['default'].inject.service(),

    model: null,
    individuals: 'siteAccess.users',
    collection: 'siteAccess.groups',

    saved: true,
    errors: null,

    showList: (function () {
      return this.get('copy.accessMode') !== 'unrestricted';
    }).property('copy.accessMode'),

    actions: {
      addAuthorized: function addAuthorized(data) {
        this.send('clearError');
        this.set('saved', false);
        this.get('copy.allowedIdentities').pushObject(data);
      },

      removeIdentity: function removeIdentity(ident) {
        this.set('saved', false);
        this.get('copy.allowedIdentities').removeObject(ident);
      },

      save: function save(btnCb) {
        var _this = this;

        this.send('clearError');

        if (this.get('showList') && !this.get('copy.allowedIdentities.length')) {
          this.send('gotError', 'You must add at least one authorized entry');
          btnCb();
          return;
        }

        this.set('saved', false);

        var copy = this.get('copy');
        copy.save().then(function () {
          _this.get('model').replaceWith(copy);
          _this.set('copy.allowedIdentities', _this.get('copy.allowedIdentities').slice());
          _this.set('saved', true);
        })['catch'](function (err) {
          _this.send('gotError', err);
        })['finally'](function () {
          btnCb();
        });
      },

      gotError: function gotError(err) {
        this.set('errors', [_uiUtilsErrors['default'].stringify(err)]);
      },

      clearError: function clearError() {
        this.set('errors', null);
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('copy', this.get('model').clone());
      this.set('copy.allowedIdentities', (this.get('copy.allowedIdentities') || []).slice());
    },

    accessModeChanged: (function () {
      this.set('saved', false);
      var identities = this.get('copy.allowedIdentities');
      if (!identities) {
        identities = [];
        this.set('copy.allowedIdentities', identities);
      }

      if (this.get('copy.accessMode') !== 'unrestricted') {
        var me = this.get('access.identity');
        var found = identities.filterBy('id', me.get('id')).length > 0;
        if (!found) {
          identities.push(me);
        }
      }
    }).observes('copy.accessMode')

  });
});