define('ui/components/orchestration/wait-kubernetes/component', ['exports', 'ember', 'ui/utils/debounce', 'ui/utils/util', 'ui/utils/constants'], function (exports, _ember, _uiUtilsDebounce, _uiUtilsUtil, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend({
    k8s: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    timer: null,
    currentStep: 0,
    subStep: 0,
    subCount: 0,

    services: null,
    hosts: null,
    stacks: null,

    init: function init() {
      this._super.apply(this, arguments);

      var store = this.get('store');
      this.set('services', store.all('service'));
      this.set('hosts', store.all('host'));
      this.set('stacks', store.all('stack'));
      this.updateStep();
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].run.cancel(this.get('timer'));
    },

    steps: ['waitKubernetes.addHost', 'waitKubernetes.activateHost', 'waitKubernetes.createStack', 'waitKubernetes.startServices', 'waitKubernetes.waitApi'],

    updateStep: (0, _uiUtilsDebounce.debouncedObserver)('hosts.@each.state', 'stacks.@each.{state,externalId}', 'services.@each.{state,healthState}', function () {
      var _this = this;

      this.set('subStep', 0);
      this.set('subCount', 0);

      if (this.get('hosts.length') === 0) {
        this.set('currentStep', 0);
        return;
      }

      if (this.get('hosts').filterBy('state', 'active').get('length') === 0) {
        this.set('currentStep', 1);
        return;
      }

      var stack = this.get('k8s').filterSystemStack(this.get('stacks'));
      if (!stack) {
        this.set('currentStep', 2);
        return;
      }

      if (stack.get('state') !== 'active') {
        if (stack.get('state') === 'inactive') {
          stack.doAction('activate');
          this.set('currentStep', 3);
          return;
        }
      }

      var services = (this.get('services') || []).filterBy('stackId', stack.get('id'));
      var num = services.get('length');
      var healthy = _uiUtilsUtil['default'].filterByValues(services, 'healthState', _uiUtilsConstants['default'].READY_STATES).get('length');
      if (num === 0 || healthy < num) {
        this.setProperties({
          currentStep: 3,
          subStep: healthy,
          subCount: num
        });

        return;
      }

      this.set('currentStep', 4);
      this.get('k8s').isReady().then(function (ready) {
        if (ready) {
          _this.set('currentStep', 5);
        } else {
          reschedule();
        }
      })['catch'](function () {
        reschedule();
      });

      var self = this;
      function reschedule() {
        self.set('timer', _ember['default'].run.later(self, 'updateStep', 5000));
      }
    }),

    stepChanged: (function () {
      if (this.get('currentStep') >= this.get('steps.length')) {
        this.sendAction('ready');
      }
    }).observes('currentStep', 'steps.length')
  });
});