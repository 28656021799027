define('ui/models/servicelog', ['exports', 'ember', 'ember-api-store/models/resource'], function (exports, _ember, _emberApiStoreModelsResource) {

  var ServiceLog = _emberApiStoreModelsResource['default'].extend({
    actions: {
      goToInstance: function goToInstance() {
        var _this = this;

        var id = this.get('instanceId');
        this.get('store').find('instance', id).then(function (inst) {
          if (inst.get('type').toLowerCase() === 'virtualmachine') {
            _this.get('router').transitionTo('virtualmachine', id);
          } else {
            _this.get('router').transitionTo('container', id);
          }
        });
      }
    },

    availableActions: (function () {
      var choices = [{ label: 'action.viewInstance', icon: 'icon icon-container', action: 'goToInstance', enabled: !!this.get('instanceId') }, { divider: true }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }];

      return choices;
    }).property('instanceId'),

    runTime: _ember['default'].computed('created', 'endTime', function () {
      if (this.get('endTime')) {
        var sec = moment(this.get('endTime')).diff(this.get('created'), 'seconds');
        if (sec > 0) {
          return sec + " sec";
        }
      } else {
        return "Running";
      }
    }),

    isError: _ember['default'].computed.equal('level', 'error'),

    displayState: _ember['default'].computed('level', function () {
      return this.get('level').toUpperCase();
    })
  });

  exports['default'] = ServiceLog;
});