define("ui/components/schema/input-service/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 4
            },
            "end": {
              "line": 6,
              "column": 4
            }
          },
          "moduleName": "ui/components/schema/input-service/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          dom.setAttribute(el1, "value", "customMode");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element2, 'selected');
          morphs[1] = dom.createMorphAt(element2, 0, 0);
          return morphs;
        },
        statements: [["attribute", "selected", ["subexpr", "eq", [["get", "customMode", ["loc", [null, [5, 47], [5, 57]]], 0, 0, 0, 0], true], [], ["loc", [null, [null, null], [5, 64]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["schema.inputService.custom.prompt"], [], ["loc", [null, [5, 65], [5, 106]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 8
              },
              "end": {
                "line": 11,
                "column": 8
              }
            },
            "moduleName": "ui/components/schema/input-service/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("/");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element0, 'selected');
            morphs[1] = dom.createAttrMorph(element0, 'value');
            morphs[2] = dom.createMorphAt(element0, 0, 0);
            morphs[3] = dom.createMorphAt(element0, 2, 2);
            return morphs;
          },
          statements: [["attribute", "selected", ["subexpr", "eq", [["get", "selected", ["loc", [null, [10, 32], [10, 40]]], 0, 0, 0, 0], ["get", "svc.id", ["loc", [null, [10, 41], [10, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [10, 49]]], 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "svc.id", ["loc", [null, [10, 58], [10, 64]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "svc.stackName", ["loc", [null, [10, 67], [10, 84]]], 0, 0, 0, 0], ["content", "svc.name", ["loc", [null, [10, 85], [10, 97]]], 0, 0, 0, 0]],
          locals: ["svc"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 4
            },
            "end": {
              "line": 13,
              "column": 4
            }
          },
          "moduleName": "ui/components/schema/input-service/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("optgroup");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'label');
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["attribute", "label", ["get", "group", ["loc", [null, [8, 24], [8, 29]]], 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "list", ["loc", [null, [9, 16], [9, 20]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [9, 8], [11, 17]]]]],
        locals: ["group", "list"],
        templates: [child0]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 2
            },
            "end": {
              "line": 17,
              "column": 2
            }
          },
          "moduleName": "ui/components/schema/input-service/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "input", [], ["class", "form-control input-sm input-select", "type", "text", "value", ["subexpr", "@mut", [["get", "custom", ["loc", [null, [16, 73], [16, 79]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["schema.inputService.custom.placeholder"], [], ["loc", [null, [16, 92], [16, 136]]], 0, 0]], ["loc", [null, [16, 4], [16, 138]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 18,
            "column": 6
          }
        },
        "moduleName": "ui/components/schema/input-service/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "input-service");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("select");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("option");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1]);
        var morphs = new Array(7);
        morphs[0] = dom.createAttrMorph(element4, 'class');
        morphs[1] = dom.createAttrMorph(element4, 'onchange');
        morphs[2] = dom.createAttrMorph(element5, 'selected');
        morphs[3] = dom.createMorphAt(element5, 0, 0);
        morphs[4] = dom.createMorphAt(element4, 3, 3);
        morphs[5] = dom.createMorphAt(element4, 4, 4);
        morphs[6] = dom.createMorphAt(element3, 3, 3);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["get", "selectClass", ["loc", [null, [2, 19], [2, 30]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "selected", ["loc", [null, [2, 57], [2, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 52], [2, 66]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [2, 89]]], 0, 0], 0, 0, 0, 0], ["attribute", "selected", ["subexpr", "and", [["subexpr", "eq", [["get", "selected", ["loc", [null, [3, 31], [3, 39]]], 0, 0, 0, 0], null], [], ["loc", [null, [3, 27], [3, 45]]], 0, 0], ["subexpr", "not-eq", [["get", "customMode", ["loc", [null, [3, 54], [3, 64]]], 0, 0, 0, 0], true], [], ["loc", [null, [3, 46], [3, 70]]], 0, 0]], [], ["loc", [null, [null, null], [3, 72]]], 0, 0], 0, 0, 0, 0], ["inline", "t", ["schema.inputService.prompt"], [], ["loc", [null, [3, 73], [3, 107]]], 0, 0], ["block", "if", [["get", "showCustomOption", ["loc", [null, [4, 10], [4, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 4], [6, 11]]]], ["block", "each-in", [["get", "grouped", ["loc", [null, [7, 15], [7, 22]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [7, 4], [13, 16]]]], ["block", "if", [["get", "customMode", ["loc", [null, [15, 8], [15, 18]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [15, 2], [17, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});