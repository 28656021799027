define('ui/admin-tab/auth/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    beforeModel: function beforeModel() {
      var _this = this;

      return this.get('authStore').rawRequest({ url: '/v1-auth/schemas', dataType: 'json' }).then(function (resp) {
        return _this.get('authStore')._bulkAdd('schema', resp.body.data);
      });
    }
  });
});