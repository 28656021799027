define('ui/initializers/inject-router', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    // Injects all Ember components & models (for actions) with a router object:
    application.inject('component', 'router', 'router:main');
    application.inject('model', 'router', 'router:main');
  }

  exports['default'] = {
    name: 'inject-router',
    initialize: initialize
  };
});