define('ui/models/instance', ['exports', 'ember', 'ember-api-store/models/resource', 'ui/utils/constants', 'ui/utils/util'], function (exports, _ember, _emberApiStoreModelsResource, _uiUtilsConstants, _uiUtilsUtil) {

  var Instance = _emberApiStoreModelsResource['default'].extend({
    isSystem: (function () {
      if (this.get('system')) {
        return true;
      }

      var labels = this.get('labels');
      return labels && !!labels[_uiUtilsConstants['default'].LABEL.SYSTEM_TYPE];
    }).property('system', 'labels'),

    memoryReservationBlurb: _ember['default'].computed('memoryReservation', function () {
      if (this.get('memoryReservation')) {
        return (0, _uiUtilsUtil.formatSi)(this.get('memoryReservation'), 1024, 'iB', 'B');
      }
    })
  });

  exports['default'] = Instance;
});