define('ui/container/ports/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var container = this.modelFor('container');
      return container.followLink('ports').then(function (ports) {
        return {
          container: container,
          ports: ports
        };
      });
    }
  });
});