define('ui/models/registry', ['exports', 'ember', 'ember-api-store/models/resource'], function (exports, _ember, _emberApiStoreModelsResource) {

  var Registry = _emberApiStoreModelsResource['default'].extend({
    type: 'registry',
    serverAddress: null,
    modalService: _ember['default'].inject.service('modal'),

    actions: {
      deactivate: function deactivate() {
        return this.doAction('deactivate');
      },

      activate: function activate() {
        return this.doAction('activate');
      },

      edit: function edit() {
        var _this = this;

        this.get('store').find('registry').then(function (registries) {
          _this.get('modalService').toggleModal('edit-registry', _ember['default'].Object.create({
            registries: registries,
            registry: _this,
            credential: _this.get('credential')
          }));
        });
      }
    },

    availableActions: (function () {
      var a = this.get('actionLinks');

      return [{ label: 'action.activate', icon: 'icon icon-play', action: 'activate', enabled: !!a.activate }, { label: 'action.deactivate', icon: 'icon icon-pause', action: 'deactivate', enabled: !!a.deactivate }, { label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: !!a.remove, altAction: 'delete' }, { divider: true }, { label: 'action.purge', icon: '', action: 'purge', enabled: !!a.purge }, { label: 'action.restore', icon: 'icon icon-medicalcross', action: 'restore', enabled: !!a.restore }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }, { divider: true }, { label: 'action.edit', icon: 'icon icon-edit', action: 'edit', enabled: !!a.update }];
    }).property('actionLinks.{update,activate,deactivate,restore,remove,purge}'),

    displayName: _ember['default'].computed.alias('displayAddress'),
    displayAddress: (function () {
      var address = this.get('serverAddress').toLowerCase();
      if (address === 'index.docker.io') {
        return 'DockerHub';
      } else if (address === 'quay.io') {
        return 'Quay';
      } else {
        return address;
      }
    }).property('serverAddress'),

    _allCredentials: null,
    credential: (function () {
      var all = this.get('_allCredentials');
      if (!all) {
        all = this.get('store').all('registrycredential');
        this.set('_allCredentials', all);
      }

      return all.filterBy('registryId', this.get('id')).get('lastObject');
    }).property('_allCredentials.@each.registryId', 'id')
  });

  exports['default'] = Registry;
});