define('ui/components/modal-container-stop/component', ['exports', 'ember', 'lacsso/components/modal-base', 'ui/utils/platform'], function (exports, _ember, _lacssoComponentsModalBase, _uiUtilsPlatform) {

  var TIMEOUT = 10;
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'medium-modal'],
    resources: _ember['default'].computed.alias('modalService.modalOpts.model'),
    inputTimeout: null,
    alternateLabel: _uiUtilsPlatform.alternateLabel,
    defaultTimeout: TIMEOUT,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('inputTimeout', TIMEOUT);
    },
    actions: {
      stop: function stop() {
        var _this = this;

        this.get('resources').forEach(function (resource) {
          resource.doAction('stop', { timeout: _this.get('inputTimeout') || TIMEOUT });
        });
        _ember['default'].run.next(function () {
          _this.send('cancel');
        });
      }
    }
  });
});