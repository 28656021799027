define("ui/hosts/new/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 3,
              "column": 72
            }
          },
          "moduleName": "ui/hosts/new/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "t", ["hostsPage.new.header.linkTo"], [], ["loc", [null, [3, 37], [3, 72]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 14,
                    "column": 10
                  },
                  "end": {
                    "line": 16,
                    "column": 10
                  }
                },
                "moduleName": "ui/hosts/new/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "alt", "custom");
                dom.setAttribute(el1, "href", "#");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element2, 'class');
                morphs[1] = dom.createElementMorph(element2);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["nav-box-item driver machine-driver custom ", ["subexpr", "if", [["subexpr", "eq", [["get", "driver", ["loc", [null, [15, 121], [15, 127]]], 0, 0, 0, 0], "custom"], [], ["loc", [null, [15, 117], [15, 137]]], 0, 0], "active"], [], ["loc", [null, [15, 112], [15, 148]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["switchDriver", "custom"], [], ["loc", [null, [15, 15], [15, 49]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 19,
                      "column": 12
                    },
                    "end": {
                      "line": 21,
                      "column": 12
                    }
                  },
                  "moduleName": "ui/hosts/new/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("a");
                  dom.setAttribute(el1, "href", "#");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element1, 'alt');
                  morphs[1] = dom.createAttrMorph(element1, 'class');
                  morphs[2] = dom.createElementMorph(element1);
                  return morphs;
                },
                statements: [["attribute", "alt", ["get", "choice.name", ["loc", [null, [20, 61], [20, 72]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["nav-box-item driver machine-driver ", ["get", "choice.name", ["loc", [null, [20, 119], [20, 130]]], 0, 0, 0, 0], " ", ["subexpr", "if", [["subexpr", "eq", [["get", "choice.name", ["loc", [null, [20, 142], [20, 153]]], 0, 0, 0, 0], ["get", "driver", ["loc", [null, [20, 154], [20, 160]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 138], [20, 161]]], 0, 0], "active"], [], ["loc", [null, [20, 133], [20, 172]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["switchDriver", ["get", "choice.name", ["loc", [null, [20, 41], [20, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 17], [20, 54]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 18,
                    "column": 10
                  },
                  "end": {
                    "line": 22,
                    "column": 10
                  }
                },
                "moduleName": "ui/hosts/new/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "choice.hasUi", ["loc", [null, [19, 18], [19, 30]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [19, 12], [21, 19]]]]],
              locals: ["choice"],
              templates: [child0]
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 10
                  },
                  "end": {
                    "line": 26,
                    "column": 10
                  }
                },
                "moduleName": "ui/hosts/new/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "alt", "other");
                dom.setAttribute(el1, "href", "#");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element0, 'class');
                morphs[1] = dom.createElementMorph(element0);
                return morphs;
              },
              statements: [["attribute", "class", ["concat", ["nav-box-item driver machine-driver other ", ["subexpr", "if", [["subexpr", "eq", [["get", "driver", ["loc", [null, [25, 118], [25, 124]]], 0, 0, 0, 0], "other"], [], ["loc", [null, [25, 114], [25, 133]]], 0, 0], "active"], [], ["loc", [null, [25, 109], [25, 144]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["switchDriver", "other"], [], ["loc", [null, [25, 15], [25, 48]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 6
                },
                "end": {
                  "line": 28,
                  "column": 6
                }
              },
              "moduleName": "ui/hosts/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "nav nav-boxes checked-active");
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element3, 1, 1);
              morphs[1] = dom.createMorphAt(element3, 3, 3);
              morphs[2] = dom.createMorphAt(element3, 5, 5);
              return morphs;
            },
            statements: [["block", "if", [["get", "allowCustom", ["loc", [null, [14, 16], [14, 27]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [14, 10], [16, 17]]]], ["block", "each", [["get", "sortedDrivers", ["loc", [null, [18, 18], [18, 31]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [18, 10], [22, 19]]]], ["block", "if", [["subexpr", "and", [["get", "allowOther", ["loc", [null, [24, 21], [24, 31]]], 0, 0, 0, 0], ["get", "hasOther", ["loc", [null, [24, 32], [24, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 16], [24, 41]]], 0, 0]], [], 2, null, ["loc", [null, [24, 10], [26, 17]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 10
                  },
                  "end": {
                    "line": 31,
                    "column": 74
                  }
                },
                "moduleName": "ui/hosts/new/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "t", ["hostsPage.new.manageLink"], [], ["loc", [null, [31, 42], [31, 74]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 6
                },
                "end": {
                  "line": 33,
                  "column": 6
                }
              },
              "moduleName": "ui/hosts/new/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              dom.setAttribute(el1, "class", "text-center small r-m0");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "link-to", ["admin-tab.machine"], [], 0, null, ["loc", [null, [31, 10], [31, 86]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 2
              },
              "end": {
                "line": 35,
                "column": 2
              }
            },
            "moduleName": "ui/hosts/new/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("section");
            dom.setAttribute(el1, "class", "well r-mb0");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(element4, 1, 1);
            morphs[1] = dom.createMorphAt(element4, 2, 2);
            return morphs;
          },
          statements: [["block", "if", [["get", "showPicker", ["loc", [null, [11, 12], [11, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [11, 6], [28, 13]]]], ["block", "if", [["get", "showManage", ["loc", [null, [29, 12], [29, 22]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [29, 6], [33, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 37,
                "column": 2
              },
              "end": {
                "line": 47,
                "column": 2
              }
            },
            "moduleName": "ui/hosts/new/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "component", [["subexpr", "if", [["subexpr", "or", [["subexpr", "not", [["get", "driverObj", ["loc", [null, [38, 29], [38, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 24], [38, 39]]], 0, 0], ["get", "driverObj.hasUi", ["loc", [null, [38, 40], [38, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 20], [38, 56]]], 0, 0], ["subexpr", "concat", ["machine/driver-", ["get", "driver", ["loc", [null, [38, 83], [38, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 57], [38, 90]]], 0, 0], "machine/driver-other"], [], ["loc", [null, [38, 16], [38, 114]]], 0, 0]], ["cancel", ["subexpr", "route-action", ["cancel"], [], ["loc", [null, [39, 13], [39, 36]]], 0, 0], "goBack", ["subexpr", "route-action", ["goBack"], [], ["loc", [null, [40, 13], [40, 36]]], 0, 0], "clonedModel", ["subexpr", "@mut", [["get", "model.clonedModel", ["loc", [null, [41, 18], [41, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "driver", ["subexpr", "concat", [["get", "driver", ["loc", [null, [42, 21], [42, 27]]], 0, 0, 0, 0], "Config"], [], ["loc", [null, [42, 13], [42, 37]]], 0, 0], "schemas", ["subexpr", "@mut", [["get", "model.schemas", ["loc", [null, [43, 14], [43, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "typeDocumentations", ["subexpr", "@mut", [["get", "model.typeDocumentations", ["loc", [null, [44, 25], [44, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "availableDrivers", ["subexpr", "@mut", [["get", "model.availableDrivers", ["loc", [null, [45, 23], [45, 45]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [38, 4], [46, 6]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 48,
              "column": 0
            }
          },
          "moduleName": "ui/hosts/new/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "or", [["get", "showPicker", ["loc", [null, [9, 12], [9, 22]]], 0, 0, 0, 0], ["get", "showManage", ["loc", [null, [9, 23], [9, 33]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 8], [9, 34]]], 0, 0]], [], 0, null, ["loc", [null, [9, 2], [35, 9]]]], ["block", "if", [["get", "driver", ["loc", [null, [37, 8], [37, 14]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [37, 2], [47, 9]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 48,
              "column": 0
            },
            "end": {
              "line": 54,
              "column": 0
            }
          },
          "moduleName": "ui/hosts/new/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "well");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "host-settings", [], ["saved", "savedHost"], ["loc", [null, [51, 6], [51, 41]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 0
          }
        },
        "moduleName": "ui/hosts/new/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element5 = dom.childAt(fragment, [0, 1]);
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(element5, 1, 1);
        morphs[1] = dom.createMorphAt(element5, 3, 3);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "link-to", ["infrastructure-tab"], [], 0, null, ["loc", [null, [3, 4], [3, 84]]]], ["inline", "t", ["hostsPage.new.header.text"], [], ["loc", [null, [4, 4], [4, 37]]], 0, 0], ["block", "if", [["get", "model.apiHostSet", ["loc", [null, [8, 6], [8, 22]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [8, 0], [54, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});