define('ui/components/login-shibboleth/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    redirectUrl: null,
    shibbolethAuth: _ember['default'].inject.service(),
    outRoute: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('outRoute', window.location.origin);
    },
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.sendAction('action');
        _ember['default'].run.later(function () {
          _this.authShibboleth();
        }, 10);
      }
    },
    authShibboleth: function authShibboleth() {
      var _this2 = this;

      this.get('shibbolethAuth').getToken().then(function (token) {
        var shibbolethAuth = _this2.get('shibbolethAuth');
        shibbolethAuth.authorizeRedirect(shibbolethAuth.buildRedirectUrl(token.redirectUrl));
      });
    }
  });
});