define('ui/services/resource-actions', ['exports', 'ember', 'ui/utils/bootstrap-fixes'], function (exports, _ember, _uiUtilsBootstrapFixes) {
  exports['default'] = _ember['default'].Service.extend({
    model: null,
    open: false,
    tooltipActions: null,
    actionToggle: null,
    actionMenu: null,

    show: function show(model, trigger, toggle) {
      var _this = this;

      if (this.get('open') && this.get('actionMenu')) {
        this.hide();
      }
      var $parent = this.set('actionParent', $('#resource-actions-parent'));
      var $menu = this.set('actionMenu', $('#resource-actions'));
      var $toggle = this.set('actionToggle', $(toggle || trigger));

      if (model === this.get('model') && this.get('open')) {
        event.preventDefault();
        return;
      }

      this.set('model', model);

      $('BODY').one('click', function () {
        // check to see if we've opened the menu
        // we can get into a state (via accessability navigation) where the
        // menu has been closed but the body click was never fired
        // this will just prevent an null error
        if (_this.get('actionMenu') && _this.get('actionToggle')) {
          _this.hide();
        }
      });

      _ember['default'].run.next(function () {

        if (_this.get('tooltipActions')) {
          $menu.addClass('tooltip-actions');
        } else {
          if ($menu.hasClass('tooltip-actions')) {
            $menu.removeClass('tooltip-actions');
          }
        }

        $menu.css('visibility', 'hidden');
        $menu.removeClass('hide');
        $toggle.addClass('open');
        $parent.addClass('open');

        _this.set('open', true);
        // Delay ensure it works in firefox
        _ember['default'].run.next(function () {
          _uiUtilsBootstrapFixes['default'].positionDropdown($menu, trigger, true);
          $('#resource-actions-first')[0].focus();
          $menu.css('visibility', 'visible');
        });
      });
    },

    hide: function hide() {
      this.get('actionToggle').removeClass('open');
      this.get('actionParent').removeClass('hide');
      this.get('actionMenu').addClass('hide');
      this.setProperties({
        actionToggle: null,
        actionMenu: null,
        open: false,
        model: null
      });
    },

    triggerAction: function triggerAction(actionName) {
      this.get('model').send(actionName);
    },

    activeActions: (function () {
      var list = (this.get('model.availableActions') || []).filter(function (act) {
        return _ember['default'].get(act, 'enabled') !== false || _ember['default'].get(act, 'divider');
      });

      // Remove dividers at the beginning
      while (list.get('firstObject.divider') === true) {
        list.shiftObject();
      }

      // Remove dividers at the end
      while (list.get('lastObject.divider') === true) {
        list.popObject();
      }

      // Remove consecutive dividers
      var last = null;
      list = list.filter(function (act) {
        var cur = act.divider === true;
        var ok = !cur || cur && !last;
        last = cur;
        return ok;
      });

      return list;
    }).property('model.availableActions.[]', 'model.availableActions.@each.enabled', 'model')
  });
});