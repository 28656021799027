define('ui/admin-tab/processes/list/route', ['exports', 'ember', 'ui/mixins/polled-model'], function (exports, _ember, _uiMixinsPolledModel) {
  exports['default'] = _ember['default'].Route.extend(_uiMixinsPolledModel['default'], {
    queryParams: {
      which: {
        refreshModel: true
      }
    },

    model: function model(params) {
      var filters = {};

      var now = moment().utc().format('YYYY-MM-DDTHH:mm:ss') + 'Z';
      if (params.which === 'running') {
        filters['runningProcessServerId_notnull'] = 'true';
        filters['endTime_null'] = 'true';
      } else if (params.which === 'delayed') {
        filters['runAfter_gt'] = now;
        filters['endTime_null'] = 'true';
        filters['runningProcessServiceId_null'] = 'true';
      } else if (params.which === 'ready') {
        filters['runAfter_lte'] = now;
        filters['endTime_null'] = 'true';
      } else if (params.which === 'completed') {
        filters['endTime_notnull'] = 'true';
      }

      return this.get('userStore').find('processinstance', null, {
        sortBy: 'id',
        sortOrder: 'desc',
        limit: 1000,
        depaginate: false,
        filter: filters,
        forceReload: true
      }).then(function (response) {
        return _ember['default'].Object.create({
          processInstances: response
        });
      });
    }
  });
});