define('ui/admin-tab/ha/route', ['exports', 'ember'], function (exports, _ember) {

  var INTERVAL = 2000;

  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.get('userStore').find('clustermembership');
    },

    activate: function activate() {
      this.scheduleTimer();
    },

    deactivate: function deactivate() {
      _ember['default'].run.cancel(this.get('timer'));
      this.set('timer', null); // This prevents scheduleTimer from rescheduling if deactivate happened at just the wrong time.
    },

    timer: null,
    scheduleTimer: function scheduleTimer() {
      var _this = this;

      _ember['default'].run.cancel(this.get('timer'));
      this.set('timer', _ember['default'].run.later(function () {
        _this.get('userStore').find('clustermembership', null, { forceReload: true }).then(function (response) {
          _this.controller.set('model', response);
          if (_this.get('timer')) {
            _this.scheduleTimer();
          }
        });
      }, INTERVAL));
    }
  });
});