define('ui/models/lbconfig', ['exports', 'ember-api-store/models/resource', 'ember-api-store/utils/denormalize'], function (exports, _emberApiStoreModelsResource, _emberApiStoreUtilsDenormalize) {
  exports['default'] = _emberApiStoreModelsResource['default'].extend({
    type: 'lbConfig',

    defaultCertificate: (0, _emberApiStoreUtilsDenormalize.denormalizeId)('defaultCertificateId', 'certificate'),
    certificates: (0, _emberApiStoreUtilsDenormalize.denormalizeIdArray)('certificateIds'),

    needsCertificate: (function () {
      return !!this.get('portRules').findBy('needsCertificate', true);
    }).property('portRules.@each.needsCertificate'),

    canSticky: (function () {
      return !!this.get('portRules').findBy('canSticky', true);
    }).property('portRules.@each.canSticky')
  });
});