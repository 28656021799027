define('ui/components/machine/driver-ubiquity/component', ['exports', 'ember', 'ui/mixins/driver', 'ember-api-store/utils/ajax-promise'], function (exports, _ember, _uiMixinsDriver, _emberApiStoreUtilsAjaxPromise) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsDriver['default'], {
    driverName: 'ubiquity',
    ubiquityConfig: _ember['default'].computed.alias('model.ubiquityConfig'),
    ubiquityHostingApi: 'api.ubiquityhosting.com/v25/api.php',

    allZones: null,
    allImages: null,
    allFlavors: null,

    step: 1,
    isStep1: _ember['default'].computed.equal('step', 1),
    isStep2: _ember['default'].computed.equal('step', 2),
    isGteStep3: _ember['default'].computed.gte('step', 3),

    bootstrap: function bootstrap() {
      var store = this.get('store');

      var config = store.createRecord({
        type: 'ubiquityConfig',
        apiToken: '',
        apiUsername: '',
        clientId: '',
        flavorId: '',
        imageId: '',
        zoneId: ''
      });

      this.set('model', store.createRecord({
        type: 'host',
        ubiquityConfig: config
      }));
    },

    willDestroyElement: function willDestroyElement() {
      this.set('errors', null);
      this.set('step', 1);
    },

    actions: {
      /* Login step */
      ubiLogin: function ubiLogin() {
        var _this = this;

        this.set('errors', null);
        this.set('step', 2);
        this.set('ubiquityConfig.clientId', (this.get('ubiquityConfig.clientId') || '').trim());
        this.set('ubiquityConfig.apiUsername', (this.get('ubiquityConfig.apiUsername') || '').trim());
        this.set('ubiquityConfig.apiToken', (this.get('ubiquityConfig.apiToken') || '').trim());

        _ember['default'].RSVP.hash({
          zones: this.getZones(),
          flavors: this.getFlavors()
        }).then(function (hash) {
          _this.set('allZones', hash.zones);
          _this.set('allFlavors', hash.flavors);

          if (!_this.get('ubiquityConfig.zoneId')) {
            _this.set('ubiquityConfig.zoneId', _this.get('allZones.firstObject.id'));
          }

          if (!_this.get('ubiquityConfig.flavorId')) {
            _this.set('ubiquityConfig.flavorId', _this.get('allFlavors.firstObject.id'));
          }

          return _this.zoneChange(_this.get('ubiquityConfig.zoneId')).then(function () {
            _this.set('step', 3);
          });
        })['catch'](function (err) {
          _this.set('errors', [err]);
          _this.set('step', 1);
        });
      },

      setZone: function setZone(zoneId) {
        this.zoneChange(zoneId);
      }
    },

    zoneChange: function zoneChange(zoneId) {
      var _this2 = this;

      this.set('ubiquityConfig.zoneId', zoneId);
      return this.getImages(zoneId).then(function (images) {
        _this2.set('allImages', images);
        var existing = _this2.get('ubiquityConfig.imageId');
        if (!existing || images.filterBy('id', existing).length === 0) {
          _this2.set('ubiquityConfig.imageId', images[0].id);
        }
      });
    },

    getZones: function getZones() {
      return this.apiRequest('list_zones').then(function (res) {
        return (res.Zones || []).map(function (zone) {
          return {
            id: zone.id,
            name: zone.name
          };
        });
      });
    },

    getImages: function getImages(zone_id) {
      return this.apiRequest('list_images', { zone_id: zone_id, docker_machine: 'true' }).then(function (res) {
        return (res.Images || []).map(function (image) {
          return {
            id: image.id,
            name: image.name,
            description: image.cat_desc
          };
        });
      });
    },

    getFlavors: function getFlavors() {
      return this.apiRequest('list_flavors').then(function (res) {
        return (res.Flavors || []).map(function (flavor) {
          return {
            id: flavor.id,
            name: flavor.name
          };
        });
      });
    },

    apiRequest: function apiRequest(command, params) {
      var url = this.get('app.proxyEndpoint') + '/' + this.ubiquityHostingApi + "?method=cloud." + encodeURIComponent(command);

      var auth = this.get('ubiquityConfig.clientId') + ':' + this.get('ubiquityConfig.apiUsername') + ':' + this.get('ubiquityConfig.apiToken');
      params = params || {};

      return (0, _emberApiStoreUtilsAjaxPromise.ajaxPromise)({
        url: url,
        method: 'POST',
        dataType: 'json',

        headers: {
          'Accept': 'application/json',
          'X-Api-Headers-Restrict': 'Content-Length',
          'X-Api-Auth-Header': 'Basic ' + window.btoa(auth)
        },

        beforeSend: function beforeSend(xhr, settings) {
          xhr.setRequestHeader('Content-Type', 'rancher:' + settings.contentType);
          return true;
        },

        data: params,
        params: params
      }, true).then(function (res) {
        if ((res || '') === '') {
          return _ember['default'].RSVP.reject('Authentication Failed: Please check the access credentials and that the server is in the list of authorized IP addresses in the Ubiquity console');
        } else {
          return res;
        }
      });
    },

    validate: function validate() {
      this._super();
      var errors = this.get('errors') || [];
      var name = this.get('model.hostname') || '';

      if (name.length < 1 || name.length > 200) {
        errors.push('"name" should be 1-200 characters long');
      }

      if (name.match(/[^a-z0-9-]/i)) {
        errors.push('"name" can only contain letters, numbers, and hyphen');
      }

      if (errors.get('length')) {
        this.set('errors', errors);
        return false;
      }

      return true;
    }
  });
});