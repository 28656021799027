define('ui/components/modal-edit-snapshot/component', ['exports', 'ember', 'lacsso/components/modal-base'], function (exports, _ember, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    editing: false,
    clone: null,
    errors: null,
    model: null,
    snapshotName: null,

    actions: {
      saveSnapshot: function saveSnapshot() {
        var _this = this;

        var opts = {
          name: this.get('snapshotName')
        };
        this.get('model').doAction('snapshot', opts).then(function () {
          _this.send('cancel');
        });
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('clone', this.get('originalModel').clone());
      this.set('model', this.get('originalModel').clone());
      this.set('snapshotName', this.get('originalModel.name') + '-' + Math.round(new Date().getTime() / 1000.0));
    },

    didRender: function didRender() {
      var _this2 = this;

      setTimeout(function () {
        _this2.$('INPUT')[0].focus();
      }, 500);
    }

  });
});