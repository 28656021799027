define('ui/components/tooltip-basic/component', ['exports', 'ember', 'ui/mixins/tooltip'], function (exports, _ember, _uiMixinsTooltip) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsTooltip['default'], {
    needs: ['application'],
    model: _ember['default'].computed.alias('tooltipService.tooltipOpts.model'),
    display: null,

    selectPartial: (function () {
      var template = this.get('tooltipService.tooltipOpts.template');
      var out = template;

      if (!template) {
        out = 'tooltip-basic';
      }

      return out;
    }).property('tooltipService.tooltipOpts.template')

  });
});