define('ui/mixins/hover-dropdowns', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {

  var DROPDOWNCLOSETIMER = 250;
  var SELECTOR = '.navbar .dropdown';
  var WINDOW_SM = 694;
  var timerObj = null;
  var dropdown = null;

  exports['default'] = _ember['default'].Mixin.create({
    didInsertElement: function didInsertElement() {
      var _this = this;

      var $body = _ember['default'].$('BODY');

      if ($body.hasClass('touch') && _ember['default'].$(window).width() <= WINDOW_SM) {

        // below iphone 6plus vertical width no need for dropdown logic
        this.$().on('click', function () {
          if (dropdown) {
            _ember['default'].run.later(function () {
              _this.clearHeaderMenus();
            }, DROPDOWNCLOSETIMER);
          }
        });

        this.$('#environment-dropdown').on('touchstart', function (e) {
          e.preventDefault();
          e.stopPropagation();
          _this.touchHandler(e);
        });

        this.$('ul[data-dropdown-id="enviroment"] > li > a').on('touchend', function () {
          if (dropdown) {
            _ember['default'].run.later(function () {
              _this.clearHeaderMenus();
            }, DROPDOWNCLOSETIMER);
          }
        });
      } else {

        // ipad/tablet width
        $body.on('touchend', function (e) {
          var $el = $(e.target);
          if ($el.closest('.navbar').length < 1) {
            _ember['default'].run.later(function () {
              _this.clearHeaderMenus();
            });
          }
        });

        this.$().on('touchstart', SELECTOR, function (e) {
          var $el = $(e.currentTarget).find('a.dropdown-toggle');

          if ($el.attr('aria-expanded') === 'false') {
            e.preventDefault();
            e.stopPropagation();
            _this.enterHandler(e);
          }
        });

        // desktop width
        this.$().on('click', SELECTOR, function (e) {
          _this.onClickHandler(e, false);
        });

        this.$().on('mouseenter', SELECTOR, function (e) {
          _this.enterHandler(e, false);
        });

        this.$().on('mouseleave', SELECTOR, function () {
          _this.leaveHandler();
        });

        this.$().on('keydown', SELECTOR + ' a', function (e) {
          _this.keydownHandler(e);
        });
      }
    },

    touchHandler: function touchHandler(e) {
      var anchor = _ember['default'].$(e.currentTarget);

      _ember['default'].run.cancel(timerObj);

      timerObj = null;
      if (dropdown) {
        // dropdown open alread

        if (dropdown.data('dropdown-id') !== _ember['default'].$(e.currentTarget).find('ul').data('dropdown-id')) {
          // not the same dropdown

          this.clearHeaderMenus();

          dropdown = _ember['default'].$(e.currentTarget).siblings('ul');

          if (dropdown) {
            this.showMenu(anchor, dropdown);
          }
        }
      } else {
        // no dropdown open

        dropdown = _ember['default'].$(e.currentTarget).siblings('ul');

        if (dropdown) {
          this.showMenu(anchor, dropdown);
        }
      }
    },

    enterHandler: function enterHandler(e) {
      var anchor = _ember['default'].$(e.currentTarget).find('a:first');

      _ember['default'].run.cancel(timerObj);

      timerObj = null;

      if (dropdown) {
        // dropdown open alread

        if (dropdown.data('dropdown-id') !== _ember['default'].$(e.currentTarget).find('ul').data('dropdown-id')) {
          // not the same dropdown

          this.clearHeaderMenus();

          dropdown = _ember['default'].$(e.currentTarget).find('ul');

          if (dropdown) {
            this.showMenu(anchor, dropdown);
          }
        }
      } else {
        // no dropdown open

        dropdown = _ember['default'].$(e.currentTarget).find('ul');

        if (dropdown) {
          this.showMenu(anchor, dropdown);
        }
      }
    },

    leaveHandler: function leaveHandler() {
      var _this2 = this;

      timerObj = _ember['default'].run.later(function () {

        if (dropdown) {

          _this2.clearHeaderMenus();

          timerObj = null;
        }
      }, DROPDOWNCLOSETIMER);
    },

    onClickHandler: function onClickHandler(e) {
      var anchor = _ember['default'].$(e.target).closest('A');
      if (anchor.hasClass('dropdown-toggle') && anchor[0].href.match(/#$/)) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }

      timerObj = null;

      var collapsedNav = _ember['default'].$('#navbar');

      if (collapsedNav.hasClass('in')) {
        collapsedNav.collapse('toggle');
      }

      this.clearHeaderMenus();
    },

    keydownHandler: function keydownHandler(e) {
      var items = this.get('items');
      var currentIndex = 0;

      var element = _ember['default'].$(e.currentTarget);
      var dropdownMenu = element.siblings('ul').length ? element.siblings('ul') : element.parent().parent('ul'); // if we're not in the top link we're in the ul links

      if (dropdownMenu) {
        items = dropdownMenu.find('li > a');
        currentIndex = items.index(e.currentTarget);

        if (currentIndex < 0) {
          currentIndex = 0;
        }
      }

      switch (e.which) {
        case _uiUtilsConstants['default'].KEY.ESCAPE:
          this.clearHeaderMenus();
          element.focus();
          break;
        case _uiUtilsConstants['default'].KEY.SPACE:
          this.clearHeaderMenus();
          this.showMenu(element, dropdownMenu);
          break;
        case _uiUtilsConstants['default'].KEY.UP:
          if (currentIndex > 0) {
            currentIndex--;
          }
          items.eq(currentIndex).focus();
          break;
        case _uiUtilsConstants['default'].KEY.DOWN:
          var $currentTarget = $(e.currentTarget);
          if (dropdownMenu && !$currentTarget.parent().parent('ul.dropdown-menu').length) {
            this.clearHeaderMenus();
            this.showMenu(element, dropdownMenu);
            dropdownMenu.addClass('block');
            if (element.attr('aria-expanded') === false) {
              element.attr('aria-expanded', true);
            }
          } else {
            if (currentIndex < items.length - 1) {
              currentIndex++;
            }
          }
          items.eq(currentIndex).focus();
          break;
        default:
          break;
      }
    },

    showMenu: function showMenu(el, drpd) {
      var body = _ember['default'].$('BODY');
      if (body.hasClass('touch')) {
        _ember['default'].$('BODY').addClass('nav-dropdown-open');
      }
      drpd.addClass('block');
      if (el.attr('aria-expanded')) {
        el.attr('aria-expanded', true);
      }
    },

    clearHeaderMenus: function clearHeaderMenus() {
      var body = _ember['default'].$('BODY');
      if (body.hasClass('touch')) {
        _ember['default'].$('BODY').removeClass('nav-dropdown-open');
      }
      var navbar = _ember['default'].$('.navbar');

      dropdown = null;

      navbar.find('.dropdown-menu.block').removeClass('block');
      navbar.find('a.dropdown-toggle[aria-expanded=true]').attr('aria-expanded', false);
    }
  });
});