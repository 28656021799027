define('ui/components/modal-process-error/component', ['exports', 'ember', 'lacsso/components/modal-base'], function (exports, _ember, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    exception: _ember['default'].computed.alias('modalService.modalOpts'),
    actions: {
      dismiss: function dismiss() {
        this.send('cancel');
      }
    }
  });
});