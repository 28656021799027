define('ui/services/github', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/util'], function (exports, _ember, _uiUtilsConstants, _uiUtilsUtil) {
  exports['default'] = _ember['default'].Service.extend({
    access: _ember['default'].inject.service(),
    cookies: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    userStore: _ember['default'].inject.service(),

    // Set by app/services/access
    hostname: null,
    scheme: null,
    clientId: null,

    generateState: function generateState() {
      var state = Math.random() + '';
      this.get('session').set('githubState', state);
      return state;
    },

    getToken: function getToken() {
      var _this = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        _this.get('userStore').rawRequest({
          url: 'token'
        }).then(function (xhr) {
          resolve(xhr.body.data[0]);
          return;
        })['catch'](function (err) {
          reject(err);
        });
      });
    },

    stateMatches: function stateMatches(actual) {
      var expected = this.get('session.githubState');
      return actual && expected === actual;
    },

    getAuthorizeUrl: function getAuthorizeUrl(test) {
      var redirect = this.get('session').get(_uiUtilsConstants['default'].SESSION.BACK_TO) || window.location.href;

      if (test) {
        redirect = _uiUtilsUtil['default'].addQueryParam(redirect, 'isTest', 1);
      }

      var url = _uiUtilsUtil['default'].addQueryParams(this.get('access.token.redirectUrl'), {
        state: this.generateState(),
        redirect_uri: redirect
      });

      return url;
    },

    authorizeRedirect: function authorizeRedirect() {
      window.location.href = this.getAuthorizeUrl();
    },

    authorizeTest: function authorizeTest(cb) {
      var responded = false;
      window.onGithubTest = function (err, code) {
        if (!responded) {
          responded = true;
          cb(err, code);
        }
      };

      var popup = window.open(this.getAuthorizeUrl(true), 'rancherAuth', _uiUtilsUtil['default'].popupWindowOptions());
      var timer = setInterval(function () {
        if (!popup || popup.closed) {
          clearInterval(timer);
          if (!responded) {
            responded = true;
            cb({ type: 'error', message: 'Github access was not authorized' });
          }
        }
      }, 500);
    }
  });
});