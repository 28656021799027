define('ui/components/form-virtualmachine/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    launchConfig: null,

    classNames: ['r-pt10'],

    init: function init() {
      this._super.apply(this, arguments);

      if (!this.get('launchConfig.memoryMb')) {
        this.set('launchConfig.memoryMb', 512);
      }
    }
  });
});