define('ui/service/port-rules/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var service = this.modelFor('service').get('service');
      if (service.get('type').toLowerCase() !== 'loadbalancerservice') {
        this.transitionTo('service.ports');
        return;
      }

      service.initPorts();
      return service;
    }
  });
});