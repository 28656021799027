define('ui/catalog-tab/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    access: _ember['default'].inject.service(),
    catalog: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),

    queryParams: {
      category: { refreshModel: true },
      catalogId: { refreshModel: true },
      templateBase: { refreshModel: true }
    },

    actions: {
      refresh: function refresh() {
        // Clear the cache so it has to ask the server again
        this.set('cache', null);
        this.refresh();
      }
    },

    deactivate: function deactivate() {
      // Clear the cache when leaving the route so that it will be reloaded when you come back.
      this.set('cache', null);
    },

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      return this.get('projects').updateOrchestrationState();
    },

    model: function model(params) {
      params.plusInfra = this.get('access').isOwner();
      var stacks = this.get('store').all('stack');
      var catalogSvc = this.get('catalog');
      return catalogSvc.fetchTemplates(params).then(function (res) {
        res.catalog.forEach(function (tpl) {
          var exists = stacks.findBy('externalIdInfo.templateId', tpl.get('id'));
          tpl.set('exists', !!exists);
        });
        res.catalogs = catalogSvc.get('catalogs').slice();
        return res;
      });
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('category', 'all');
        controller.set('catalogId', 'all');
        controller.set('templateBase', '');
      }
    }
  });
});