define('ui/login/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    access: _ember['default'].inject.service(),
    language: _ember['default'].inject.service('user-language'),

    beforeModel: function beforeModel(transition) {
      var _this = this;

      this._super.apply(this, arguments);
      return this.get('language').initUnauthed().then(function () {
        if (!_this.get('access.enabled') && !transition.queryParams.shibbolethTest) {
          _this.transitionTo('authenticated');
        }
      });
    }
  });
});