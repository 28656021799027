define('ui/helpers/default-str', ['exports', 'ember'], function (exports, _ember) {
  exports.defaultStr = defaultStr;

  function defaultStr(params, options) {
    var out = null;
    var i = 0;
    while (!out && i < params.length) {
      out = params[i];
      i++;
    }

    if (!out && options && options['default']) {
      out = options['default'];
    }

    return out;
  }

  exports['default'] = _ember['default'].Helper.helper(defaultStr);
});