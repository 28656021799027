define('ui/containers/index/controller', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    bulkActionHandler: _ember['default'].inject.service(),
    bulkActionsList: _uiUtilsConstants['default'].BULK_ACTIONS,
    sortBy: 'name',
    prefs: _ember['default'].inject.service(),

    queryParams: ['sortBy'],

    showSystem: _ember['default'].computed('prefs.' + _uiUtilsConstants['default'].PREFS.SHOW_SYSTEM, {
      get: function get() {
        return this.get('prefs.' + _uiUtilsConstants['default'].PREFS.SHOW_SYSTEM) !== false;
      },

      set: function set(key, value) {
        this.set('prefs.' + _uiUtilsConstants['default'].PREFS.SHOW_SYSTEM, value);
        return value;
      }
    }),

    show: _ember['default'].computed('showSystem', function () {
      return this.get('showSystem') === false ? 'standard' : 'all';
    }),

    actions: {
      applyBulkAction: function applyBulkAction(name, selectedElements) {
        if (selectedElements.length === 1) {
          selectedElements.objectAt(0).send(name);
        } else {
          this.get('bulkActionHandler')[name](selectedElements);
        }
      }
    },

    headers: [{
      classNames: '',
      name: 'stateSort',
      searchField: 'displayState',
      sort: ['stateSort', 'name', 'id'],
      translationKey: 'containersPage.index.table.header.state',
      width: '125px'
    }, {
      name: 'name',
      sort: ['name', 'id'],
      translationKey: 'containersPage.index.table.header.name'
    }, {
      name: 'displayIp',
      sort: ['displayIp', 'name', 'id'],
      width: '110px',
      translationKey: 'containersPage.index.table.header.ip'
    }, {
      name: 'primaryHost.displayName',
      sort: ['primaryHost.displayName', 'name', 'id'],
      translationKey: 'containersPage.index.table.header.host'
    }, {
      name: 'imageUuid',
      sort: ['imageUuid', 'id'],
      translationKey: 'containersPage.index.table.header.image'
    }, {
      name: 'command',
      sort: ['command', 'name', 'id'],
      translationKey: 'containersPage.index.table.header.command'
    }, {
      isActions: true,
      width: '110px'
    }],

    // showChanged should be an observer rather then init to correctly set the showSystem checkbox
    // if showSystem is set on init show does not contain the correct qp as the router has not set it
    // so the checkbox never gets set
    showChanged: (function () {
      this.set('showSystem', this.get('show') === 'all');
    }).observes('show'),

    showSystemChanged: (function () {
      this.set('show', this.get('showSystem') ? 'all' : 'standard');
    }).observes('showSystem'),

    filtered: (function () {
      var all = this.get('model');
      if (this.get('showSystem')) {
        return all;
      } else {
        return all.filterBy('isSystem', false);
      }
    }).property('model.@each.system', 'showSystem')

  });
});