define('ui/services/cookies', ['exports', 'ember'], function (exports, _ember) {

  function parseValue(value) {
    if (value.charAt(0) === '"') {
      value = value.slice(1, -1).replace(/\\"/g, '"').replace(/\\\\/g, '\\').replace(/\+/g, ' ');
    }

    return decodeURIComponent(value);
  }

  function getAll() {
    var cookies = document.cookie.split(/;\s*/);
    var ret = {};

    cookies.forEach(function (cookie) {
      if (!cookie) {
        return;
      }

      var idx = cookie.indexOf('=');

      if (idx === -1) {
        idx = cookie.length;
      }

      var name = decodeURIComponent(cookie.substr(0, idx));
      var val = parseValue(cookie.substr(idx + 1));

      ret[name] = val;
    });

    return ret;
  }

  exports['default'] = _ember['default'].Service.extend({
    unknownProperty: function unknownProperty(key) {
      var all = getAll();
      return all[key] || null;
    },

    setUnknownProperty: function setUnknownProperty(key, value) {
      this.setWithOptions(key, value);
    },

    // Opt: expire: date or number of days, path, domain, secure
    setWithOptions: function setWithOptions(name, value, opt) {
      opt = opt || {};
      opt.path = typeof opt.path === 'undefined' ? '/' : opt.path;
      opt.secure = typeof opt.path === 'undefined' ? false : !!opt.secure;

      var str = encodeURIComponent(name) + '=' + encodeURIComponent(value);

      if (opt.expire) {
        var date = undefined;
        if (typeof opt.expire === 'object') {
          date = opt.expire;
        } else {
          date = new Date(new Date().getTime() + 86400000 * opt.expire);
        }

        str += ';expires=' + date.toGMTString();
      }

      if (opt.path) {
        str += ';path=' + opt.path;
      }

      if (opt.domain) {
        str += ';domain=' + opt.domain;
      }

      if (opt.secure) {
        str += ';secure';
      }

      try {
        document.cookie = str;
      } catch (e) {
        return false;
      }

      this.notifyPropertyChange(name);
      return true;
    },

    remove: function remove(name, opt) {
      opt = opt || {};
      opt.expire = new Date('Wed, 24 Feb 1982 18:42:00 UTC');
      return this.setWithOptions(name, 'removed', opt);
    }
  });
});