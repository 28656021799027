define('ui/authenticated/project/api/keys/controller', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/util', 'ui/mixins/sortable'], function (exports, _ember, _uiUtilsConstants, _uiUtilsUtil, _uiMixinsSortable) {
  function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) arr2[i] = arr[i]; return arr2; } else { return Array.from(arr); } }

  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], {
    access: _ember['default'].inject.service(),
    'tab-session': _ember['default'].inject.service(),

    sortBy: 'name',
    sorts: {
      state: ['stateSort', 'name', 'id'],
      name: ['name', 'id'],
      description: ['description', 'name', 'id'],
      publicValue: ['publicValue', 'id'],
      created: ['created', 'name', 'id']
    },

    application: _ember['default'].inject.controller(),
    cookies: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    growl: _ember['default'].inject.service(),
    project: _ember['default'].computed.alias('projects.current'),
    endpointService: _ember['default'].inject.service('endpoint'),
    modalService: _ember['default'].inject.service('modal'),

    accountArranged: (function () {
      var _get$filter;

      var me = this.get('session.' + _uiUtilsConstants['default'].SESSION.ACCOUNT_ID);
      var sort = this.get('sorts')[this.get('sortBy')];

      var out = (_get$filter = this.get('model.account').filter(function (row) {
        return row.get('accountId') === me;
      })).sortBy.apply(_get$filter, _toConsumableArray(sort));

      if (this.get('descending')) {
        out = out.reverse();
      }

      return out;
    }).property('model.account.@each.{accountId,name,createdTs}', 'sortBy', 'descending'),

    environmentArranged: (function () {
      var _get$filter2;

      var project = this.get('project.id');
      var sort = this.get('sorts')[this.get('sortBy')];

      var out = (_get$filter2 = this.get('model.environment').filter(function (row) {
        return row.get('accountId') === project;
      })).sortBy.apply(_get$filter2, _toConsumableArray(sort));

      if (this.get('descending')) {
        out = out.reverse();
      }

      return out;
    }).property('model.environment.@each.{accountId,name,createdTs}', 'sortBy', 'descending'),

    actions: {
      newApikey: function newApikey(kind) {
        var cred;
        if (kind === 'account') {
          cred = this.get('userStore').createRecord({
            type: 'apikey',
            accountId: this.get('session.' + _uiUtilsConstants['default'].SESSION.ACCOUNT_ID)
          });
        } else {
          cred = this.get('store').createRecord({
            type: 'apikey',
            accountId: this.get('projects.current.id')
          });
        }

        this.get('modalService').toggleModal('edit-apikey', cred);
      }
    },

    endpoint: (function () {
      // Strip trailing slash off of the absoluteEndpoint
      var base = this.get('endpointService.absolute').replace(/\/+$/, '');
      // Add a single slash
      base += '/';

      var current = this.get('app.apiEndpoint').replace(/^\/+/, '');
      var legacy = this.get('app.legacyApiEndpoint').replace(/^\/+/, '');

      // Go to the project-specific version
      var projectId = this.get('tab-session').get(_uiUtilsConstants['default'].TABSESSION.PROJECT);
      var project = '';
      if (projectId) {
        project = '/projects/' + projectId;
      }

      // For local development where API doesn't match origin, add basic auth token
      var authBase = base;
      if (base.indexOf(window.location.origin) !== 0) {
        var token = this.get('cookies').get(_uiUtilsConstants['default'].COOKIE.TOKEN);
        if (token) {
          authBase = _uiUtilsUtil['default'].addAuthorization(base, _uiUtilsConstants['default'].USER.BASIC_BEARER, token);
        }
      }

      return {
        auth: {
          account: {
            current: authBase + current,
            legacy: authBase + legacy
          },
          environment: {
            current: authBase + current + project,
            legacy: authBase + legacy + project
          }
        },
        display: {
          account: {
            current: base + current,
            legacy: base + legacy
          },
          environment: {
            current: base + current + project,
            legacy: base + legacy + project
          }
        }
      };
    }).property('endpointService.absolute', 'app.{apiEndpoint,legacyApiEndpoint}', 'tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT)
  });
});