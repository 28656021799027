define('ui/authenticated/project/api/hooks/new-receiver/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['receiverId'],
    receiverId: null,

    actions: {
      cancel: function cancel() {
        this.send('goToPrevious');
      }
    }

  });
});