define('ui/components/project-list/component', ['exports', 'ember', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsSortable['default'], {
    projects: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    sortBy: 'name',
    sorts: {
      state: ['stateSort', 'name', 'id'],
      name: ['name', 'id'],
      description: ['description', 'name', 'id'],
      projectTemplate: ['projectTemplate.name', 'name', 'id'],
      orchestration: ['displayOrchestration', 'name', 'id']
    }
  });
});