define('ui/instance-initializers/auth-store', ['exports', 'ui/mixins/store-tweaks'], function (exports, _uiMixinsStoreTweaks) {
  exports.initialize = initialize;

  function initialize(instance) {
    var application = instance.lookup('application:main');
    var store = instance.lookup('service:auth-store');

    store.reopen(_uiMixinsStoreTweaks['default']);
    store.reopen({
      removeAfterDelete: false,
      baseUrl: application.authEndpoint,
      skipTypeifyKeys: ['labels']
    });
  }

  exports['default'] = {
    name: 'auth-store',
    initialize: initialize
  };
});