define('ui/stack/code/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() /*params, transition*/{
      var par = this.modelFor('stack');
      var stack = par.get('stack');
      return stack.doAction('exportconfig').then(function (config) {
        // Windows needs CRLFs
        var dockerCompose = config.dockerComposeConfig.split(/\r?\n/).join('\r\n');
        var rancherCompose = config.rancherComposeConfig.split(/\r?\n/).join('\r\n');

        return _ember['default'].Object.create({
          stack: stack,
          all: par.get('all'),
          dockerCompose: dockerCompose,
          rancherCompose: rancherCompose
        });
      });
    }
  });
});