define('ui/components/page-header-user/component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',

    access: _ember['default'].inject.service(),
    modalService: _ember['default'].inject.service('modal'),

    accessEnabled: _ember['default'].computed.alias('access.enabled'),

    isLocalAuth: (function () {
      return this.get('access.enabled') && this.get('access.provider') === 'localauthconfig';
    }).property('access.{enabled,provider}'),

    actions: {
      changePassword: function changePassword() {
        var _this = this;

        var us = this.get('userStore');

        us.findAll('password').then(function () {
          us.find('account', _this.get('session.' + _uiUtilsConstants['default'].SESSION.ACCOUNT_ID)).then(function (account) {
            _this.get('modalService').toggleModal('edit-account', account);
          });
        });
      }
    }
  });
});