define('ui/initializers/extend-resource', ['exports', 'ember-api-store/models/resource', 'ui/mixins/cattle-transitioning-resource'], function (exports, _emberApiStoreModelsResource, _uiMixinsCattleTransitioningResource) {
  exports.initialize = initialize;

  function initialize() /*application */{
    _emberApiStoreModelsResource['default'].reopen(_uiMixinsCattleTransitioningResource['default']);
    _emberApiStoreModelsResource['default'].reopenClass({
      defaultStateIcon: 'icon icon-help',
      defaultStateColor: 'text-primary',
      defaultSortBy: 'name'
    });
  }

  exports['default'] = {
    name: 'extend-resource',
    initialize: initialize
  };
});