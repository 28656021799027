define('ui/service/new-balancer/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['stackId', 'serviceId', 'tab', 'upgrade', 'upgradeImage'],
    stackId: null,
    serviceId: null,
    tab: 'ssl',
    upgrade: null,
    upgradeImage: 'false',

    actions: {
      done: function done() {
        return this.transitionToRoute('stack', this.get('model.service.stackId'));
      },

      cancel: function cancel() {
        this.send('goToPrevious');
      }
    }
  });
});