define("ui/host/containers/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 13,
              "column": 4
            },
            "end": {
              "line": 24,
              "column": 4
            }
          },
          "moduleName": "ui/host/containers/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "container-row", [], ["tagName", "tr", "model", ["subexpr", "@mut", [["get", "container", ["loc", [null, [16, 14], [16, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "showStats", true, "showActions", true, "showCommand", "inline", "cpuMax", ["subexpr", "@mut", [["get", "cpuMax", ["loc", [null, [20, 15], [20, 21]]], 0, 0, 0, 0]], [], [], 0, 0], "memoryMax", ["subexpr", "@mut", [["get", "memoryMax", ["loc", [null, [21, 18], [21, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "storageMax", ["subexpr", "@mut", [["get", "storageMax", ["loc", [null, [22, 19], [22, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "networkMax", ["subexpr", "@mut", [["get", "networkMax", ["loc", [null, [23, 19], [23, 29]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 6], [23, 31]]], 0, 0]],
        locals: ["container"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 24,
              "column": 4
            },
            "end": {
              "line": 26,
              "column": 4
            }
          },
          "moduleName": "ui/host/containers/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("tr");
          var el2 = dom.createElement("td");
          dom.setAttribute(el2, "colspan", "7");
          dom.setAttribute(el2, "class", "text-center text-muted");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["hostsPage.hostPage.containersTab.table.body.noData"], [], ["loc", [null, [25, 57], [25, 115]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "ui/host/containers/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("table");
        dom.setAttribute(el1, "class", "grid fixed container-table");
        dom.setAttribute(el1, "style", "margin-bottom: 0;");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("thead");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "stats");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "class", "actions");
        dom.setAttribute(el4, "width", "70");
        var el5 = dom.createTextNode(" ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("tbody");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [1, 1]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(element1, 1, 1);
        morphs[1] = dom.createMorphAt(element1, 3, 3);
        morphs[2] = dom.createMorphAt(element1, 5, 5);
        morphs[3] = dom.createMorphAt(element1, 7, 7);
        morphs[4] = dom.createMorphAt(dom.childAt(element1, [9]), 0, 0);
        morphs[5] = dom.createMorphAt(dom.childAt(element0, [3]), 1, 1);
        return morphs;
      },
      statements: [["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [4, 29], [4, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "state", "label", "hostsPage.hostPage.containersTab.table.header.state", "width", "125"], ["loc", [null, [4, 6], [4, 140]]], 0, 0], ["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [5, 29], [5, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "name", "label", "hostsPage.hostPage.containersTab.table.header.name", "width", "125"], ["loc", [null, [5, 6], [5, 138]]], 0, 0], ["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [6, 29], [6, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "ip", "width", "120", "label", "hostsPage.hostPage.containersTab.table.header.ip"], ["loc", [null, [6, 6], [6, 134]]], 0, 0], ["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [7, 29], [7, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "image", "label", "hostsPage.hostPage.containersTab.table.header.image", "width", "130"], ["loc", [null, [7, 6], [7, 140]]], 0, 0], ["inline", "t", ["hostsPage.hostPage.containersTab.table.header.stats"], [], ["loc", [null, [8, 24], [8, 83]]], 0, 0], ["block", "each", [["get", "arranged", ["loc", [null, [13, 12], [13, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [13, 4], [26, 13]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});