define('ui/components/theme-toggle/component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend({
    prefs: _ember['default'].inject.service(),
    userTheme: _ember['default'].inject.service('user-theme'),

    classNames: ['btn-group', 'btn-group-sm'],

    theme: _ember['default'].computed('prefs.' + _uiUtilsConstants['default'].PREFS.THEME, function () {
      return this.get('prefs.' + _uiUtilsConstants['default'].PREFS.THEME);
    }),

    actions: {
      changeTheme: function changeTheme(theme) {
        var userTheme = this.get('userTheme');
        var currentTheme = userTheme.getTheme();

        if (theme !== currentTheme) {
          userTheme.setTheme(theme);
        }
      }
    }

  });
});