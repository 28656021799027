define('ui/components/modal-edit-driver/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'lacsso/components/modal-base'], function (exports, _ember, _uiMixinsNewOrEdit, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewOrEdit['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    settings: _ember['default'].inject.service(),

    clone: null,
    primaryResource: _ember['default'].computed.alias('originalModel'),
    errors: null,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      this.set('clone', this.get('originalModel').clone());
      this.set('model', this.get('originalModel').clone());
      _ember['default'].run.scheduleOnce('afterRender', function () {
        _this.$('INPUT')[0].focus();
      });
    },

    editing: (function () {
      return !!this.get('clone.id');
    }).property('clone.id'),

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});