define('ui/admin-tab/accounts/new/controller', ['exports', 'ember', 'ui/mixins/new-or-edit', 'ui/utils/constants'], function (exports, _ember, _uiMixinsNewOrEdit, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsNewOrEdit['default'], {
    primaryResource: _ember['default'].computed.alias('model.account'),
    settings: _ember['default'].inject.service(),

    actions: {
      cancel: function cancel() {
        this.transitionToRoute('admin-tab.accounts');
      }
    },

    validateDescription: _ember['default'].computed(function () {
      return this.get('settings').get(_uiUtilsConstants['default'].SETTING.AUTH_LOCAL_VALIDATE_DESC) || null;
    }),

    accountTypeChoices: [{ label: 'model.account.kind.user', value: 'user' }, { label: 'model.account.kind.admin', value: 'admin' }],

    validate: function validate() {
      var errors = [];

      if ((this.get('model.credential.publicValue') || '').trim().length === 0) {
        errors.push('Login Username is requried');
      }

      if ((this.get('model.credential.secretValue') || '').trim().length === 0) {
        errors.push('Password is requried');
      }

      if (errors.length) {
        this.set('errors', errors.uniq());
        return false;
      } else {
        this.set('errors', null);
      }

      return true;
    },

    didSave: function didSave() {
      var account = this.get('model.account');
      var cred = this.get('model.credential');

      cred.set('accountId', account.get('id'));
      return cred.save().then(function () {
        return cred.waitForState('active');
      });
    },

    doneSaving: function doneSaving() {
      this.transitionToRoute('admin-tab.accounts');
    }
  });
});