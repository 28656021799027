define('ui/containers/new/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params /*, transition*/) {
      var store = this.get('store');

      var dependencies = {
        allHosts: store.findAll('host') };

      // Need inactive ones in case a link points to an inactive host
      if (params.containerId) {
        dependencies.existing = store.find('container', params.containerId, { include: ['ports', 'instanceLinks'] });
      }

      return _ember['default'].RSVP.hash(dependencies, 'Load container dependencies').then(function (results) {

        var data, healthCheckData;
        if (results.existing) {
          data = results.existing.serializeForNew();
          data.ports = (data.ports || []).map(function (port) {
            delete port.id;
            return port;
          });

          if (_ember['default'].isArray(data.instanceLinks)) {
            data.instanceLinks = (data.instanceLinks || []).map(function (link) {
              delete link.id;
              return link;
            });
          }

          if (!data.environment) {
            data.environment = {};
          }

          healthCheckData = data.healthCheck;
          delete data.healthCheck;
        } else {
          data = {
            type: 'container',
            requestedHostId: params.hostId,
            tty: true,
            stdinOpen: true
          };
        }

        var instance = store.createRecord(data);
        if (healthCheckData) {
          // The type isn't set on an existing one
          healthCheckData.type = 'instanceHealthCheck';
          instance.set('healthCheck', store.createRecord(healthCheckData));
        }

        return _ember['default'].Object.create({
          instance: instance,
          allHosts: results.allHosts
        });
      });
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('hostId', null);
        controller.set('stackId', null);
        controller.set('containerId', null);
      }
    }
  });
});