define('ui/helpers/run-time', ['exports', 'ember'], function (exports, _ember) {
  exports.runTime = runTime;

  function runTime(params) {
    var s = moment(params[0]);
    var e = moment(params[1]);
    var time = Math.round(e.diff(s) / 100) / 10;
    if (time) {
      if (time > 60) {
        time = Math.round(time);
      }

      return time + ' sec';
    } else {
      return '<span class="text-muted">-</span>'.htmlSafe();
    }
  }

  exports['default'] = _ember['default'].Helper.helper(runTime);
});