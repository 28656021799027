define("ui/components/svg-service-graph/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 15,
              "column": 0
            },
            "end": {
              "line": 17,
              "column": 0
            }
          },
          "moduleName": "ui/components/svg-service-graph/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "svg-service-container", [], ["node", ["subexpr", "@mut", [["get", "node", ["loc", [null, [16, 31], [16, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "openInfo"], ["loc", [null, [16, 2], [16, 55]]], 0, 0]],
        locals: ["node"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 0
            },
            "end": {
              "line": 20,
              "column": 0
            }
          },
          "moduleName": "ui/components/svg-service-graph/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "svg-edge", [], ["path", ["subexpr", "@mut", [["get", "path", ["loc", [null, [19, 18], [19, 22]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 2], [19, 24]]], 0, 0]],
        locals: ["path"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 21,
            "column": 0
          }
        },
        "moduleName": "ui/components/svg-service-graph/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("defs");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("marker");
        dom.setAttribute(el2, "id", "markerCircle");
        dom.setAttribute(el2, "markerWidth", "5");
        dom.setAttribute(el2, "markerHeight", "5");
        dom.setAttribute(el2, "refX", "2");
        dom.setAttribute(el2, "refY", "2");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("circle");
        dom.setAttribute(el3, "cx", "2");
        dom.setAttribute(el3, "cy", "2");
        dom.setAttribute(el3, "r", "2");
        dom.setAttribute(el3, "style", "stroke: none; fill:#9CB6B5;");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("marker");
        dom.setAttribute(el2, "id", "markerSquare");
        dom.setAttribute(el2, "markerWidth", "7");
        dom.setAttribute(el2, "markerHeight", "7");
        dom.setAttribute(el2, "refX", "1");
        dom.setAttribute(el2, "refY", "4");
        dom.setAttribute(el2, "orient", "auto");
        dom.setAttribute(el2, "markerUnits", "strokeWidth");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("rect");
        dom.setAttribute(el3, "x", "1");
        dom.setAttribute(el3, "y", "1");
        dom.setAttribute(el3, "width", "1");
        dom.setAttribute(el3, "height", "6");
        dom.setAttribute(el3, "style", "stroke: none; fill:#9CB6B5;");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("marker");
        dom.setAttribute(el2, "id", "markerArrow");
        dom.setAttribute(el2, "markerWidth", "6");
        dom.setAttribute(el2, "markerHeight", "6");
        dom.setAttribute(el2, "refX", "2");
        dom.setAttribute(el2, "refY", "4");
        dom.setAttribute(el2, "orient", "auto");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("path");
        dom.setAttribute(el3, "d", "M2,2 L2,6 L6,4.25 L2,2");
        dom.setAttribute(el3, "style", "fill: #9CB6B5;");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "each", [["get", "nodes", ["loc", [null, [15, 8], [15, 13]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [15, 0], [17, 9]]]], ["block", "each", [["get", "paths", ["loc", [null, [18, 8], [18, 13]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [18, 0], [20, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});