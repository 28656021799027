define('ui/admin-tab/auth/activedirectory/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    resourceType: 'ldapconfig',
    access: _ember['default'].inject.service(),

    model: function model() {
      var _this = this;

      return this.get('authStore').find('config', null, {
        forceReload: true
      }).then(function (collection) {

        if (!collection.enabled) {
          (function () {
            var existing = _this.get('authStore').createRecord(collection.ldapConfig, { type: 'ldapconfig' });
            var defaults = _this.get('authStore').getById('schema', _this.get('resourceType')).get('resourceFields');

            Object.keys(defaults).forEach(function (key) {
              var field = defaults[key];
              if (field && field['default'] && !existing.get(key)) {
                existing.set(key, field['default']);
              }
            });

            collection.ldapConfig = existing;
          })();
        } else {
          collection.identity = _this.get('access.identity');
        }
        return collection;
      });
    },

    setupController: function setupController(controller, model) {
      controller.setProperties({
        model: model,
        confirmDisable: false,
        testing: false,
        organizations: this.get('session.orgs') || [],
        errors: null
      });
    }
  });
});