define('ui/components/save-cancel/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    editing: null,
    createLabel: 'saveCancel.create',
    editLabel: 'saveCancel.edit',
    cancelLabel: 'saveCancel.cancel',
    saveDisabled: false,
    cancelDisabled: false,
    savedLabel: 'saveCancel.saved',

    classNames: ['footer-actions'],
    saving: false,
    saved: false,

    actions: {
      save: function save() {
        var _this = this;

        // The save action must take a callback and call it when done (whether it succeded or not)
        // to update the state of the button.
        //
        this.set('saving', true);
        this.set('saved', false);
        this.sendAction('save', function (success) {
          _this.set('saving', false);
          _this.set('saved', success === true);
        });
      },

      cancel: function cancel() {
        this.sendAction('cancel');
      }
    },

    btnLabel: (function () {
      if (this.get('saved')) {
        return this.get('savedLabel');
      } else if (this.get('editing')) {
        return this.get('editLabel');
      } else {
        return this.get('createLabel');
      }
    }).property('saved', 'editing', 'savedLabel', 'editLabel', 'createLabel'),

    savedChanged: (function () {
      var _this2 = this;

      if (this.get('saved')) {
        _ember['default'].run.later(this, function () {
          if (_this2.isDestroyed || _this2.isDestroying) {
            return;
          }

          _this2.set('saved', false);
        }, 5000);
      }
    }).observes('saved')
  });
});