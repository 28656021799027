define('ui/components/help-btn/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    settings: _ember['default'].inject.service(),

    title: 'Go to documentation',
    link: '',
    target: '_blank',

    tagName: 'A',
    attributeBindings: ['title', 'href', 'target'],
    classNames: ['small'],
    classNameBindings: ['settings.isRancher::hide'],

    href: (function () {
      return this.get('settings.docsBase') + this.get('link');
    }).property('link')

  });
});