define('ui/components/svg-service-container/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    resourceActions: _ember['default'].inject.service('resource-actions'),

    node: null,

    tagName: 'g',
    attributeBindings: ['transform'],

    width: 235,
    height: 80,

    actions: {
      showDropDown: function showDropDown() {
        this.get('resourceActions').show(this.get('node.service'), _ember['default'].$('#dropdown-toggler'), this.$());
      }
    },

    click: function click(e) {
      var target = e.target;
      if (_ember['default'].$(target).closest('action-menu').length === 0) {
        this.sendAction('action', this.get('node.service'));
      }

      e.preventDefault();
      e.stopPropagation();
    },

    transform: _ember['default'].computed('node.{x,y}', function () {
      return 'translate(' + this.get('node.x') + ',' + this.get('node.y') + ')';
    }),

    containerCount: _ember['default'].computed('node.service.instances', function () {
      if (this.get('node.service.instances')) {
        return this.get('node.service.instances').length;
      } else {
        return this.get('node.service.scale') ? this.get('node.service.scale') : 0;
      }
    }),

    stateBackground: (function () {
      return this.get('node.service.stateColor').replace("text-", "bg-");
    }).property('node.service.stateColor')

  });
});