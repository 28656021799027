define('ui/models/backuptarget', ['exports', 'ember-api-store/models/resource'], function (exports, _emberApiStoreModelsResource) {

  var BackupTarget = _emberApiStoreModelsResource['default'].extend({
    type: 'backupTarget',
    availableActions: (function () {
      return [{ label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: this.get('canDelete'), altAction: 'delete' }, { divider: true }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }];
    }).property('actionLinks.{restore,purge}', 'model.canDelete')
  });

  exports['default'] = BackupTarget;
});