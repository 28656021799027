define('ui/components/edit-service/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'lacsso/components/modal-base'], function (exports, _ember, _uiMixinsNewOrEdit, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewOrEdit['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    service: null,

    primaryResource: _ember['default'].computed.alias('service'),

    editing: true,
    isService: true,

    actions: {
      done: function done() {
        this.send('cancel');
      },
      setScale: function setScale(scale) {
        this.set('service.scale', scale);
      },
      setServiceLinks: function setServiceLinks(links) {
        this.set('serviceLinksArray', links);
      }

    },

    init: function init() {
      this._super.apply(this, arguments);
      this.set('service', this.get('originalModel').clone());
    },

    didSave: function didSave() {
      var service = this.get('service');
      var ary = [];
      this.get('serviceLinksArray').forEach(function (row) {
        if (row.serviceId) {
          ary.push({ name: row.name, serviceId: row.serviceId });
        } else if (row.service) {
          ary.push({ name: row.name, service: row.service });
        }
      });

      return service.doAction('setservicelinks', { serviceLinks: ary });
    },

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});