define('ui/components/hero-add-service/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    settings: _ember['default'].inject.service(),

    showUser: true,
    catalog: null,

    stackId: null,

    actions: {
      newService: function newService() {
        var _this = this;

        var stackId = this.get('stackId');

        if (stackId) {
          this.get('router').transitionTo('service.new', { queryParams: { stackId: stackId } });
        } else {
          var stack = this.get('store').createRecord({
            type: 'stack',
            name: 'Default'
          });

          return stack.save().then(function () {
            _this.get('router').transitionTo('service.new', { queryParams: { stackId: stack.get('id') } });
          });
        }
      }
    }
  });
});