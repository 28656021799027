define("ui/components/consumed-service-link/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "ui/components/consumed-service-link/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createElement("div");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("label");
          dom.setAttribute(el2, "class", "text-muted");
          dom.setAttribute(el2, "style", "padding: 0 10px;");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [0]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element0, 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [2]), 0, 0);
          morphs[2] = dom.createMorphAt(element0, 4, 4);
          return morphs;
        },
        statements: [["content", "link.service.name", ["loc", [null, [2, 7], [2, 28]]], 0, 0, 0, 0], ["inline", "t", ["formServiceLinks.asName"], [], ["loc", [null, [2, 80], [2, 111]]], 0, 0], ["content", "link.name", ["loc", [null, [2, 120], [2, 133]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 4
                },
                "end": {
                  "line": 11,
                  "column": 4
                }
              },
              "moduleName": "ui/components/consumed-service-link/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["inline", "t", [["subexpr", "if", [["subexpr", "eq", [["get", "link.service.stackId", ["loc", [null, [6, 18], [6, 38]]], 0, 0, 0, 0], ["get", "stackId", ["loc", [null, [6, 39], [6, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 14], [6, 47]]], 0, 0], "serviceLink.noAlias.sameStack", "serviceLink.noAlias.differentStack"], [], ["loc", [null, [6, 10], [6, 117]]], 0, 0]], ["stack", ["subexpr", "@mut", [["get", "link.service.displayStack", ["loc", [null, [7, 14], [7, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "service", ["subexpr", "@mut", [["get", "link.service.displayName", ["loc", [null, [8, 16], [8, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "alias", ["subexpr", "@mut", [["get", "link.name", ["loc", [null, [9, 14], [9, 23]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 6], [10, 8]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 12,
                    "column": 6
                  },
                  "end": {
                    "line": 18,
                    "column": 6
                  }
                },
                "moduleName": "ui/components/consumed-service-link/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "t", [["subexpr", "if", [["subexpr", "eq", [["get", "link.service.stackId", ["loc", [null, [13, 20], [13, 40]]], 0, 0, 0, 0], ["get", "stackId", ["loc", [null, [13, 41], [13, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [13, 16], [13, 49]]], 0, 0], "serviceLink.withAlias.sameStack", "serviceLink.withAlias.differentStack"], [], ["loc", [null, [13, 12], [13, 123]]], 0, 0]], ["stack", ["subexpr", "@mut", [["get", "link.service.displayStack", ["loc", [null, [14, 16], [14, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "service", ["subexpr", "@mut", [["get", "link.service.displayName", ["loc", [null, [15, 18], [15, 42]]], 0, 0, 0, 0]], [], [], 0, 0], "alias", ["subexpr", "@mut", [["get", "link.name", ["loc", [null, [16, 16], [16, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 8], [17, 10]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 18,
                    "column": 6
                  },
                  "end": {
                    "line": 23,
                    "column": 6
                  }
                },
                "moduleName": "ui/components/consumed-service-link/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["inline", "t", [["subexpr", "if", [["subexpr", "eq", [["get", "link.service.stackId", ["loc", [null, [19, 20], [19, 40]]], 0, 0, 0, 0], ["get", "stackId", ["loc", [null, [19, 41], [19, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 16], [19, 49]]], 0, 0], "serviceLink.noAlias.sameStack", "serviceLink.noAlias.differentStack"], [], ["loc", [null, [19, 12], [19, 119]]], 0, 0]], ["stack", ["subexpr", "@mut", [["get", "link.service.displayStack", ["loc", [null, [20, 16], [20, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "service", ["subexpr", "@mut", [["get", "link.service.displayName", ["loc", [null, [21, 18], [21, 42]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 8], [22, 10]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 4
                },
                "end": {
                  "line": 24,
                  "column": 4
                }
              },
              "moduleName": "ui/components/consumed-service-link/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "link.name", ["loc", [null, [12, 13], [12, 22]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [12, 6], [23, 15]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 2
              },
              "end": {
                "line": 25,
                "column": 2
              }
            },
            "moduleName": "ui/components/consumed-service-link/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "link.service.name", ["loc", [null, [5, 15], [5, 32]]], 0, 0, 0, 0], ["get", "link.name", ["loc", [null, [5, 33], [5, 42]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 11], [5, 43]]], 0, 0]], [], 0, 1, ["loc", [null, [5, 4], [24, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 26,
              "column": 0
            }
          },
          "moduleName": "ui/components/consumed-service-link/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "link-to", ["service", ["get", "link.service.stackId", ["loc", [null, [4, 24], [4, 44]]], 0, 0, 0, 0], ["get", "link.service.id", ["loc", [null, [4, 45], [4, 60]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 2], [25, 14]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "ui/components/consumed-service-link/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "link.service.arbitraryString", ["loc", [null, [1, 7], [1, 35]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [26, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});