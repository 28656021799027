define('ui/admin-tab/accounts/index/controller', ['exports', 'ember', 'ui/mixins/sortable', 'ui/mixins/filter-state'], function (exports, _ember, _uiMixinsSortable, _uiMixinsFilterState) {

  var showKinds = ['user', 'admin'];

  exports['default'] = _ember['default'].Controller.extend(_uiMixinsFilterState['default'], _uiMixinsSortable['default'], {
    access: _ember['default'].inject.service(),

    sortableContent: _ember['default'].computed.alias('filteredByKind'),
    sortBy: 'name',
    sorts: {
      state: ['stateSort', 'name', 'id'],
      name: ['name', 'id'],
      username: ['username', 'id'],
      kind: ['kind', 'name', 'id'],
      ip: ['displayIp', 'name', 'id'],
      image: ['imageUuid', 'id'],
      command: ['command', 'name', 'id']
    },

    filteredByKind: (function () {
      return this.get('filtered').filter(function (row) {
        var kind = (row.get('kind') || '').toLowerCase();
        return showKinds.indexOf(kind) !== -1;
      });
    }).property('filtered.@each.kind'),

    isLocal: (function () {
      return this.get('access.provider') === 'localauthconfig';
    }).property('access.provider')
  });
});