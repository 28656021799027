define('ui/components/waiting-orchestration/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    projects: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    k8s: _ember['default'].inject.service(),

    hosts: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('hosts', this.get('store').all('host'));
    },

    expectHosts: (function () {
      return this.get('projects.current.orchestration') === 'mesos' ? 3 : 1;
    }).property('projects.current.orchestration'),

    hasHosts: (function () {
      return this.get('hosts.length') >= this.get('expectHosts');
    }).property('hosts.length'),

    actions: {
      kubernetesReady: function kubernetesReady() {
        var _this = this;

        this.get('k8s').allNamespaces().then(function () {
          _this.get('projects').updateOrchestrationState().then(function () {
            _this.transitionToRoute('k8s-tab');
          });
        });
      },

      swarmReady: function swarmReady() {
        var _this2 = this;

        this.get('projects').updateOrchestrationState().then(function () {
          _this2.transitionToRoute('swarm-tab');
        });
      },

      mesosReady: function mesosReady() {
        var _this3 = this;

        this.get('projects').updateOrchestrationState().then(function () {
          _this3.transitionToRoute('mesos-tab');
        });
      }
    }
  });
});