define('ui/components/modal-confirm-deactivate/component', ['exports', 'ember', 'ui/utils/platform', 'lacsso/components/modal-base'], function (exports, _ember, _uiUtilsPlatform, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'medium-modal', 'modal-logs'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts.originalModel'),
    action: _ember['default'].computed.alias('modalService.modalOpts.action'),
    alternateLabel: _uiUtilsPlatform.alternateLabel,
    intl: _ember['default'].inject.service(),

    actions: {

      confirm: function confirm() {
        this.get('originalModel').send(this.get('action'));
        this.send('cancel');
      }
    },

    didRender: function didRender() {
      var _this = this;

      setTimeout(function () {
        try {
          _this.$('BUTTON')[0].focus();
        } catch (e) {}
      }, 500);
    },

    isService: _ember['default'].computed('originalModel.type', 'intl._locale', function () {
      var type = this.get('originalModel.type');
      var out = {};
      var intl = this.get('intl');

      switch (type) {
        case 'project':
          out.message = intl.t('modalConfirmDeactivate.buttons.project.message');
          out.button = intl.t('modalConfirmDeactivate.buttons.project.button');
          break;
        case 'environment':
          out.message = intl.t('modalConfirmDeactivate.buttons.environment.message');
          out.button = intl.t('modalConfirmDeactivate.buttons.environment.button');
          break;
        default:
          out.message = intl.t('modalConfirmDeactivate.buttons.default.message');
          out.button = intl.t('modalConfirmDeactivate.buttons.default.button');
          break;
      }

      return out;
    })
  });
});