define('ui/mixins/new-service-alias', ['exports', 'ember', 'ui/mixins/new-or-edit'], function (exports, _ember, _uiMixinsNewOrEdit) {
  exports['default'] = _ember['default'].Mixin.create(_uiMixinsNewOrEdit['default'], {
    service: null,
    existing: null,
    targetResources: null,
    targetsArray: null,
    primaryResource: _ember['default'].computed.alias('service'),

    init: function init() {
      this._super.apply(this, arguments);

      this.set('targetsArray', []);
      this.set('targetResources', []);
    },
    actions: {
      setTargets: function setTargets(array, resources) {
        this.set('targetsArray', array);
        this.set('targetResources', resources);
      }
    },
    // ----------------------------------
    // Save
    // ----------------------------------

    didSave: function didSave() {
      // Set balancer targets
      return this.get('service').doAction('setservicelinks', {
        serviceLinks: this.get('targetResources')
      });
    }

  });
});