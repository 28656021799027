define('ui/admin-tab/auth/azuread/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.get('userStore').find('azureadconfig', null, { forceReload: true }).then(function (collection) {
        var obj = collection.get('firstObject');
        obj.set('accessMode', 'unrestricted');
        return obj;
      });
    }
  });
});