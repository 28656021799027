define("ui/admin-tab/processes/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 2
              },
              "end": {
                "line": 17,
                "column": 2
              }
            },
            "moduleName": "ui/admin-tab/processes/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element7 = dom.childAt(fragment, [1]);
            var element8 = dom.childAt(fragment, [3]);
            var element9 = dom.childAt(fragment, [5]);
            var element10 = dom.childAt(fragment, [7]);
            var morphs = new Array(8);
            morphs[0] = dom.createAttrMorph(element7, 'data-title');
            morphs[1] = dom.createMorphAt(element7, 0, 0);
            morphs[2] = dom.createAttrMorph(element8, 'data-title');
            morphs[3] = dom.createMorphAt(element8, 0, 0);
            morphs[4] = dom.createAttrMorph(element9, 'data-title');
            morphs[5] = dom.createMorphAt(element9, 0, 0);
            morphs[6] = dom.createAttrMorph(element10, 'data-title');
            morphs[7] = dom.createMorphAt(element10, 0, 0);
            return morphs;
          },
          statements: [["attribute", "data-title", ["concat", [["get", "dt.name", ["loc", [null, [13, 22], [13, 29]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "row.processName", ["loc", [null, [13, 33], [13, 52]]], 0, 0, 0, 0], ["attribute", "data-title", ["concat", [["get", "dt.running", ["loc", [null, [14, 22], [14, 32]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "row.running", ["loc", [null, [14, 36], [14, 51]]], 0, 0, 0, 0], ["attribute", "data-title", ["concat", [["get", "dt.ready", ["loc", [null, [15, 22], [15, 30]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "row.ready", ["loc", [null, [15, 34], [15, 47]]], 0, 0, 0, 0], ["attribute", "data-title", ["concat", [["get", "dt.delay", ["loc", [null, [16, 22], [16, 30]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "row.delay", ["loc", [null, [16, 34], [16, 47]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 2
                },
                "end": {
                  "line": 19,
                  "column": 2
                }
              },
              "moduleName": "ui/admin-tab/processes/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              dom.setAttribute(el1, "class", "text-center text-muted lacsso pt-20 pb-20");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element6 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element6, 'colspan');
              morphs[1] = dom.createMorphAt(element6, 0, 0);
              return morphs;
            },
            statements: [["attribute", "colspan", ["concat", [["get", "headers.length", ["loc", [null, [18, 19], [18, 33]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["processesPage.summary.noMatch"], [], ["loc", [null, [18, 87], [18, 124]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 19,
                    "column": 2
                  },
                  "end": {
                    "line": 21,
                    "column": 2
                  }
                },
                "moduleName": "ui/admin-tab/processes/index/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                dom.setAttribute(el1, "class", "text-center text-muted lacsso pt-20 pb-20");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element5, 'colspan');
                morphs[1] = dom.createMorphAt(element5, 0, 0);
                return morphs;
              },
              statements: [["attribute", "colspan", ["concat", [["get", "headers.length", ["loc", [null, [20, 19], [20, 33]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["processesPage.summary.noData"], [], ["loc", [null, [20, 87], [20, 123]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 21,
                      "column": 2
                    },
                    "end": {
                      "line": 28,
                      "column": 2
                    }
                  },
                  "moduleName": "ui/admin-tab/processes/index/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("    ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("tr");
                  dom.setAttribute(el1, "class", "total");
                  var el2 = dom.createTextNode("\n      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n      ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("td");
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n    ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n  ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var element1 = dom.childAt(element0, [1]);
                  var element2 = dom.childAt(element0, [3]);
                  var element3 = dom.childAt(element0, [5]);
                  var element4 = dom.childAt(element0, [7]);
                  var morphs = new Array(8);
                  morphs[0] = dom.createAttrMorph(element1, 'data-title');
                  morphs[1] = dom.createMorphAt(element1, 0, 0);
                  morphs[2] = dom.createAttrMorph(element2, 'data-title');
                  morphs[3] = dom.createMorphAt(element2, 0, 0);
                  morphs[4] = dom.createAttrMorph(element3, 'data-title');
                  morphs[5] = dom.createMorphAt(element3, 0, 0);
                  morphs[6] = dom.createAttrMorph(element4, 'data-title');
                  morphs[7] = dom.createMorphAt(element4, 0, 0);
                  return morphs;
                },
                statements: [["attribute", "data-title", ["concat", [["get", "dt.name", ["loc", [null, [23, 24], [23, 31]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "total.processName", ["loc", [null, [23, 35], [23, 56]]], 0, 0, 0, 0], ["attribute", "data-title", ["concat", [["get", "dt.running", ["loc", [null, [24, 24], [24, 34]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "total.running", ["loc", [null, [24, 38], [24, 55]]], 0, 0, 0, 0], ["attribute", "data-title", ["concat", [["get", "dt.ready", ["loc", [null, [25, 24], [25, 32]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "total.ready", ["loc", [null, [25, 36], [25, 51]]], 0, 0, 0, 0], ["attribute", "data-title", ["concat", [["get", "dt.delay", ["loc", [null, [26, 24], [26, 32]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "total.delay", ["loc", [null, [26, 36], [26, 51]]], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 21,
                    "column": 2
                  },
                  "end": {
                    "line": 28,
                    "column": 2
                  }
                },
                "moduleName": "ui/admin-tab/processes/index/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "and", [["get", "model.summary.length", ["loc", [null, [21, 17], [21, 37]]], 0, 0, 0, 0], ["subexpr", "eq", [["get", "kind", ["loc", [null, [21, 42], [21, 46]]], 0, 0, 0, 0], "prefix"], [], ["loc", [null, [21, 38], [21, 56]]], 0, 0]], [], ["loc", [null, [21, 12], [21, 57]]], 0, 0]], [], 0, null, ["loc", [null, [21, 2], [28, 2]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 2
                },
                "end": {
                  "line": 28,
                  "column": 2
                }
              },
              "moduleName": "ui/admin-tab/processes/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "kind", ["loc", [null, [19, 16], [19, 20]]], 0, 0, 0, 0], "norows"], [], ["loc", [null, [19, 12], [19, 30]]], 0, 0]], [], 0, 1, ["loc", [null, [19, 2], [28, 2]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 2
              },
              "end": {
                "line": 28,
                "column": 2
              }
            },
            "moduleName": "ui/admin-tab/processes/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "kind", ["loc", [null, [17, 16], [17, 20]]], 0, 0, 0, 0], "nomatch"], [], ["loc", [null, [17, 12], [17, 31]]], 0, 0]], [], 0, 1, ["loc", [null, [17, 2], [28, 2]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "ui/admin-tab/processes/index/template.hbs"
        },
        isEmpty: false,
        arity: 3,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "kind", ["loc", [null, [12, 12], [12, 16]]], 0, 0, 0, 0], "row"], [], ["loc", [null, [12, 8], [12, 23]]], 0, 0]], [], 0, 1, ["loc", [null, [12, 2], [28, 9]]]]],
        locals: ["row", "kind", "dt"],
        templates: [child0, child1]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 0
          }
        },
        "moduleName": "ui/admin-tab/processes/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "sortable-table", [], ["classNames", "lacsso grid sortable-table", "bulkActions", false, "prefix", true, "paging", false, "search", false, "sortBy", ["subexpr", "@mut", [["get", "sortBy", ["loc", [null, [7, 9], [7, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "headers", ["subexpr", "@mut", [["get", "headers", ["loc", [null, [8, 10], [8, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "body", ["subexpr", "@mut", [["get", "model.summary", ["loc", [null, [9, 7], [9, 20]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [29, 19]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});