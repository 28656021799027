define('ui/stack/graph/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      return this.modelFor('stack');
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.setProperties({
          showServiceInfo: false,
          selectedService: null
        });
      }
    }
  });
});