define('ui/components/top-errors/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    errors: null,

    classNames: ['top-errors', 'alert', 'alert-danger'],
    classNameBindings: ['errors.length::hide'],

    errorsDidChange: (function () {
      var _this = this;

      if (this.get('errors.length')) {
        _ember['default'].run.later(function () {
          _this.$().scrollIntoView();
        }, 100);
      }
    }).property('errors.[]')
  });
});