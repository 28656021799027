define('ui/components/edit-certificate/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'lacsso/components/modal-base'], function (exports, _ember, _uiMixinsNewOrEdit, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewOrEdit['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    editing: true,
    model: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', this.get('originalModel').clone());
    },

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});