define('ui/instance-initializers/intl', ['exports', 'ember', 'ui/utils/intl/missing-message'], function (exports, _ember, _uiUtilsIntlMissingMessage) {
  exports.initialize = initialize;
  var get = _ember['default'].get;
  var makeArray = _ember['default'].makeArray;

  function initialize(instance) {
    var intl = instance.lookup('service:intl');

    intl.reopen({
      // calling findTranslationByKey with a null key explodes, make it return something
      _findTranslationByKey: intl.findTranslationByKey,
      findTranslationByKey: function findTranslationByKey(key, locales) {
        locales = makeArray(locales || get(this, '_locale'));

        if (locales[0] === 'none') {
          return (0, _uiUtilsIntlMissingMessage['default'])(key, locales);
        } else if (key) {
          return this._findTranslationByKey.apply(this, arguments);
        } else {
          return this._findTranslationByKey('generic.missing', locales);
        }
      },

      tHtml: function tHtml(key) {
        for (var _len = arguments.length, args = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
          args[_key - 1] = arguments[_key];
        }

        var options = args[0];

        var translation = this.findTranslationByKey(key, options && options.locale);
        return this.formatHtmlMessage.apply(this, [translation].concat(args));
      }
    });
  }

  exports['default'] = {
    name: 'intl',
    after: 'ember-intl',
    initialize: initialize
  };
});