define("ui/components/settings/telemetry-opt/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 10,
            "column": 0
          }
        },
        "moduleName": "ui/components/settings/telemetry-opt/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h3");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h5");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("a");
        dom.setAttribute(el2, "target", "_blank");
        dom.setAttribute(el2, "rel", "noopener nofollow");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "form-label checkbox");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [2]);
        var element1 = dom.childAt(element0, [2]);
        var element2 = dom.childAt(fragment, [6, 1]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 0, 0);
        morphs[2] = dom.createAttrMorph(element1, 'href');
        morphs[3] = dom.createMorphAt(element1, 0, 0);
        morphs[4] = dom.createMorphAt(element2, 0, 0);
        morphs[5] = dom.createMorphAt(element2, 2, 2);
        morphs[6] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        return morphs;
      },
      statements: [["inline", "t", ["telemetryOpt.header"], [], ["loc", [null, [1, 4], [1, 31]]], 0, 0], ["inline", "format-html-message", ["telemetryOpt.subtext"], ["appName", ["subexpr", "@mut", [["get", "settings.appName", ["loc", [null, [2, 57], [2, 73]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 4], [2, 75]]], 0, 0], ["attribute", "href", ["concat", [["get", "settings.docsBase", ["loc", [null, [2, 87], [2, 104]]], 0, 0, 0, 0], "/telemetry"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "t", ["telemetryOpt.learnMore.label"], [], ["loc", [null, [2, 158], [2, 194]]], 0, 0], ["inline", "input", [], ["type", "checkbox", "checked", ["subexpr", "@mut", [["get", "optIn", ["loc", [null, [6, 41], [6, 46]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 9], [6, 48]]], 0, 0], ["inline", "t", ["telemetryOpt.label"], [], ["loc", [null, [6, 49], [6, 75]]], 0, 0], ["inline", "save-cancel", [], ["cancelDisabled", true, "editing", true, "save", "save"], ["loc", [null, [9, 0], [9, 60]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});