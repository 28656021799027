define('ui/utils/add-view-action', ['exports', 'ember'], function (exports, _ember) {
  exports.addAction = addAction;

  function addAction(action, selector) {
    return function () {
      if (_ember['default'].Component.detectInstance(this)) {
        this._super();
      } else {
        this.get('controller').send(action);
      }

      _ember['default'].run.next(this, function () {
        var matches = this.$(selector);
        if (matches) {
          var last = matches.last();
          if (last) {
            last.focus();
          }
        }
      });
    };
  }
});