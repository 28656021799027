define('ui/utils/filtered-sorted-array-proxy', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].ArrayProxy.extend({
    sourceContent: null,
    sortProperties: null,

    // Override this and return true/false for items that should/n't be included
    filterFn: null,

    // Override this with the keys the filterFn needs, as an array of single properties
    // e.g  'sourceContent.@each.thing
    dependentKeys: null,

    _boundFn: null,

    init: function init() {
      var _this = this;

      if (!this.get('sortProperties')) {
        this.set('sortProperties', ['displayName', 'name', 'id']);
      }

      if (!this.get('filterFn')) {
        this.set('filterFn', function () {
          return true;
        });
      }

      (this.get('dependentKeys') || []).forEach(function (key) {
        _this.addObserver(key, _this, 'sourceContentChanged');
      });

      this.set('_boundFn', this.get('filterFn').bind(this));
      this.updateContent();
      this._super();
    },

    sourceContentChanged: function sourceContentChanged() {
      _ember['default'].run.once(this, 'updateContent');
    },

    updateContent: function updateContent() {
      var neu = (this.get('sourceContent') || []).filter(this.get('_boundFn'));
      this.set('content', neu);
    },

    // The array proxy reads this property
    arrangedContent: _ember['default'].computed.sort('content', 'sortProperties')
  });
});