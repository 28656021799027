define('ui/models/kubernetesservice', ['exports', 'ui/models/service', 'ember'], function (exports, _uiModelsService, _ember) {

  var esc = _ember['default'].Handlebars.Utils.escapeExpression;

  var KubernetesService = _uiModelsService['default'].extend({
    type: 'kubernetesService',
    spec: _ember['default'].computed.alias('template.spec'),

    displayPorts: (function () {
      var pub = '';
      (this.get('spec.ports') || []).forEach(function (port, idx) {
        pub += '<span>' + (idx === 0 ? '' : ', ') + esc(port.port) + '</span>';
      });

      var out = '<label>Ports: </label>' + (pub || '<span class="text-muted">None</span>');

      return out.htmlSafe();
    }).property('spec.ports.[]')
  });

  exports['default'] = KubernetesService;
});