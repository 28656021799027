define('ui/mixins/select-tab', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    tab: null,

    actions: {
      selectTab: function selectTab(name) {
        this.set('tab', name);
        this.$('.tab').removeClass('active');
        this.$('.tab[data-section="' + name + '"]').addClass('active');
        this.$('.section').addClass('hide');
        this.$('.section[data-section="' + name + '"]').removeClass('hide');
      }
    }
  });
});