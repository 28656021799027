define('ui/containers/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var store = this.get('store');
      return _ember['default'].RSVP.hash({
        instances: store.findAll('instance'),
        hosts: store.findAll('host')
      }).then(function () {
        return store.all('container');
      });
    }
  });
});