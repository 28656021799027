define('ui/host/ports/controller', ['exports', 'ember', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], {
    sortableContent: _ember['default'].computed.alias('model.displayEndpoints'),
    sortBy: 'ip',
    sorts: {
      ip: ['ipAddress', 'port'],
      port: ['port', 'ipAddress', 'instanceId'],
      service: ['service.displayName', 'port', 'ipAddress'],
      container: ['instance.displayName', 'port', 'ipAddress']
    }
  });
});