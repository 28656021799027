define('ui/models/githubconfig', ['exports', 'ember-api-store/models/resource'], function (exports, _emberApiStoreModelsResource) {

  var GithubConfig = _emberApiStoreModelsResource['default'].extend({
    type: 'githubConfig'
  });

  // Projects don't get pushed by /subscribe WS, so refresh more often
  GithubConfig.reopenClass({
    mangleIn: function mangleIn(data, store) {
      if (data.allowedIdentities) {
        // Labels shouldn't be a model even if it has a key called 'type'
        data.allowedIdentities = data.allowedIdentities.map(function (obj) {
          obj.type = 'identity';
          return store.createRecord(obj);
        });
      }

      return data;
    }
  });

  exports['default'] = GithubConfig;
});