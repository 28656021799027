define('ui/service/new-balancer/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    settings: _ember['default'].inject.service(),

    model: function model(params /*, transition*/) {
      var _this = this;

      var store = this.get('store');

      var dependencies = {
        allHosts: store.findAll('hosts'),
        allCertificates: store.findAll('certificate')
      };

      if (params.serviceId) {
        dependencies['existingService'] = store.find('service', params.serviceId);
      }

      return _ember['default'].RSVP.hash(dependencies).then(function (hash) {
        var service = undefined;
        if (hash.existingService) {
          if (params.upgrade + '' === 'true') {
            service = hash.existingService.clone();

            if (params.upgradeImage + '' === 'true') {
              service.set('launchConfig.imageUuid', 'docker:' + _this.get('settings.' + _uiUtilsConstants['default'].SETTING.BALANCER_IMAGE));
            }

            hash.existing = hash.existingService;
          } else {
            service = hash.existingService.cloneForNew();
          }

          delete hash.existingService;
          delete service.instanceIds;
        } else {
          service = store.createRecord({
            type: 'loadBalancerService',
            name: '',
            description: '',
            scale: 1,
            stackId: params.stackId,
            startOnCreate: true,
            launchConfig: store.createRecord({
              imageUuid: 'docker:' + _this.get('settings.' + _uiUtilsConstants['default'].SETTING.BALANCER_IMAGE),
              type: 'launchConfig',
              restartPolicy: { name: 'always' }
            }),
            lbConfig: store.createRecord({
              type: 'lbConfig',
              config: '',
              certificateIds: [],
              stickinessPolicy: null,
              portRules: [store.createRecord({
                type: 'portRule',
                protocol: 'http',
                priority: 1,
                access: 'public'
              })]
            })
          });
        }

        hash.service = service;
        return hash;
      });
    },

    resetController: function resetController(controller, isExisting /*, transition*/) {
      if (isExisting) {
        controller.set('tab', 'ssl');
        controller.set('stickiness', 'none');
        controller.set('stackId', null);
        controller.set('serviceId', null);
        controller.set('upgrade', null);
        controller.set('upgradeImage', null);
      }
    },

    actions: {
      cancel: function cancel() {
        this.goToPrevious();
      }
    }
  });
});