define("ui/catalog-tab/launch/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "ui/catalog-tab/launch/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "new-catalog", [], ["allTemplates", ["subexpr", "@mut", [["get", "model.allTemplates", ["loc", [null, [2, 15], [2, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "stackResource", ["subexpr", "@mut", [["get", "model.stack", ["loc", [null, [3, 16], [3, 27]]], 0, 0, 0, 0]], [], [], 0, 0], "templateResource", ["subexpr", "@mut", [["get", "model.tpl", ["loc", [null, [4, 19], [4, 28]]], 0, 0, 0, 0]], [], [], 0, 0], "versionLinks", ["subexpr", "@mut", [["get", "model.versionLinks", ["loc", [null, [5, 15], [5, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "versionsArray", ["subexpr", "@mut", [["get", "model.versionsArray", ["loc", [null, [6, 16], [6, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "parentRoute", ["subexpr", "@mut", [["get", "parentRoute", ["loc", [null, [7, 14], [7, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "showName", ["subexpr", "and", [["subexpr", "not", [["get", "editing", ["loc", [null, [8, 21], [8, 28]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 16], [8, 29]]], 0, 0], ["subexpr", "not", [["get", "model.stack.system", ["loc", [null, [8, 35], [8, 53]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 30], [8, 54]]], 0, 0]], [], ["loc", [null, [8, 11], [8, 55]]], 0, 0], "cancel", ["subexpr", "action", ["cancel"], [], ["loc", [null, [9, 9], [9, 26]]], 0, 0]], ["loc", [null, [1, 0], [10, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});