define('ui/mixins/filter-state', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Mixin.create({
    filterStates: null,
    filterableContent: _ember['default'].computed.alias('model'),

    init: function init() {
      this._super();
      if (!this.get('filterStates')) {
        this.set('filterStates', _uiUtilsConstants['default'].REMOVEDISH_STATES.slice());
      }
    },

    filtered: (function () {
      var filterStates = this.get('filterStates');
      return (this.get('filterableContent') || []).filter(function (row) {
        var state = (row.get('state') || '').toLowerCase();
        return filterStates.indexOf(state) === -1;
      });
    }).property('filterableContent.@each.state', 'filterStates.[]')
  });
});