define("ui/components/limit-parameters/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 27,
            "column": 0
          }
        },
        "moduleName": "ui/components/limit-parameters/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row form-group");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-2 form-label");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("\n  <div class=\"col-md-4\">\n    <label>{{t 'reservationParameters.localStorage.labelText'}}</label>\n    <div class=\"input-group\">\n      {{input-integer min=\"1\" step=\"1\" value=model.localStorageMb classNames=\"form-control\" placeholder=(t 'reservationParameters.localStorage.placeholder')}}\n      <div class=\"input-group-addon\">{{t 'reservationParameters.localStorage.unit'}}</div>\n    </div>\n  </div>\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-5");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "input-group");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "input-group-addon");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-md-5");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "input-group");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "input-group-addon");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [5]);
        var element2 = dom.childAt(element1, [3]);
        var element3 = dom.childAt(element0, [7]);
        var element4 = dom.childAt(element3, [3]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
        morphs[1] = dom.createMorphAt(dom.childAt(element1, [1]), 0, 0);
        morphs[2] = dom.createMorphAt(element2, 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(element2, [3]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element3, [1]), 0, 0);
        morphs[5] = dom.createMorphAt(element4, 1, 1);
        morphs[6] = dom.createMorphAt(dom.childAt(element4, [3]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["reservationParameters.label"], [], ["loc", [null, [2, 35], [2, 70]]], 0, 0], ["inline", "t", ["reservationParameters.memory.labelText"], [], ["loc", [null, [13, 11], [13, 57]]], 0, 0], ["inline", "input-integer", [], ["min", "4", "step", "1", "value", ["subexpr", "@mut", [["get", "memoryLimit", ["loc", [null, [15, 45], [15, 56]]], 0, 0, 0, 0]], [], [], 0, 0], "classNames", "form-control", "placeholder", ["subexpr", "t", ["reservationParameters.memory.placeholder"], [], ["loc", [null, [15, 95], [15, 141]]], 0, 0]], ["loc", [null, [15, 6], [15, 143]]], 0, 0], ["inline", "t", ["reservationParameters.memory.unit"], [], ["loc", [null, [16, 37], [16, 78]]], 0, 0], ["inline", "t", ["reservationParameters.milliCpu.labelText"], [], ["loc", [null, [20, 11], [20, 59]]], 0, 0], ["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "model.milliCpu", ["loc", [null, [22, 32], [22, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "classNames", "form-control", "placeholder", ["subexpr", "t", ["reservationParameters.milliCpu.placeholder"], [], ["loc", [null, [22, 85], [22, 133]]], 0, 0]], ["loc", [null, [22, 6], [22, 135]]], 0, 0], ["inline", "t", ["reservationParameters.milliCpu.unit"], [], ["loc", [null, [23, 37], [23, 80]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});