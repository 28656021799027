define('ui/settings/projects/new-template/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    catalog: _ember['default'].inject.service(),

    model: function model() {
      var _this = this;

      return _ember['default'].RSVP.hash({
        catalogInfo: this.get('catalog').fetchTemplates({ templateBase: _uiUtilsConstants['default'].EXTERNAL_ID.KIND_INFRA, category: _uiUtilsConstants['default'].EXTERNAL_ID.KIND_ALL })
      }).then(function (hash) {
        var existing = _this.modelFor('settings.projects').projectTemplates;

        var def = existing.find(function (tpl) {
          return (tpl.get('name') || '').toLowerCase() === _uiUtilsConstants['default'].PROJECT_TEMPLATE.DEFAULT;
        });
        if (def) {
          var tpl = def.cloneForNew();
          tpl.isPublic = false;
          tpl.name = '';
          tpl.description = '';
          hash.projectTemplate = tpl;
        } else {
          hash.projectTemplate = _this.get('userStore').createRecord({
            type: 'projectTemplate',
            stacks: [],
            isPublic: false
          });
        }

        hash.originalProjectTemplate = hash.projectTemplate;
        return hash;
      });
    }
  });
});