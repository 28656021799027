define('ui/console-vm/controller', ['exports', 'ember', 'ui/mixins/console'], function (exports, _ember, _uiMixinsConsole) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsConsole['default'], {

    bootstrap: (function () {
      var body = _ember['default'].$('body');
      var application = _ember['default'].$('#application');

      body.css('overflow', 'hidden');

      application.css('padding-bottom', '0');
    }).on('init')

  });
});