define('ui/services/user-language', ['exports', 'ember', 'ui/utils/constants', 'ember-api-store/utils/ajax-promise', 'ui/utils/load-script'], function (exports, _ember, _uiUtilsConstants, _emberApiStoreUtilsAjaxPromise, _uiUtilsLoadScript) {

  var RTL_LANGUAGES = ['fa-ir'];

  exports['default'] = _ember['default'].Service.extend({
    prefs: _ember['default'].inject.service(),
    session: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    locales: _ember['default'].computed.alias('app.locales'),
    growl: _ember['default'].inject.service(),
    cookies: _ember['default'].inject.service(),
    userTheme: _ember['default'].inject.service('user-theme'),
    loadedLocales: null,

    bootstrap: (function () {
      this.set('loadedLocales', []);
    }).on('init'),

    initUnauthed: function initUnauthed() {
      var lang = _uiUtilsConstants['default'].LANGUAGE.DEFAULT;
      var fromSession = this.get('session.' + _uiUtilsConstants['default'].SESSION.LANGUAGE);
      var fromCookie = this.get('cookies').get(_uiUtilsConstants['default'].COOKIE.LANG);

      if (fromSession) {
        lang = fromSession;
      } else if (fromCookie) {
        lang = fromCookie;
      }

      return this.sideLoadLanguage(lang);
    },

    initLanguage: function initLanguage() {
      var save = arguments.length <= 0 || arguments[0] === undefined ? false : arguments[0];

      var lang = _uiUtilsConstants['default'].LANGUAGE.DEFAULT;
      var session = this.get('session');

      var fromLogin = session.get(_uiUtilsConstants['default'].SESSION.LOGIN_LANGUAGE);
      var fromPrefs = this.get('prefs.' + _uiUtilsConstants['default'].PREFS.LANGUAGE); // get language from user prefs
      var fromSession = session.get(_uiUtilsConstants['default'].SESSION.LANGUAGE); // get local language
      var fromCookie = this.get('cookies').get(_uiUtilsConstants['default'].COOKIE.LANG); // get language from cookie

      if (fromLogin) {
        lang = fromLogin;
        if (save) {
          session.set(_uiUtilsConstants['default'].SESSION.LOGIN_LANGUAGE, undefined);
        }
      } else if (fromPrefs) {
        lang = fromPrefs;
      } else if (fromSession) {
        lang = fromSession;
      } else if (fromCookie) {
        lang = fromCookie;
      }

      lang = this.normalizeLang(lang);

      this.setLanguage(lang, save);
      return this.sideLoadLanguage(lang);
    },

    normalizeLang: function normalizeLang(lang) {
      return lang.toLowerCase();
    },

    getLocale: function getLocale() {
      return this.get('intl._locale')[0];
    },

    setLanguage: function setLanguage(lang) {
      var savePref = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

      var session = this.get('session');
      lang = lang || session.get(_uiUtilsConstants['default'].SESSION.LANGUAGE);

      session.set(_uiUtilsConstants['default'].SESSION.LANGUAGE, lang);
      if (savePref && session.get(_uiUtilsConstants['default'].SESSION.ACCOUNT_ID)) {
        return this.set('prefs.' + _uiUtilsConstants['default'].PREFS.LANGUAGE, lang);
      } else {
        return _ember['default'].RSVP.resolve();
      }
    },

    sideLoadLanguage: function sideLoadLanguage(language) {
      var _this = this;

      var application = this.get('app');
      var loadedLocales = this.get('loadedLocales');

      if (loadedLocales.includes(language)) {
        this.get('intl').setLocale(language);
        this.setLanguage(language, false);
        this.get('userTheme').writeStyleNode();
        return _ember['default'].RSVP.resolve();
      } else {
        return (0, _emberApiStoreUtilsAjaxPromise.ajaxPromise)({ url: this.get('app.baseAssets') + 'translations/' + language + '.json?' + application.version,
          method: 'GET',
          dataType: 'json'
        }).then(function (resp) {
          var promise = undefined;
          if (_this.get('app.needIntlPolyfill')) {
            promise = (0, _uiUtilsLoadScript.loadScript)(_this.get('app.baseAssets') + 'assets/intl/locales/' + language.toLowerCase() + '.js?' + application.version);
          } else {
            promise = _ember['default'].RSVP.resolve();
          }

          return promise.then(function () {
            loadedLocales.push(language);
            return _this.get('intl').addTranslations(language, resp.xhr.responseJSON).then(function () {
              _this.get('intl').setLocale(language);
              _this.setLanguage(language, false);
              _this.get('userTheme').writeStyleNode();
            });
          });
        })['catch'](function (err) {
          _this.get('growl').fromError('Error loading language: ' + language, err);
          if (language !== _uiUtilsConstants['default'].LANGUAGE.DEFAULT) {
            return _this.sideLoadLanguage(_uiUtilsConstants['default'].LANGUAGE.DEFAULT);
          }
        });
      }
    },

    getAvailableTranslations: function getAvailableTranslations() {
      return this.get('intl').getLocalesByTranslations();
    },

    isRtl: function isRtl(lang) {
      return RTL_LANGUAGES.includes(lang.toLowerCase());
    }

  });
});