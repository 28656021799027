define('ui/service/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect(model) {
      if (model.service.kind !== 'dnsService') {
        this.transitionTo('service.containers');
      } else {
        this.transitionTo('service.links');
      }
    }
  });
});