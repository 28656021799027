define('ui/models/secret', ['exports', 'ember', 'ember-api-store/models/resource'], function (exports, _ember, _emberApiStoreModelsResource) {
  exports['default'] = _emberApiStoreModelsResource['default'].extend({
    modalService: _ember['default'].inject.service('modal'),
    actions: {
      edit: function edit() {
        this.get('modalService').toggleModal('edit-secret', this);
      }
    },

    availableActions: (function () {
      var a = this.get('actionLinks');
      if (!a) {
        return [];
      }

      var choices = [{ label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: !!a.remove, altAction: 'delete', bulkable: true }, { divider: true }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }, { divider: true }, { label: 'action.edit', icon: 'icon icon-edit', action: 'edit', enabled: !!a.update || true }];

      return choices;
    }).property('actionLinks.{remove,update}')
  });
});