define('ui/models/dnsservice', ['exports', 'ember', 'ui/models/service'], function (exports, _ember, _uiModelsService) {

  var esc = _ember['default'].Handlebars.Utils.escapeExpression;

  var DnsService = _uiModelsService['default'].extend({
    type: 'dnsService',
    intl: _ember['default'].inject.service(),

    healthState: 'healthy',

    displayDetail: (function () {
      var _this = this;

      var intl = this.get('intl');
      var toTranslation = intl.tHtml('generic.to');
      var noneTranslation = intl.tHtml('generic.none');

      var services = '';
      (this.get('consumedServicesWithNames') || []).forEach(function (map, idx) {
        services += '<span>' + (idx === 0 ? '' : ', ') + (map.get('service.stackId') === _this.get('stackId') ? '' : esc(map.get('service.displayStack')) + '/') + esc(map.get('service.displayName')) + '</span>';
      });

      var out = '<label>' + toTranslation + ': </label>' + services || '<span class="text-muted">' + noneTranslation + '</span>';

      return out.htmlSafe();
    }).property('consumedServicesWithNames.@each.{name,service}', 'intl._locale')
  });

  exports['default'] = DnsService;
});