define('ui/authenticated/project/api/hooks/new-receiver/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    webhookStore: _ember['default'].inject.service(),

    model: function model(params) {
      var promise = undefined;
      var store = this.get('webhookStore');
      if (params.receiverId) {
        promise = store.find('receiver', params.receiverId);
      } else {
        promise = _ember['default'].RSVP.resolve(store.createRecord({
          type: 'receiver',
          driver: 'scaleService'
        }));
      }

      return promise.then(function (receiver) {
        return _ember['default'].Object.create({
          receiver: receiver.cloneForNew()
        });
      });
    },

    resetController: function resetController(controller, isExisting /*, transition*/) {
      if (isExisting) {
        controller.set('errors', null);
        controller.set('receiverId', null);
      }
    }
  });
});