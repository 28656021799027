define('ui/authenticated/project/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    projects: _ember['default'].inject.service(),

    redirect: function redirect() {
      var orch = this.get('projects.current.orchestration');

      if (orch === 'kubernetes') {
        this.replaceWith('k8s-tab');
      } else if (orch === 'swarm') {
        this.replaceWith('swarm-tab');
      } else if (orch === 'mesos') {
        this.replaceWith('mesos-tab');
      } else {
        this.replaceWith('applications-tab');
      }
    }
  });
});