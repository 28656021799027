define('ui/services/endpoint', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Service.extend({
    cookies: _ember['default'].inject.service(),
    'tab-session': _ember['default'].inject.service('tab-session'),
    settings: _ember['default'].inject.service(),

    absolute: (function () {
      var url = this.get('app.apiServer');

      // If the URL is relative, add on the current base URL from the browser
      if (url.indexOf('http') !== 0) {
        url = window.location.origin + '/' + url.replace(/^\/+/, '');
      }

      // URL must end in a single slash
      url = url.replace(/\/+$/, '') + '/';

      return url;
    }).property('app.apiServer'),

    host: (function () {
      var a = document.createElement('a');
      a.href = this.get('absolute');
      return a.host;
    }).property('absolute'),

    origin: (function () {
      var a = document.createElement('a');
      a.href = this.get('absolute');
      return a.origin;
    }).property('absolute'),

    swarm: (function () {
      var port = this.get('settings.' + _uiUtilsConstants['default'].SETTING.SWARM_PORT);
      if (!port) {
        port = parseInt(window.location.port, 10);
      }

      if (!port) {
        port = window.location.protocol === 'https:' ? 443 : 80;
      }

      return 'tcp://' + window.location.hostname + ':' + port;
    }).property('settings.' + _uiUtilsConstants['default'].SETTING.SWARM_PORT)
  });
});