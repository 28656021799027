define('ui/stack/chart/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({

    selectedService: null,
    showAddtlInfo: false,

    actions: {
      openInfo: function openInfo(service) {
        this.set('selectedService', service);
        this.set('showAddtlInfo', true);
      },
      dismiss: function dismiss() {
        this.set('showAddtlInfo', false);
      }
    }
  });
});