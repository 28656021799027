define('ui/admin-tab/machine/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    catalog: _ember['default'].inject.service(),

    model: function model() {
      return _ember['default'].RSVP.hash({
        drivers: this.get('userStore').findAll('machinedriver', null, { forceReload: true }),
        catalogDrivers: this.get('catalog').fetchTemplates({ templateBase: 'machine', category: 'all', allowFailure: true })
      }).then(function (hash) {
        return hash;
      });
    }
  });
});