define('ui/stack/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    showAddtlInfo: false,
    selectedService: null,

    actions: {
      showAddtlInfo: function showAddtlInfo(service) {
        this.set('selectedService', service);
        this.set('showAddtlInfo', true);
      },

      dismiss: function dismiss() {
        this.set('showAddtlInfo', false);
        this.set('selectedService', null);
      }
    },

    instanceCount: (function () {
      var count = 0;
      (this.get('model.stack.services') || []).forEach(function (service) {
        count += service.get('instances.length') || 0;
      });

      return count;
    }).property('model.stack.services.@each.healthState')
  });
});