define('ui/helpers/select-property', ['exports', 'lacsso/helpers/select-property'], function (exports, _lacssoHelpersSelectProperty) {
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function get() {
      return _lacssoHelpersSelectProperty['default'];
    }
  });
  Object.defineProperty(exports, 'selectProperty', {
    enumerable: true,
    get: function get() {
      return _lacssoHelpersSelectProperty.selectProperty;
    }
  });
});