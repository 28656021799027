define('ui/backuptargets/index/controller', ['exports', 'ember', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], {
    sortableContent: _ember['default'].computed.alias('model.all'),
    sortBy: 'name',
    sorts: {
      state: ['stateSort', 'name', 'id'],
      name: ['name', 'id'],
      server: ['nfsConfig.server', 'name', 'id'],
      label: ['nfsConfig.label', 'name', 'id'],
      mountOptions: ['nfsConfig.mountOptions', 'name', 'id']
    }

  });
});