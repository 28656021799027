define("ui/utils/rackspace-choices", ["exports"], function (exports) {
  exports["default"] = [{
    "id": "2",
    "name": "512MB Standard Instance"
  }, {
    "id": "3",
    "name": "1GB Standard Instance"
  }, {
    "id": "4",
    "name": "2GB Standard Instance"
  }, {
    "id": "5",
    "name": "4GB Standard Instance"
  }, {
    "id": "6",
    "name": "8GB Standard Instance"
  }, {
    "id": "7",
    "name": "15GB Standard Instance"
  }, {
    "id": "8",
    "name": "30GB Standard Instance"
  }, {
    "id": "compute1-15",
    "name": "15 GB Compute v1"
  }, {
    "id": "compute1-30",
    "name": "30 GB Compute v1"
  }, {
    "id": "compute1-4",
    "name": "3.75 GB Compute v1"
  }, {
    "id": "compute1-60",
    "name": "60 GB Compute v1"
  }, {
    "id": "compute1-8",
    "name": "7.5 GB Compute v1"
  }, {
    "id": "general1-1",
    "name": "1 GB General Purpose v1"
  }, {
    "id": "general1-2",
    "name": "2 GB General Purpose v1"
  }, {
    "id": "general1-4",
    "name": "4 GB General Purpose v1"
  }, {
    "id": "general1-8",
    "name": "8 GB General Purpose v1"
  }, {
    "id": "io1-120",
    "name": "120 GB I/O v1"
  }, {
    "id": "io1-15",
    "name": "15 GB I/O v1"
  }, {
    "id": "io1-30",
    "name": "30 GB I/O v1"
  }, {
    "id": "io1-60",
    "name": "60 GB I/O v1"
  }, {
    "id": "io1-90",
    "name": "90 GB I/O v1"
  }, {
    "id": "memory1-120",
    "name": "120 GB Memory v1"
  }, {
    "id": "memory1-15",
    "name": "15 GB Memory v1"
  }, {
    "id": "memory1-240",
    "name": "240 GB Memory v1"
  }, {
    "id": "memory1-30",
    "name": "30 GB Memory v1"
  }, {
    "id": "memory1-60",
    "name": "60 GB Memory v1"
  }, {
    "id": "performance1-1",
    "name": "1 GB Performance"
  }, {
    "id": "performance1-2",
    "name": "2 GB Performance"
  }, {
    "id": "performance1-4",
    "name": "4 GB Performance"
  }, {
    "id": "performance1-8",
    "name": "8 GB Performance"
  }, {
    "id": "performance2-120",
    "name": "120 GB Performance"
  }, {
    "id": "performance2-15",
    "name": "15 GB Performance"
  }, {
    "id": "performance2-30",
    "name": "30 GB Performance"
  }, {
    "id": "performance2-60",
    "name": "60 GB Performance"
  }, {
    "id": "performance2-90",
    "name": "90 GB Performance"
  }];
});