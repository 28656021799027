define('ui/settings/projects/new/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    access: _ember['default'].inject.service(),
    catalog: _ember['default'].inject.service(),

    model: function model() /*params, transition*/{
      var _this = this;

      var userStore = this.get('userStore');
      return _ember['default'].RSVP.hash({
        all: userStore.findAll('project'),
        projectTemplates: userStore.findAll('projectTemplate'),
        catalogTemplates: this.get('catalog').fetchTemplates({ templateBase: _uiUtilsConstants['default'].EXTERNAL_ID.KIND_INFRA, category: _uiUtilsConstants['default'].EXTERNAL_ID.KIND_ALL })
      }).then(function (hash) {
        var tplId = null;
        var tpl = hash.projectTemplates.objectAt(0);
        if (tpl) {
          tplId = tpl.get('id');
        }

        var project = userStore.createRecord({
          type: 'project',
          name: '',
          description: '',
          projectTemplateId: tplId
        });

        if (_this.get('access.enabled')) {
          var identity = _this.get('session.' + _uiUtilsConstants['default'].SESSION.IDENTITY);
          identity.type = 'identity';
          var me = userStore.createRecord(identity);
          me.set('role', _uiUtilsConstants['default'].PROJECT.ROLE_OWNER);
          project.set('projectMembers', [me]);
        } else {
          project.set('projectMembers', []);
        }

        return _ember['default'].Object.create({
          originalProject: null,
          project: project,
          all: hash.all,
          projectTemplates: hash.projectTemplates,
          stacks: []
        });
      });
    }
  });
});