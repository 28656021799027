define('ui/backuptargets/new-target/controller', ['exports', 'ember', 'ui/mixins/new-or-edit'], function (exports, _ember, _uiMixinsNewOrEdit) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsNewOrEdit['default'], {

    doneSaving: function doneSaving() {
      this.transitionToRoute('backuptargets');
    },

    actions: {
      cancel: function cancel() {
        this.send('goToPrevious');
      }
    }
  });
});