define('ui/components/machine/driver-vmwarevsphere/component', ['exports', 'ember', 'ui/mixins/driver'], function (exports, _ember, _uiMixinsDriver) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsDriver['default'], {
    driverName: 'vmwarevsphere',
    model: null,
    config: _ember['default'].computed.alias('model.vmwarevsphereConfig'),
    showEngineUrl: false,

    bootstrap: function bootstrap() {
      var config = this.get('store').createRecord({
        type: 'vmwarevsphereConfig',
        cpuCount: 2,
        memorySize: 2048,
        diskSize: 20000,
        vcenterPort: 443
      });

      this.set('model', this.get('store').createRecord({
        type: 'host',
        vmwarevsphereConfig: config,
        engineInstallUrl: ''
      }));
    }
  });
});