define('ui/components/input-integer/component', ['exports', 'ember', 'ui/utils/platform'], function (exports, _ember, _uiUtilsPlatform) {

  function sanitize(val) {
    val = (val + '').trim().replace(/[^0-9]/g, '');
    val = val.replace(/\..*$/g, '');
    return val;
  }

  exports['default'] = _ember['default'].TextField.extend({
    type: _ember['default'].computed(function () {
      return _uiUtilsPlatform.isMobile ? 'number' : 'text';
    }),

    attributeBindings: ['pattern', 'inputmode'],
    pattern: "[0-9]*",
    inputmode: "numeric",

    _elementValueDidChange: function _elementValueDidChange() {
      var val = this.element.value;
      var cur = val;
      val = sanitize(val);

      var num = parseInt(val, 10);
      var max = parseInt(this.get('max'), 10);
      if (!isNaN(num) && !isNaN(max) && num > max) {
        val = "" + max;
      }

      if (cur !== val) {
        this.element.value = val;
      }
      this.set('value', val);
    },

    focusOut: function focusOut() {
      this._super.apply(this, arguments);

      var val = this.element.value;
      var cur = val;
      val = sanitize(val);

      var num = parseInt(val, 10);
      var min = parseInt(this.get('min'), 10);
      if (!isNaN(num) && !isNaN(min) && num < min) {
        val = "" + min;
      }

      if (cur !== val) {
        this.element.value = val;
        this.set('value', val);
      }
    }
  });
});