define('ui/instance-initializers/theme', ['exports', 'ui/utils/constants'], function (exports, _uiUtilsConstants) {
  exports.initialize = initialize;

  function initialize(instance) {
    var session = instance.lookup('service:session');
    var userTheme = instance.lookup('service:user-theme');
    var theme = session.get(_uiUtilsConstants['default'].PREFS.THEME) || _uiUtilsConstants['default'].THEME.DEFAULT;

    userTheme.setTheme(theme, false);
  }

  exports['default'] = {
    name: 'theme',
    initialize: initialize
  };
});