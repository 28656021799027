define('ui/components/radio-button/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'input',
    type: 'radio',
    disabled: false,
    attributeBindings: ['name', 'type', 'checked:checked', 'disabled:disabled'],

    click: function click() {
      this.set('selection', this.get('value'));
    },

    checked: (function () {
      return this.get('value') === this.get('selection');
    }).property('value', 'selection')
  });
});