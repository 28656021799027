define('ui/mixins/throttled-resize', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    boundResize: null,
    throttleTimer: null,
    resizeInterval: 200,

    setup: (function () {

      this.set('boundResize', this.triggerResize.bind(this));
      $(window).on('resize', this.get('boundResize'));
      $(window).on('focus', this.get('boundResize'));
      _ember['default'].run.next(this, 'onResize');
    }).on('init'),

    triggerResize: function triggerResize() {
      var timer = _ember['default'].run.throttle(this, 'onResize', this.get('resizeInterval'), false);
      this.set('throttleTimer', timer);
    },

    onResize: function onResize() {
      // Override me with resize logic
    },

    willDestroyElement: function willDestroyElement() {
      _ember['default'].run.cancel(this.get('throttleTimer'));
      $(window).off('resize', this.get('boundResize'));
      $(window).off('focus', this.get('boundResize'));
    }
  });
});