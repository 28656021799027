define('ui/login/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    activate: function activate() {
      $('BODY').addClass('farm');
    },

    deactivate: function deactivate() {
      $('BODY').removeClass('farm');
    }
  });
});