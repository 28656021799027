define('ui/models/clustermembership', ['exports', 'ember-api-store/models/resource'], function (exports, _emberApiStoreModelsResource) {

  var ClusterMembership = _emberApiStoreModelsResource['default'].extend({
    type: 'clusterMembership'
  });

  ClusterMembership.reopenClass({
    pollTransitioningDelay: 1000,
    pollTransitioningInterval: 5000,

    mangleIn: function mangleIn(data) {
      try {
        (function () {
          var more = JSON.parse(data.config);
          Object.keys(more).forEach(function (key) {
            data[key] = more[key];
          });
          delete data.config;
        })();
      } catch (e) {}

      return data;
    }
  });

  exports['default'] = ClusterMembership;
});