define('ui/components/upgrade-btn/component', ['exports', 'ember', 'ui/mixins/upgrade-component'], function (exports, _ember, _uiMixinsUpgradeComponent) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsUpgradeComponent['default'], {
    tagName: 'button',
    classNames: ['btn', 'btn-sm'],
    classNameBindings: ['color'],

    click: function click() {
      this.doUpgrade();
    }
  });
});