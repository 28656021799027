define('ui/components/machine/driver-exoscale/component', ['exports', 'ember', 'ui/mixins/driver', 'ember-api-store/utils/ajax-promise'], function (exports, _ember, _uiMixinsDriver, _emberApiStoreUtilsAjaxPromise) {

  var RANCHER_GROUP = 'rancher-machine';
  var RANCHER_INGRESS_RULES = [{
    startport: 22,
    endport: 22,
    cidrlist: '0.0.0.0/0',
    protocol: 'TCP'
  }, {
    startport: 2376,
    endport: 2376,
    cidrlist: '0.0.0.0/0',
    protocol: 'TCP'
  }, {
    startport: 500,
    endport: 500,
    cidrlist: '0.0.0.0/0',
    protocol: 'UDP'
  }, {
    startport: 4500,
    endport: 4500,
    cidrlist: '0.0.0.0/0',
    protocol: 'UDP'
  }, {
    icmptype: 8,
    icmpcode: 0,
    cidrlist: '0.0.0.0/0',
    protocol: 'ICMP'
  }];

  exports['default'] = _ember['default'].Component.extend(_uiMixinsDriver['default'], {
    driverName: 'exoscale',
    model: null,
    exoscaleConfig: _ember['default'].computed.alias('model.exoscaleConfig'),

    allInstanceProfiles: null,
    allTemplates: null,

    allZones: null,
    selectedZone: null,
    defaultZone: 'ch-dk-2',
    whichZone: '91e5e9e4-c9ed-4b76-bee4-427004b3baf9',
    allSecurityGroups: null,
    selectedSecurityGroup: null,
    defaultSecurityGroup: null,
    defaultSecurityGroupName: RANCHER_GROUP,
    whichSecurityGroup: 'default',
    isCustomSecurityGroup: _ember['default'].computed.equal('whichSecurityGroup', 'custom'),
    exoscaleApi: 'api.exoscale.ch/compute',

    step: 1,
    isStep1: _ember['default'].computed.equal('step', 1),
    isStep2: _ember['default'].computed.equal('step', 2),
    isStep3: _ember['default'].computed.equal('step', 3),
    isStep4: _ember['default'].computed.equal('step', 4),
    isStep5: _ember['default'].computed.equal('step', 5),
    isStep6: _ember['default'].computed.equal('step', 6),
    isStep7: _ember['default'].computed.equal('step', 7),
    isStep8: _ember['default'].computed.equal('step', 8),
    isGteStep3: _ember['default'].computed.gte('step', 3),
    isGteStep4: _ember['default'].computed.gte('step', 4),
    isGteStep5: _ember['default'].computed.gte('step', 5),
    isGteStep6: _ember['default'].computed.gte('step', 6),
    isGteStep7: _ember['default'].computed.gte('step', 7),
    isGteStep8: _ember['default'].computed.gte('step', 8),

    bootstrap: function bootstrap() {
      var config = this.get('store').createRecord({
        type: 'exoscaleConfig',
        apiKey: '',
        apiSecretKey: '',
        diskSize: 50,
        template: 'Linux Ubuntu 14.04 LTS 64-bit',
        instanceProfile: 'Medium',
        securityGroup: 'rancher-machine'
      });

      this.set('model', this.get('store').createRecord({
        type: 'host',
        exoscaleConfig: config
      }));
    },

    afterInit: (function () {
      this._super();

      var cur = this.get('exoscaleConfig.securityGroup');
      if (cur === RANCHER_GROUP) {
        this.setProperties({
          whichSecurityGroup: 'default',
          selectedSecurityGroup: null
        });
      } else {
        this.setProperties({
          whichSecurityGroup: 'custom',
          selectedSecurityGroup: cur
        });
      }
    }).on('init'),

    willDestroyElement: function willDestroyElement() {
      this.set('errors', null);
      this.set('step', 1);
    },

    actions: {
      /* Login step */
      exoscaleLogin: function exoscaleLogin() {
        var _this = this;

        this.set('errors', null);
        this.set('step', 2);

        this.set('exoscaleConfig.apiKey', (this.get('exoscaleConfig.apiKey') || '').trim());
        this.set('exoscaleConfig.apiSecretKey', (this.get('exoscaleConfig.apiSecretKey') || '').trim());

        this.apiRequest('listZones').then(function (res) {
          var zones = [];
          var defaultZone = null;

          (res.listzonesresponse.zone || []).forEach(function (zone) {
            var obj = {
              id: zone.id,
              name: zone.name,
              isDefault: zone.name === _this.get('defaultZone')
            };

            zones.push(obj);
            if (zone.isDefault && !defaultZone) {
              defaultZone = obj;
            }
          });

          _this.set('step', 3);
          _this.set('allZones', zones);
          _this.set('defaultZone', defaultZone);
          _this.set('selectedZone', _this.get('exoscaleConfig.zone') || _this.get('allZones.firstObject.id'));
        }, function (err) {
          var errors = _this.get('errors') || [];
          errors.pushObject(_this.apiErrorMessage(err, 'listzonesresponse', 'While requesting zones', 'Authentication failure: please check the provided access credentials'));
          _this.set('errors', errors);
          _this.set('step', 1);
        });
      },

      /* Zone selection */
      selectZone: function selectZone() {
        var _this2 = this;

        this.set('errors', null);

        this.set('exoscaleConfig.zone', this.get('selectedZone'));
        (this.get('allZones') || []).forEach(function (zone) {
          if (zone.id === _this2.get('selectedZone')) {
            _this2.set('exoscaleConfig.zoneName', zone.name);
          }
        });

        this.set('step', 4);
        this.apiRequest('listSecurityGroups').then(function (res) {
          var groups = [];
          var defaultGroup = null;

          // Retrieve the list of security groups.
          (res.listsecuritygroupsresponse.securitygroup || []).forEach(function (group) {
            var obj = {
              id: group.id,
              name: group.name,
              description: group.description,
              isDefault: group.name === _this2.get('defaultSecurityGroupName')
            };

            groups.push(obj);
            if (obj.isDefault && !defaultGroup) {
              defaultGroup = obj;
            }
          });

          // Move to next step
          _this2.set('step', 5);
          _this2.set('allSecurityGroups', groups);
          _this2.set('defaultSecurityGroup', defaultGroup);
          _this2.set('selectedSecurityGroup', _this2.get('exoscaleConfig.securityGroup') || _this2.get('allSecurityGroups.firstObject.id'));
        }, function (err) {
          var errors = _this2.get('errors') || [];
          errors.pushObject(_this2.apiErrorMessage(err, 'listsecuritygroupsresponse', 'While requesting security groups', 'Authentication failure: please check the provided access credentials'));
          _this2.set('errors', errors);
          _this2.set('step', 3);
        });
      },

      /* Security group selection */
      selectSecurityGroup: function selectSecurityGroup() {
        var _this3 = this;

        this.set('errors', null);

        /* When selecting a custom security group, we don't have to do anything more */
        if (this.get('isCustomSecurityGroup')) {
          this.set('exoscaleConfig.securityGroup', this.get('selectedSecurityGroup'));
          this.fetchInstanceSettings();
          return;
        }

        /* Otherwise, do we need to create the default security group? */
        this.set('exoscaleConfig.securityGroup', this.get('defaultSecurityGroupName'));
        var group = this.get('defaultSecurityGroup');
        if (group) {
          /* Already exists, we assume that it contains the appropriate rules */
          this.set('exoscaleConfig.securityGroup', group.name);
          this.fetchInstanceSettings();
          return;
        }

        /* We need to create the security group */
        this.set('step', 6);
        this.apiRequest('createSecurityGroup', {
          name: this.get('defaultSecurityGroupName'),
          description: this.get('settings.appName') + ' default security group'
        }).then(function (res) {
          return async.eachSeries(RANCHER_INGRESS_RULES, function (item, cb) {
            item.securitygroupid = res.createsecuritygroupresponse.securitygroup.id;
            _this3.apiRequest('authorizeSecurityGroupIngress', item).then(function () {
              return cb();
            }, function (err) {
              return cb(err);
            });
          }, function (err) {
            if (err) {
              var errors = _this3.get('errors') || [];
              errors.pushObject(_this3.apiErrorMessage(err, 'authorizesecuritygroupingressresponse', 'While setting default security group', 'Unable to configure the default security group'));
              _this3.set('errors', errors);
              _this3.set('step', 5);
            } else {
              _this3.fetchInstanceSettings();
            }
          });
        }, function (err) {
          var errors = _this3.get('errors') || [];
          errors.pushObject(_this3.apiErrorMessage(err, 'createsecuritygroupresponse', 'While creating default security group', 'Unable to create the default security group'));
          _this3.set('errors', errors);
          _this3.set('step', 5);
        });
      }
    },

    fetchInstanceSettings: function fetchInstanceSettings() {
      var _this4 = this;

      this.set('step', 7);

      /* First, get a list of templates to get available disk sizes */
      this.apiRequest('listTemplates', {
        templatefilter: 'featured',
        zoneid: this.get('exoscaleConfig.zone')
      }).then(function (res) {
        _this4.set('allTemplates', res.listtemplatesresponse.template.filter(function (item) {
          return item.name.startsWith('Linux');
        }).map(function (item) {
          return item.name;
        }).sort().uniq());
        /* Also get the instance types */
        return _this4.apiRequest('listServiceOfferings', {
          issystem: 'false'
        }).then(function (res) {
          _this4.set('allInstanceProfiles', res.listserviceofferingsresponse.serviceoffering.sort(function (a, b) {
            if (a.memory < b.memory) {
              return -1;
            }
            if (b.memory < a.memory) {
              return 1;
            }
            return 0;
          }).map(function (item) {
            return { name: item.name, displaytext: item.displaytext };
          }));
          _this4.set('step', 8);
        }, function (err) {
          var errors = _this4.get('errors') || [];
          errors.pushObject(_this4.apiErrorMessage(err, 'listserviceofferingsresponse', 'While getting list of instance types', 'Unable to get list of instance types'));
          _this4.set('errors', errors);
          _this4.set('step', 5);
        });
      }, function (err) {
        var errors = _this4.get('errors') || [];
        errors.pushObject(_this4.apiErrorMessage(err, 'listtemplatesresponse', 'While getting list of available images', 'Unable to get list of available images'));
        _this4.set('errors', errors);
        _this4.set('step', 5);
      });
    },

    apiErrorMessage: function apiErrorMessage(err, kind, prefix, def) {
      var answer = (err.xhr || {}).responseJSON || {};
      var text = (answer[kind] || {}).errortext;
      if (text) {
        return prefix + ": " + text;
      } else {
        return def;
      }
    },

    apiRequest: function apiRequest(command, params) {
      var _this5 = this;

      var url = this.get('app.proxyEndpoint') + '/' + this.exoscaleApi;
      params = params || {};
      params.command = command;
      params.apiKey = this.get('exoscaleConfig.apiKey');
      params.response = 'json';

      return (0, _emberApiStoreUtilsAjaxPromise.ajaxPromise)({ url: url,
        method: 'POST',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'X-API-Headers-Restrict': 'Content-Length'
        },
        beforeSend: function beforeSend(xhr, settings) {
          // Append 'rancher:' to Content-Type
          xhr.setRequestHeader('Content-Type', 'rancher:' + settings.contentType);

          // Compute the signature
          var qs = settings.data.split('&').map(function (q) {
            return q.replace(/\+/g, '%20');
          }).map(Function.prototype.call, String.prototype.toLowerCase).sort().join('&');
          settings.data += '&signature=' + encodeURIComponent(AWS.util.crypto.hmac(_this5.get('exoscaleConfig.apiSecretKey'), qs, 'base64', 'sha1'));
          return true;
        },
        data: params }, true);
    }

  });
});