define('ui/service/log/route', ['exports', 'ember', 'ui/utils/filtered-sorted-array-proxy'], function (exports, _ember, _uiUtilsFilteredSortedArrayProxy) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      var par = this.modelFor('service');
      var serviceId = par.get('service.id');

      // Find just the recent ones for this service
      return this.get('store').find('serviceLog', null, {
        filter: { serviceId: serviceId },
        sortBy: 'id',
        sortOrder: 'desc',
        depaginate: false,
        limit: 100
      }).then(function () {
        var all = _this.get('store').all('serviceLog');
        return _ember['default'].Object.create({
          service: par.get('service'),
          stack: par.get('stack'),
          logs: _uiUtilsFilteredSortedArrayProxy['default'].create({
            sourceContent: all,
            sortProperties: ['createdTS:desc'],
            dependentKeys: ['sourceContent.@each.serviceId'],
            filterFn: function filterFn(log) {
              return log.get('serviceId') === serviceId;
            }
          })
        });
      });
    }
  });
});