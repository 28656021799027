define('ui/components/process-link/component', ['exports', 'ember'], function (exports, _ember) {

  var IN_APP = ['container', 'instance', 'stack', 'host', 'service'];

  exports['default'] = _ember['default'].Component.extend({
    model: null,

    tagName: '',
    endpoint: _ember['default'].inject.service(),
    growl: _ember['default'].inject.service(),

    inApp: (function () {
      return IN_APP.indexOf(this.get('model.resourceType')) >= 0;
    }).property('model.resourceType'),

    resourceLink: (function () {
      if (this.get('model').hasLink('resource')) {
        return this.get('model').linkFor('resource');
      } else {
        // Strip trailing slash off of the absoluteEndpoint
        var url = this.get('endpoint.absolute').replace(/\/+$/, '') + '/';

        // And strip leading slashes off the API endpoint
        url += this.get('app.apiEndpoint').replace(/^\/+/, '') + '/';

        url += this.get('model.resourceType') + '/' + this.get('model.resourceId');
        return url;
      }
    }).property('model.{resourceType,resourceId}', 'model.links.self'),

    actions: {
      showInApp: function showInApp() {
        var _this = this;

        this.get('userStore').find(this.get('model.resourceType'), this.get('model.resourceId')).then(function (response) {

          var type = response.type;
          var accountId = response.accountId;
          var id = response.id;
          var stackId = response.stackId;

          var url = _this.get('model').linkFor('self');

          switch (type) {
            case 'container':
            case 'instance':
              url = '/env/' + accountId + '/infra/containers/' + id;
              break;
            case 'environment':
              url = '/env/' + accountId + '/apps/' + id;
              break;
            case 'host':
              url = '/env/' + accountId + '/infra/hosts/' + id + '/containers';
              break;
            case 'service':
              url = '/env/' + accountId + '/apps/stacks/' + stackId + '/services/' + id;
              break;
          }

          window.open(url, '_blank');
        })['catch'](function (err) {
          _this.get('growl').fromError('Error getting resource', err);
        });
      }
    }
  });
});