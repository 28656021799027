define('ui/stacks/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      willTransition: function willTransition() {
        this.controller.set('showAddtlInfo', null);
      }
    },

    resetController: function resetController(controller, isExisting /*, transition*/) {
      if (isExisting) {
        controller.set('showAddtlInfo', false);
      }
    }
  });
});