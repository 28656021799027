define('ui/admin-tab/processes/index/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    sortBy: 'name',
    headers: [{
      translationKey: 'generic.name',
      name: 'name',
      sort: ['name']
    }, {
      translationKey: 'processesPage.summary.table.running',
      name: 'running',
      sort: ['running', 'name']
    }, {
      translationKey: 'processesPage.summary.table.ready',
      name: 'ready',
      sort: ['ready', 'name']
    }, {
      translationKey: 'processesPage.summary.table.delay',
      name: 'delay',
      sort: ['delay', 'name']
    }],

    total: _ember['default'].computed('model.summary.[]', function () {
      var running = 0;
      var ready = 0;
      var delay = 0;

      this.get('model.summary').forEach(function (summary) {
        running += summary.get('running') || 0;
        ready += summary.get('ready') || 0;
        delay += summary.get('delay') || 0;
      });

      return _ember['default'].Object.create({
        processName: 'Total',
        running: running,
        ready: ready,
        delay: delay
      });
    })
  });
});