define('ui/services/settings', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/parse-version'], function (exports, _ember, _uiUtilsConstants, _uiUtilsParseVersion) {
  exports.normalizeName = normalizeName;
  exports.denormalizeName = denormalizeName;

  function normalizeName(str) {
    return str.replace(/\./g, _uiUtilsConstants['default'].SETTING.DOT_CHAR).toLowerCase();
  }

  function denormalizeName(str) {
    return str.replace(new RegExp('[' + _uiUtilsConstants['default'].SETTING.DOT_CHAR + ']', 'g'), '.').toLowerCase();
  }

  exports['default'] = _ember['default'].Service.extend(_ember['default'].Evented, {
    access: _ember['default'].inject.service(),
    cookies: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    userStore: _ember['default'].inject.service('user-store'),

    all: null,
    promiseCount: 0,

    init: function init() {
      this._super();
      this.set('all', this.get('userStore').all('activesetting'));
    },

    unknownProperty: function unknownProperty(key) {
      var obj = this.findByName(key);
      if (obj) {
        var val = obj.get('value');
        if (val === 'false') {
          return false;
        } else if (val === 'true') {
          return true;
        } else {
          return val;
        }
      }

      return null;
    },

    setUnknownProperty: function setUnknownProperty(key, value) {
      var _this = this;

      var obj = this.findByName(key);

      if (value === undefined) {
        // Delete by set to undefined is not needed for settings
        throw new Error('Deleting settings is not supported');
      }

      if (!obj) {
        obj = this.get('userStore').createRecord({
          type: 'setting',
          name: denormalizeName(key)
        });
      }

      this.incrementProperty('promiseCount');

      obj.set('value', value + ''); // Values are all strings in settings.
      obj.save().then(function () {
        _this.notifyPropertyChange(normalizeName(key));
      })['catch'](function (err) {
        console.log('Error saving setting:', err);
      })['finally'](function () {
        _this.decrementProperty('promiseCount');
      });

      return value;
    },

    promiseCountObserver: (function () {

      if (this.get('promiseCount') <= 0) {
        this.trigger('settingsPromisesResolved');
      }
    }).observes('promiseCount'),

    findByName: function findByName(name) {
      return this.get('asMap')[normalizeName(name)];
    },

    loadAll: function loadAll() {
      return this.get('userStore').find('setting');
    },

    load: function load(names) {
      if (!_ember['default'].isArray(names)) {
        names = [names];
      }

      var userStore = this.get('userStore');

      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        async.eachLimit(names, 3, function (name, cb) {
          userStore.find('setting', denormalizeName(name)).then(function () {
            cb();
          })['catch'](function (err) {
            cb(err);
          });
        }, function (err) {
          if (err) {
            reject(err);
          } else {
            resolve();
          }
        });
      });

      return promise;
    },

    asMap: (function () {
      var out = {};
      (this.get('all') || []).forEach(function (setting) {
        var name = normalizeName(setting.get('name'));
        out[name] = setting;
      });

      return out;
    }).property('all.@each.{name,value}'),

    uiVersion: (function () {
      return 'v' + this.get('app.version');
    }).property('app.version'),

    issueUrl: (function () {
      var str = '*Describe your issue here*\n\n\n---\n| Useful | Info |\n| :-- | :-- |\n' + ('|Versions|Rancher `' + (this.get('rancherVersion') || '-') + '` ') + ('Cattle: `' + (this.get('cattleVersion') || '-') + '` ') + ('UI: `' + (this.get('uiVersion') || '--') + '` |\n');

      if (this.get('access.enabled')) {
        str += '|Access|`' + this.get('access.provider').replace(/config/, '') + '` ' + (this.get('access.admin') ? '\`admin\`' : '') + '|\n';
      } else {
        str += '|Access|`Disabled`|\n';
      }

      str += '|Orchestration|`' + this.get('projects.current.displayOrchestration') + '`|\n';
      str += '|Route|`' + this.get('app.currentRouteName') + '`|\n';

      var url = _uiUtilsConstants['default'].EXT_REFERENCES.GITHUB + '/issues/new?body=' + encodeURIComponent(str);
      return url;
    }).property('app.currentRouteName', 'access.{provider,admin}', 'cattleVersion', 'rancherVersion', 'uiVersion', 'projects.current.displayOrchestration'),

    rancherImage: _ember['default'].computed.alias('asMap.' + _uiUtilsConstants['default'].SETTING.IMAGE_RANCHER + '.value'),
    rancherVersion: _ember['default'].computed.alias('asMap.' + _uiUtilsConstants['default'].SETTING.VERSION_RANCHER + '.value'),
    composeVersion: _ember['default'].computed.alias('asMap.' + _uiUtilsConstants['default'].SETTING.VERSION_COMPOSE + '.value'),
    cattleVersion: _ember['default'].computed.alias('asMap.' + _uiUtilsConstants['default'].SETTING.VERSION_CATTLE + '.value'),
    cliVersion: _ember['default'].computed.alias('asMap.' + _uiUtilsConstants['default'].SETTING.VERSION_CLI + '.value'),
    dockerMachineVersion: _ember['default'].computed.alias('asMap.' + _uiUtilsConstants['default'].SETTING.VERSION_MACHINE + '.value'),
    goMachineVersion: _ember['default'].computed.alias('asMap.' + _uiUtilsConstants['default'].SETTING.VERSION_GMS + '.value'),

    _plValue: (function () {
      return this.get('cookies.' + _uiUtilsConstants['default'].COOKIE.PL) || '';
    }).property('cookies.' + _uiUtilsConstants['default'].COOKIE.PL),

    isRancher: (function () {
      return this.get('_plValue').toUpperCase() === _uiUtilsConstants['default'].COOKIE.PL_RANCHER_VALUE.toUpperCase();
    }).property('_plValue'),

    isOSS: (function () {
      return this.get('rancherImage') === 'rancher/server';
    }).property('rancherImage'),

    appName: (function () {
      if (this.get('isRancher')) {
        return this.get('app.appName'); // Rancher
      } else {
          return this.get('_plValue');
        }
    }).property('isRancher', '_plValue'),

    minDockerVersion: _ember['default'].computed.alias('asMap.' + _uiUtilsConstants['default'].SETTING.MIN_DOCKER + '.value'),

    minorVersion: (function () {
      var version = this.get('rancherVersion');
      if (!version) {
        return null;
      }

      return (0, _uiUtilsParseVersion.minorVersion)(version);
    }).property('rancherVersion'),

    docsBase: (function () {
      var full = this.get('rancherVersion');
      var version = 'latest';
      if (full) {
        version = (0, _uiUtilsParseVersion.minorVersion)(full);
      }

      var lang = ((this.get('intl._locale') || [])[0] || '').replace(/-.*$/, '');
      if (!lang || lang === 'none' || _uiUtilsConstants['default'].LANGUAGE.DOCS.indexOf(lang) === -1) {
        lang = 'en';
      }

      return _uiUtilsConstants['default'].EXT_REFERENCES.DOCS + '/' + version + '/' + lang;
    }).property('intl._locale', 'minorVersion')
  });
});