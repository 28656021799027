define('ui/components/form-name-description/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    // Inputs
    // You can either set model or name+description
    model: null,
    name: null,
    description: null,

    _name: '',
    _description: '',

    nameLabel: 'formNameDescription.name.label',
    namePlaceholder: 'formNameDescription.name.placeholder',
    nameHelpText: '',
    nameRequired: false,
    nameDisabled: false,

    descriptionLabel: 'formNameDescription.description.label',
    descriptionHelp: '',
    descriptionPlaceholder: 'formNameDescription.description.placeholder',
    descriptionRequired: false,
    descriptionDisabled: false,
    descriptionShown: true,

    init: function init() {
      this._super.apply(this, arguments);

      if (this.get('model')) {
        this.modelChanged();
      } else {
        this.setProperties({
          _name: this.get('name'),
          _description: this.get('description')
        });
      }
    },

    modelChanged: (function () {
      this.setProperties({
        _name: this.get('model.name'),
        _description: this.get('model.description')
      });
    }).observes('model'),

    nameChanged: (function () {
      var _this = this;

      _ember['default'].run.once(function () {
        var val = _this.get('_name');
        if (_this.get('model')) {
          _this.set('model.name', val);
        } else {
          _this.set('name', val);
        }
      });
    }).observes('_name'),

    descriptionChanged: (function () {
      var _this2 = this;

      _ember['default'].run.once(function () {
        var val = _this2.get('_description');
        if (_this2.get('model')) {
          _this2.set('model.description', val);
        } else {
          _this2.set('description', val);
        }
      });
    }).observes('_description'),

    didInsertElement: function didInsertElement() {
      var _this3 = this;

      _ember['default'].run.next(function () {
        if (_this3.isDestroyed || _this3.isDestroying) {
          return;
        }

        _this3.$('INPUT')[0].focus();
      });
    }
  });
});