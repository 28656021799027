define("ui/stack/index/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "ui/stack/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "stack-header", [], ["model", ["subexpr", "@mut", [["get", "model.stack", ["loc", [null, [1, 21], [1, 32]]], 0, 0, 0, 0]], [], [], 0, 0], "all", ["subexpr", "@mut", [["get", "model.all.stacks", ["loc", [null, [1, 37], [1, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "hideAddtlInfo", "dismiss"], ["loc", [null, [1, 0], [1, 79]]], 0, 0], ["inline", "stack-section", [], ["model", ["subexpr", "@mut", [["get", "model.stack", ["loc", [null, [3, 22], [3, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "single", true, "collapsed", false, "showAddtlInfo", ["subexpr", "action", ["showAddtlInfo"], [], ["loc", [null, [3, 76], [3, 100]]], 0, 0], "classNames", "r-mt20"], ["loc", [null, [3, 0], [3, 122]]], 0, 0], ["inline", "service-addtl-info", [], ["service", ["subexpr", "@mut", [["get", "selectedService", ["loc", [null, [5, 29], [5, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "show", ["subexpr", "@mut", [["get", "showAddtlInfo", ["loc", [null, [5, 50], [5, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "dismiss", "dismiss"], ["loc", [null, [5, 0], [5, 83]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});