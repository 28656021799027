define('ui/components/machine/driver-custom/component', ['exports', 'ember', 'ui/mixins/manage-labels', 'ui/utils/util', 'ui/utils/constants'], function (exports, _ember, _uiMixinsManageLabels, _uiUtilsUtil, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsManageLabels['default'], {
    settings: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    cattleAgentIp: null,
    model: null,
    subnet: null,
    routerIp: null,

    actions: {
      cancel: function cancel() {
        this.attrs.cancel();
      },

      setLabels: function setLabels(labels) {
        if (this.get('model')) {
          var out = {};
          labels.forEach(function (row) {
            out[row.key] = row.value;
          });
          var subnet = this.get('subnet');
          var routerIp = this.get('routerIp');
          if (subnet) {
            out[_uiUtilsConstants['default'].LABEL.PER_HOST_SUBNET] = subnet;
          }
          if (routerIp) {
            out[_uiUtilsConstants['default'].LABEL.PER_HOST_SUBNET_ROUTING_IP] = routerIp;
          }
          this.set('model.labels', out);
        }
      },

      subnetOnChange: function subnetOnChange() {
        if (this.get('model')) {
          var out = {};
          var labels = Object.assign({}, this.get('model.labels'));
          for (var tmp in labels) {
            out[tmp] = labels[tmp];
          }
          var subnet = this.get('subnet');
          var routerIp = this.get('routerIp');
          if (subnet) {
            out[_uiUtilsConstants['default'].LABEL.PER_HOST_SUBNET] = subnet;
          }
          if (routerIp) {
            out[_uiUtilsConstants['default'].LABEL.PER_HOST_SUBNET_ROUTING_IP] = routerIp;
          }
          this.set('model.labels', out);
        }
      }
    },

    bootstrap: (function () {
      var _this = this;

      if (this.get('clonedModel')) {
        this.set('model', this.get('clonedModel'));
      } else {
        this.get('store').find('registrationToken', null, { filter: { state: 'active' }, forceReload: true }).then(function (tokens) {
          if (tokens.get('length') === 0) {
            // There should always be one already, but if there isn't go create one...
            var model = _this.get('store').createRecord({
              type: 'registrationToken'
            });
            _this.set('model', model);
            model.save();
          } else {
            _this.set('model', tokens.get('firstObject'));
          }
        });
      }
    }).on('init'),

    registrationCommand: (function () {
      var cmd = this.get('model.command');
      var cattleIp = this.get('cattleAgentIp');
      var lookFor = 'docker run';

      if (!cmd) {
        return null;
      }

      var idx = cmd.indexOf(lookFor);
      var env = _uiUtilsUtil['default'].addQueryParams('', this.get('model.labels') || {});

      if (env) {
        lookFor = 'docker run';
        idx = cmd.indexOf(lookFor);
        env = env.substr(1); // Strip off the leading '?'
        if (idx >= 0) {
          cmd = cmd.substr(0, idx + lookFor.length) + ' -e CATTLE_HOST_LABELS=\'' + env + '\' ' + cmd.substr(idx + lookFor.length);
        }
      }

      if (cattleIp) {
        if (idx >= 0) {
          cmd = cmd.substr(0, idx + lookFor.length) + ' -e CATTLE_AGENT_IP="' + cattleIp + '" ' + cmd.substr(idx + lookFor.length);
        }
      }

      return cmd;
    }).property('model.command', 'model.labels', 'cattleAgentIp'),

    registrationCommandWindows: (function () {
      var url = this.get('model.registrationUrl');
      var cattleIp = this.get('cattleAgentIp');
      var env = _uiUtilsUtil['default'].addQueryParams('', this.get('model.labels') || {});
      var cmd1 = 'New-Item -Path \'C:\\Program Files\\rancher\' -Type Directory';
      var cmd2 = this.get('model.windowsCommand');
      cmd2 = cmd2 + (' -RegisterUrl ' + url);
      if (env) {
        env = env.substr(1);
        cmd2 = cmd2 + (' -HostLabels "' + env + '"');
      }
      if (cattleIp) {
        cmd2 = cmd2 + (' -AgentIp "' + cattleIp + '"');
      }
      cmd2 = '$obj=$(' + cmd2 + ')';
      var cmd3 = '$obj |& "C:\\Program Files\\rancher\\startup.ps1"';
      return cmd1 + "\n" + cmd2 + "\n" + cmd3;
    }).property('model.command', 'model.labels', 'cattleAgentIp')

  });
});