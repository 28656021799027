define('ui/components/form-balancer-rules/component', ['exports', 'ember', 'ui/utils/parse-port'], function (exports, _ember, _uiUtilsParsePort) {
  exports['default'] = _ember['default'].Component.extend({
    intl: _ember['default'].inject.service(),

    service: null,
    ruleType: 'portRule',
    showListeners: _ember['default'].computed.equal('ruleType', 'portRule'),

    rules: null,
    protocolChoices: null,
    showBackend: null,
    showIp: null,
    showRegion: null,
    hasRegion: null,

    onInit: (function () {
      var _this = this;

      var rules = this.get('service.lbConfig.portRules');
      if (!rules) {
        rules = [];
        this.set('service.lbConfig.portRules', rules);
      }

      rules.forEach(function (rule) {
        rule.isSelector = !!rule.selector;
      });

      this.set('rules', rules);
      if (rules.length === 0) {
        this.send('addRule');
      }

      var protos = this.get('store').getById('schema', 'portrule').optionsFor('protocol');
      protos.removeObject('udp');
      protos.sort();
      this.set('protocolChoices', protos);

      var regions = this.get('userStore').all('region');
      this.set('hasRegion', regions.get('length') > 0);

      if (this.get('showBackend') === null) {
        var hasName = !!rules.findBy('backendName');
        this.set('showBackend', hasName);
      }

      if (this.get('showIp') === null) {
        this.get('service.launchConfig.ports').forEach(function (port) {
          var parsed = (0, _uiUtilsParsePort.parsePortSpec)(port, 'tcp');
          if (parsed.hostIp) {
            _this.set('showIp', true);
          }
        });
      }

      if (this.get('showRegion') === null) {
        rules.forEach(function (rule) {
          if (rule.environment) {
            _this.set('showRegion', true);
          }
        });
      }
    }).on('init'),

    actions: {
      addRule: function addRule(isSelector) {
        var max = 0;
        var rules = this.get('rules');
        rules.forEach(function (rule) {
          max = Math.max(rule.priority, max);
        });

        rules.pushObject(this.get('store').createRecord({
          type: this.get('ruleType'),
          access: 'public',
          isSelector: isSelector,
          protocol: 'http',
          priority: max + 1
        }));
      },

      moveUp: function moveUp(rule) {
        var rules = this.get('rules');
        var idx = rules.indexOf(rule);
        if (idx <= 0) {
          return;
        }

        rules.removeAt(idx);
        rules.insertAt(idx - 1, rule);
        this.updatePriorities();
      },

      moveDown: function moveDown(rule) {
        var rules = this.get('rules');
        var idx = rules.indexOf(rule);
        if (idx < 0 || idx - 1 >= rules.get('length')) {
          return;
        }

        rules.removeAt(idx);
        rules.insertAt(idx + 1, rule);
        this.updatePriorities();
      },

      removeRule: function removeRule(rule) {
        this.get('rules').removeObject(rule);
      },

      showBackend: function showBackend() {
        this.set('showBackend', true);
      },

      showIp: function showIp() {
        this.set('showIp', true);
      },

      showRegion: function showRegion() {
        this.set('showRegion', true);
      }
    },

    updatePriorities: function updatePriorities() {
      var pri = 1;
      this.get('rules').forEach(function (rule) {
        rule.set('priority', pri);
        pri++;
      });
    },

    minPriority: (function () {
      var val = null;
      this.get('rules').forEach(function (rule) {
        var cur = rule.get('priority');
        if (val === null) {
          val = cur;
        } else {
          val = Math.min(val, cur);
        }
      });

      return val;
    }).property('rules.@each.priority'),

    maxPriority: (function () {
      var val = 0;
      this.get('rules').forEach(function (rule) {
        val = Math.max(val, rule.get('priority'));
      });

      return val;
    }).property('rules.@each.priority')
  });
});