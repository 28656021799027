define('ui/services/tooltip', ['exports', 'ember'], function (exports, _ember) {

  var DELAY = 250;

  exports['default'] = _ember['default'].Service.extend({
    mouseLeaveTimer: null,
    requireClick: false,
    tooltipOpts: null,
    openedViaContextClick: false,

    startTimer: function startTimer() {
      var _this = this;

      this.set('mouseLeaveTimer', _ember['default'].run.later(function () {
        _this.hide();
      }, DELAY));
    },

    cancelTimer: function cancelTimer() {
      _ember['default'].run.cancel(this.get('mouseLeaveTimer'));
    },

    hide: function hide() {
      this.set('tooltipOpts', null);
    },

    leave: function leave() {
      if (!this.get('requireClick')) {
        this.startTimer();
      }
    }
  });
});