define('ui/mesos-tab/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    mesos: _ember['default'].inject.service(),

    model: function model() {
      var _this = this;

      return this.get('mesos').publicUrl().then(function (url) {
        return _ember['default'].Object.create({
          url: url,
          hosts: _this.modelFor('authenticated').get('hosts')
        });
      })['catch'](function () {
        return _ember['default'].Object.create({
          url: null,
          hosts: _this.modelFor('authenticated').get('hosts'),
          ready: false
        });
      });
    }
  });
});