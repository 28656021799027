define('ui/service/containers/route', ['exports', 'ember', 'ui/utils/multi-stats'], function (exports, _ember, _uiUtilsMultiStats) {
  exports['default'] = _ember['default'].Route.extend({
    statsSocket: null,

    model: function model() {
      // Load the hosts for the instances if they're not already there
      var service = this.modelFor('service').get('service');
      return service;
    },

    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.connectStats();
    },

    connectStats: function connectStats() {
      var _this = this;

      var stats = this.get('statsSocket');
      if (stats) {
        stats.close();
      }

      stats = _uiUtilsMultiStats['default'].create({
        resource: this.modelFor('service').get('service'),
        linkName: 'containerStats'
      });

      this.set('statsSocket', stats);
      stats.on('dataPoint', function (data) {
        var controller = _this.get('controller');
        if (controller) {
          controller.onDataPoint(data);
        }
      });
    },

    deactivate: function deactivate() {
      this.get('statsSocket').close();
    }
  });
});