define('ui/components/login-github/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    github: _ember['default'].inject.service(),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.sendAction('action');
        _ember['default'].run.later(function () {
          _this.get('github').authorizeRedirect();
        }, 10);
      }
    }
  });
});