define('ui/applications-tab/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    projects: _ember['default'].inject.service(),

    model: function model() {
      var store = this.get('store');
      return _ember['default'].RSVP.all([store.findAll('stack'), store.findAll('service')]).then(function (results) {
        return _ember['default'].Object.create({
          stacks: results[0],
          services: results[1]
        });
      });
    }
  });
});