define('ui/components/form-stickiness/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    service: null,

    lbConfig: _ember['default'].computed.alias('service.lbConfig'),

    lbCookie: null,
    stickiness: 'none',
    isNone: _ember['default'].computed.equal('stickiness', 'none'),
    isCookie: _ember['default'].computed.equal('stickiness', 'cookie'),

    modeChoices: [{ value: 'rewrite', label: 'Rewrite' }, { value: 'insert', label: 'Insert' }, { value: 'prefix', label: 'Prefix' }],

    init: function init() {
      this._super.apply(this, arguments);

      var policy = this.get('lbConfig.stickinessPolicy');
      var stickiness = 'none';

      if (policy) {
        stickiness = 'cookie';
      }

      if (!policy) {
        policy = this.get('store').createRecord({
          type: 'loadBalancerCookieStickinessPolicy'
        });
      }

      this.setProperties({
        policy: policy,
        stickiness: stickiness
      });
    },

    stickinessDidChange: (function () {
      var stickiness = this.get('stickiness');
      if (!this.get('lbConfig.canSticky') || stickiness === 'none') {
        this.set('lbConfig.stickinessPolicy', null);
      } else if (stickiness === 'cookie') {
        this.set('lbConfig.stickinessPolicy', this.get('policy'));
      }
    }).observes('stickiness', 'lbConfig.canSticky')
  });
});