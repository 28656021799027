define('ui/components/new-externalservice/component', ['exports', 'ember', 'ui/mixins/new-or-edit'], function (exports, _ember, _uiMixinsNewOrEdit) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], {
    service: null,

    primaryResource: _ember['default'].computed.alias('service'),

    actions: {
      done: function done() {
        this.sendAction('done');
      },

      cancel: function cancel() {
        this.sendAction('cancel');
      }
    },

    didInsertElement: function didInsertElement() {
      this.$('INPUT')[0].focus();
    },

    validate: function validate() {
      var errors = [];
      if (!this.get('service.externalIpAddresses.length') && !this.get('service.hostname')) {
        errors.push('Choose one or more targets to send traffic to');
      } else {
        this._super();
        errors = this.get('errors') || [];
      }

      if (errors.length) {
        this.set('errors', errors.uniq());
        return false;
      }

      return true;
    },

    willSave: function willSave() {
      this.set('service.launchConfig', {});
      return this._super.apply(this, arguments);
    },

    doneSaving: function doneSaving() {
      this.send('done');
    }
  });
});