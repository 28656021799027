define('ui/initializers/session', ['exports', 'ember-api-store/mixins/serializable'], function (exports, _emberApiStoreMixinsSerializable) {
  exports.initialize = initialize;

  // Don't serialize the injected session
  _emberApiStoreMixinsSerializable['default'].reopen({
    reservedKeys: ['session']
  });

  function initialize(application) {
    application.inject('controller', 'session', 'service:session');
    application.inject('route', 'session', 'service:session');
    application.inject('model', 'session', 'service:session');
    application.inject('component', 'session', 'service:session');
    application.inject('controller', 'tab-session', 'service:tab-session');
    application.inject('route', 'tab-session', 'service:tab-session');
    application.inject('model', 'tab-session', 'service:tab-session');
    application.inject('component', 'tab-session', 'service:tab-session');
  }

  exports['default'] = {
    name: 'session',
    initialize: initialize
  };
});