define('ui/stack/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params) {
      var store = this.get('store');
      var all = this.modelFor('stacks');
      return store.find('stack', params.stack_id).then(function (stack) {
        return _ember['default'].Object.create({
          stack: stack,
          all: all
        });
      });
    }
  });
});