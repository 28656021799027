define('ui/storagepools/new-volume/controller', ['exports', 'ember', 'ui/mixins/new-or-edit'], function (exports, _ember, _uiMixinsNewOrEdit) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsNewOrEdit['default'], {
    intl: _ember['default'].inject.service(),

    queryParams: ['driverName'],
    driverName: null,

    primaryResource: _ember['default'].computed.alias('model.volume'),

    validate: function validate() {
      var errors = [];

      if (this.get('primaryResource.name').match(/[^a-z0-9._@-]/i)) {
        errors.push(this.get('intl').t('formVolumes.errors.invalidName'));
      }

      this.set('errors', errors);
      return errors.length === 0;
    },

    doneSaving: function doneSaving() {
      this.transitionToRoute('storagepools');
    },

    actions: {
      cancel: function cancel() {
        this.send('goToPrevious');
      }
    }
  });
});