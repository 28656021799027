define('ui/components/process-execution-rows/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: '',
    expanded: false,
    expandAll: false,
    expandSelf: false,
    depth: 0,
    modalService: _ember['default'].inject.service('modal'),

    actions: {
      expand: function expand() {
        this.toggleProperty('expanded');
      },
      showError: function showError(model) {
        this.get('modalService').toggleModal('modal-process-error', model);
      }
    },

    setup: _ember['default'].on('init', function () {

      if (this.get('nodeDepth')) {
        this.set('depth', this.incrementProperty('nodeDepth'));
      } else {
        this.set('depth', 1);
      }
    }),

    checkProcessHandlerExecutions: (function () {
      if (this.get('execution').hasOwnProperty('processHandlerExecutions') && this.get('execution').processHandlerExecutions.length > 0) {
        return true;
      } else {
        return false;
      }
    }).property(),

    expandChildren: (function () {
      if (this.get('expandAll')) {
        this.set('expanded', true);
      } else {
        this.set('expanded', false);
      }
    }).observes('expandAll').on('init')
  });
});