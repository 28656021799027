define('ui/components/select-tab/component', ['exports', 'ember', 'ui/mixins/select-tab'], function (exports, _ember, _uiMixinsSelectTab) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsSelectTab['default'], {
    tagName: 'section',
    initialTab: '',
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);
      _ember['default'].run.scheduleOnce('afterRender', function () {
        _this.send('selectTab', _this.get('initialTab'));
      });
    }
  });
});