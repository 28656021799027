define('ui/models/identity', ['exports', 'ember', 'ember-api-store/models/resource', 'ui/utils/constants'], function (exports, _ember, _emberApiStoreModelsResource, _uiUtilsConstants) {

  var Identity = _emberApiStoreModelsResource['default'].extend({
    intl: _ember['default'].inject.service(),

    isUser: _ember['default'].computed.equal('externalIdType', _uiUtilsConstants['default'].PROJECT.TYPE_USER),
    isTeam: _ember['default'].computed.equal('externalIdType', _uiUtilsConstants['default'].PROJECT.TYPE_TEAM),
    isOrg: _ember['default'].computed.equal('externalIdType', _uiUtilsConstants['default'].PROJECT.TYPE_ORG),

    avatarSrc: (function () {
      if (this.get('isGithub') && this.get('profilePicture')) {
        return this.get('profilePicture');
      } else {
        return 'data:image/png;base64,' + new Identicon(md5(this.get('externalId') || 'Unknown'), 80, 0.01).toString();
      }
    }).property('isGithub', 'externalId', 'profilePicture'),

    isGithub: (function () {
      return [_uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_ORG, _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_TEAM, _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_USER].indexOf(this.get('externalIdType')) >= 0;
    }).property('externalIdType'),

    isMyRancher: (function () {
      return this.get('externalIdType') === _uiUtilsConstants['default'].PROJECT.TYPE_RANCHER && this.get('externalId') === this.get('session').get(_uiUtilsConstants['default'].SESSION.ACCOUNT_ID);
    }).property('{externalId,externalIdType}'),

    logicalType: (function () {
      switch (this.get('externalIdType')) {
        case _uiUtilsConstants['default'].PROJECT.TYPE_RANCHER:
        case _uiUtilsConstants['default'].PROJECT.TYPE_AZURE_USER:
        case _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_USER:
        case _uiUtilsConstants['default'].PROJECT.TYPE_LDAP_USER:
        case _uiUtilsConstants['default'].PROJECT.TYPE_OPENLDAP_USER:
        case _uiUtilsConstants['default'].PROJECT.TYPE_SHIBBOLETH_USER:
          return _uiUtilsConstants['default'].PROJECT.PERSON;

        case _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_TEAM:
          return _uiUtilsConstants['default'].PROJECT.TEAM;

        case _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_ORG:
        case _uiUtilsConstants['default'].PROJECT.TYPE_AZURE_GROUP:
        case _uiUtilsConstants['default'].PROJECT.TYPE_LDAP_GROUP:
        case _uiUtilsConstants['default'].PROJECT.TYPE_OPENLDAP_GROUP:
        case _uiUtilsConstants['default'].PROJECT.TYPE_SHIBBOLETH_GROUP:
          return _uiUtilsConstants['default'].PROJECT.ORG;
      }
    }).property('externalIdType'),

    logicalTypeSort: (function () {
      switch (this.get('logicalType')) {
        case _uiUtilsConstants['default'].PROJECT.ORG:
          return 1;
        case _uiUtilsConstants['default'].PROJECT.TEAM:
          return 2;
        case _uiUtilsConstants['default'].PROJECT.PERSON:
          return 3;
        default:
          return 4;
      }
    }).property('logicalType'),

    displayType: (function () {
      var key = 'model.identity.displayType.unknown';
      var type = this.get('externalIdType');
      switch (type) {
        case _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_USER:
        case _uiUtilsConstants['default'].PROJECT.TYPE_AZURE_USER:
        case _uiUtilsConstants['default'].PROJECT.TYPE_LDAP_USER:
        case _uiUtilsConstants['default'].PROJECT.TYPE_OPENLDAP_USER:
        case _uiUtilsConstants['default'].PROJECT.TYPE_SHIBBOLETH_USER:
          key = 'model.identity.displayType.user';
          break;
        case _uiUtilsConstants['default'].PROJECT.TYPE_AZURE_GROUP:
        case _uiUtilsConstants['default'].PROJECT.TYPE_LDAP_GROUP:
        case _uiUtilsConstants['default'].PROJECT.TYPE_OPENLDAP_GROUP:
        case _uiUtilsConstants['default'].PROJECT.TYPE_SHIBBOLETH_GROUP:
          key = 'model.identity.displayType.group';
          break;
        case _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_TEAM:
          key = 'model.identity.displayType.team';
          break;
        case _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_ORG:
          key = 'model.identity.displayType.org';
          break;
        case _uiUtilsConstants['default'].PROJECT.TYPE_RANCHER:
          key = 'model.identity.displayType.localUser';
          break;
      }

      return this.get('intl').t(key, { type: type });
    }).property('externalIdType', 'intl._locale')
  });

  exports['default'] = Identity;
});