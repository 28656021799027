define('ui/components/tooltip-element/component', ['exports', 'ember'], function (exports, _ember) {

  var DELAY = 100;

  exports['default'] = _ember['default'].Component.extend({
    classNameBindings: ['inlineBlock:inline-block', 'clip:clip'],
    tooltipService: _ember['default'].inject.service('tooltip'),
    inlineBlock: true,
    clip: false,
    model: null,
    size: 'default',
    ariaRole: ['tooltip'],
    textChangedEvent: null,
    tooltipFor: null,

    showTimer: null,

    textChanged: _ember['default'].observer('textChangedEvent', function () {
      this.show(this.get('textChangedEvent'));
    }),

    mouseEnter: function mouseEnter(evt) {
      var _this = this;

      if (!this.get('tooltipService.requireClick')) {
        (function () {
          var tgt = _ember['default'].$(evt.currentTarget);

          if (_this.get('tooltipService.tooltipOpts')) {
            _this.set('tooltipService.tooltipOpts', null);
          }

          // Wait for a little bit of time so that the mouse can pass through
          // another tooltip-element on the way to the dropdown trigger of a
          // tooltip-action-menu without changing the tooltip.
          _this.set('showTimer', _ember['default'].run.later(function () {
            _this.show(tgt);
          }, DELAY));
        })();
      }
    },

    show: function show(node) {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }

      var svc = this.get('tooltipService');

      this.set('showTimer', null);
      svc.cancelTimer();

      var out = {
        type: this.get('type'),
        baseClass: this.get('baseClass'),
        eventPosition: node.offset(),
        originalNode: node,
        model: this.get('model'),
        template: this.get('tooltipTemplate'),
        tooltipFor: this.get('tooltipFor')
      };

      if (this.get('isCopyTo')) {
        out.isCopyTo = true;
      }

      svc.set('tooltipOpts', out);
    },

    mouseLeave: function mouseLeave() {
      if (!this.get('tooltipService.openedViaContextClick')) {
        if (this.get('showTimer')) {
          _ember['default'].run.cancel(this.get('showTimer'));
        } else {
          this.get('tooltipService').leave();
        }
      }
    },

    modelObserver: _ember['default'].observer('model', 'textChangedEvent', function () {
      var opts = this.get('tooltipService.tooltipOpts');
      if (opts && this.get('tooltipFor') === opts.tooltipFor) {
        this.set('tooltipService.tooltipOpts.model', this.get('model'));
      }
    })
  });
});