define('ui/components/form-target-ip/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    actions: {
      addTargetIp: function addTargetIp() {
        this.get('targetIpArray').pushObject({ value: null });
      },
      removeTargetIp: function removeTargetIp(obj) {
        this.get('targetIpArray').removeObject(obj);
      }
    },

    which: null,
    userHostname: null,
    targetIpArray: null,

    init: function init() {
      this._super.apply(this, arguments);

      var hostname = this.get('service.hostname');
      if (hostname) {
        this.set('userHostname', hostname);
        this.set('which', 'hostname');
        this.set('targetIpArray', []);
      } else {
        var ips = this.get('service.externalIpAddresses');
        var out = [];
        if (ips) {
          ips.forEach(function (ip) {
            out.push({ value: ip });
          });
        } else {
          out.push({ value: null });
        }

        this.set('targetIpArray', out);
        this.set('which', 'ip');
      }
    },

    valuesDidChange: (function () {
      if (this.get('which') === 'hostname') {
        this.setProperties({
          'service.hostname': this.get('userHostname'),
          'service.externalIpAddresses': null
        });
      } else {
        var targets = this.get('targetIpArray');
        if (targets) {
          var out = targets.filterBy('value').map(function (choice) {
            return _ember['default'].get(choice, 'value');
          }).uniq();

          this.setProperties({
            'service.hostname': null,
            'service.externalIpAddresses': out
          });
        }
      }
    }).observes('targetIpArray.@each.{value}', 'userHostname', 'which')
  });
});