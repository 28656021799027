define("ui/components/edit-projecttemplate/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 4
            },
            "end": {
              "line": 5,
              "column": 4
            }
          },
          "moduleName": "ui/components/edit-projecttemplate/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["editProjectTemplate.title.edit"], ["displayName", ["subexpr", "@mut", [["get", "originalProjectTemplate.name", ["loc", [null, [4, 55], [4, 83]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 6], [4, 85]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 4
            },
            "end": {
              "line": 7,
              "column": 4
            }
          },
          "moduleName": "ui/components/edit-projecttemplate/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "t", ["editProjectTemplate.title.add"], [], ["loc", [null, [6, 6], [6, 43]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 2
            },
            "end": {
              "line": 34,
              "column": 2
            }
          },
          "moduleName": "ui/components/edit-projecttemplate/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-xs-12");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("label");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "row");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-6");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "radio");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "col-md-6");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "radio");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("label");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element9 = dom.childAt(fragment, [3]);
          var element10 = dom.childAt(element9, [1, 1, 1]);
          var element11 = dom.childAt(element9, [3, 1, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
          morphs[1] = dom.createMorphAt(element10, 0, 0);
          morphs[2] = dom.createMorphAt(element10, 2, 2);
          morphs[3] = dom.createMorphAt(element11, 0, 0);
          morphs[4] = dom.createMorphAt(element11, 2, 2);
          return morphs;
        },
        statements: [["inline", "t", ["editProjectTemplate.access.label"], [], ["loc", [null, [19, 15], [19, 55]]], 0, 0], ["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "projectTemplate.isPublic", ["loc", [null, [25, 42], [25, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "value", false], ["loc", [null, [25, 17], [25, 80]]], 0, 0], ["inline", "t", ["editProjectTemplate.access.private"], [], ["loc", [null, [25, 81], [25, 123]]], 0, 0], ["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "projectTemplate.isPublic", ["loc", [null, [30, 42], [30, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "value", true], ["loc", [null, [30, 17], [30, 79]]], 0, 0], ["inline", "t", ["editProjectTemplate.access.public"], [], ["loc", [null, [30, 80], [30, 121]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 46,
                "column": 12
              },
              "end": {
                "line": 46,
                "column": 96
              }
            },
            "moduleName": "ui/components/edit-projecttemplate/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "small clip r-pt5");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 0, 0);
            return morphs;
          },
          statements: [["content", "driver.source", ["loc", [null, [46, 73], [46, 90]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 8
            },
            "end": {
              "line": 48,
              "column": 8
            }
          },
          "moduleName": "ui/components/edit-projecttemplate/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("          ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("a");
          dom.setAttribute(el1, "href", "#");
          dom.setAttribute(el1, "tabindex", "1");
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "small clip r-pt5");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element7 = dom.childAt(fragment, [1]);
          var element8 = dom.childAt(element7, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element7, 'title');
          morphs[1] = dom.createAttrMorph(element7, 'class');
          morphs[2] = dom.createElementMorph(element7);
          morphs[3] = dom.createAttrMorph(element8, 'src');
          morphs[4] = dom.createMorphAt(dom.childAt(element7, [3]), 0, 0);
          morphs[5] = dom.createMorphAt(element7, 5, 5);
          return morphs;
        },
        statements: [["attribute", "title", ["concat", [["get", "driver.title", ["loc", [null, [43, 89], [43, 101]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["nav-box-item driver orchestration-driver r-p5 ", ["subexpr", "if", [["get", "driver.active", ["loc", [null, [43, 163], [43, 176]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [43, 158], [43, 187]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["selectOrchestration", ["get", "driver.name", ["loc", [null, [43, 66], [43, 77]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 35], [43, 79]]], 0, 0], ["attribute", "src", ["concat", [["get", "driver.image", ["loc", [null, [44, 24], [44, 36]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "uc-first", [["get", "driver.title", ["loc", [null, [45, 53], [45, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [45, 42], [45, 67]]], 0, 0], ["block", "if", [["get", "showOrchestrationOrigin", ["loc", [null, [46, 18], [46, 41]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [46, 12], [46, 103]]]]],
        locals: ["driver"],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 50,
              "column": 6
            },
            "end": {
              "line": 54,
              "column": 6
            }
          },
          "moduleName": "ui/components/edit-projecttemplate/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "text-center");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("button");
          dom.setAttribute(el2, "type", "button");
          dom.setAttribute(el2, "class", "btn btn-default");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element6 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element6);
          morphs[1] = dom.createMorphAt(element6, 0, 0);
          return morphs;
        },
        statements: [["element", "action", ["editOrchestration"], [], ["loc", [null, [52, 56], [52, 86]]], 0, 0], ["inline", "t", ["editProjectTemplate.stackEdit"], [], ["loc", [null, [52, 87], [52, 124]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 68,
                        "column": 14
                      },
                      "end": {
                        "line": 71,
                        "column": 14
                      }
                    },
                    "moduleName": "ui/components/edit-projecttemplate/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1, "type", "button");
                    dom.setAttribute(el1, "class", "btn btn-primary");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("button");
                    dom.setAttribute(el1, "type", "button");
                    dom.setAttribute(el1, "class", "btn btn-default btn-disabled");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element3 = dom.childAt(fragment, [1]);
                    var element4 = dom.childAt(fragment, [3]);
                    var morphs = new Array(4);
                    morphs[0] = dom.createElementMorph(element3);
                    morphs[1] = dom.createMorphAt(element3, 0, 0);
                    morphs[2] = dom.createElementMorph(element4);
                    morphs[3] = dom.createMorphAt(element4, 0, 0);
                    return morphs;
                  },
                  statements: [["element", "action", ["enableStack", ["get", "obj", ["loc", [null, [69, 85], [69, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [69, 62], [69, 90]]], 0, 0], ["inline", "t", ["editProjectTemplate.stackEdit"], [], ["loc", [null, [69, 91], [69, 128]]], 0, 0], ["element", "action", ["disableStack", ["get", "obj", ["loc", [null, [70, 99], [70, 102]]], 0, 0, 0, 0], true], ["allowedKeys", "meta ctrl"], ["loc", [null, [70, 75], [70, 133]]], 0, 0], ["inline", "t", ["editProjectTemplate.stackRequired"], [], ["loc", [null, [70, 134], [70, 175]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              var child1 = (function () {
                var child0 = (function () {
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 71,
                          "column": 14
                        },
                        "end": {
                          "line": 74,
                          "column": 14
                        }
                      },
                      "moduleName": "ui/components/edit-projecttemplate/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("button");
                      dom.setAttribute(el1, "type", "button");
                      dom.setAttribute(el1, "class", "btn btn-primary");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("button");
                      dom.setAttribute(el1, "type", "button");
                      dom.setAttribute(el1, "class", "btn btn-danger");
                      var el2 = dom.createComment("");
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var element1 = dom.childAt(fragment, [1]);
                      var element2 = dom.childAt(fragment, [3]);
                      var morphs = new Array(4);
                      morphs[0] = dom.createElementMorph(element1);
                      morphs[1] = dom.createMorphAt(element1, 0, 0);
                      morphs[2] = dom.createElementMorph(element2);
                      morphs[3] = dom.createMorphAt(element2, 0, 0);
                      return morphs;
                    },
                    statements: [["element", "action", ["enableStack", ["get", "obj", ["loc", [null, [72, 85], [72, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [72, 62], [72, 90]]], 0, 0], ["inline", "t", ["editProjectTemplate.stackEdit"], [], ["loc", [null, [72, 91], [72, 128]]], 0, 0], ["element", "action", ["disableStack", ["get", "obj", ["loc", [null, [73, 85], [73, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [73, 61], [73, 90]]], 0, 0], ["inline", "t", ["generic.disable"], [], ["loc", [null, [73, 91], [73, 114]]], 0, 0]],
                    locals: [],
                    templates: []
                  };
                })();
                var child1 = (function () {
                  var child0 = (function () {
                    return {
                      meta: {
                        "revision": "Ember@2.9.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 74,
                            "column": 14
                          },
                          "end": {
                            "line": 76,
                            "column": 14
                          }
                        },
                        "moduleName": "ui/components/edit-projecttemplate/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("button");
                        dom.setAttribute(el1, "type", "button");
                        dom.setAttribute(el1, "class", "btn btn-primary");
                        var el2 = dom.createComment("");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n              ");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element0 = dom.childAt(fragment, [1]);
                        var morphs = new Array(2);
                        morphs[0] = dom.createElementMorph(element0);
                        morphs[1] = dom.createMorphAt(element0, 0, 0);
                        return morphs;
                      },
                      statements: [["element", "action", ["enableStack", ["get", "obj", ["loc", [null, [75, 85], [75, 88]]], 0, 0, 0, 0]], [], ["loc", [null, [75, 62], [75, 90]]], 0, 0], ["inline", "t", ["generic.enable"], [], ["loc", [null, [75, 91], [75, 113]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  })();
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 74,
                          "column": 14
                        },
                        "end": {
                          "line": 76,
                          "column": 14
                        }
                      },
                      "moduleName": "ui/components/edit-projecttemplate/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["block", "if", [["get", "obj.supported", ["loc", [null, [74, 24], [74, 37]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [74, 14], [76, 14]]]]],
                    locals: [],
                    templates: [child0]
                  };
                })();
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 71,
                        "column": 14
                      },
                      "end": {
                        "line": 76,
                        "column": 14
                      }
                    },
                    "moduleName": "ui/components/edit-projecttemplate/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "if", [["get", "obj.enabled", ["loc", [null, [71, 24], [71, 35]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [71, 14], [76, 14]]]]],
                  locals: [],
                  templates: [child0, child1]
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 67,
                      "column": 12
                    },
                    "end": {
                      "line": 77,
                      "column": 12
                    }
                  },
                  "moduleName": "ui/components/edit-projecttemplate/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "and", [["get", "obj.required", ["loc", [null, [68, 25], [68, 37]]], 0, 0, 0, 0], ["get", "obj.enabled", ["loc", [null, [68, 38], [68, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [68, 20], [68, 50]]], 0, 0]], [], 0, 1, ["loc", [null, [68, 14], [76, 21]]]]],
                locals: [],
                templates: [child0, child1]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 66,
                    "column": 10
                  },
                  "end": {
                    "line": 78,
                    "column": 10
                  }
                },
                "moduleName": "ui/components/edit-projecttemplate/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "eq", [["get", "section", ["loc", [null, [67, 22], [67, 29]]], 0, 0, 0, 0], "footer"], [], ["loc", [null, [67, 18], [67, 39]]], 0, 0]], [], 0, null, ["loc", [null, [67, 12], [77, 19]]]]],
              locals: ["section"],
              templates: [child0]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 65,
                  "column": 8
                },
                "end": {
                  "line": 79,
                  "column": 8
                }
              },
              "moduleName": "ui/components/edit-projecttemplate/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "catalog-box", [], ["model", ["subexpr", "@mut", [["get", "obj.tpl", ["loc", [null, [66, 31], [66, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "active", ["subexpr", "@mut", [["get", "obj.enabled", ["loc", [null, [66, 46], [66, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "showSource", true], 0, null, ["loc", [null, [66, 10], [78, 26]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 64,
                "column": 6
              },
              "end": {
                "line": 80,
                "column": 6
              }
            },
            "moduleName": "ui/components/edit-projecttemplate/template.hbs"
          },
          isEmpty: false,
          arity: 2,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "and", [["get", "obj.supported", ["loc", [null, [65, 19], [65, 32]]], 0, 0, 0, 0], ["subexpr", "array-includes", [["get", "obj.tpl.categoryArray", ["loc", [null, [65, 49], [65, 70]]], 0, 0, 0, 0], ["get", "category", ["loc", [null, [65, 71], [65, 79]]], 0, 0, 0, 0]], [], ["loc", [null, [65, 33], [65, 80]]], 0, 0]], [], ["loc", [null, [65, 14], [65, 81]]], 0, 0]], [], 0, null, ["loc", [null, [65, 8], [79, 15]]]]],
          locals: ["id", "obj"],
          templates: [child0]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 59,
              "column": 0
            },
            "end": {
              "line": 83,
              "column": 0
            }
          },
          "moduleName": "ui/components/edit-projecttemplate/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h4");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("hr");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "section container-fluid container-catalog clearfix");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element5 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(element5, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element5, [5]), 1, 1);
          return morphs;
        },
        statements: [["content", "category", ["loc", [null, [61, 8], [61, 20]]], 0, 0, 0, 0], ["block", "each-in", [["get", "stacksMap", ["loc", [null, [64, 17], [64, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [64, 6], [80, 18]]]]],
        locals: ["category"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 89,
            "column": 0
          }
        },
        "moduleName": "ui/components/edit-projecttemplate/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "well r-mb0");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "well r-mb0");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "row");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3, "class", "col-xs-12");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("label");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "nav nav-boxes checked-active");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("      ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element12 = dom.childAt(fragment, [2]);
        var element13 = dom.childAt(fragment, [4, 1, 1]);
        var morphs = new Array(9);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(element12, 1, 1);
        morphs[2] = dom.createMorphAt(element12, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element13, [1]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(element13, [3]), 1, 1);
        morphs[5] = dom.createMorphAt(element13, 5, 5);
        morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[7] = dom.createMorphAt(fragment, 10, 10, contextualElement);
        morphs[8] = dom.createMorphAt(fragment, 12, 12, contextualElement);
        return morphs;
      },
      statements: [["block", "if", [["get", "editing", ["loc", [null, [3, 10], [3, 17]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [3, 4], [7, 11]]]], ["inline", "form-name-description", [], ["model", ["subexpr", "@mut", [["get", "projectTemplate", ["loc", [null, [13, 10], [13, 25]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [12, 2], [14, 4]]], 0, 0], ["block", "if", [["get", "access.admin", ["loc", [null, [16, 8], [16, 20]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [16, 2], [34, 9]]]], ["inline", "t", ["editProjectTemplate.orchestration"], [], ["loc", [null, [40, 13], [40, 54]]], 0, 0], ["block", "each", [["get", "orchestrationChoices", ["loc", [null, [42, 16], [42, 36]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [42, 8], [48, 17]]]], ["block", "if", [["subexpr", "not-eq", [["get", "activeOrchestration", ["loc", [null, [50, 20], [50, 39]]], 0, 0, 0, 0], "cattle"], [], ["loc", [null, [50, 12], [50, 49]]], 0, 0]], [], 4, null, ["loc", [null, [50, 6], [54, 13]]]], ["block", "each", [["get", "categories", ["loc", [null, [59, 8], [59, 18]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [59, 0], [83, 9]]]], ["inline", "top-errors", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [87, 20], [87, 26]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [87, 0], [87, 28]]], 0, 0], ["inline", "save-cancel", [], ["editing", ["subexpr", "@mut", [["get", "editing", ["loc", [null, [88, 22], [88, 29]]], 0, 0, 0, 0]], [], [], 0, 0], "save", "save", "cancel", "cancel"], ["loc", [null, [88, 0], [88, 59]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5]
    };
  })());
});