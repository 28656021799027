define('ui/admin-tab/machine/controller', ['exports', 'ember', 'ui/mixins/sortable', 'ui/utils/constants', 'ui/utils/parse-externalid'], function (exports, _ember, _uiMixinsSortable, _uiUtilsConstants, _uiUtilsParseExternalid) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], {
    application: _ember['default'].inject.controller(),
    growl: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    sortBy: 'name',
    sorts: { name: ['name'] },
    modalService: _ember['default'].inject.service('modal'),

    actions: {
      activate: function activate(driver) {
        var action = null;
        if (driver.hasAction('activate')) {
          action = 'activate';
        } else if (driver.get('actionLinks.reactivate')) {
          action = 'reactivate';
        }

        driver.doAction(action);
      },

      addNewDriver: function addNewDriver() {
        var newDriver = this.get('userStore').createRecord({
          type: 'machineDriver',
          name: null,
          description: null,
          checksum: null,
          url: null,
          activateOnCreate: true
        });

        this.get('modalService').toggleModal('modal-edit-driver', newDriver);
      },

      addCatalogDriver: function addCatalogDriver(driver) {
        var _this = this;

        this.get('store').request({ url: this.get('app.catalogEndpoint') + '/templates/' + driver.id }).then(function (template) {

          _this.get('store').request({ url: template.versionLinks[template.defaultVersion] }).then(function (driver) {

            var newDriver = _this.createNewDriver(driver);

            _this.get('userStore').createRecord(newDriver).save()['catch'](function (err) {
              _this.get('growl').fromError(err);
            });
          });
        });
      },

      upgradeDriver: function upgradeDriver(driver, version, url) {
        var _this2 = this;

        this.get('store').request({ url: url }).then(function (template) {
          driver.setProperties(_this2.createNewDriver(template));
          driver.save()['catch'](function (err) {
            _this2.get('growl').fromError(err);
          });
        });
      }
    },

    createNewDriver: function createNewDriver(driver) {
      return {
        type: 'machineDriver',
        description: driver.description || null,
        checksum: (driver.files.checksum || '').trim() || null,
        uiUrl: (driver.files['ui-url'] || driver.files.uiUrl || '').trim() || null,
        url: (driver.files.url || '').trim() || null,
        externalId: _uiUtilsConstants['default'].EXTERNAL_ID.KIND_CATALOG + _uiUtilsConstants['default'].EXTERNAL_ID.KIND_SEPARATOR + driver.id,
        activateOnCreate: true
      };
    },

    sortableContent: _ember['default'].computed('model.drivers.@each.{state,id,version,externalId}', 'model.catalogDrivers.@each.{id,catalogId,name}', function () {
      // possibly add some search here
      var cDrivers = this.get('model.catalogDrivers.catalog');
      var drivers = this.get('model.drivers.content');
      var newContent = [];

      cDrivers.forEach(function (cDriver) {
        var driverExistsInDrivers = drivers.find(function (driver) {

          if (driver.externalId && _uiUtilsConstants['default'].REMOVEDISH_STATES.indexOf(driver.get('state')) === -1) {
            var parsed = (0, _uiUtilsParseExternalid.parseExternalId)(driver.externalId);
            if (cDriver.id === parsed.templateId) {
              return true;
            }
          }

          return false;
        });

        if (!driverExistsInDrivers) {
          //not in drivers
          newContent.push(cDriver);
        }
      });

      newContent = newContent.concat(drivers);
      return newContent;
    })
  });
});