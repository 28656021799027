define('ui/models/backup', ['exports', 'ember-api-store/models/resource', 'ember-api-store/utils/denormalize'], function (exports, _emberApiStoreModelsResource, _emberApiStoreUtilsDenormalize) {

  var Backup = _emberApiStoreModelsResource['default'].extend({
    type: 'backup',

    volume: (0, _emberApiStoreUtilsDenormalize.denormalizeId)('volumeId'),

    actions: {
      restoreFromBackup: function restoreFromBackup() {
        this.get('volume').doAction('restorefrombackup', {
          backupId: this.get('id')
        });
      }
    },

    availableActions: (function () {
      var a = this.get('actionLinks');
      var volA = this.get('volume.actionLinks');

      var created = this.get('state') === 'created';

      return [{ label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: !!a.remove, altAction: 'delete' }, { label: 'action.restoreFromBackup', icon: 'icon icon-history', action: 'restoreFromBackup', enabled: created && volA && !!volA.restorefrombackup }, { divider: true }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }];
    }).property('actionLinks.remove', 'volume.actionLinks.restorefrombackup', 'state', 'volume.state')

  });

  exports['default'] = Backup;
});