define("ui/components/new-externalservice/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 0
          }
        },
        "moduleName": "ui/components/new-externalservice/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode(" ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "horizontal-form container-fluid well");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "horizontal-form container-fluid well");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "horizontal-form container-fluid well");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "alert alert-info");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0, 1]);
        var element1 = dom.childAt(fragment, [6]);
        var morphs = new Array(8);
        morphs[0] = dom.createMorphAt(element0, 0, 0);
        morphs[1] = dom.createMorphAt(element0, 2, 2);
        morphs[2] = dom.createMorphAt(dom.childAt(fragment, [2]), 1, 1);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
        morphs[4] = dom.createMorphAt(element1, 1, 1);
        morphs[5] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
        morphs[6] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        morphs[7] = dom.createMorphAt(fragment, 10, 10, contextualElement);
        return morphs;
      },
      statements: [["inline", "t", ["newExternalService.header"], [], ["loc", [null, [2, 6], [2, 39]]], 0, 0], ["inline", "help-btn", [], ["link", "/cattle/adding-external-services/"], ["loc", [null, [2, 40], [2, 93]]], 0, 0], ["inline", "form-name-description", [], ["model", ["subexpr", "@mut", [["get", "service", ["loc", [null, [7, 10], [7, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "namePlaceholder", "editExternalService.name.placeholder", "descriptionPlaceholder", "editExternalService.description.placeholder"], ["loc", [null, [6, 2], [10, 4]]], 0, 0], ["inline", "form-target-ip", [], ["service", ["subexpr", "@mut", [["get", "service", ["loc", [null, [15, 12], [15, 19]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [14, 2], [16, 4]]], 0, 0], ["inline", "form-healthcheck", [], ["isService", true, "showStrategy", false, "healthCheck", ["subexpr", "@mut", [["get", "service.healthCheck", ["loc", [null, [23, 16], [23, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [20, 2], [24, 4]]], 0, 0], ["inline", "t", ["newExternalService.alert"], [], ["loc", [null, [25, 32], [25, 64]]], 0, 0], ["inline", "top-errors", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [28, 20], [28, 26]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [28, 0], [28, 28]]], 0, 0], ["inline", "save-cancel", [], ["save", "save", "cancel", "cancel"], ["loc", [null, [29, 0], [29, 43]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});