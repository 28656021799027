define('ui/mixins/store-tweaks', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _ember['default'].Mixin.create({
    cookies: _ember['default'].inject.service(),

    defaultPageSize: -1,
    removeAfterDelete: false,

    headers: (function () {
      var _out;

      var out = (_out = {}, _defineProperty(_out, _uiUtilsConstants['default'].HEADER.ACTIONS, _uiUtilsConstants['default'].HEADER.ACTIONS_VALUE), _defineProperty(_out, _uiUtilsConstants['default'].HEADER.NO_CHALLENGE, _uiUtilsConstants['default'].HEADER.NO_CHALLENGE_VALUE), _out);

      var csrf = this.get('cookies.' + _uiUtilsConstants['default'].COOKIE.CSRF);
      if (csrf) {
        out[_uiUtilsConstants['default'].HEADER.CSRF] = csrf;
      }

      return out;
    }).property().volatile()
  });
});