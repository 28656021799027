define('ui/storagepools/new-volume/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params /*, transition*/) {
      var store = this.get('store');

      return _ember['default'].Object.create({
        volume: store.createRecord({
          type: 'volume',
          driver: params.driverName,
          name: '',
          driverOpts: {}
        })
      });
    },

    resetController: function resetController(controller, isExisting /*, transition*/) {
      if (isExisting) {
        controller.set('errors', null);
      }
    }
  });
});