define('ui/components/new-secret/component', ['exports', 'ember', 'ui/mixins/new-or-edit'], function (exports, _ember, _uiMixinsNewOrEdit) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], {
    model: null,

    userValue: '',
    userValueChanged: _ember['default'].observer('userValue', function () {
      this.set('primaryResource.value', AWS.util.base64.encode(this.get('userValue')));
    }),

    actions: {
      cancel: function cancel() {
        this.sendAction('cancel');
      }
    },

    doneSaving: function doneSaving() {
      this.sendAction('cancel');
    }
  });
});