define('ui/components/storagepool-section/component', ['exports', 'ember', 'ui/mixins/sortable'], function (exports, _ember, _uiMixinsSortable) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsSortable['default'], {
    model: null,
    single: false,

    sortableContent: _ember['default'].computed.alias('model.volumes'),
    sortBy: 'name',
    sorts: {
      state: ['state', 'displayName', 'id'],
      name: ['displayName', 'id'],
      mounts: ['mounts.length', 'displayName', 'id']
    },

    init: function init() {
      this._super();
    },

    hostsByName: (function () {
      return (this.get('model.hosts') || []).sortBy('displayName');
    }).property('model.hosts.@each.displayName'),

    classNames: ['stack-section', 'storage', 'clear-section']
  });
});