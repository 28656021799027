define("ui/components/form-secrets/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 12
              },
              "end": {
                "line": 23,
                "column": 12
              }
            },
            "moduleName": "ui/components/form-secrets/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            dom.setAttribute(el1, "class", "divided text-muted");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            dom.setAttribute(el1, "class", "text-muted");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("th");
            dom.setAttribute(el1, "class", "text-muted");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(fragment, [5]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["formSecrets.uid.label"], [], ["loc", [null, [20, 45], [20, 74]]], 0, 0], ["inline", "t", ["formSecrets.gid.label"], [], ["loc", [null, [21, 37], [21, 66]]], 0, 0], ["inline", "t", ["formSecrets.mode.label"], [], ["loc", [null, [22, 37], [22, 67]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 14
                },
                "end": {
                  "line": 54,
                  "column": 14
                }
              },
              "moduleName": "ui/components/form-secrets/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              dom.setAttribute(el1, "class", "divided");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n\n                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              var el2 = dom.createTextNode("\n                  ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var element2 = dom.childAt(fragment, [3]);
              var element3 = dom.childAt(fragment, [5]);
              var morphs = new Array(6);
              morphs[0] = dom.createAttrMorph(element1, 'data-title');
              morphs[1] = dom.createMorphAt(element1, 1, 1);
              morphs[2] = dom.createAttrMorph(element2, 'data-title');
              morphs[3] = dom.createMorphAt(element2, 1, 1);
              morphs[4] = dom.createAttrMorph(element3, 'data-title');
              morphs[5] = dom.createMorphAt(element3, 1, 1);
              return morphs;
            },
            statements: [["attribute", "data-title", ["concat", [["subexpr", "t", ["formSecrets.uid.label"], [], ["loc", [null, [44, 48], [44, 77]]], 0, 0], ": "], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "input-integer", [], ["type", "text", "class", "form-control input-sm", "value", ["subexpr", "@mut", [["get", "row.uid", ["loc", [null, [45, 82], [45, 89]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["formSecrets.uid.placeholder"], [], ["loc", [null, [45, 102], [45, 135]]], 0, 0]], ["loc", [null, [45, 18], [45, 137]]], 0, 0], ["attribute", "data-title", ["concat", [["subexpr", "t", ["formSecrets.gid.label"], [], ["loc", [null, [47, 32], [47, 61]]], 0, 0], ": "], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "input-integer", [], ["class", "form-control input-sm", "value", ["subexpr", "@mut", [["get", "row.gid", ["loc", [null, [48, 70], [48, 77]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["formSecrets.gid.placeholder"], [], ["loc", [null, [48, 90], [48, 123]]], 0, 0]], ["loc", [null, [48, 18], [48, 125]]], 0, 0], ["attribute", "data-title", ["concat", [["subexpr", "t", ["formSecrets.mode.label"], [], ["loc", [null, [51, 32], [51, 62]]], 0, 0], ": "], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "input-integer", [], ["class", "form-control input-sm", "value", ["subexpr", "@mut", [["get", "row.mode", ["loc", [null, [52, 70], [52, 78]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["formSecrets.mode.placeholder"], [], ["loc", [null, [52, 91], [52, 125]]], 0, 0]], ["loc", [null, [52, 18], [52, 127]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 29,
                "column": 10
              },
              "end": {
                "line": 60,
                "column": 10
              }
            },
            "moduleName": "ui/components/form-secrets/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-center");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            dom.setAttribute(el3, "class", "form-control-static");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "icon icon-chevron-right");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "class", "text-right");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("button");
            dom.setAttribute(el3, "class", "btn btn-primary btn-sm");
            var el4 = dom.createElement("i");
            dom.setAttribute(el4, "class", "icon icon-minus");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("span");
            dom.setAttribute(el4, "class", "sr-only");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element4 = dom.childAt(fragment, [1]);
            var element5 = dom.childAt(element4, [1]);
            var element6 = dom.childAt(element4, [5]);
            var element7 = dom.childAt(element4, [9, 1]);
            var morphs = new Array(7);
            morphs[0] = dom.createAttrMorph(element5, 'data-title');
            morphs[1] = dom.createMorphAt(element5, 1, 1);
            morphs[2] = dom.createAttrMorph(element6, 'data-title');
            morphs[3] = dom.createMorphAt(element6, 1, 1);
            morphs[4] = dom.createMorphAt(element4, 7, 7);
            morphs[5] = dom.createElementMorph(element7);
            morphs[6] = dom.createMorphAt(dom.childAt(element7, [1]), 0, 0);
            return morphs;
          },
          statements: [["attribute", "data-title", ["concat", [["subexpr", "t", ["formSecrets.secret.label"], [], ["loc", [null, [31, 30], [31, 62]]], 0, 0], ": "], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "schema/input-secret", [], ["selectClass", "form-control input-sm", "selected", ["subexpr", "@mut", [["get", "row.secretId", ["loc", [null, [32, 83], [32, 95]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [32, 16], [32, 97]]], 0, 0], ["attribute", "data-title", ["concat", [["subexpr", "t", ["formSecrets.name.label"], [], ["loc", [null, [39, 30], [39, 60]]], 0, 0], ": "], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "input", [], ["type", "text", "class", "form-control input-sm", "value", ["subexpr", "@mut", [["get", "row.name", ["loc", [null, [40, 72], [40, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "placeholder", ["subexpr", "t", ["formSecrets.name.placeholder"], [], ["loc", [null, [40, 93], [40, 127]]], 0, 0]], ["loc", [null, [40, 16], [40, 129]]], 0, 0], ["block", "if", [["get", "showPermissions", ["loc", [null, [43, 20], [43, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [43, 14], [54, 21]]]], ["element", "action", ["removeSecret", ["get", "row", ["loc", [null, [57, 79], [57, 82]]], 0, 0, 0, 0]], [], ["loc", [null, [57, 55], [57, 84]]], 0, 0], ["inline", "t", ["generic.remove"], [], ["loc", [null, [57, 135], [57, 157]]], 0, 0]],
          locals: ["row"],
          templates: [child0]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 65,
                "column": 8
              },
              "end": {
                "line": 69,
                "column": 8
              }
            },
            "moduleName": "ui/components/form-secrets/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "href", "#");
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createElementMorph(element0);
            morphs[1] = dom.createMorphAt(element0, 1, 1);
            return morphs;
          },
          statements: [["element", "action", ["showPermissions"], [], ["loc", [null, [66, 22], [66, 50]]], 0, 0], ["inline", "t", ["formSecrets.help.showPermissionsLink"], [], ["loc", [null, [67, 12], [67, 56]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 4
            },
            "end": {
              "line": 72,
              "column": 4
            }
          },
          "moduleName": "ui/components/form-secrets/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1, "class", "grid fixed no-lines no-top-padding tight small r-mb0");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("thead");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4, "class", "text-muted");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("*");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4, "width", "30");
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4, "class", "text-muted");
          var el5 = dom.createComment("");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          dom.setAttribute(el4, "width", "40");
          var el5 = dom.createTextNode(" ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "help-block");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var element9 = dom.childAt(element8, [1, 1]);
          var element10 = dom.childAt(fragment, [3]);
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element9, [5]), 0, 0);
          morphs[2] = dom.createMorphAt(element9, 7, 7);
          morphs[3] = dom.createMorphAt(dom.childAt(element8, [3]), 1, 1);
          morphs[4] = dom.createMorphAt(element10, 1, 1);
          morphs[5] = dom.createMorphAt(element10, 3, 3);
          morphs[6] = dom.createMorphAt(element10, 5, 5);
          return morphs;
        },
        statements: [["inline", "t", ["formSecrets.secret.label"], [], ["loc", [null, [16, 35], [16, 67]]], 0, 0], ["inline", "t", ["formSecrets.name.label"], [], ["loc", [null, [18, 35], [18, 65]]], 0, 0], ["block", "if", [["get", "showPermissions", ["loc", [null, [19, 18], [19, 33]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [19, 12], [23, 19]]]], ["block", "each", [["get", "secrets", ["loc", [null, [29, 18], [29, 25]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [29, 10], [60, 19]]]], ["inline", "format-html-message", ["formSecrets.help.prefix"], [], ["loc", [null, [64, 8], [64, 57]]], 0, 0], ["block", "unless", [["get", "showPermissions", ["loc", [null, [65, 18], [65, 33]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [65, 8], [69, 19]]]], ["inline", "format-html-message", ["formSecrets.help.suffix"], [], ["loc", [null, [70, 8], [70, 57]]], 0, 0]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 4
            },
            "end": {
              "line": 74,
              "column": 4
            }
          },
          "moduleName": "ui/components/form-secrets/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "text-muted");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "t", ["formSecrets.noData"], [], ["loc", [null, [73, 31], [73, 57]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 77,
            "column": 0
          }
        },
        "moduleName": "ui/components/form-secrets/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-6 col-md-2 form-label form-control-static");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-6 col-md-8");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("button");
        dom.setAttribute(el3, "class", "btn-circle-text");
        var el4 = dom.createElement("i");
        dom.setAttribute(el4, "class", "icon icon-plus-circle");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode(" ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "col-xs-12");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element11 = dom.childAt(fragment, [0]);
        var element12 = dom.childAt(element11, [3, 1]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(dom.childAt(element11, [1, 1]), 0, 0);
        morphs[1] = dom.createElementMorph(element12);
        morphs[2] = dom.createMorphAt(dom.childAt(element12, [2]), 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [2, 1]), 1, 1);
        return morphs;
      },
      statements: [["inline", "t", ["formSecrets.label"], [], ["loc", [null, [3, 11], [3, 36]]], 0, 0], ["element", "action", ["addSecret"], [], ["loc", [null, [6, 36], [6, 58]]], 0, 0], ["inline", "t", ["formSecrets.addLabel"], [], ["loc", [null, [6, 100], [6, 128]]], 0, 0], ["block", "if", [["get", "secrets.length", ["loc", [null, [12, 10], [12, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [12, 4], [74, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  })());
});