define('ui/stacks/index/controller', ['exports', 'ember', 'ui/mixins/sortable', 'ui/utils/constants', 'ui/models/stack'], function (exports, _ember, _uiMixinsSortable, _uiUtilsConstants, _uiModelsStack) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsSortable['default'], {
    stacksController: _ember['default'].inject.controller('stacks'),
    projects: _ember['default'].inject.service(),
    prefs: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    infraTemplates: _ember['default'].computed.alias('stacksController.infraTemplates'),
    which: _ember['default'].computed.alias('stacksController.which'),
    tags: _ember['default'].computed.alias('stacksController.tags'),
    showAddtlInfo: false,
    selectedService: null,

    actions: {
      showAddtlInfo: function showAddtlInfo(service) {
        this.set('selectedService', service);
        this.set('showAddtlInfo', true);
      },

      dismiss: function dismiss() {
        this.set('showAddtlInfo', false);
        this.set('selectedService', null);
      },

      sortResults: function sortResults(name) {
        this.get('prefs').set(_uiUtilsConstants['default'].PREFS.SORT_STACKS_BY, name);
        this.send('setSort', name);
      }
    },

    filteredStacks: (function () {
      var which = this.get('which');
      var needTags = (0, _uiModelsStack.tagsToArray)(this.get('tags'));
      var out = this.get('model.stacks');

      if (which === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_NOT_ORCHESTRATION) {
        out = out.filter(function (obj) {
          return _uiUtilsConstants['default'].EXTERNAL_ID.KIND_ORCHESTRATION.indexOf(obj.get('grouping')) === -1;
        });
      } else if (which !== _uiUtilsConstants['default'].EXTERNAL_ID.KIND_ALL) {
        out = out.filterBy('grouping', which);
      }

      if (needTags.length) {
        out = out.filter(function (obj) {
          return obj.hasTags(needTags);
        });
      }

      out = out.filter(function (obj) {
        return obj.get('type').toLowerCase() !== 'kubernetesstack';
      });

      return out;

      // state isn't really a dependency here, but sortable won't recompute when it changes otherwise
    }).property('model.stacks.[]', 'model.stacks.@each.{state,grouping}', 'which', 'tags'),

    sortableContent: _ember['default'].computed.alias('filteredStacks'),
    sortBy: 'name',
    sorts: {
      state: ['stateSort', 'name', 'id'],
      name: ['name', 'id']
    },

    pageHeader: (function () {
      var which = this.get('which');
      var tags = this.get('tags');

      if (tags && tags.length) {
        return 'stacksPage.header.tags';
      } else if (which === _uiUtilsConstants['default'].EXTERNAL_ID.KIND_ALL) {
        return 'stacksPage.header.all';
      } else if (_uiUtilsConstants['default'].EXTERNAL_ID.SHOW_AS_SYSTEM.indexOf(which) >= 0) {
        return 'stacksPage.header.infra';
      } else if (which.toLowerCase() === 'user') {
        return 'stacksPage.header.user';
      } else {
        return 'stacksPage.header.custom';
      }
    }).property('which', 'tags')
  });
});