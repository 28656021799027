define("ui/container/commands/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 8,
            "column": 0
          }
        },
        "moduleName": "ui/container/commands/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["inline", "form-command", [], ["instance", ["subexpr", "@mut", [["get", "model", ["loc", [null, [3, 13], [3, 18]]], 0, 0, 0, 0]], [], [], 0, 0], "initialLabels", ["subexpr", "@mut", [["get", "model.labels", ["loc", [null, [4, 18], [4, 30]]], 0, 0, 0, 0]], [], [], 0, 0], "editing", false], ["loc", [null, [2, 2], [6, 4]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});