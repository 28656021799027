define('ui/utils/intl/missing-message', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = missingMessage;
  var logger = _ember['default'].Logger;

  function missingMessage(key, locales) {
    if (key) {
      locales = locales || ['unknown'];
      if (locales[0] !== 'none') {
        logger.warn('translation not found: locale=\'' + locales.join(', ') + '\', key=\'' + key + '\'.');
      }

      return '*%' + key + '%*';
    } else {
      return '';
    }
  }
});