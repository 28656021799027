define('ui/mixins/safe-style', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    safeStyle: null,
    _safeStyle: (function () {
      if (this.get('safeStyle')) {
        return this.get('safeStyle').htmlSafe();
      } else {
        return ''.htmlSafe();
      }
    }).property('safeStyle'),

    attributeBindings: ['_safeStyle:style']
  });
});