define('ui/services/projects', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {

  var ACTIVEISH = ['active', 'upgrading', 'updating-active'];

  exports['default'] = _ember['default'].Service.extend({
    access: _ember['default'].inject.service(),
    'tab-session': _ember['default'].inject.service('tab-session'),
    prefs: _ember['default'].inject.service(),
    k8sSvc: _ember['default'].inject.service('k8s'),
    swarmSvc: _ember['default'].inject.service('swarm'),
    mesosSvc: _ember['default'].inject.service('mesos'),
    userStore: _ember['default'].inject.service('user-store'),
    store: _ember['default'].inject.service(),

    current: null,
    all: null,

    active: (function () {
      return this.get('all').filter(function (project) {
        return ACTIVEISH.includes(project.get('state'));
      });
    }).property('all.@each.state'),

    getAll: function getAll() {
      var opt = {
        url: 'projects', // This is called in authenticated/route before schemas are loaded
        forceReload: true
      };

      if (!this.get('access.enabled')) {
        opt.filter = { all: 'true' };
      }

      return this.get('userStore').find('project', null, opt);
    },

    refreshAll: function refreshAll() {
      var _this = this;

      this.getAll().then(function (all) {
        _this.set('all', all);
        _this.selectDefault();
      });
    },

    selectDefault: function selectDefault(desired) {
      var _this2 = this;

      var self = this;
      var tabSession = this.get('tab-session');

      // The one specifically asked for
      return this._activeProjectFromId(desired).then(select)['catch'](function () {
        // Try the project ID in the session
        return _this2._activeProjectFromId(tabSession.get(_uiUtilsConstants['default'].TABSESSION.PROJECT)).then(select)['catch'](function () {
          // Then the default project ID from the prefs
          return _this2._activeProjectFromId(_this2.get('prefs').get(_uiUtilsConstants['default'].PREFS.PROJECT_DEFAULT)).then(select)['catch'](function () {
            // Then the first active project you're a member of
            var project = _this2.get('active.firstObject');
            if (project) {
              return select(project, true);
            } else if (_this2.get('access.admin')) {
              // Then if you're an admin the first active of any kind
              return _this2.getAll().then(function (all) {
                var firstActive = all.find(function (project) {
                  return ACTIVEISH.includes(project.get('state'));
                });

                if (firstActive) {
                  return select(firstActive, true);
                } else {
                  return fail();
                }
              })['catch'](function () {
                return fail();
              });
            } else {
              return fail();
            }
          });
        });
      });

      function select(project, overwriteDefault) {
        if (project) {
          tabSession.set(_uiUtilsConstants['default'].TABSESSION.PROJECT, project.get('id'));

          // If there is no default project, set it
          var def = self.get('prefs').get(_uiUtilsConstants['default'].PREFS.PROJECT_DEFAULT);
          if (!def || overwriteDefault === true) {
            self.get('prefs').set(_uiUtilsConstants['default'].PREFS.PROJECT_DEFAULT, project.get('id'));
          }

          return self.setCurrent(project);
        } else {
          tabSession.set(_uiUtilsConstants['default'].TABSESSION.PROJECT, undefined);
          return self.setCurrent(null);
        }
      }

      function fail() {
        // Then cry
        select(null);
        return _ember['default'].RSVP.reject();
      }
    },

    setCurrent: function setCurrent(project) {
      this.set('current', project);
      if (project) {
        this.set('store.baseUrl', this.get('app.apiEndpoint') + '/projects/' + project.get('id'));
      } else {
        this.set('store.baseUrl', this.get('app.apiEndpoint'));
      }
      return _ember['default'].RSVP.resolve(project);
    },

    _activeProjectFromId: function _activeProjectFromId(projectId) {
      var _this3 = this;

      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        if (!projectId) {
          reject();
          return;
        }

        _this3.get('userStore').find('project', projectId, { url: 'projects/' + encodeURIComponent(projectId) }).then(function (project) {
          if (ACTIVEISH.includes(project.get('state'))) {
            resolve(project);
          } else {
            reject();
          }
        })['catch'](function () {
          reject();
        });
      });
    },

    orchestrationState: null,
    updateOrchestrationState: function updateOrchestrationState() {
      var _this4 = this;

      var hash = {
        hasKubernetes: false,
        hasSwarm: false,
        hasMesos: false,
        kubernetesReady: false,
        swarmReady: false,
        mesosReady: false
      };

      var promises = [];

      if (this.get('current')) {
        var orch = this.get('current.orchestration');
        if (orch === 'kubernetes') {
          hash.hasKubernetes = true;
          promises.push(this.get('k8sSvc').isReady().then(function (ready) {
            hash.kubernetesReady = ready;
          }));
        }

        if (orch === 'swarm') {
          hash.hasSwarm = true;
          promises.push(this.get('swarmSvc').isReady().then(function (ready) {
            hash.swarmReady = ready;
          }));
        }

        if (orch === 'mesos') {
          hash.hasMesos = true;
          promises.push(this.get('mesosSvc').isReady().then(function (ready) {
            hash.mesosReady = ready;
          }));
        }
      }

      return _ember['default'].RSVP.all(promises).then(function () {
        _this4.set('orchestrationState', hash);
        return _ember['default'].RSVP.resolve(hash);
      })['catch'](function (e) {
        return _ember['default'].RSVP.reject(e);
      });
    },

    orchestrationStateShouldChange: (function () {
      _ember['default'].run.once(this, 'updateOrchestrationState', true);
    }).observes('current.{id,orchestration}'),

    isReady: (function () {
      var state = this.get('orchestrationState');

      if (!state) {
        return false;
      }

      return (!state.hasKubernetes || state.kubernetesReady) && (!state.hasSwarm || state.swarmReady) && (!state.hasMesos || state.mesosReady);
    }).property('orchestrationState') });
});
// The state object is always completely replaced, so this is ok