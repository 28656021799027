define('ui/admin-tab/auth/azuread/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    access: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),

    confirmDisable: false,
    errors: null,
    testing: false,
    error: null,

    loginUsername: null,
    loginPassword: null,

    actions: {
      test: function test() {
        var _this = this;

        this.send('clearError');

        var model = this.get('model');
        model.setProperties({
          enabled: false
        });

        var errors = model.validationErrors();
        if (errors.get('length')) {
          this.set('errors', errors);
        } else {
          this.set('testing', true);
          model.save().then(function () {
            _this.send('authenticate');
          })['catch'](function (err) {
            _this.send('gotError', err);
          });
        }
      },

      authenticate: function authenticate() {
        var _this2 = this;

        this.send('clearError');
        var code = this.get('loginUsername') + ':' + this.get('loginPassword');
        this.get('access').login(code).then(function (res) {
          _this2.send('authenticationSucceeded', res.body);
        })['catch'](function (err) {
          _this2.send('gotError', err);
        });
      },

      authenticationSucceeded: function authenticationSucceeded() /*auth*/{
        var _this3 = this;

        this.send('clearError');

        // Set this to true so the token will be sent with the request
        this.set('access.enabled', true);

        var model = this.get('model');
        model.setProperties({
          enabled: true
        });

        model.save().then(function () {
          _this3.send('waitAndRefresh');
        })['catch'](function (err) {
          _this3.set('access.enabled', false);
          _this3.send('gotError', err);
        });
      },

      waitAndRefresh: function waitAndRefresh(url) {
        $('#loading-underlay, #loading-overlay').removeClass('hide').show();
        setTimeout(function () {
          window.location.href = url || window.location.href;
        }, 1000);
      },

      promptDisable: function promptDisable() {
        this.set('confirmDisable', true);
        _ember['default'].run.later(this, function () {
          this.set('confirmDisable', false);
        }, 10000);
      },

      gotError: function gotError(err) {
        if (err.message) {
          this.send('showError', err.message + (err.detail ? '(' + err.detail + ')' : ''));
        } else {
          this.send('showError', 'Error (' + err.status + ' - ' + err.code + ')');
        }

        this.set('testing', false);
        this.set('saving', false);
      },

      showError: function showError(msg) {
        this.set('errors', [msg]);
        window.scrollY = 0;
      },

      clearError: function clearError() {
        this.set('errors', null);
      },

      disable: function disable() {
        var _this4 = this;

        this.send('clearError');

        var model = this.get('model');
        model.setProperties({
          enabled: false,
          username: "",
          password: ""
        });

        model.save().then(function () {
          _this4.get('access').clearSessionKeys();
          _this4.set('access.enabled', false);
          _this4.send('waitAndRefresh');
        })['catch'](function (err) {
          _this4.send('gotError', err);
        })['finally'](function () {
          _this4.set('confirmDisable', false);
        });
      }
    },

    headerText: _ember['default'].computed('access.enabled', 'intl._locale', function () {
      var out = this.get('intl').findTranslationByKey('authPage.azuread.header.disabled');
      if (this.get('access.enabled')) {
        out = this.get('intl').findTranslationByKey('authPage.azuread.header.enabled');
      }
      return this.get('intl').formatHtmlMessage(out);
    })
  });
});