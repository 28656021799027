define('ui/models/amazonec2config', ['exports', 'ember-api-store/models/resource'], function (exports, _emberApiStoreModelsResource) {

  var Amazonec2config = _emberApiStoreModelsResource['default'].extend({
    type: 'githubConfig'
  });

  Amazonec2config.reopenClass({
    //tags are in a really dumb format, key1,value1,key2,value2
    // so we need to fix them before they get to the user
    mangleIn: function mangleIn(data /* , store */) {
      if (data.tags.length > 0) {
        var tags = data.tags.split(',');
        var tagsOut = {};

        for (var i = 0; i < tags.length - 1; i += 2) {
          tagsOut[tags[i]] = tags[i + 1];
        }

        data.tags = tagsOut;
      }

      return data;
    },
    mangleOut: function mangleOut(data /* , store */) {
      if (data.tags && Object.keys(data.tags)) {
        (function () {
          var tags = data.tags;
          var tagsOut = [];

          // key1,value1,key2,value2
          Object.keys(tags).forEach(function (key) {
            tagsOut.push(key, tags[key]);
          });

          data.tags = tagsOut.join(',');
        })();
      }

      return data;
    }
  });

  exports['default'] = Amazonec2config;
});