define("ui/authenticated/project/api/hooks/edit-receiver/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 6,
            "column": 0
          }
        },
        "moduleName": "ui/authenticated/project/api/hooks/edit-receiver/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "webhook/new-receiver", [], ["editing", true, "model", ["subexpr", "@mut", [["get", "model.receiver", ["loc", [null, [3, 8], [3, 22]]], 0, 0, 0, 0]], [], [], 0, 0], "cancel", ["subexpr", "action", ["cancel"], [], ["loc", [null, [4, 9], [4, 26]]], 0, 0]], ["loc", [null, [1, 0], [5, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});