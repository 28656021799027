define('ui/mixins/intl-placeholder', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    intl: _ember['default'].inject.service(),

    attributeBindings: ['i18nPlaceholder:placeholder'],

    i18nPlaceholder: (function () {
      return this.get('intl').t(this.get('placeholder'));
    }).property('placeholder', 'intl._locale')
  });
});