define('ui/components/catalog-configure/component', ['exports', 'ember', 'lacsso/components/modal-base'], function (exports, _ember, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'generic', 'full-width-modal'],

    serviceChoices: _ember['default'].computed.alias('modalService.modalOpts.serviceChoices'),
    originalModel: _ember['default'].computed.alias('modalService.modalOpts.originalModel'),
    selectedTemplateUrl: _ember['default'].computed.alias('modalService.modalOpts.selectedTemplateUrl'),

    model: null,

    onInit: (function () {
      this._super();

      var orig = this.get('originalModel');
      var tpl = orig.get('tpl');
      var links = tpl.get('versionLinks');
      var verArr = Object.keys(links).filter(function (key) {
        // Filter out empty values for rancher/rancher#5494
        return !!links[key];
      }).map(function (key) {
        return { version: key, link: links[key] };
      });

      // New-Catalog wants answers as the environment property
      var stackish = orig.get('stack').clone();
      stackish.set('environment', stackish.get('answers'));
      stackish.set('answers', null);

      var out = {
        stack: stackish,
        serviceChoices: this.get('serviceChoices'),
        tpl: tpl,
        currentUrl: this.get('selectedTemplateUrl') || orig.get('tplVersion.links.self'),
        versionLinks: links,
        versionsArray: verArr
      };

      this.set('model', out);
    }).on('init'),

    actions: {
      doSave: function doSave(opt) {
        var orig = this.get('originalModel');
        var stack = orig.get('stack');

        stack.setProperties({
          templateId: opt.templateId,
          templateVersionId: opt.templateVersionId,
          answers: opt.answers
        });

        orig.set('enabled', true);
        this.send('cancel');
      }
    }
  });
});