define('ui/components/form-ssl-termination/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    service: null,
    allCertificates: null,

    lbConfig: _ember['default'].computed.alias('service.lbConfig'),

    alternates: null,

    init: function init() {
      this._super.apply(this, arguments);

      var alternates = (this.get('lbConfig.certificateIds') || []).map(function (id) {
        return { value: id };
      });

      this.set('alternates', alternates);
    },

    actions: {
      addAlternate: function addAlternate() {
        this.get('alternates').pushObject({ value: null });
      },

      removeAlternate: function removeAlternate(alt) {
        this.get('alternates').removeObject(alt);
      }
    },

    alternateCertificates: (function () {
      var def = this.get('lbConfig.defaultCertificateId');
      return this.get('allCertificates').slice().filter(function (obj) {
        return _ember['default'].get(obj, 'id') !== def;
      });
    }).property('allCertificates.@each.id', 'lbConfig.defaultCertificateId'),

    defaultDidChange: (function () {
      var def = this.get('lbConfig.defaultCertificateId');
      this.get('alternates').forEach(function (obj) {
        if (_ember['default'].get(obj, 'value') === def) {
          _ember['default'].set(obj, 'value', null);
        }
      });
    }).observes('lbConfig.defaultCertificateId'),

    alternatesDidChange: (function () {
      this.set('lbConfig.certificateIds', this.get('alternates').map(function (obj) {
        return _ember['default'].get(obj, 'value');
      }).filter(function (id) {
        return !!id;
      }).uniq());
    }).observes('alternates.@each.value'),

    updateLabels: function updateLabels(labels) {
      this.sendAction('setLabels', labels);
    }
  });
});