define("ui/components/waiting-orchestration/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "ui/components/waiting-orchestration/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "orchestration/wait-kubernetes", [], ["ready", ["subexpr", "action", ["kubernetesReady"], [], ["loc", [null, [2, 40], [2, 66]]], 0, 0]], ["loc", [null, [2, 2], [2, 68]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "ui/components/waiting-orchestration/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "orchestration/wait-swarm", [], ["ready", ["subexpr", "action", ["swarmReady"], [], ["loc", [null, [6, 35], [6, 56]]], 0, 0]], ["loc", [null, [6, 2], [6, 58]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 0
            },
            "end": {
              "line": 11,
              "column": 0
            }
          },
          "moduleName": "ui/components/waiting-orchestration/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "orchestration/wait-mesos", [], ["ready", ["subexpr", "action", ["mesosReady"], [], ["loc", [null, [10, 35], [10, 56]]], 0, 0]], ["loc", [null, [10, 2], [10, 58]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "ui/components/waiting-orchestration/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["subexpr", "and", [["get", "projects.orchestrationState.hasKubernetes", ["loc", [null, [1, 11], [1, 52]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "projects.orchestrationState.kubernetesReady", ["loc", [null, [1, 58], [1, 101]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 53], [1, 102]]], 0, 0]], [], ["loc", [null, [1, 6], [1, 103]]], 0, 0]], [], 0, null, ["loc", [null, [1, 0], [3, 7]]]], ["block", "if", [["subexpr", "and", [["get", "projects.orchestrationState.hasSwarm", ["loc", [null, [5, 11], [5, 47]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "projects.orchestrationState.swarmReady", ["loc", [null, [5, 53], [5, 91]]], 0, 0, 0, 0]], [], ["loc", [null, [5, 48], [5, 92]]], 0, 0]], [], ["loc", [null, [5, 6], [5, 93]]], 0, 0]], [], 1, null, ["loc", [null, [5, 0], [7, 7]]]], ["block", "if", [["subexpr", "and", [["get", "projects.orchestrationState.hasMesos", ["loc", [null, [9, 11], [9, 47]]], 0, 0, 0, 0], ["subexpr", "not", [["get", "projects.orchestrationState.mesosReady", ["loc", [null, [9, 53], [9, 91]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 48], [9, 92]]], 0, 0]], [], ["loc", [null, [9, 6], [9, 93]]], 0, 0]], [], 2, null, ["loc", [null, [9, 0], [11, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});