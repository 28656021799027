define('ui/components/service-addtl-info/component', ['exports', 'ember', 'ui/mixins/manage-labels', 'ui/utils/constants'], function (exports, _ember, _uiMixinsManageLabels, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsManageLabels['default'], {
    service: null,
    show: false,
    activeTab: '',

    tagName: 'div',

    classNames: ['service-addtl-info', 'collapse'],

    actions: {
      selectTab: function selectTab(tab) {
        this.set('activeTab', tab);
      },

      dismiss: function dismiss() {
        this.sendAction('dismiss');
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this.serviceChanged();
    },

    stateBackground: (function () {
      return this.get('service.stateColor').replace("text-", "bg-");
    }).property('service.stateColor'),

    showChanged: (function () {
      var _this = this;

      if (this.get('show')) {
        $('.stacks-wrap').addClass('summary-shown');
        this.$().show().animate({ height: '260px' }, 250, 'easeOutBack');
      } else {
        this.$().animate({ height: '0' }, 250, function () {
          if (_this._state === 'inDOM') {
            _this.$().hide();
          }

          $('.stacks-wrap').removeClass('summary-shown');
        });

        this.setProperties({
          primaryContainers: null,
          sidekicks: null,
          service: null
        });
      }
    }).observes('show'),

    primaryContainers: null,
    sidekicks: null,
    serviceChanged: (function () {
      if (!this.get('service')) {
        return;
      }

      this.initLabels(this.get('service.launchConfig.labels'));
      this.set('activeTab', '');

      var primary = [];
      var sidekicks = [];
      var sidekickByName = {};

      var slcs = this.get('service.secondaryLaunchConfigs') || [];
      slcs.forEach(function (config) {
        var obj = {
          name: config.name,
          config: config,
          instances: []
        };

        sidekicks.push(obj);
        sidekickByName[config.name] = obj;
      });

      (this.get('service.instances') || []).forEach(function (instance) {
        var lc = instance.get('labels')[_uiUtilsConstants['default'].LABEL.LAUNCH_CONFIG];

        if (_uiUtilsConstants['default'].REMOVEDISH_STATES.includes(instance.state)) {
          return;
        }

        // Primary service
        if (lc === _uiUtilsConstants['default'].LABEL.LAUNCH_CONFIG_PRIMARY) {
          primary.push(instance);
        } else {
          // Sidekick services
          var sidekick = sidekickByName[lc];
          if (sidekick) {
            sidekick.instances.push(instance);
          }
        }
      });

      this.setProperties({
        primaryContainers: primary,
        sidekicks: sidekicks
      });
    }).observes('service.instances.@each.{state,labels}')
  });
});