define('ui/registries/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var _this = this;

      return this.get('store').findAll('registrycredential').then(function () {
        return _this.get('store').findAll('registry');
      });
    }
  });
});