define('ui/stacks/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    projects: _ember['default'].inject.service(),

    model: function model() {
      return _ember['default'].RSVP.hash({
        stacks: this.get('store').findAll('stack')
      });
    },

    resetController: function resetController(controller /*, isExisting, transition*/) {
      if (this.get('projects.current.orchestrationState.hasKubernetes') || this.get('projects.current.orchestrationState.hasSwarm') || this.get('projects.current.orchestrationState.hasMesos')) {
        controller.set('which', _uiUtilsConstants['default'].EXTERNAL_ID.KIND_INFRA);
      } else {
        controller.set('which', _uiUtilsConstants['default'].EXTERNAL_ID.KIND_USER);
      }
    }
  });
});