define('ui/components/vm-console/component', ['exports', 'ember', 'ui/utils/parse-uri', 'ui/utils/util'], function (exports, _ember, _uiUtilsParseUri, _uiUtilsUtil) {
  exports['default'] = _ember['default'].Component.extend({
    instance: null,

    status: 'Connecting...',
    rfb: null,
    rfbState: null,
    showProtip: true,

    actions: {
      outsideClick: function outsideClick() {},

      cancel: function cancel() {
        this.disconnect();
        this.sendAction('dismiss');
      },

      ctrlAltDelete: function ctrlAltDelete() {
        this.get('rfb').sendCtrlAltDel();
      }
    },

    didInsertElement: function didInsertElement() {
      this._super();
      _ember['default'].run.next(this, 'exec');
    },

    willDestroyElement: function willDestroyElement() {
      this.disconnect();
      this._super();
    },

    exec: function exec() {
      var _this = this;

      var instance = this.get('instance');
      instance.doAction('console').then(function (exec) {
        exec.set('instance', instance);
        _this.connect(exec);
      })['catch'](function (err) {
        _this.set('status', 'Error:', err);
      });
    },

    connect: function connect(exec) {
      var parts = (0, _uiUtilsParseUri['default'])(exec.get('url'));

      var self = this;
      function updateState(rfb, state, oldstate, msg) {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }

        if (typeof msg !== 'undefined') {
          self.set('status', (msg + '').replace(/ \(unencrypted\)/, ''));
        }

        self.set('rfbState', state);
      }

      var rfb = new NoVNC.RFB({
        target: this.$('.console-canvas')[0],
        encrypt: parts.protocol === 'wss',
        true_color: true,
        local_cursor: true,
        shared: true,
        view_only: false,
        onUpdateState: updateState,
        wsProtocols: ['binary']
      });

      var path = _uiUtilsUtil['default'].addQueryParam(parts.path.substr(1), 'token', exec.get('token'));

      rfb.connect(parts.host, parts.port, null, path);

      this.set('rfb', rfb);
    },

    rfbStateChanged: (function () {
      if (this.get('rfbState') === 'disconnected' && !this.get('userClosed')) {
        this.send('cancel');
      }

      if (this.get('rfbState') === 'normal') {
        var $body = this.$('.console-body');
        var width = $('CANVAS').width() + parseInt($body.css('padding-left'), 10) + parseInt($body.css('padding-right'), 10);
        console.log('set width', width);
        $body.width(width);
      }
    }).observes('rfbState'),

    disconnect: function disconnect() {
      this.set('status', 'Closed');
      this.set('userClosed', true);

      var rfb = this.get('rfb');
      if (rfb) {
        rfb.disconnect();
        this.set('rfb', null);
      }
    },

    ctrlAltDeleteDisabled: (function () {
      return this.get('rfbState') !== 'normal';
    }).property('rfbState')

  });
});