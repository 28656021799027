define('ui/containers/new/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    queryParams: ['stackId', 'containerId', 'hostId'],
    hostId: null,
    stackId: null,
    containerId: null,
    editing: false,

    actions: {
      done: function done() {
        this.transitionToRoute('container', this.get('model.instance.id'));
      },

      cancel: function cancel() {
        this.send('goToPrevious');
      }
    }
  });
});