define('ui/utils/load-script', ['exports', 'ember'], function (exports, _ember) {
  exports.loadScript = loadScript;

  var nextId = 1;

  function loadScript(url, id) {
    if (!id) {
      id = "loadScript-" + nextId;
      nextId++;
    }

    return new _ember['default'].RSVP.Promise(function (resolve, reject) {
      var script = document.createElement('script');
      script.onload = resolve;
      script.onerror = reject;
      script.src = url;
      script.id = id;
      document.getElementsByTagName('BODY')[0].appendChild(script);
    });
  }
});