define('ui/components/modal-feedback/component', ['exports', 'ember', 'ui/utils/constants', 'lacsso/components/modal-base', 'ui/utils/load-script'], function (exports, _ember, _uiUtilsConstants, _lacssoComponentsModalBase, _uiUtilsLoadScript) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'span-8', 'offset-2', 'modal-welcome'],
    settings: _ember['default'].inject.service(),
    prefs: _ember['default'].inject.service(),
    access: _ember['default'].inject.service(),

    loading: true,

    init: function init() {
      this._super.apply(this, arguments);
      var self = this;

      var opt = JSON.parse(this.get('settings.' + _uiUtilsConstants['default'].SETTING.FEEDBACK_FORM) || '{}');

      _ember['default'].run.scheduleOnce('afterRender', this, function () {
        (0, _uiUtilsLoadScript.loadScript)('//js.hsforms.net/forms/v2.js').then(function () {
          window['hbspt'].forms.create({
            css: '',
            portalId: opt.portalId, // '468859',
            formId: opt.formId, // 'bfca2d1d-ed50-4ed7-a582-3f0440f236ca',
            target: '#feedback-form',
            errorClass: 'form-control',
            onFormReady: function onFormReady() {
              self.styleForm();
              $('INPUT[name=rancher_account_id]')[0].value = self.get('session.' + _uiUtilsConstants['default'].SESSION.ACCOUNT_ID);
              $('INPUT[name=github_username]')[0].value = self.get('access.identity.login');
              self.set('loading', false);
            },
            onFormSubmit: function onFormSubmit() {
              self.styleForm();
              _ember['default'].run.later(function () {
                self.send('sent');
              }, 1000);
            }
          });
        });
      });
    },

    styleForm: function styleForm() {
      var self = this;

      var form = $('#feedback-form');

      form.find('.field').not('.hs_sandbox_acknowledgement').addClass('col-md-6');
      form.find('.field.hs_sandbox_acknowledgement').addClass('span-12');

      form.find('INPUT[type=text],INPUT[type=email],SELECT').addClass('form-control');
      form.find('LABEL').addClass('r-pt10');

      form.find('INPUT[type=submit]').addClass('hide');

      form.find('UL').addClass('list-unstyled');
      form.find('INPUT[type=checkbox]').addClass('r-mr10');
      form.find('.hs-form-booleancheckbox-display').css('font-weight', 'normal');

      form.find('SELECT').on('change', function () {
        self.styleForm();
      });
    },

    actions: {
      submit: function submit() {
        var form = $('#feedback-form');
        form.find('INPUT[type=submit]').click();
      },

      sent: function sent() {
        this.set('prefs.' + _uiUtilsConstants['default'].PREFS.FEEDBACK, 'sent');
        this.send('cancel');
      }
    }
  });
});