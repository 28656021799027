define('ui/components/snapshot-timeline/component', ['exports', 'ember', 'ui/mixins/throttled-resize'], function (exports, _ember, _uiMixinsThrottledResize) {
  var get = _ember['default'].get;
  var set = _ember['default'].set;
  var computed = _ember['default'].computed;

  var SHIFT_DIFF = 3;
  var PLOT_HEIGHT = 7;

  exports['default'] = _ember['default'].Component.extend(_uiMixinsThrottledResize['default'], {
    tagName: 'div',
    classNames: ['timeline-container'],
    startDate: null,
    endDate: null,
    range: null,

    init: function init() {
      this._super.apply(this, arguments);

      if (!get(this, 'model') || get(this, 'model').length < 1) {
        set(this, 'classNames', []);
      }
    },

    onResize: function onResize() {
      if (get(this, 'model')) {
        this.plotSnapshots();
      }
    },

    snapshots: computed('model', function () {
      return this.plotSnapshots();
    }),

    plotSnapshots: function plotSnapshots() {

      var model = get(this, 'model');
      var snapshots = null;

      if (model && model.length > 1) {
        (function () {

          var prevPlot = null;
          var start = model[0].created;
          var end = model[model.length - 1].created;
          var range = moment(end).diff(start, 'seconds');

          snapshots = model.sortBy('created');
          snapshots.forEach(function (snapshot) {
            var myDate = snapshot.created;
            var fromStart = moment(myDate).diff(start, 'seconds');
            var shift = fromStart / range * 100;

            if (prevPlot !== null && prevPlot >= 0 && shift !== 100) {
              if (shift - prevPlot <= SHIFT_DIFF) {
                shift = prevPlot + SHIFT_DIFF;
              }
            }

            prevPlot = shift;

            set(snapshot, 'position', _ember['default'].String.htmlSafe('left: calc(' + shift + '% - ' + PLOT_HEIGHT + ');'));
            return snapshot;
          });

          prevPlot = null;

          snapshots.reverse().forEach(function (snapshot) {

            var myDate = snapshot.created;
            var fromStart = moment(myDate).diff(start, 'seconds');
            var shift = fromStart / range * 100;

            if (prevPlot !== null && shift >= 0) {
              if (Math.abs(shift - prevPlot) <= SHIFT_DIFF) {
                shift = Math.max(0, prevPlot - SHIFT_DIFF);
              }
            }

            prevPlot = shift;

            set(snapshot, 'position', _ember['default'].String.htmlSafe('left: calc(' + shift + '% - ' + PLOT_HEIGHT + 'px);'));
            return snapshot;
          });

          snapshots.reverse();
        })();
      }

      return snapshots;
    }
  });
});