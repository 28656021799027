define('ui/components/lb-addtl-info/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    service: null,
    containers: null,
    listenersArray: null,
    targetsArray: null,
    defaultCert: null,
    secondaryCerts: null,

    tagName: '',

    willInsertElement: function willInsertElement() {
      if (this.get('service')) {
        this.bootstrap();
      }
    },

    serviceObserver: (function () {
      this.bootstrap();
    }).observes('service'),

    targets: _ember['default'].computed.alias('service.lbConfig.portRules'),

    bootstrap: function bootstrap() {
      var _this = this;

      this.get('store').findAll('certificate').then(function (result) {
        result.forEach(function (cert) {
          if (_this.get('service.defaultCertificateId') === cert.id) {
            _this.set('defaultCert', cert);
          } else {
            if (!_this.get('secondaryCerts')) {
              _this.set('secondaryCerts', []);
            }
            if (_this.get('service.certificateIds')) {
              if (_this.get('service.certificateIds').indexOf(cert.id) !== -1) {
                _this.get('secondaryCerts').pushObject(cert);
              }
            }
          }
        });
      });
    }
  });
});