define('ui/services/all-services', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Service.extend({
    intl: _ember['default'].inject.service(),
    store: _ember['default'].inject.service(),

    list: (function () {
      var intl = this.get('intl');

      return this.get('_allServices').filter(function (service) {
        return service.get('system') !== true;
      }).map(function (service) {
        var stackName = service.get('stack.displayName') || '(' + service.get('stackId') + ')';

        return {
          group: intl.t('allServices.stackGroup', { name: stackName }),
          id: service.get('id'),
          stackName: stackName,
          name: service.get('displayName'),
          kind: service.get('type'),
          obj: service
        };
      });
    }).property('_allServices.@each.{id,system,displayName,type,hostname}'),

    grouped: (function () {
      return this.group(this.get('list'));
    }).property('list.[]'),

    group: function group(list) {
      var out = {};

      list.slice().sortBy('group', 'name', 'id').forEach(function (service) {
        var ary = out[service.group];
        if (!ary) {
          ary = [];
          out[service.group] = ary;
        }

        ary.push(service);
      });

      return out;
    },

    _allServices: (function () {
      var store = this.get('store');
      store.find('service');
      return store.all('service');
    }).property(),

    byId: function byId(id) {
      return this.get('_allServices').findBy('id', id);
    }
  });
});