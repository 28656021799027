define('ui/components/limit-parameters/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    memoryLimit: null,
    init: function init() {
      this._super.apply(this, arguments);
      var memReservation = this.get('model.memory');
      if (memReservation) {
        this.set('memoryLimit', parseInt(memReservation, 10) / 1048576);
      } else {
        this.set('memoryLimit', '');
      }
    },
    memoryReservationChanged: _ember['default'].observer('memoryLimit', function () {
      var mem = this.get('memoryLimit');

      if (isNaN(mem) || mem <= 0) {
        this.set('model.memory', '');
      } else {
        this.set('model.memory', mem * 1048576);
      }
    })
  });
});