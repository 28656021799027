define('ui/components/modal-edit-backup/component', ['exports', 'ember', 'lacsso/components/modal-base'], function (exports, _ember, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts.originalModel'),
    backupTargets: _ember['default'].computed.alias('modalService.modalOpts.backupTargets'),
    editing: false,
    clone: null,
    errors: null,
    model: null,
    backupName: null,
    backupTarget: null,

    actions: {
      saveBackup: function saveBackup() {
        var _this = this;

        var opts = {
          name: this.get('backupName'),
          backupTargetId: this.get('backupTarget')
        };
        this.get('model').doAction('backup', opts).then(function () {
          _this.send('cancel');
        });
      }
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('clone', this.get('originalModel').clone());
      this.set('model', this.get('originalModel').clone());
      this.set('backupName', this.get('originalModel.name') + '-' + Math.round(new Date().getTime() / 1000.0));
    },

    didRender: function didRender() {
      var _this2 = this;

      setTimeout(function () {
        _this2.$('INPUT')[0].focus();
      }, 500);
    }
  });
});