define('ui/components/identity-block/component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend({
    // Identity or externalId+externalIdType
    identity: null,
    externalIdType: null,
    externalId: null,
    identityNotParsed: null,

    avatar: true,
    link: true,
    size: 35,

    loading: false,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var eType = this.get('externalIdType');
      var eId = this.get('externalId');
      var id = this.get('identityNotParsed');

      if (!id && eType && eId) {
        id = '1i!' + eType + ':' + eId;
      }

      if (!this.get('identity')) {
        if (id) {
          this.set('loading', true);
          this.get('userStore').find('identity', id).then(function (identity) {
            if (_this.isDestroyed || _this.isDestroying) {
              return;
            }

            _this.set('identity', identity);
          })['catch'](function () /*err*/{
            // Do something..
          })['finally'](function () {
            if (_this.isDestroyed || _this.isDestroying) {
              return;
            }

            _this.set('loading', false);
          });
        }
      }
    },

    classNames: ['gh-block'],
    attributeBindings: ['aria-label:identity.name'],

    avatarSrc: _ember['default'].computed.alias('identity.profilePicture'),
    url: _ember['default'].computed.alias('identity.profileUrl'),
    login: _ember['default'].computed.alias('identity.login'),

    displayDescription: (function () {
      var out;
      var name = this.get('identity.name');
      if (this.get('identity.externalIdType') === _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_TEAM) {
        out = name.replace(/:.*/, '') + ' team';
      } else {
        if (name) {
          out = name;
        } else {
          out = this.get('identity.externalId');
        }
      }
      return out;
    }).property('identity.{externalIdType,name,externalId}')
  });
});