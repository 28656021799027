define('ui/service/controller', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Controller.extend({
    service: _ember['default'].computed.alias('model.service'),
    stack: _ember['default'].computed.alias('model.stack'),
    application: _ember['default'].inject.controller(),

    actions: {
      changeService: function changeService(service) {
        var transitionTo = this.get('application.currentRouteName');

        if (service.type === 'dnsService') {
          transitionTo = 'service.links';
        }

        this.transitionToRoute(transitionTo, service.get('id'));
      }
    }
  });
});