define('ui/fail-whale/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    storeReset: _ember['default'].inject.service(),

    actions: {
      activate: function activate() {
        $('BODY').addClass('farm');
      },

      deactivate: function deactivate() {
        $('BODY').removeClass('farm');
      }
    },

    model: function model() {
      return this.controllerFor('application').get('error');
    },

    afterModel: function afterModel(model) {
      if (model) {
        this.get('storeReset').reset();
      } else {
        this.transitionTo('authenticated');
      }
    }
  });
});