define('ui/authenticated/project/help/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    actions: {
      didTransition: function didTransition() {
        var _this = this;

        _ember['default'].$.getJSON(_uiUtilsConstants['default'].EXT_REFERENCES.FORUM + '/categories.json').then(function (response) {
          var modelOut = {
            resolved: true
          };

          var promises = {};

          response.category_list.categories.forEach(function (item) {
            switch (item.name) {
              case 'Announcements':
                modelOut.announcements = item;
                promises['announcements'] = _ember['default'].$.getJSON(_uiUtilsConstants['default'].EXT_REFERENCES.FORUM + '/c/' + item.id + '/l/latest.json');
                break;
              case 'General':
                modelOut.general = item;
                promises['general'] = _ember['default'].$.getJSON(_uiUtilsConstants['default'].EXT_REFERENCES.FORUM + '/c/' + item.id + '/l/latest.json');
                break;
              case 'Rancher':
                modelOut.rancher = item;
                promises['rancher'] = _ember['default'].$.getJSON(_uiUtilsConstants['default'].EXT_REFERENCES.FORUM + '/c/' + item.id + '/l/latest.json');
                break;
              case 'RancherOS':
                modelOut.rancherOS = item;
                promises['rancherOS'] = _ember['default'].$.getJSON(_uiUtilsConstants['default'].EXT_REFERENCES.FORUM + '/c/' + item.id + '/l/latest.json');
                break;
              default:
                break;
            }
          });

          _ember['default'].RSVP.hash(promises).then(function (hash) {
            Object.keys(hash).forEach(function (key) {
              var topics = hash[key].topic_list.topics.filterBy('pinned', false);
              topics.length = 5;
              modelOut[key].topics = topics;
            });

            _this.controller.set('model', modelOut);
          })['catch'](fail);
        }, fail);

        return true; //bubble the transition event

        function fail() /*error*/{
          var modelOut = {
            resolved: true,
            error: true
          };

          this.controller.set('model', modelOut);
        }
      }
    },

    beforeModel: function beforeModel() {
      var _this2 = this;

      this.get('store').findAll('host').then(function (hosts) {
        _this2.controllerFor('authenticated.project.help').set('hasHosts', hosts.get('length') > 0);
      });
    },

    resetController: function resetController(controller, isExisting /*, transition*/) {
      if (isExisting) {
        controller.set('modelResolved', false);
        controller.set('modelError', false);
      }
    }
  });
});