define('ui/admin-tab/auth/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    access: _ember['default'].inject.service(),

    model: function model() {
      var route = (this.get('access.provider') || '').toLowerCase().replace(/config$/i, '');
      if (route === 'ldap') {
        route = 'activedirectory';
      }

      if (this.get('access.enabled')) {
        this.replaceWith('admin-tab.auth.' + route);
      } else {
        this.replaceWith('admin-tab.auth.github');
      }
    }
  });
});