define('ui/components/edit-apikey/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'lacsso/components/modal-base'], function (exports, _ember, _uiMixinsNewOrEdit, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewOrEdit['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    model: null,
    clone: null,
    justCreated: false,

    didReceiveAttrs: function didReceiveAttrs() {
      this.set('clone', this.get('originalModel').clone());
      this.set('model', this.get('originalModel').clone());
      this.set('justCreated', false);
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      setTimeout(function () {
        _this.$('INPUT[type="text"]')[0].focus();
      }, 250);
    },

    editing: (function () {
      return !!this.get('clone.id');
    }).property('clone.id'),

    doneSaving: function doneSaving(neu) {
      if (this.get('editing')) {
        this.send('cancel');
      } else {
        this.setProperties({
          justCreated: true,
          clone: neu.clone()
        });
      }
    }

  });
});