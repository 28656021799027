define('ui/service/new-alias/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model(params /*, transition*/) {
      var store = this.get('store');

      var dependencies = [];

      if (params.serviceId) {
        dependencies.pushObject(store.find('service', params.serviceId));
      }

      return _ember['default'].RSVP.all(dependencies, 'Load dependencies').then(function (results) {
        var existing = results[0];

        var dns;
        if (existing) {
          dns = existing.cloneForNew();
        } else {
          dns = store.createRecord({
            type: 'dnsService',
            name: '',
            description: '',
            stackId: params.stackId,
            startOnCreate: true
          });
        }

        return {
          service: dns,
          existing: existing
        };
      });
    },

    resetController: function resetController(controller, isExisting /*, transition*/) {
      if (isExisting) {
        controller.set('stackId', null);
        controller.set('serviceId', null);
      }
    }
  });
});