define("ui/components/service-addtl-info/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 6
            },
            "end": {
              "line": 11,
              "column": 6
            }
          },
          "moduleName": "ui/components/service-addtl-info/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("li");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "href", "#");
          dom.setAttribute(el2, "class", "clip");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createMorphAt(element2, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "activeTab", ["loc", [null, [8, 28], [8, 37]]], 0, 0, 0, 0], ["get", "sidekick.name", ["loc", [null, [8, 38], [8, 51]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 24], [8, 52]]], 0, 0], "active"], [], ["loc", [null, [8, 19], [8, 63]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["selectTab", ["get", "sidekick.name", ["loc", [null, [9, 56], [9, 69]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 35], [9, 71]]], 0, 0], ["content", "sidekick.name", ["loc", [null, [9, 72], [9, 89]]], 0, 0, 0, 0]],
        locals: ["sidekick"],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 18,
              "column": 10
            },
            "end": {
              "line": 20,
              "column": 10
            }
          },
          "moduleName": "ui/components/service-addtl-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "lb-addtl-info", [], ["service", ["subexpr", "@mut", [["get", "service", ["loc", [null, [19, 36], [19, 43]]], 0, 0, 0, 0]], [], [], 0, 0], "containers", ["subexpr", "@mut", [["get", "primaryContainers", ["loc", [null, [19, 55], [19, 72]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [19, 12], [19, 74]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 21,
                "column": 12
              },
              "end": {
                "line": 23,
                "column": 12
              }
            },
            "moduleName": "ui/components/service-addtl-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "partial", ["alias-service-addtl-info"], [], ["loc", [null, [22, 14], [22, 52]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 24,
                  "column": 14
                },
                "end": {
                  "line": 26,
                  "column": 14
                }
              },
              "moduleName": "ui/components/service-addtl-info/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "partial", ["ext-service-addtl-info"], [], ["loc", [null, [25, 16], [25, 52]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 26,
                  "column": 14
                },
                "end": {
                  "line": 28,
                  "column": 14
                }
              },
              "moduleName": "ui/components/service-addtl-info/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "partial", ["service-addtl-info"], [], ["loc", [null, [27, 16], [27, 48]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 23,
                "column": 12
              },
              "end": {
                "line": 29,
                "column": 12
              }
            },
            "moduleName": "ui/components/service-addtl-info/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "service.type", ["loc", [null, [24, 24], [24, 36]]], 0, 0, 0, 0], "externalService"], [], ["loc", [null, [24, 20], [24, 55]]], 0, 0]], [], 0, 1, ["loc", [null, [24, 14], [28, 21]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 20,
              "column": 10
            },
            "end": {
              "line": 30,
              "column": 10
            }
          },
          "moduleName": "ui/components/service-addtl-info/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["subexpr", "eq", [["get", "service.type", ["loc", [null, [21, 22], [21, 34]]], 0, 0, 0, 0], "dnsService"], [], ["loc", [null, [21, 18], [21, 48]]], 0, 0]], [], 0, 1, ["loc", [null, [21, 12], [29, 19]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child3 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 6
            },
            "end": {
              "line": 39,
              "column": 6
            }
          },
          "moduleName": "ui/components/service-addtl-info/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("        ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "inner");
          var el3 = dom.createTextNode("\n            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element0, 'class');
          morphs[1] = dom.createMorphAt(dom.childAt(element0, [1]), 1, 1);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "unless", [["subexpr", "eq", [["get", "activeTab", ["loc", [null, [34, 33], [34, 42]]], 0, 0, 0, 0], ["get", "sidekick.name", ["loc", [null, [34, 43], [34, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [34, 29], [34, 57]]], 0, 0], "hide"], [], ["loc", [null, [34, 20], [34, 66]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "sidekick-addtl-info", [], ["sidekick", ["subexpr", "@mut", [["get", "sidekick.config", ["loc", [null, [36, 43], [36, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "containers", ["subexpr", "@mut", [["get", "sidekick.instances", ["loc", [null, [36, 70], [36, 88]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [36, 12], [36, 90]]], 0, 0]],
        locals: ["sidekick"],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 44,
            "column": 0
          }
        },
        "moduleName": "ui/components/service-addtl-info/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "row-same-height row-full-height");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("ul");
        dom.setAttribute(el2, "class", "nav nav-tabs nav-tabs-well shadowed");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("li");
        var el4 = dom.createTextNode("\n         ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("span");
        dom.setAttribute(el4, "class", "close");
        var el5 = dom.createElement("i");
        dom.setAttribute(el5, "class", "icon icon-x");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n         ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("a");
        dom.setAttribute(el4, "href", "#");
        dom.setAttribute(el4, "class", "clip");
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment(" Tab panes ");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "tab-content");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("div");
        dom.setAttribute(el4, "class", "inner");
        var el5 = dom.createTextNode("\n");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element3 = dom.childAt(fragment, [0]);
        var element4 = dom.childAt(element3, [1]);
        var element5 = dom.childAt(element4, [1]);
        var element6 = dom.childAt(element5, [1]);
        var element7 = dom.childAt(element5, [3]);
        var element8 = dom.childAt(element3, [5]);
        var element9 = dom.childAt(element8, [1]);
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element5, 'class');
        morphs[1] = dom.createElementMorph(element6);
        morphs[2] = dom.createElementMorph(element7);
        morphs[3] = dom.createMorphAt(element7, 0, 0);
        morphs[4] = dom.createMorphAt(element4, 3, 3);
        morphs[5] = dom.createAttrMorph(element9, 'class');
        morphs[6] = dom.createMorphAt(dom.childAt(element9, [1]), 1, 1);
        morphs[7] = dom.createMorphAt(element8, 3, 3);
        morphs[8] = dom.createMorphAt(element3, 7, 7);
        return morphs;
      },
      statements: [["attribute", "class", ["concat", [["subexpr", "if", [["subexpr", "eq", [["get", "activeTab", ["loc", [null, [3, 26], [3, 35]]], 0, 0, 0, 0], ""], [], ["loc", [null, [3, 22], [3, 39]]], 0, 0], "active"], [], ["loc", [null, [3, 17], [3, 50]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["dismiss"], [], ["loc", [null, [4, 29], [4, 49]]], 0, 0], ["element", "action", ["selectTab", ""], [], ["loc", [null, [5, 34], [5, 59]]], 0, 0], ["content", "service.name", ["loc", [null, [5, 60], [5, 76]]], 0, 0, 0, 0], ["block", "each", [["get", "sidekicks", ["loc", [null, [7, 14], [7, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [7, 6], [11, 15]]]], ["attribute", "class", ["concat", [["subexpr", "unless", [["subexpr", "eq", [["get", "activeTab", ["loc", [null, [16, 31], [16, 40]]], 0, 0, 0, 0], ""], [], ["loc", [null, [16, 27], [16, 44]]], 0, 0], "hide"], [], ["loc", [null, [16, 18], [16, 53]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["subexpr", "eq", [["get", "service.type", ["loc", [null, [18, 20], [18, 32]]], 0, 0, 0, 0], "loadBalancerService"], [], ["loc", [null, [18, 16], [18, 55]]], 0, 0]], [], 1, 2, ["loc", [null, [18, 10], [30, 17]]]], ["block", "each", [["get", "sidekicks", ["loc", [null, [33, 14], [33, 23]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [33, 6], [39, 15]]]], ["content", "yield", ["loc", [null, [41, 2], [41, 11]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  })());
});