define('ui/components/form-target-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tgt: null,
    exclude: null,
    isAdvanced: null,
    isBalancer: null,

    tagName: 'TR',

    actions: {
      remove: function remove() {
        this.sendAction('remove', this.get('tgt'));
      }
    }
  });
});