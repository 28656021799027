define("ui/components/container-logs/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "ui/components/container-logs/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "protip");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("hr");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["containerLogs.protip"], ["key", ["subexpr", "@mut", [["get", "alternateLabel", ["loc", [null, [3, 37], [3, 51]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [3, 6], [3, 53]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 0
            },
            "end": {
              "line": 12,
              "column": 0
            }
          },
          "moduleName": "ui/components/container-logs/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "text-muted");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "format-html-message", ["containerLogs.onlyCombined"], [], ["loc", [null, [11, 26], [11, 78]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child2 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 0
            },
            "end": {
              "line": 18,
              "column": 0
            }
          },
          "moduleName": "ui/components/container-logs/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("ul");
          dom.setAttribute(el1, "class", "nav nav-tabs");
          dom.setAttribute(el1, "style", "border-bottom: 0");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "role", "presentation");
          var el3 = dom.createElement("a");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "role", "presentation");
          var el3 = dom.createElement("a");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("li");
          dom.setAttribute(el2, "role", "presentation");
          var el3 = dom.createElement("a");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [0]);
          var element3 = dom.childAt(element0, [3]);
          var element4 = dom.childAt(element3, [0]);
          var element5 = dom.childAt(element0, [5]);
          var element6 = dom.childAt(element5, [0]);
          var morphs = new Array(9);
          morphs[0] = dom.createAttrMorph(element1, 'class');
          morphs[1] = dom.createElementMorph(element2);
          morphs[2] = dom.createMorphAt(element2, 0, 0);
          morphs[3] = dom.createAttrMorph(element3, 'class');
          morphs[4] = dom.createElementMorph(element4);
          morphs[5] = dom.createMorphAt(element4, 0, 0);
          morphs[6] = dom.createAttrMorph(element5, 'class');
          morphs[7] = dom.createElementMorph(element6);
          morphs[8] = dom.createMorphAt(element6, 0, 0);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", [["subexpr", "if", [["get", "isCombined", ["loc", [null, [14, 40], [14, 50]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [14, 35], [14, 61]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["changeShow", "combined"], [], ["loc", [null, [14, 66], [14, 100]]], 0, 0], ["inline", "t", ["containerLogs.combined"], [], ["loc", [null, [14, 101], [14, 131]]], 0, 0], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "isStdOut", ["loc", [null, [15, 40], [15, 48]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [15, 35], [15, 59]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["changeShow", "stdout"], [], ["loc", [null, [15, 64], [15, 96]]], 0, 0], ["inline", "t", ["containerLogs.stdout"], [], ["loc", [null, [15, 97], [15, 125]]], 0, 0], ["attribute", "class", ["concat", [["subexpr", "if", [["get", "isStdErr", ["loc", [null, [16, 40], [16, 48]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [16, 35], [16, 59]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["changeShow", "stderr"], [], ["loc", [null, [16, 64], [16, 96]]], 0, 0], ["inline", "t", ["containerLogs.stderr"], [], ["loc", [null, [16, 97], [16, 125]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 29,
            "column": 0
          }
        },
        "moduleName": "ui/components/container-logs/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h2");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "icon icon-file");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("pre");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "footer-actions");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "console-status text-muted");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-primary");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element7 = dom.childAt(fragment, [4]);
        var element8 = dom.childAt(fragment, [8]);
        var element9 = dom.childAt(element8, [3]);
        var element10 = dom.childAt(element8, [5]);
        var element11 = dom.childAt(element8, [7]);
        var element12 = dom.childAt(element8, [9]);
        var morphs = new Array(14);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
        morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
        morphs[3] = dom.createAttrMorph(element7, 'class');
        morphs[4] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[5] = dom.createMorphAt(dom.childAt(element8, [1]), 0, 0);
        morphs[6] = dom.createElementMorph(element9);
        morphs[7] = dom.createMorphAt(element9, 0, 0);
        morphs[8] = dom.createElementMorph(element10);
        morphs[9] = dom.createMorphAt(element10, 0, 0);
        morphs[10] = dom.createElementMorph(element11);
        morphs[11] = dom.createMorphAt(element11, 0, 0);
        morphs[12] = dom.createElementMorph(element12);
        morphs[13] = dom.createMorphAt(element12, 0, 0);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "showProtip", ["loc", [null, [1, 6], [1, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [6, 7]]]], ["inline", "t", ["containerLogs.title"], ["instanceName", ["subexpr", "@mut", [["get", "instance.displayName", ["loc", [null, [8, 72], [8, 92]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 33], [8, 94]]], 0, 0], ["block", "if", [["get", "onlyCombinedLog", ["loc", [null, [10, 6], [10, 21]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [10, 0], [18, 7]]]], ["attribute", "class", ["concat", ["log-body ", ["subexpr", "unless", [["get", "stdErrVisible", ["loc", [null, [19, 30], [19, 43]]], 0, 0, 0, 0], "hide-stderr"], [], ["loc", [null, [19, 21], [19, 59]]], 0, 0], " ", ["subexpr", "unless", [["get", "stdOutVisible", ["loc", [null, [19, 69], [19, 82]]], 0, 0, 0, 0], "hide-stdout"], [], ["loc", [null, [19, 60], [19, 98]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [21, 0], [21, 9]]], 0, 0, 0, 0], ["inline", "t", [["subexpr", "concat-str", ["containerLogs.status", ["get", "status", ["loc", [null, [23, 80], [23, 86]]], 0, 0, 0, 0]], ["character", "."], ["loc", [null, [23, 45], [23, 101]]], 0, 0]], [], ["loc", [null, [23, 41], [23, 103]]], 0, 0], ["element", "action", ["scrollToTop"], [], ["loc", [null, [24, 10], [24, 34]]], 0, 0], ["inline", "t", ["containerLogs.scrollTop"], [], ["loc", [null, [24, 59], [24, 90]]], 0, 0], ["element", "action", ["scrollToBottom"], [], ["loc", [null, [25, 10], [25, 37]]], 0, 0], ["inline", "t", ["containerLogs.scrollBottom"], [], ["loc", [null, [25, 62], [25, 96]]], 0, 0], ["element", "action", ["clear"], [], ["loc", [null, [26, 10], [26, 28]]], 0, 0], ["inline", "t", ["containerLogs.clear"], [], ["loc", [null, [26, 53], [26, 80]]], 0, 0], ["element", "action", ["cancel"], [], ["loc", [null, [27, 10], [27, 29]]], 0, 0], ["inline", "t", ["generic.closeModal"], [], ["loc", [null, [27, 54], [27, 80]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});