define('ui/helpers/is-last', ['exports', 'ember'], function (exports, _ember) {
  exports.isLast = isLast;

  function isLast(params /*, hash*/) {
    var ary = params[0];
    var idx = params[1];

    return idx + 1 >= _ember['default'].get(ary, 'length');
  }

  exports['default'] = _ember['default'].Helper.helper(isLast);
});