define('ui/authenticated/route', ['exports', 'ember', 'ui/utils/constants', 'ui/mixins/subscribe', 'ui/utils/platform', 'ui/mixins/promise-to-cb'], function (exports, _ember, _uiUtilsConstants, _uiMixinsSubscribe, _uiUtilsPlatform, _uiMixinsPromiseToCb) {

  var CHECK_AUTH_TIMER = 60 * 10 * 1000;

  exports['default'] = _ember['default'].Route.extend(_uiMixinsSubscribe['default'], _uiMixinsPromiseToCb['default'], {
    catalog: _ember['default'].inject.service(),
    prefs: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    access: _ember['default'].inject.service(),
    userTheme: _ember['default'].inject.service('user-theme'),
    language: _ember['default'].inject.service('user-language'),
    storeReset: _ember['default'].inject.service(),
    modalService: _ember['default'].inject.service('modal'),

    testTimer: null,

    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (this.get('access.enabled')) {
        if (this.get('access.isLoggedIn')) {
          this.testAuthToken();
        } else {
          transition.send('logout', transition, false);
          return _ember['default'].RSVP.reject('Not logged in');
        }
      }
    },

    testAuthToken: function testAuthToken() {
      var _this = this;

      var timer = _ember['default'].run.later(function () {
        _this.get('access').testAuth().then(function () /* res */{
          _this.testAuthToken();
        }, function () /* err */{
          _this.send('logout', null, true);
        });
      }, CHECK_AUTH_TIMER);

      this.set('testTimer', timer);
    },

    model: function model(params, transition) {
      var _this2 = this;

      // Save whether the user is admin
      var type = this.get('session.' + _uiUtilsConstants['default'].SESSION.USER_TYPE);
      var isAdmin = type === _uiUtilsConstants['default'].USER.TYPE_ADMIN || !this.get('access.enabled');
      this.set('access.admin', isAdmin);

      this.get('session').set(_uiUtilsConstants['default'].SESSION.BACK_TO, undefined);

      var promise = new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var tasks = {
          userSchemas: _this2.toCb('loadUserSchemas'),
          projects: _this2.toCb('loadProjects'),
          preferences: _this2.toCb('loadPreferences'),
          settings: _this2.toCb('loadPublicSettings'),
          regions: ['userSchemas', _this2.toCb('loadRegions')],
          project: ['projects', 'preferences', _this2.toCb('selectProject', transition)],
          projectSchemas: ['project', _this2.toCb('loadProjectSchemas')],
          catalogs: ['project', _this2.toCb('loadCatalogs')],
          orchestrationState: ['projectSchemas', _this2.toCb('updateOrchestration')],
          instances: ['projectSchemas', _this2.cbFind('instance')],
          services: ['projectSchemas', _this2.cbFind('service')],
          hosts: ['projectSchemas', _this2.cbFind('host')],
          stacks: ['projectSchemas', _this2.cbFind('stack')],
          mounts: ['projectSchemas', _this2.cbFind('mount', 'store', { filter: { state_ne: 'inactive' } })],
          storagePools: ['projectSchemas', _this2.cbFind('storagepool')],
          volumes: ['projectSchemas', _this2.cbFind('volume')],
          certificate: ['projectSchemas', _this2.cbFind('certificate')],
          secret: ['projectSchemas', _this2.toCb('loadSecrets')],
          identities: ['userSchemas', _this2.cbFind('identity', 'userStore')]
        };

        async.auto(tasks, _uiUtilsPlatform.xhrConcur, function (err, res) {
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        });
      }, 'Load all the things');

      return promise.then(function (hash) {
        return _ember['default'].Object.create(hash);
      })['catch'](function (err) {
        return _this2.loadingError(err, transition, _ember['default'].Object.create({
          projects: [],
          project: null
        }));
      });
    },

    activate: function activate() {
      var app = this.controllerFor('application');

      this._super();
      if (!this.controllerFor('application').get('isPopup') && this.get('projects.current')) {
        this.connectSubscribe();
      }

      if (this.get('settings.isRancher') && !app.get('isPopup')) {
        var form = this.get('settings.' + _uiUtilsConstants['default'].SETTING.FEEDBACK_FORM);

        //Show the telemetry opt-in
        var opt = this.get('settings.' + _uiUtilsConstants['default'].SETTING.TELEMETRY);
        if (this.get('access.admin') && (!opt || opt === 'prompt')) {
          _ember['default'].run.scheduleOnce('afterRender', this, function () {
            this.get('modalService').toggleModal('modal-welcome');
          });
        } else if (form && !this.get('prefs.' + _uiUtilsConstants['default'].PREFS.FEEDBACK)) {
          _ember['default'].run.scheduleOnce('afterRender', this, function () {
            this.get('modalService').toggleModal('modal-feedback');
          });
        }
      }
    },

    deactivate: function deactivate() {
      this._super();
      this.disconnectSubscribe();
      _ember['default'].run.cancel(this.get('testTimer'));

      // Forget all the things
      this.get('storeReset').reset();
    },

    loadingError: function loadingError(err, transition, ret) {
      var isAuthEnabled = this.get('access.enabled');

      console.log('Loading Error:', err);
      if (err && (isAuthEnabled || [401, 403].indexOf(err.status) >= 0)) {
        this.set('access.enabled', true);
        this.send('logout', transition, transition.targetName !== 'authenticated.index');
        return;
      }

      this.replaceWith('settings.projects');
      return ret;
    },

    cbFind: function cbFind(type) {
      var _this3 = this;

      var store = arguments.length <= 1 || arguments[1] === undefined ? 'store' : arguments[1];
      var opt = arguments.length <= 2 || arguments[2] === undefined ? null : arguments[2];

      return function (results, cb) {
        if (typeof results === 'function') {
          cb = results;
          results = null;
        }

        return _this3.get(store).find(type, null, opt).then(function (res) {
          cb(null, res);
        })['catch'](function (err) {
          cb(err, null);
        });
      };
    },

    loadPreferences: function loadPreferences() {
      var _this4 = this;

      return this.get('userStore').find('userpreference', null, { url: 'userpreferences', forceReload: true }).then(function (res) {
        // Save the account ID from the response headers into session
        if (res) {
          _this4.set('session.' + _uiUtilsConstants['default'].SESSION.ACCOUNT_ID, res.xhr.headers.get(_uiUtilsConstants['default'].HEADER.ACCOUNT_ID));
        }

        _this4.get('language').initLanguage(true);
        _this4.get('userTheme').setupTheme();

        if (_this4.get('prefs.' + _uiUtilsConstants['default'].PREFS.I_HATE_SPINNERS)) {
          _ember['default'].$('BODY').addClass('i-hate-spinners');
        }

        return res;
      });
    },

    loadProjectSchemas: function loadProjectSchemas() {
      var store = this.get('store');
      store.resetType('schema');
      return store.rawRequest({ url: 'schema', dataType: 'json' }).then(function (xhr) {
        store._bulkAdd('schema', xhr.body.data);
      });
    },

    loadUserSchemas: function loadUserSchemas() {
      // @TODO Inline me into releases
      var userStore = this.get('userStore');
      return userStore.rawRequest({ url: 'schema', dataType: 'json' }).then(function (xhr) {
        userStore._bulkAdd('schema', xhr.body.data);
      });
    },

    loadProjects: function loadProjects() {
      var svc = this.get('projects');
      return svc.getAll().then(function (all) {
        svc.set('all', all);
        return all;
      });
    },

    loadCatalogs: function loadCatalogs() {
      return this.get('catalog').fetchCatalogs();
    },

    loadRegions: function loadRegions() {
      if (this.get('userStore').getById('schema', 'region')) {
        return this.get('userStore').find('region');
      } else {
        return _ember['default'].RSVP.resolve();
      }
    },

    updateOrchestration: function updateOrchestration() {
      return this.get('projects').updateOrchestrationState();
    },

    loadPublicSettings: function loadPublicSettings() {
      return this.get('userStore').find('setting', null, { url: 'setting', forceReload: true, filter: { all: 'false' } });
    },

    loadSecrets: function loadSecrets() {
      if (this.get('store').getById('schema', 'secret')) {
        return this.get('store').find('secret');
      } else {
        return _ember['default'].RSVP.resolve();
      }
    },

    selectProject: function selectProject(transition) {
      var projectId = null;
      if (transition.params && transition.params['authenticated.project'] && transition.params['authenticated.project'].project_id) {
        projectId = transition.params['authenticated.project'].project_id;
      }

      // Make sure a valid project is selected
      return this.get('projects').selectDefault(projectId);
    },

    actions: {
      error: function error(err, transition) {
        // Unauthorized error, send back to login screen
        if (err.status === 401) {
          this.send('logout', transition, true);
          return false;
        } else {
          // Bubble up
          return true;
        }
      },

      showAbout: function showAbout() {
        this.controllerFor('application').set('showAbout', true);
      },

      switchProject: function switchProject(projectId) {
        var _this5 = this;

        var transition = arguments.length <= 1 || arguments[1] === undefined ? true : arguments[1];

        console.log('Switch to ' + projectId);
        this.disconnectSubscribe(function () {
          console.log('Switch is disconnected');
          _this5.send('finishSwitchProject', projectId, transition);
        });
      },

      finishSwitchProject: function finishSwitchProject(projectId, transition) {
        console.log('Switch finishing');
        this.get('storeReset').reset();
        if (transition) {
          this.intermediateTransitionTo('authenticated');
        }
        this.set('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT, projectId);
        this.refresh();
        console.log('Switch finished');
      }
    }
  });
});