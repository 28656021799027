define('ui/settings/projects/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var userStore = this.get('userStore');
      return _ember['default'].RSVP.hash({
        projects: userStore.find('project', null, { url: 'projects', filter: { all: 'true' }, forceReload: true, removeMissing: true }),
        projectTemplates: userStore.find('projecttemplate', null, { url: 'projectTemplates', forceReload: true, removeMissing: true })
      }).then(function () {
        return {
          projects: userStore.all('project'),
          projectTemplates: userStore.all('projecttemplate')
        };
      });
    }
  });
});