define('ui/components/disk-row/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    driverChoices: null,
    disk: null,

    gigs: null,
    tagName: 'tr',

    actions: {
      remove: function remove() {
        this.sendAction('remove');
      },

      setSize: function setSize(gigs) {
        this.set('gigs', gigs);
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      if (this.get('disk.size')) {
        this.set('gigs', parseInt(this.get('disk.size', 10)));
      } else {
        this.set('gigs', 10);
      }
    },

    gigsChanged: (function () {
      this.set('disk.size', this.get('gigs') + 'g');
    }).observes('gigs')
  });
});