define("ui/components/vm-console/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 6,
              "column": 0
            }
          },
          "moduleName": "ui/components/vm-console/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "text-muted r-mt5 r-mb10");
          dom.setAttribute(el1, "style", "font-size: 12px;");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "t", ["vmConsole.protip"], ["key", ["subexpr", "@mut", [["get", "alternateLabel", ["loc", [null, [4, 31], [4, 45]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 4], [4, 47]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 20,
            "column": 0
          }
        },
        "moduleName": "ui/components/vm-console/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("h2");
        var el2 = dom.createElement("i");
        dom.setAttribute(el2, "class", "icon icon-terminal");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode(" ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "console-body clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "id", "noVNC_screen");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("canvas");
        dom.setAttribute(el3, "class", "console-canvas");
        dom.setAttribute(el3, "width", "640");
        dom.setAttribute(el3, "height", "20");
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n    ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "footer-actions");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-default");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-primary");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "console-status text-muted");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [5]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var morphs = new Array(10);
        morphs[0] = dom.createMorphAt(element0, 2, 2);
        morphs[1] = dom.createMorphAt(element0, 4, 4);
        morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [3, 1, 1]), 1, 1);
        morphs[4] = dom.createAttrMorph(element2, 'disabled');
        morphs[5] = dom.createElementMorph(element2);
        morphs[6] = dom.createMorphAt(element2, 0, 0);
        morphs[7] = dom.createElementMorph(element3);
        morphs[8] = dom.createMorphAt(element3, 0, 0);
        morphs[9] = dom.createMorphAt(dom.childAt(element1, [5]), 0, 0);
        return morphs;
      },
      statements: [["inline", "t", ["vmConsole.header"], [], ["loc", [null, [1, 39], [1, 63]]], 0, 0], ["content", "instance.displayName", ["loc", [null, [1, 64], [1, 88]]], 0, 0, 0, 0], ["block", "if", [["get", "showProtip", ["loc", [null, [2, 6], [2, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 0], [6, 7]]]], ["inline", "t", ["vmConsole.canvas"], [], ["loc", [null, [10, 6], [10, 30]]], 0, 0], ["attribute", "disabled", ["get", "ctrlAltDeleteDisabled", ["loc", [null, [16, 72], [16, 93]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["ctrlAltDelete"], [], ["loc", [null, [16, 10], [16, 36]]], 0, 0], ["inline", "t", ["vmConsole.footerActions.buttonSend"], [], ["loc", [null, [16, 96], [16, 138]]], 0, 0], ["element", "action", ["cancel"], [], ["loc", [null, [17, 10], [17, 29]]], 0, 0], ["inline", "t", ["vmConsole.footerActions.buttonCancel"], [], ["loc", [null, [17, 54], [17, 98]]], 0, 0], ["content", "status", ["loc", [null, [18, 41], [18, 51]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  })());
});