define('ui/services/prefs', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Service.extend({
    userStore: _ember['default'].inject.service('user-store'),

    unremoved: (function () {
      return this.get('userStore').all('userpreference');
    }).property('userStore.generation'),

    findByName: function findByName(key) {
      return this.get('unremoved').filterBy('name', key)[0];
    },

    unknownProperty: function unknownProperty(key) {
      var value; // = undefined;

      var existing = this.findByName(key);
      if (existing) {
        try {
          value = JSON.parse(existing.get('value'));
        } catch (e) {
          console.log("Error parsing storage ['" + key + "']");
          //this.notifyPropertyChange(key);
        }
      }

      return value;
    },

    setUnknownProperty: function setUnknownProperty(key, value) {
      var _this = this;

      var obj = this.findByName(key);

      // Delete by set to undefined
      if (value === undefined) {
        if (obj) {
          obj.set('value', undefined);
          obj['delete']();
          this.notifyPropertyChange(key);
        }

        return;
      }

      if (!obj) {
        obj = this.get('userStore').createRecord({
          type: 'userPreference',
          name: key
        });
      }

      var neu = JSON.stringify(value);
      if (!obj.get('id') || obj.get('value') !== neu) {
        obj.set('value', neu);
        obj.save().then(function () {
          _ember['default'].run(function () {
            _this.notifyPropertyChange(key);
          });
        });
      }

      return value;
    },

    clear: function clear() {
      var _this2 = this;

      this.beginPropertyChanges();

      this.get('unremoved').forEach(function (obj) {
        _this2.set(obj.get('name'), undefined);
      });

      this.endPropertyChanges();
    },

    tablePerPage: _ember['default'].computed('' + _uiUtilsConstants['default'].PREFS.TABLE_COUNT, function () {
      var out = this.get('' + _uiUtilsConstants['default'].PREFS.TABLE_COUNT);
      if (!out) {
        out = _uiUtilsConstants['default'].TABLES.DEFAULT_COUNT;
      }

      return out;
    })
  });
});