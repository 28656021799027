define('ui/components/form-scale/component', ['exports', 'ember', 'ui/utils/constants', 'ui/mixins/manage-labels', 'ui/utils/debounce'], function (exports, _ember, _uiUtilsConstants, _uiMixinsManageLabels, _uiUtilsDebounce) {

  // Subtract 1 (because 11...), round up to the nearest 10, then double it
  function roundScale(num) {
    return Math.ceil((num - 1) / 10) * 10 * 2;
  }

  exports['default'] = _ember['default'].Component.extend(_uiMixinsManageLabels['default'], {
    initialLabels: null,
    initialScale: null,
    isGlobal: null,
    editing: false,
    isVm: null,

    scale: null,
    max: 11,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('scale', this.get('initialScale') || 1);
      this.set('max', Math.max(11, roundScale(this.get('scale'))));

      this.initLabels(this.get('initialLabels'), null, _uiUtilsConstants['default'].LABEL.SCHED_GLOBAL);
      _ember['default'].run.scheduleOnce('afterRender', function () {
        var on = _this.getLabel(_uiUtilsConstants['default'].LABEL.SCHED_GLOBAL) === 'true';
        _this.sendAction('setGlobal', _this.set('isGlobal', on));
      });
    },

    updateLabels: function updateLabels(labels) {
      this.sendAction('setLabels', labels);
    },

    isGlobalChanged: (function () {
      var on = this.get('isGlobal');
      if (on) {
        this.setLabel(_uiUtilsConstants['default'].LABEL.SCHED_GLOBAL, 'true');
      } else {
        this.removeLabel(_uiUtilsConstants['default'].LABEL.SCHED_GLOBAL);
      }

      this.sendAction('setGlobal', on);
    }).observes('isGlobal'),

    scaleChanged: (0, _uiUtilsDebounce.debouncedObserver)('scale', function () {
      if (this.get('scale') >= this.get('max')) {
        this.set('max', roundScale(this.get('scale')));
      }

      this.sendAction('setScale', this.get('scale'));
    }, 500),

    oneLouder: (function () {
      return this.get('max') + 1;
    }).property('max')

  });
});