define('ui/components/edit-secret/component', ['exports', 'lacsso/components/modal-base', 'ember'], function (exports, _lacssoComponentsModalBase, _ember) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'span-8', 'offset-2'],

    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    editing: true,
    model: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('model', this.get('originalModel').clone());
    },

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});