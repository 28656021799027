define("ui/components/input-certificate/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 0
          }
        },
        "moduleName": "ui/components/input-certificate/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-md-4 form-group");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "clearfix r-mb10 r-mt15");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "r-pt5");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("*");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-md-4 form-group");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "clearfix r-mb10 r-mt15");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "r-pt5");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("*");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "col-md-4 form-group");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "clearfix r-mb10 r-mt15");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("label");
        dom.setAttribute(el3, "class", "r-pt5");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(fragment, [2]);
        var element2 = dom.childAt(fragment, [4]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [1, 1]), 0, 0);
        morphs[1] = dom.createMorphAt(element0, 3, 3);
        morphs[2] = dom.createMorphAt(dom.childAt(element1, [1, 1]), 0, 0);
        morphs[3] = dom.createMorphAt(element1, 3, 3);
        morphs[4] = dom.createMorphAt(dom.childAt(element2, [1, 1]), 0, 0);
        morphs[5] = dom.createMorphAt(element2, 3, 3);
        return morphs;
      },
      statements: [["inline", "t", ["inputCertificate.key.label"], [], ["loc", [null, [3, 25], [3, 59]]], 0, 0], ["inline", "input-text-file", [], ["value", ["subexpr", "@mut", [["get", "model.key", ["loc", [null, [6, 10], [6, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "accept", "text/plain,.pem,.pkey,.key", "minHeight", 60, "placeholder", "inputCertificate.key.placeholder"], ["loc", [null, [5, 2], [10, 4]]], 0, 0], ["inline", "t", ["inputCertificate.cert.label"], [], ["loc", [null, [15, 25], [15, 60]]], 0, 0], ["inline", "input-text-file", [], ["value", ["subexpr", "@mut", [["get", "model.cert", ["loc", [null, [18, 10], [18, 20]]], 0, 0, 0, 0]], [], [], 0, 0], "accept", "application/x-x509-ca-cert,text/plain,.pem,.crt", "minHeight", 60, "placeholder", "inputCertificate.cert.placeholder"], ["loc", [null, [17, 2], [22, 4]]], 0, 0], ["inline", "t", ["inputCertificate.certChain.label"], [], ["loc", [null, [27, 25], [27, 65]]], 0, 0], ["inline", "input-text-file", [], ["value", ["subexpr", "@mut", [["get", "model.certChain", ["loc", [null, [30, 10], [30, 25]]], 0, 0, 0, 0]], [], [], 0, 0], "accept", "application/x-x509-ca-cert,text/plain,.pem,.crt", "minHeight", 60, "placeholder", "inputCertificate.certChain.placeholder"], ["loc", [null, [29, 2], [34, 4]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});