define('ui/components/settings/telemetry-opt/component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {

  var IN = 'in';
  var OUT = 'out';

  exports['default'] = _ember['default'].Component.extend({
    settings: _ember['default'].inject.service(),

    initialValue: null,
    optIn: null,

    actions: {
      save: function save(btnCb) {
        var _this = this;

        this.get('settings').set(_uiUtilsConstants['default'].SETTING.TELEMETRY, this.get('optIn') ? IN : OUT);
        this.get('settings').one('settingsPromisesResolved', function () {
          btnCb(true);
          _this.sendAction('saved');
        });
      }
    },

    init: function init() {
      this._super.apply(this, arguments);

      var val = false;
      if (this.get('initialValue') === IN) {
        val = true;
      }

      this.set('optIn', val);
    }
  });
});