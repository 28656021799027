define('ui/utils/socket', ['exports', 'ember', 'ui/utils/platform', 'ui/utils/util'], function (exports, _ember, _uiUtilsPlatform, _uiUtilsUtil) {

  var INSECURE = 'ws://';
  var SECURE = 'wss://';
  var sockId = 1;
  var safariWarningShown = false;

  var DISCONNECTED = 'disconnected';
  var CONNECTING = 'connecting';
  var CONNECTED = 'connected';
  var CLOSING = 'closing';
  var RECONNECTING = 'reconnecting';

  exports['default'] = _ember['default'].Object.extend(_ember['default'].Evented, {
    url: null,
    autoReconnect: true,
    frameTimeout: 11000,
    metadata: null,

    _socket: null,
    _state: DISCONNECTED,
    _framesReceived: 0,
    _frameTimer: null,
    _reconnectTimer: null,
    _tries: 0,
    _disconnectCbs: null,
    _disconnectedAt: null,
    _closingId: null,

    connect: function connect(metadata) {
      if (this.get('_socket')) {
        console.error('Socket refusing to connect while another socket exists');
        return;
      }

      this.set('_disconnectCbs', this.get('_disconnectCbs') || []);
      this.set('metadata', metadata || this.get('metadata') || {});

      var url = this.get('url');

      // If the site is SSL, the WebSocket should be too...
      if (window.location.protocol === 'https:' && url.indexOf(INSECURE) === 0) {
        url = SECURE + url.substr(INSECURE.length);
        this.set('url', url);
      }

      var id = sockId++;
      console.log('Socket connecting (id=' + id + ', url=' + (url.replace(/\?.*/, '') + '...') + ')');

      var socket = new WebSocket(_uiUtilsUtil['default'].addQueryParam(url, 'sockId', id));
      socket.__sockId = id;
      socket.metadata = this.get('metadata');
      socket.onmessage = _ember['default'].run.bind(this, this._message);
      socket.onopen = _ember['default'].run.bind(this, this._opened);
      socket.onerror = _ember['default'].run.bind(this, this._error);
      socket.onclose = _ember['default'].run.bind(this, this._closed);

      this.setProperties({
        _socket: socket,
        _state: CONNECTING
      });
    },

    disconnect: function disconnect(cb) {
      if (cb) {
        this.get('_disconnectCbs').pushObject(cb);
      }

      this.set('autoReconnect', false);
      this._close();
    },

    reconnect: function reconnect(metadata) {
      this.set('metadata', metadata || {});
      if (this.get('_socket')) {
        this._close();
      } else {
        this.connect(metadata);
      }
    },

    getMetadata: function getMetadata() {
      var socket = this.get('_socket');
      if (socket) {
        return socket.metadata;
      } else {
        return {};
      }
    },

    getId: function getId() {
      var socket = this.get('_socket');
      if (socket) {
        return socket.__sockId;
      } else {
        return null;
      }
    },

    _close: function _close() {
      var socket = this.get('_socket');
      if (socket) {
        try {
          this._log('closing');
          this.set('_closingId', socket.__sockId);
          socket.onopen = null;
          socket.onerror = null;
          socket.onmessage = null;
          socket.close();
        } catch (e) {
          this._log('Socket exception', e);
          // Meh..
        }

        this.setProperties({
          _state: CLOSING
        });
      }
    },

    _opened: function _opened() {
      this._log('opened');
      var now = new Date().getTime();

      var at = this.get('_disconnectedAt');
      var after = null;
      if (at) {
        after = now - at;
      }

      this.setProperties({
        _state: CONNECTED,
        _framesReceived: 0,
        _disconnectedAt: null
      });

      this.trigger('connected', this.get('_tries'), after);
      this._resetWatchdog();
      _ember['default'].run.cancel(this.get('_reconnectTimer'));
    },

    _message: function _message(event) {
      this._resetWatchdog();
      this.set('_tries', 0);
      this.incrementProperty('_framesReceived');
      this.trigger('message', event);
    },

    _resetWatchdog: function _resetWatchdog() {
      if (this.get('_frameTimer')) {
        _ember['default'].run.cancel(this.get('_frameTimer'));
      }

      var timeout = this.get('frameTimeout');
      if (timeout && this.get('_state') === CONNECTED) {
        this.set('_frameTimer', _ember['default'].run.later(this, function () {
          this._log('Socket watchdog expired after', timeout, 'closing');
          this._close();
        }, timeout));
      }
    },

    _error: function _error() {
      this.set('_closingId', this.get('_socket.__sockId'));
      this._log('error');
    },

    _closed: function _closed() {
      console.log('Socket ' + this.get('_closingId') + ' closed');

      this.set('_closingId', null);
      this.set('_socket', null);
      _ember['default'].run.cancel(this.get('_reconnectTimer'));
      _ember['default'].run.cancel(this.get('_frameTimer'));

      var cbs = this.get('_disconnectCbs') || [];
      while (cbs.get('length')) {
        var cb = cbs.popObject();
        cb.apply(this);
      }

      var wasConnected = false;
      if ([CONNECTED, CLOSING].indexOf(this.get('_state')) >= 0) {
        this.trigger('disconnected');
        wasConnected = true;
      }

      if (this.get('_disconnectedAt') === null) {
        this.set('_disconnectedAt', new Date().getTime());
      }

      if (_uiUtilsPlatform.isSafari && !wasConnected && this.get('url').indexOf('wss://') === 0) {
        this.set('autoReconnect', false);
        this.set('_state', DISCONNECTED);
        if (!safariWarningShown) {
          safariWarningShown = true;
          window.l('service:growl').error('Error connecting to WebSocket', 'Safari does not support WebSockets connecting to a self-signed or unrecognized certificate.  Use http:// instead of https:// or reconfigure the server with a valid certificate from a recognized certificate authority.  Streaming stats, logs, shell/console, and auto-updating of the state of resources in the UI will not work until this is resolved.');
        }
      } else if (this.get('autoReconnect')) {
        this.set('_state', RECONNECTING);
        this.incrementProperty('_tries');
        var delay = Math.max(1000, Math.min(1000 * this.get('_tries'), 30000));
        this.set('_reconnectTimer', _ember['default'].run.later(this, this.connect, delay));
      } else {
        this.set('_state', DISCONNECTED);
      }
    },

    _log: function _log() /*arguments*/{
      var args = ['Socket'];
      for (var i = 0; i < arguments.length; i++) {
        args.push(arguments[i]);
      }

      args.push('(state=' + this.get('_state') + ', id=' + this.get('_socket.__sockId') + ')');

      console.log(args.join(" "));
    }
  });
});