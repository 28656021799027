define('ui/components/schema/input-certificate/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    field: null,
    value: null,

    choices: null,
    'default': _ember['default'].computed.alias('field.default'),
    loading: true,

    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.get('store').findAll('certificate').then(function (choices) {
        var def = _this.get('default');
        if (_this.get('value') === undefined) {
          if (def) {
            var match = choices.filterBy('name', def);
            if (match.length > 0) {
              _this.set('value', def);
            } else {
              _this.set('value', null);
            }
          } else {
            _this.set('value', null);
          }
        }

        _this.setProperties({
          loading: false,
          choices: choices
        });
      });
    }
  });
});