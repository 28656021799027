define('ui/mixins/subscribe', ['exports', 'ember', 'ui/utils/socket', 'ui/utils/constants'], function (exports, _ember, _uiUtilsSocket, _uiUtilsConstants) {
  var get = _ember['default'].get;

  var ORCHESTRATION_STACKS = ['k8s', 'swarm', 'mesos'];

  exports['default'] = _ember['default'].Mixin.create({
    k8s: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    'tab-session': _ember['default'].inject.service(),

    subscribeSocket: null,
    reconnect: true,
    connected: false,
    k8sUidBlacklist: null,

    init: function init() {
      var _this = this;

      this._super();
      this.set('k8sUidBlacklist', []);

      var store = this.get('store');

      var socket = _uiUtilsSocket['default'].create();

      socket.on('message', function (event) {
        _ember['default'].run.schedule('actions', _this, function () {
          // Fail-safe: make sure the message is for this project
          var currentProject = this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT);
          var metadata = socket.getMetadata();
          var socketProject = metadata.projectId;
          if (currentProject !== socketProject) {
            console.error('Subscribe ignoring message, current=' + currentProject + ' socket=' + socketProject + ' ' + this.forStr());
            this.connectSubscribe();
            return;
          }

          var d = JSON.parse(event.data);
          var resource = undefined;
          if (d.data && d.data.resource) {
            resource = store._typeify(d.data.resource);
            d.data.resource = resource;
          }

          //this._trySend('subscribeMessage',d);

          switch (d.name) {
            case 'resource.change':
              var key = d.resourceType + 'Changed';
              if (this[key]) {
                this[key](d);
              }

              if (resource && _uiUtilsConstants['default'].REMOVEDISH_STATES.includes(resource.state)) {
                var type = get(resource, 'type');
                var baseType = get(resource, 'baseType');

                store._remove(type, resource);

                if (baseType && type !== baseType) {
                  store._remove(baseType, resource);
                }
              }
              break;
            case 'logout':
              this.send('logout', false);
              break;
            case 'ping':
              this.subscribePing(d);
              break;
          }
        });
      });

      socket.on('connected', function (tries, after) {
        _this.subscribeConnected(tries, after);
      });

      socket.on('disconnected', function () {
        _this.subscribeDisconnected(_this.get('tries'));
      });

      this.set('subscribeSocket', socket);
    },

    connectSubscribe: function connectSubscribe() {
      var socket = this.get('subscribeSocket');
      var projectId = this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT);
      var url = ("ws://" + window.location.host + this.get('app.wsEndpoint')).replace(this.get('app.projectToken'), projectId);

      this.set('reconnect', true);

      socket.setProperties({
        url: url,
        autoReconnect: true
      });
      socket.reconnect({ projectId: projectId });
    },

    disconnectSubscribe: function disconnectSubscribe(cb) {
      this.set('reconnect', false);
      var socket = this.get('subscribeSocket');
      if (socket && socket._state !== 'disconnected') {
        console.log('Subscribe disconnect ' + this.forStr());
        socket.disconnect(cb);
      } else if (cb) {
        cb();
      }
    },

    forStr: function forStr() {
      var out = '';
      var socket = this.get('subscribeSocket');
      var projectId = this.get('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT);
      if (socket) {
        out = '(projectId=' + projectId + ', sockId=' + socket.getId() + ')';
      }

      return out;
    },

    // WebSocket connected
    subscribeConnected: function subscribeConnected(tries, msec) {
      this.set('connected', true);

      var msg = 'Subscribe connected ' + this.forStr();
      if (tries > 0) {
        msg += ' (after ' + tries + ' ' + (tries === 1 ? 'try' : 'tries');
        if (msec) {
          msg += ', ' + msec / 1000 + ' sec';
        }

        msg += ')';
      }

      console.log(msg);
    },

    // WebSocket disconnected (unexpectedly)
    subscribeDisconnected: function subscribeDisconnected() {
      this.set('connected', false);

      console.log('Subscribe disconnected ' + this.forStr());
      if (this.get('reconnect')) {
        this.connectSubscribe();
      }
    },

    subscribePing: function subscribePing() {
      console.log('Subscribe ping ' + this.forStr());
    },

    stackChanged: function stackChanged(change) {
      var stack = change.data.resource;
      var info = stack.get('externalIdInfo');

      if (info && info.name && ORCHESTRATION_STACKS.includes(info.name)) {
        _ember['default'].run.once(this, function () {
          var _this2 = this;

          this.get('projects.current').reload().then(function () {
            _this2.get('projects').updateOrchestrationState();
          });
        });
      }
    }
  });
});