define("ui/settings/projects/new/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 11,
            "column": 0
          }
        },
        "moduleName": "ui/settings/projects/new/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["inline", "view-edit-project", [], ["project", ["subexpr", "@mut", [["get", "model.project", ["loc", [null, [2, 10], [2, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "allProjects", ["subexpr", "@mut", [["get", "model.all", ["loc", [null, [3, 14], [3, 23]]], 0, 0, 0, 0]], [], [], 0, 0], "projectTemplates", ["subexpr", "@mut", [["get", "availableProjectTemplates", ["loc", [null, [4, 19], [4, 44]]], 0, 0, 0, 0]], [], [], 0, 0], "initialStacks", null, "showEdit", true, "editing", false, "done", ["subexpr", "action", ["done"], [], ["loc", [null, [8, 7], [8, 22]]], 0, 0], "cancel", ["subexpr", "action", ["cancel"], [], ["loc", [null, [9, 9], [9, 26]]], 0, 0]], ["loc", [null, [1, 0], [10, 2]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});