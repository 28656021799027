define('ui/initializers/growl', ['exports'], function (exports) {
  exports.initialize = initialize;

  function initialize(application) {
    application.inject('controller', 'session', 'service:growl');
    application.inject('route', 'session', 'service:growl');
    application.inject('model', 'session', 'service:growl');
    application.inject('component', 'session', 'service:growl');
  }

  exports['default'] = {
    name: 'growl',
    initialize: initialize
  };
});