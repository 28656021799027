define('ui/components/new-aliasservice/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'ui/mixins/new-service-alias'], function (exports, _ember, _uiMixinsNewOrEdit, _uiMixinsNewServiceAlias) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsNewOrEdit['default'], _uiMixinsNewServiceAlias['default'], {

    actions: {
      done: function done() {
        this.sendAction('done');
      },

      cancel: function cancel() {
        this.sendAction('cancel');
      }
    },

    didInsertElement: function didInsertElement() {
      this.$('INPUT')[0].focus();
    },

    doneSaving: function doneSaving() {
      this.send('done');
    }
  });
});