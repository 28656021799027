define('ui/components/add-subpod/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'a',
    model: null,
    currentController: null,
    label: 'Add',

    classNames: ['btn', 'btn-primary', 'add-to-pod'],

    click: function click() {
      this.sendAction();
    }
  });
});