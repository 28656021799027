define('ui/admin-tab/accounts/new/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    model: function model() {
      var account = this.get('userStore').createRecord({ type: 'account', kind: 'user' });
      var credential = this.get('userStore').createRecord({ type: 'password' });

      return _ember['default'].Object.create({
        account: account,
        credential: credential
      });
    },

    resetController: function resetController(controller, isExisting /*, transition*/) {
      if (isExisting) {
        controller.set('errors', null);
      }
    }
  });
});