define('ui/components/page-header/component', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/navigation-tree', 'ui/mixins/hover-dropdowns'], function (exports, _ember, _uiUtilsConstants, _uiUtilsNavigationTree, _uiMixinsHoverDropdowns) {

  function fnOrValue(val, ctx) {
    if (typeof val === 'function') {
      return val.call(ctx);
    } else {
      return val;
    }
  }

  exports['default'] = _ember['default'].Component.extend(_uiMixinsHoverDropdowns['default'], {
    // Inputs
    hasCattleSystem: null,
    currentPath: null,

    // Injections
    projects: _ember['default'].inject.service(),
    project: _ember['default'].computed.alias('projects.current'),
    projectId: _ember['default'].computed.alias('tab-session.' + _uiUtilsConstants['default'].TABSESSION.PROJECT),
    catalog: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),
    access: _ember['default'].inject.service(),
    prefs: _ember['default'].inject.service(),
    isAdmin: _ember['default'].computed.alias('access.admin'),
    hasVm: _ember['default'].computed.alias('project.virtualMachine'),
    hasSwarm: _ember['default'].computed.alias('projects.orchestrationState.hasSwarm'),
    hasKubernetes: _ember['default'].computed.alias('projects.orchestrationState.hasKubernetes'),
    hasMesos: _ember['default'].computed.alias('projects.orchestrationState.hasMesos'),
    swarmReady: _ember['default'].computed.alias('projects.orchestrationState.swarmReady'),
    mesosReady: _ember['default'].computed.alias('projects.orchestrationState.mesosReady'),
    stacks: null,
    services: null,

    // Component options
    tagName: 'header',
    classNames: ['clearfix', 'no-select'],
    dropdownSelector: '.navbar .dropdown',

    actions: {
      switchProject: function switchProject(id) {
        this.sendAction('switchProject', id);
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.set('stacks', this.get('store').all('stack'));
      this.set('services', this.get('store').all('service'));
      this.set('hosts', this.get('store').all('host'));
      this.set('stackSchema', this.get('store').getById('schema', 'stack'));
      this.updateNavTree();
    },

    // This computed property generates the active list of choices to display
    navTree: null,
    updateNavTree: function updateNavTree() {
      var _this = this;

      var services = this.get('services');
      var servicesNav = [];
      if (services) {
        services.forEach(function (ele) {
          var serviceApp = ele.get('serviceApp');
          if (serviceApp) {
            var exist = (0, _uiUtilsNavigationTree.get)().findBy('id', ele.id);
            if (!exist) {
              servicesNav.pushObject({
                id: ele.id,
                label: serviceApp.label,
                url: serviceApp.url,
                target: '_blank',
                ctx: [_this.get('projectId')]
              });
            }
          }
        });
      }

      var out = (0, _uiUtilsNavigationTree.get)().concat(servicesNav).filter(function (item) {
        if (typeof item.condition === 'function') {
          if (!item.condition.call(_this)) {
            return false;
          }
        }

        item.localizedLabel = fnOrValue(item.localizedLabel, _this);
        item.label = fnOrValue(item.label, _this);
        item.route = fnOrValue(item.route, _this);
        item.ctx = (item.ctx || []).map(function (prop) {
          return fnOrValue(prop, _this);
        });
        item.submenu = fnOrValue(item.submenu, _this);

        item.showAlert = false;
        if (typeof item.alertCondition === 'function' && item.alertCondition.call(_this) === true) {
          item.showAlert = true;
        }

        item.submenu = (item.submenu || []).filter(function (subitem) {
          if (typeof subitem.condition === 'function' && !subitem.condition.call(_this)) {
            return false;
          }

          subitem.localizedLabel = fnOrValue(subitem.localizedLabel, _this);
          subitem.label = fnOrValue(subitem.label, _this);
          subitem.route = fnOrValue(subitem.route, _this);
          subitem.ctx = (subitem.ctx || []).map(function (prop) {
            return fnOrValue(prop, _this);
          });

          return true;
        });

        return true;
      });

      this.set('navTree', out);
    },

    serviceAppChanged: (function () {
      var services = this.get('services') || [];
      var newServices = services.find(function (ele) {
        var serviceApp = ele.get('serviceApp');
        if (serviceApp) {
          var exist = (0, _uiUtilsNavigationTree.get)().findBy('id', ele.id);
          return !exist;
        }
        return false;
      });

      if (newServices) {
        _ember['default'].run.once(this, 'updateNavTree');
      }
    }).observes('services.@each.serviceApp'),

    shouldUpdateNavTree: (function () {
      _ember['default'].run.once(this, 'updateNavTree');
    }).observes('projectId', 'projects.orchestrationState', 'project.virtualMachine', 'stacks.@each.group', 'catalog.catalogs.@each.{id,name}', 'settings.' + _uiUtilsConstants['default'].SETTING.CATALOG_URL, 'prefs.' + _uiUtilsConstants['default'].PREFS.ACCESS_WARNING, 'access.enabled', 'isAdmin'),

    // Utilities you can use in the condition() function to decide if an item is shown or hidden,
    // beyond things listed in "Inputs"
    hasProject: (function () {
      return !!this.get('project');
    }).property('project'),

    canEdit: (function () {
      return !!this.get('project.actionLinks.update') || !!this.get('project.actionLinks.setmembers');
    }).property('project.actionLinks.{update,setmembers}'),

    kubernetesReady: (function () {
      return this.get('hasKubernetes') && this.get('projects.orchestrationState.kubernetesReady');
    }).property('hasKubernetes', 'projects.orchestrationState.kubernetesReady')
  });
});