define('ui/helpers/concat-str', ['exports', 'ember'], function (exports, _ember) {
  exports.concatStr = concatStr;

  function concatStr(params, hash) {
    var chr = hash.character === undefined ? ' ' : hash.character;

    if (_ember['default'].isArray(params[0])) {
      return params[0].join(chr);
    } else {
      return params.join(chr);
    }
  }

  exports['default'] = _ember['default'].Helper.helper(concatStr);
});