define("ui/components/view-edit-project/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 6
              },
              "end": {
                "line": 6,
                "column": 6
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode(" ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.showEdit.edit"], [], ["loc", [null, [5, 8], [5, 45]]], 0, 0], ["content", "originalProject.displayName", ["loc", [null, [5, 46], [5, 77]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 6
              },
              "end": {
                "line": 8,
                "column": 6
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.showEdit.add"], [], ["loc", [null, [7, 8], [7, 44]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 10,
              "column": 2
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h1");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "editing", ["loc", [null, [4, 12], [4, 19]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 6], [8, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 8
              },
              "end": {
                "line": 11,
                "column": 70
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.header"], [], ["loc", [null, [11, 40], [11, 70]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 6
              },
              "end": {
                "line": 14,
                "column": 6
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("          ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "select-dot", [], ["model", ["subexpr", "@mut", [["get", "obj", ["loc", [null, [13, 29], [13, 32]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [13, 10], [13, 34]]], 0, 0]],
          locals: ["obj"],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 10,
              "column": 2
            },
            "end": {
              "line": 20,
              "column": 2
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h1");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "pull-right");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element27 = dom.childAt(fragment, [5]);
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[2] = dom.createMorphAt(element27, 1, 1);
          morphs[3] = dom.createMorphAt(element27, 3, 3);
          return morphs;
        },
        statements: [["block", "link-to", ["settings.projects"], [], 0, null, ["loc", [null, [11, 8], [11, 82]]]], ["block", "power-select", [], ["options", ["subexpr", "@mut", [["get", "allProjects", ["loc", [null, [12, 30], [12, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "selected", ["subexpr", "@mut", [["get", "project", ["loc", [null, [12, 51], [12, 58]]], 0, 0, 0, 0]], [], [], 0, 0], "onchange", ["subexpr", "action", ["changeProject"], [], ["loc", [null, [12, 68], [12, 92]]], 0, 0], "searchField", "displayName"], 1, null, ["loc", [null, [12, 6], [14, 23]]]], ["inline", "header-state", [], ["model", ["subexpr", "@mut", [["get", "project", ["loc", [null, [17, 27], [17, 34]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [17, 6], [17, 36]]], 0, 0], ["inline", "action-menu", [], ["model", ["subexpr", "@mut", [["get", "project", ["loc", [null, [18, 26], [18, 33]]], 0, 0, 0, 0]], [], [], 0, 0], "size", "sm", "classNames", "r-ml10"], ["loc", [null, [18, 6], [18, 65]]], 0, 0]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 12
                },
                "end": {
                  "line": 45,
                  "column": 12
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("              ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "href", "#");
              dom.setAttribute(el1, "tabindex", "1");
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("img");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "clip");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n              ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element23 = dom.childAt(fragment, [1]);
              var element24 = dom.childAt(element23, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createAttrMorph(element23, 'class');
              morphs[1] = dom.createElementMorph(element23);
              morphs[2] = dom.createAttrMorph(element24, 'src');
              morphs[3] = dom.createMorphAt(dom.childAt(element23, [3]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["nav-box-item driver orchestration-driver r-p5 ", ["subexpr", "if", [["subexpr", "eq", [["get", "project.projectTemplateId", ["loc", [null, [41, 136], [41, 161]]], 0, 0, 0, 0], ["get", "tpl.id", ["loc", [null, [41, 162], [41, 168]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 132], [41, 169]]], 0, 0], "active"], [], ["loc", [null, [41, 127], [41, 180]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["selectTemplate", ["get", "tpl.id", ["loc", [null, [41, 65], [41, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 39], [41, 73]]], 0, 0], ["attribute", "src", ["concat", [["get", "tpl.image", ["loc", [null, [42, 28], [42, 37]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "tpl.name", ["loc", [null, [43, 34], [43, 46]]], 0, 0, 0, 0]],
            locals: ["tpl"],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 49,
                  "column": 8
                },
                "end": {
                  "line": 53,
                  "column": 8
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 2,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "text-center");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "class", "text-capitalize");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(": ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element22 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(dom.childAt(element22, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(element22, 3, 3);
              return morphs;
            },
            statements: [["content", "category", ["loc", [null, [51, 43], [51, 55]]], 0, 0, 0, 0], ["content", "names", ["loc", [null, [51, 65], [51, 74]]], 0, 0, 0, 0]],
            locals: ["category", "names"],
            templates: []
          };
        })();
        var child2 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 8
                },
                "end": {
                  "line": 57,
                  "column": 8
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "text-center");
              var el2 = dom.createTextNode("\n            ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("label");
              dom.setAttribute(el2, "class", "text-capitalize");
              var el3 = dom.createTextNode("Orchestration");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(": Cattle\n          ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 2
              },
              "end": {
                "line": 60,
                "column": 2
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("section");
            dom.setAttribute(el1, "class", "well");
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "row");
            var el3 = dom.createTextNode("\n        ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("div");
            dom.setAttribute(el3, "class", "col-xs-12");
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("label");
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n          ");
            dom.appendChild(el3, el4);
            var el4 = dom.createElement("div");
            dom.setAttribute(el4, "class", "nav nav-boxes checked-active");
            var el5 = dom.createTextNode("\n");
            dom.appendChild(el4, el5);
            var el5 = dom.createComment("");
            dom.appendChild(el4, el5);
            var el5 = dom.createTextNode("          ");
            dom.appendChild(el4, el5);
            dom.appendChild(el3, el4);
            var el4 = dom.createTextNode("\n        ");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("      ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element25 = dom.childAt(fragment, [1, 1]);
            var element26 = dom.childAt(element25, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element26, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element26, [3]), 1, 1);
            morphs[2] = dom.createMorphAt(element25, 3, 3);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.showEdit.template"], [], ["loc", [null, [38, 17], [38, 58]]], 0, 0], ["block", "each", [["get", "templateChoices", ["loc", [null, [40, 20], [40, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [40, 12], [45, 21]]]], ["block", "each-in", [["get", "selectedProjectTemplate.summary", ["loc", [null, [49, 19], [49, 50]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [49, 8], [57, 20]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 23,
              "column": 0
            },
            "end": {
              "line": 61,
              "column": 0
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          dom.setAttribute(el1, "class", "well r-mb0");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["inline", "form-name-description", [], ["model", ["subexpr", "@mut", [["get", "project", ["loc", [null, [26, 12], [26, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "namePlaceholder", "viewEditDescription.form.name.placeholder", "descriptionPlaceholder", "viewEditDescription.form.description.placeholder", "nameDisabled", ["subexpr", "not", [["get", "canEditProject", ["loc", [null, [29, 24], [29, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 19], [29, 39]]], 0, 0], "descriptionDisabled", ["subexpr", "not", [["get", "canEditProject", ["loc", [null, [30, 31], [30, 45]]], 0, 0, 0, 0]], [], ["loc", [null, [30, 26], [30, 46]]], 0, 0]], ["loc", [null, [25, 4], [31, 6]]], 0, 0], ["block", "unless", [["get", "editing", ["loc", [null, [34, 12], [34, 19]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [34, 2], [60, 13]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child3 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 61,
                "column": 0
              },
              "end": {
                "line": 66,
                "column": 0
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("  ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("section");
            dom.setAttribute(el1, "class", "well r-mb0");
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("label");
            dom.setAttribute(el2, "class", "inline-block r-m0");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(": ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("p");
            dom.setAttribute(el2, "class", "inline-block r-m0");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n  ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element21 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(dom.childAt(element21, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element21, [3]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.description"], [], ["loc", [null, [63, 37], [63, 72]]], 0, 0], ["content", "project.description", ["loc", [null, [64, 33], [64, 56]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 61,
              "column": 0
            },
            "end": {
              "line": 66,
              "column": 0
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "project.description", ["loc", [null, [61, 10], [61, 29]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [61, 0], [66, 0]]]]],
        locals: [],
        templates: [child0]
      };
    })();
    var child4 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 72,
              "column": 4
            },
            "end": {
              "line": 76,
              "column": 4
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "form-group");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "input-identity", [], ["action", "checkMember", "onError", "error"], ["loc", [null, [74, 8], [74, 63]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child5 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 85,
                "column": 12
              },
              "end": {
                "line": 85,
                "column": 54
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("th");
            dom.setAttribute(el1, "width", "40");
            var el2 = dom.createTextNode(" ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 98,
                    "column": 20
                  },
                  "end": {
                    "line": 100,
                    "column": 20
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("option");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element16 = dom.childAt(fragment, [1]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element16, 'value');
                morphs[1] = dom.createAttrMorph(element16, 'selected');
                morphs[2] = dom.createMorphAt(element16, 0, 0);
                return morphs;
              },
              statements: [["attribute", "value", ["get", "choice.value", ["loc", [null, [99, 38], [99, 50]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "selected", ["subexpr", "eq", [["get", "member.role", ["loc", [null, [99, 67], [99, 78]]], 0, 0, 0, 0], ["get", "choice.value", ["loc", [null, [99, 79], [99, 91]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [99, 93]]], 0, 0], 0, 0, 0, 0], ["inline", "t", [["get", "choice.label", ["loc", [null, [99, 98], [99, 110]]], 0, 0, 0, 0]], [], ["loc", [null, [99, 94], [99, 112]]], 0, 0]],
              locals: ["choice"],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 96,
                  "column": 16
                },
                "end": {
                  "line": 102,
                  "column": 16
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("select");
              dom.setAttribute(el1, "class", "form-control input-sm");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                  ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element17 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element17, 'onchange');
              morphs[1] = dom.createMorphAt(element17, 1, 1);
              return morphs;
            },
            statements: [["attribute", "onchange", ["subexpr", "action", [["subexpr", "mut", [["get", "member.role", ["loc", [null, [97, 79], [97, 90]]], 0, 0, 0, 0]], [], ["loc", [null, [97, 74], [97, 91]]], 0, 0]], ["value", "target.value"], ["loc", [null, [null, null], [97, 114]]], 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "roleOptions", ["loc", [null, [98, 28], [98, 39]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [98, 20], [100, 29]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 102,
                  "column": 16
                },
                "end": {
                  "line": 104,
                  "column": 16
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                  ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "uc-first", [["get", "member.role", ["loc", [null, [103, 29], [103, 40]]], 0, 0, 0, 0]], [], ["loc", [null, [103, 18], [103, 42]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child2 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 108,
                    "column": 18
                  },
                  "end": {
                    "line": 110,
                    "column": 18
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "btn btn-primary btn-sm pull-right gh-action");
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "icon icon-minus");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element15 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createElementMorph(element15);
                return morphs;
              },
              statements: [["element", "action", ["removeMember", ["get", "member", ["loc", [null, [109, 104], [109, 110]]], 0, 0, 0, 0]], [], ["loc", [null, [109, 80], [109, 112]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 110,
                    "column": 18
                  },
                  "end": {
                    "line": 112,
                    "column": 18
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                     \n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 106,
                  "column": 14
                },
                "end": {
                  "line": 114,
                  "column": 14
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
              return morphs;
            },
            statements: [["block", "if", [["get", "accessEnabled", ["loc", [null, [108, 24], [108, 37]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [108, 18], [112, 25]]]]],
            locals: [],
            templates: [child0, child1]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 89,
                "column": 10
              },
              "end": {
                "line": 116,
                "column": 10
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element18 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createMorphAt(dom.childAt(element18, [1]), 1, 1);
            morphs[1] = dom.createMorphAt(dom.childAt(element18, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element18, [5]), 1, 1);
            morphs[3] = dom.createMorphAt(element18, 7, 7);
            return morphs;
          },
          statements: [["inline", "identity-block", [], ["identity", ["subexpr", "@mut", [["get", "member", ["loc", [null, [92, 42], [92, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [92, 16], [92, 50]]], 0, 0], ["content", "member.displayType", ["loc", [null, [94, 18], [94, 40]]], 0, 0, 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [96, 22], [96, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [96, 16], [104, 23]]]], ["block", "if", [["get", "showEdit", ["loc", [null, [106, 20], [106, 28]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [106, 14], [114, 21]]]]],
          locals: ["member"],
          templates: [child0, child1, child2]
        };
      })();
      var child2 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 116,
                "column": 10
              },
              "end": {
                "line": 122,
                "column": 10
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n              ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2, "colspan", "3");
            dom.setAttribute(el2, "class", "text-center text-muted");
            var el3 = dom.createTextNode("\n                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n              ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.showEdit.noMembers"], [], ["loc", [null, [119, 16], [119, 58]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 78,
              "column": 4
            },
            "end": {
              "line": 126,
              "column": 4
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1, "class", "grid fixed");
          dom.setAttribute(el1, "style", "margin-bottom: 0;");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("thead");
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n            ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("        ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("hr");
          dom.setAttribute(el1, "class", "r-m0");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element19 = dom.childAt(fragment, [1]);
          var element20 = dom.childAt(element19, [1, 1]);
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(element20, 1, 1);
          morphs[1] = dom.createMorphAt(element20, 3, 3);
          morphs[2] = dom.createMorphAt(element20, 5, 5);
          morphs[3] = dom.createMorphAt(element20, 7, 7);
          morphs[4] = dom.createMorphAt(dom.childAt(element19, [3]), 1, 1);
          return morphs;
        },
        statements: [["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [82, 35], [82, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "name", "label", "generic.name"], ["loc", [null, [82, 12], [82, 94]]], 0, 0], ["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [83, 35], [83, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "type", "label", "generic.type"], ["loc", [null, [83, 12], [83, 94]]], 0, 0], ["inline", "sortable-th", [], ["sortable", ["subexpr", "@mut", [["get", "this", ["loc", [null, [84, 35], [84, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "changeSort", "name", "role", "label", "generic.role"], ["loc", [null, [84, 12], [84, 94]]], 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [85, 18], [85, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [85, 12], [85, 61]]]], ["block", "each", [["get", "arranged", ["loc", [null, [89, 18], [89, 26]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [89, 10], [122, 19]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    })();
    var child6 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 126,
              "column": 4
            },
            "end": {
              "line": 130,
              "column": 4
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("p");
          dom.setAttribute(el1, "class", "help-block text-center");
          var el2 = dom.createTextNode("\n        ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["inline", "format-html-message", ["viewEditProject.helpBlock"], [], ["loc", [null, [128, 8], [128, 59]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child7 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 146,
                "column": 6
              },
              "end": {
                "line": 152,
                "column": 6
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "row");
            var el2 = dom.createTextNode("\n          ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            dom.setAttribute(el2, "class", "col-xs-12");
            var el3 = dom.createTextNode("\n            ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("p");
            var el4 = dom.createComment("");
            dom.appendChild(el3, el4);
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n          ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
            return morphs;
          },
          statements: [["inline", "t", ["viewEditProject.networkPolicy.noManager"], [], ["loc", [null, [149, 15], [149, 62]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 152,
                  "column": 6
                },
                "end": {
                  "line": 158,
                  "column": 6
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-xs-12");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("p");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
              return morphs;
            },
            statements: [["inline", "t", ["viewEditProject.networkPolicy.unsupported"], [], ["loc", [null, [155, 15], [155, 64]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 162,
                    "column": 12
                  },
                  "end": {
                    "line": 169,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1, 1]);
                var element7 = dom.childAt(fragment, [3, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element6, 0, 0);
                morphs[1] = dom.createMorphAt(element6, 2, 2);
                morphs[2] = dom.createMorphAt(element7, 0, 0);
                morphs[3] = dom.createMorphAt(element7, 2, 2);
                return morphs;
              },
              statements: [["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinLinked.action", ["loc", [null, [164, 48], [164, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "allow"], ["loc", [null, [164, 23], [164, 85]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.allow"], [], ["loc", [null, [164, 91], [164, 134]]], 0, 0], ["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinLinked.action", ["loc", [null, [167, 48], [167, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "deny"], ["loc", [null, [167, 23], [167, 84]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.deny"], [], ["loc", [null, [167, 90], [167, 132]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 169,
                    "column": 12
                  },
                  "end": {
                    "line": 175,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "row");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("div");
                dom.setAttribute(el2, "class", "col-xs-12");
                var el3 = dom.createTextNode("\n                  ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("p");
                dom.setAttribute(el3, "class", "text-muted");
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n                ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1, 1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "t", ["viewEditProject.networkPolicy.description"], [], ["loc", [null, [172, 40], [172, 89]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 179,
                    "column": 12
                  },
                  "end": {
                    "line": 186,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element4 = dom.childAt(fragment, [1, 1]);
                var element5 = dom.childAt(fragment, [3, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element4, 0, 0);
                morphs[1] = dom.createMorphAt(element4, 2, 2);
                morphs[2] = dom.createMorphAt(element5, 0, 0);
                morphs[3] = dom.createMorphAt(element5, 2, 2);
                return morphs;
              },
              statements: [["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinService.action", ["loc", [null, [181, 48], [181, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "allow"], ["loc", [null, [181, 23], [181, 86]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.allow"], [], ["loc", [null, [181, 92], [181, 135]]], 0, 0], ["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinService.action", ["loc", [null, [184, 48], [184, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "deny"], ["loc", [null, [184, 23], [184, 85]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.deny"], [], ["loc", [null, [184, 91], [184, 133]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 186,
                    "column": 12
                  },
                  "end": {
                    "line": 188,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "uc-first", [["get", "npWithinService.action", ["loc", [null, [187, 30], [187, 52]]], 0, 0, 0, 0]], [], ["loc", [null, [187, 19], [187, 54]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 192,
                    "column": 12
                  },
                  "end": {
                    "line": 199,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1, 1]);
                var element3 = dom.childAt(fragment, [3, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element2, 0, 0);
                morphs[1] = dom.createMorphAt(element2, 2, 2);
                morphs[2] = dom.createMorphAt(element3, 0, 0);
                morphs[3] = dom.createMorphAt(element3, 2, 2);
                return morphs;
              },
              statements: [["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinStack.action", ["loc", [null, [194, 48], [194, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "allow"], ["loc", [null, [194, 23], [194, 84]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.allow"], [], ["loc", [null, [194, 90], [194, 133]]], 0, 0], ["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "npWithinStack.action", ["loc", [null, [197, 48], [197, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "deny"], ["loc", [null, [197, 23], [197, 83]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.deny"], [], ["loc", [null, [197, 89], [197, 131]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child5 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 199,
                    "column": 12
                  },
                  "end": {
                    "line": 201,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "uc-first", [["get", "npWithinStack.action", ["loc", [null, [200, 30], [200, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [200, 19], [200, 52]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child6 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 205,
                    "column": 12
                  },
                  "end": {
                    "line": 212,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "radio");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("label");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode(" ");
                dom.appendChild(el2, el3);
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1, 1]);
                var element1 = dom.childAt(fragment, [3, 1]);
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(element0, 0, 0);
                morphs[1] = dom.createMorphAt(element0, 2, 2);
                morphs[2] = dom.createMorphAt(element1, 0, 0);
                morphs[3] = dom.createMorphAt(element1, 2, 2);
                return morphs;
              },
              statements: [["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "network.defaultPolicyAction", ["loc", [null, [207, 48], [207, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "allow"], ["loc", [null, [207, 23], [207, 91]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.allow"], [], ["loc", [null, [207, 97], [207, 140]]], 0, 0], ["inline", "radio-button", [], ["selection", ["subexpr", "@mut", [["get", "network.defaultPolicyAction", ["loc", [null, [210, 48], [210, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "value", "deny"], ["loc", [null, [210, 23], [210, 90]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.deny"], [], ["loc", [null, [210, 96], [210, 138]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child7 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 212,
                    "column": 12
                  },
                  "end": {
                    "line": 214,
                    "column": 12
                  }
                },
                "moduleName": "ui/components/view-edit-project/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                return morphs;
              },
              statements: [["inline", "uc-first", [["get", "network.defaultPolicyAction", ["loc", [null, [213, 30], [213, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [213, 19], [213, 59]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 158,
                  "column": 6
                },
                "end": {
                  "line": 217,
                  "column": 6
                }
              },
              "moduleName": "ui/components/view-edit-project/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "row");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-3 col-sm-6");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-3 col-sm-6");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-3 col-sm-6");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "col-md-3 col-sm-6");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("label");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element8 = dom.childAt(fragment, [1]);
              var element9 = dom.childAt(element8, [1]);
              var element10 = dom.childAt(element8, [3]);
              var element11 = dom.childAt(element8, [5]);
              var element12 = dom.childAt(element8, [7]);
              var morphs = new Array(8);
              morphs[0] = dom.createMorphAt(dom.childAt(element9, [1]), 0, 0);
              morphs[1] = dom.createMorphAt(element9, 3, 3);
              morphs[2] = dom.createMorphAt(dom.childAt(element10, [1]), 0, 0);
              morphs[3] = dom.createMorphAt(element10, 3, 3);
              morphs[4] = dom.createMorphAt(dom.childAt(element11, [1]), 0, 0);
              morphs[5] = dom.createMorphAt(element11, 3, 3);
              morphs[6] = dom.createMorphAt(dom.childAt(element12, [1]), 0, 0);
              morphs[7] = dom.createMorphAt(element12, 3, 3);
              return morphs;
            },
            statements: [["inline", "t", ["viewEditProject.networkPolicy.withinLinked"], [], ["loc", [null, [161, 19], [161, 69]]], 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [162, 18], [162, 26]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [162, 12], [175, 19]]]], ["inline", "t", ["viewEditProject.networkPolicy.withinService"], [], ["loc", [null, [178, 19], [178, 70]]], 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [179, 18], [179, 26]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [179, 12], [188, 19]]]], ["inline", "t", ["viewEditProject.networkPolicy.withinStack"], [], ["loc", [null, [191, 19], [191, 68]]], 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [192, 18], [192, 26]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [192, 12], [201, 19]]]], ["inline", "t", ["viewEditProject.networkPolicy.default"], [], ["loc", [null, [204, 19], [204, 64]]], 0, 0], ["block", "if", [["get", "showEdit", ["loc", [null, [205, 18], [205, 26]]], 0, 0, 0, 0]], [], 6, 7, ["loc", [null, [205, 12], [214, 19]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 152,
                "column": 6
              },
              "end": {
                "line": 217,
                "column": 6
              }
            },
            "moduleName": "ui/components/view-edit-project/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "hasUnsupportedPolicy", ["loc", [null, [152, 16], [152, 36]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [152, 6], [217, 6]]]]],
          locals: [],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 135,
              "column": 0
            },
            "end": {
              "line": 220,
              "column": 0
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("section");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h4");
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("hr");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "well");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "class", "row");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("div");
          dom.setAttribute(el4, "class", "col-xs-12");
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("p");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("hr");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element13 = dom.childAt(fragment, [1]);
          var element14 = dom.childAt(element13, [5]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(dom.childAt(element13, [1]), 0, 0);
          morphs[1] = dom.createMorphAt(dom.childAt(element14, [1, 1, 1]), 0, 0);
          morphs[2] = dom.createMorphAt(element14, 3, 3);
          return morphs;
        },
        statements: [["inline", "t", ["viewEditProject.networkPolicy.label"], [], ["loc", [null, [137, 8], [137, 51]]], 0, 0], ["inline", "t", ["viewEditProject.networkPolicy.description"], [], ["loc", [null, [142, 13], [142, 62]]], 0, 0], ["block", "if", [["get", "missingManager", ["loc", [null, [146, 12], [146, 26]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [146, 6], [217, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    })();
    var child8 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 222,
              "column": 0
            },
            "end": {
              "line": 225,
              "column": 0
            }
          },
          "moduleName": "ui/components/view-edit-project/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          return morphs;
        },
        statements: [["inline", "top-errors", [], ["errors", ["subexpr", "@mut", [["get", "errors", ["loc", [null, [223, 22], [223, 28]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [223, 2], [223, 30]]], 0, 0], ["inline", "save-cancel", [], ["editing", ["subexpr", "@mut", [["get", "editing", ["loc", [null, [224, 24], [224, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "save", "save", "cancel", "cancel"], ["loc", [null, [224, 2], [224, 61]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 226,
            "column": 0
          }
        },
        "moduleName": "ui/components/view-edit-project/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h4");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "well");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element28 = dom.childAt(fragment, [4]);
        var element29 = dom.childAt(element28, [5]);
        var morphs = new Array(7);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(dom.childAt(element28, [1]), 0, 0);
        morphs[3] = dom.createMorphAt(element29, 1, 1);
        morphs[4] = dom.createMorphAt(element29, 3, 3);
        morphs[5] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        morphs[6] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "showEdit", ["loc", [null, [2, 8], [2, 16]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [20, 9]]]], ["block", "if", [["get", "showEdit", ["loc", [null, [23, 6], [23, 14]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [23, 0], [66, 7]]]], ["inline", "t", ["viewEditProject.showEdit.accessControl"], [], ["loc", [null, [69, 6], [69, 52]]], 0, 0], ["block", "if", [["subexpr", "and", [["get", "accessEnabled", ["loc", [null, [72, 15], [72, 28]]], 0, 0, 0, 0], ["get", "showEdit", ["loc", [null, [72, 29], [72, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [72, 10], [72, 38]]], 0, 0]], [], 4, null, ["loc", [null, [72, 4], [76, 11]]]], ["block", "if", [["get", "accessEnabled", ["loc", [null, [78, 10], [78, 23]]], 0, 0, 0, 0]], [], 5, 6, ["loc", [null, [78, 4], [130, 11]]]], ["block", "if", [["get", "network", ["loc", [null, [135, 6], [135, 13]]], 0, 0, 0, 0]], [], 7, null, ["loc", [null, [135, 0], [220, 7]]]], ["block", "if", [["get", "showEdit", ["loc", [null, [222, 6], [222, 14]]], 0, 0, 0, 0]], [], 8, null, ["loc", [null, [222, 0], [225, 7]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
    };
  })());
});