define('ui/components/edit-stack/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'lacsso/components/modal-base', 'ui/models/stack'], function (exports, _ember, _uiMixinsNewOrEdit, _lacssoComponentsModalBase, _uiModelsStack) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewOrEdit['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    editing: true,
    model: null,

    actions: {
      addTag: function addTag(tag) {
        var neu = (0, _uiModelsStack.tagsToArray)(this.get('primaryResource.group'));
        neu.addObject(tag);
        this.set('primaryResource.group', neu.join(', '));
      }
    },

    allStacks: null,
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);
      var orig = this.get('originalModel');
      var clone = orig.clone();
      delete clone.services;
      this.set('model', clone);
      this.set('allStacks', this.get('store').all('stack'));
    },

    tagChoices: (function () {
      return (0, _uiModelsStack.tagChoices)(this.get('allStacks')).sort();
    }).property('allStacks.@each.group'),

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});