define('ui/components/sortable-th/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    tagName: 'TH',
    classNames: ['sortable'],
    name: null,
    sortable: null,
    width: null,
    label: null,
    ariaRole: ['columnheader'],

    current: _ember['default'].computed.alias('sortable.sortBy'),
    descending: _ember['default'].computed.alias('sortable.descending'),

    activeAscending: (function () {
      return !this.get('descending') && this.get('current') === this.get('name');
    }).property('name', 'current', 'descending'),

    activeDescending: (function () {
      return this.get('descending') && this.get('current') === this.get('name');
    }).property('name', 'current', 'descending'),

    attributeBindings: ['width'],

    click: function click() {
      this.sendAction('action', this.get('name'));
    }
  });
});