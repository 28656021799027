define('ui/components/schema/input-boolean/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    classNames: ['form-control-static'],

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('value') === 'false') {
        this.set('value', false);
      } else if (this.get('value') === 'true') {
        this.set('value', true);
      }
    }
  });
});