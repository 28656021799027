define('ui/components/catalog-box/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    settings: _ember['default'].inject.service(),

    classNames: ['catalog-box'],
    classNameBindings: ['active::inactive'],

    model: null,
    showIcon: true,
    showSource: false,
    showDescription: true,
    active: true
  });
});