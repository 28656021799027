define('ui/components/edit-registry/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'lacsso/components/modal-base'], function (exports, _ember, _uiMixinsNewOrEdit, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewOrEdit['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    error: null,
    credentials: null,
    model: null,
    editing: true,
    primaryResource: null,

    init: function init() {
      this._super.apply(this, arguments);
      var orig = this.get('originalModel');

      this.set('model', _ember['default'].Object.create({
        allRegistries: orig.get('registries'),
        registry: orig.get('registry').clone(),
        credential: orig.get('credential').clone()
      }));

      this.setProperties({
        'primaryResource': this.get('model.credential'),
        'activeDriver': 'custom',
        'editing': true
      });
    },

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});