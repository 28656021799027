define('ui/models/volume', ['exports', 'ember', 'ember-api-store/models/resource', 'ember-api-store/utils/denormalize'], function (exports, _ember, _emberApiStoreModelsResource, _emberApiStoreUtilsDenormalize) {

  var Volume = _emberApiStoreModelsResource['default'].extend({
    type: 'volume',
    modalService: _ember['default'].inject.service('modal'),

    mounts: (0, _emberApiStoreUtilsDenormalize.denormalizeIdArray)('mountIds'),
    snapshots: (0, _emberApiStoreUtilsDenormalize.denormalizeIdArray)('snapshotIds'),

    isRoot: _ember['default'].computed.notEmpty('instanceId'),

    actions: {
      snapshot: function snapshot() {
        this.get('modalService').toggleModal('modal-edit-snapshot', this);
        this.get('application').setProperties({
          editSnapshot: true,
          originalModel: this
        });
      }
    },

    availableActions: (function () {
      var a = this.get('actionLinks');

      return [{ label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: !!a.remove, altAction: 'delete' }, { divider: true }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }, { label: 'action.restore', icon: '', action: 'restore', enabled: !!a.restore }, { label: 'action.purge', icon: '', action: 'purge', enabled: !!a.purge }, { label: 'action.snapshot', icon: 'icon icon-copy', action: 'snapshot', enabled: !!a.snapshot }];
    }).property('actionLinks.{restore,purge,remove}'),

    displayUri: (function () {
      return (this.get('uri') || '').replace(/^file:\/\//, '');
    }).property('uri')
  });

  Volume.reopenClass({
    stateMap: {
      'active': { icon: 'icon icon-hdd', color: 'text-success' }
    }
  });

  exports['default'] = Volume;
});