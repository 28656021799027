define('ui/components/link-to-as-attrs/component', ['exports', 'ember'], function (exports, _ember) {

  // This is a link-to where models (path components) and query-params can be set as attribtues instead of positional params
  exports['default'] = _ember['default'].LinkComponent.extend({
    attributeBindings: ['role', 'aria-haspopup', 'aria-expanded'],

    'current-when': (function () {
      var base = this.get('qualifiedRouteName');
      if (this.get('moreCurrentWhen.length')) {
        return this.get('moreCurrentWhen').concat(base).join(' ');
      }
    }).property('moreCurrentWhen'),

    willRender: function willRender() {
      this._super.apply(this, arguments);
      this.set('models', this.get('attrs.models.value') || []);
      this.set('queryParams', {
        isQueryParams: true,
        values: this.get('attrs.queryParams.value') || {}
      });
    }
  });
});