define('ui/components/edit-externalservice/component', ['exports', 'ui/mixins/new-or-edit', 'ember', 'lacsso/components/modal-base'], function (exports, _uiMixinsNewOrEdit, _ember, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewOrEdit['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    existing: _ember['default'].computed.alias('originalModel'),
    editing: true,

    service: null,
    primaryResource: _ember['default'].computed.alias('service'),

    actions: {
      done: function done() {
        this.send('cancel');
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      var original = this.get('originalModel');
      this.set('service', original.clone());
    },

    doneSaving: function doneSaving() {
      this.send('cancel');
    },

    didInsertElement: function didInsertElement() {
      this.$('INPUT')[0].focus();
    }
  });
});