define('ui/models/setting', ['exports', 'ember-api-store/models/resource', 'ember'], function (exports, _emberApiStoreModelsResource, _ember) {
  exports['default'] = _emberApiStoreModelsResource['default'].extend({
    isDefault: (function () {
      var source = this.get('source');
      if (!source) {
        return true;
      }

      if (source === 'Database') {
        return false;
      }

      return this.get('value') === this.get('activeValue');
    }).property('source', 'value', 'activeValue'),

    'delete': function _delete() {
      var _this = this;

      return this._super().then(function (res) {
        _ember['default'].run.later(_this, 'reload', 500);
        return res;
      });
    }
  });
});