define('ui/components/login-user-pass/component', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Component.extend({
    access: _ember['default'].inject.service(),

    waiting: null,

    username: null,
    password: null,

    actions: {
      authenticate: function authenticate() {
        var code = this.get('username') + ':' + this.get('password');
        this.set('password', '');
        this.sendAction('action', code);
      }
    }
  });
});