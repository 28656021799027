define('ui/components/modal-auditlog-info/component', ['exports', 'ember', 'lacsso/components/modal-base'], function (exports, _ember, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend({
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    requestObject: _ember['default'].computed.alias('modalService.modalOpts.requestObject'),
    responseObject: _ember['default'].computed.alias('modalService.modalOpts.responseObject'),
    requestJSON: null,
    responseJSON: null,

    init: function init() {
      this._super.apply(this, arguments);

      // Pretty-ify the JSON
      this.set('requestJSON', JSON.stringify(JSON.parse(this.get('requestObject')), null, 2));
      this.set('responseJSON', JSON.stringify(JSON.parse(this.get('responseObject')), null, 2));
    },

    actions: {
      dismiss: function dismiss() {
        this.send('cancel');
      }
    }
  });
});