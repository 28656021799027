define('ui/components/env-catalog/component', ['exports', 'ember', 'ui/utils/constants', 'ui/utils/util'], function (exports, _ember, _uiUtilsConstants, _uiUtilsUtil) {
  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  exports['default'] = _ember['default'].Component.extend({
    catalog: _ember['default'].inject.service(),
    project: null,
    catalogs: null,
    ary: null,
    global: null,

    toRemove: null,
    old: null,

    kindChoices: [{ translationKey: 'catalogSettings.more.kind.native', value: 'native' }, { translationKey: 'catalogSettings.more.kind.helm', value: 'helm' }],

    init: function init() {
      this._super.apply(this, arguments);
      this.set('toRemove', []);
      var old = this.get('catalogs').filterBy('environmentId', this.get('project.id')).map(function (x) {
        var y = x.clone();
        y.uiId = _uiUtilsUtil['default'].randomStr();
        return y;
      });
      this.set('old', old);

      this.setProperties({
        ary: old.map(function (x) {
          return x.clone();
        }),
        global: this.get('catalogs').filterBy('environmentId', 'global').slice() });
    },

    // this should change to falsey check when josh updates the catalog to remove 'global' from the id
    actions: {
      add: function add() {
        var _this = this;

        var obj = _ember['default'].Object.create({
          name: '',
          branch: _uiUtilsConstants['default'].CATALOG.DEFAULT_BRANCH,
          url: '',
          kind: 'native',
          isNew: true
        });

        this.get('ary').pushObject(obj);

        _ember['default'].run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }

          _this.$('INPUT.name').last()[0].focus();
        });
      },

      remove: function remove(obj) {
        this.get('ary').removeObject(obj);
        if (!obj.get('isNew')) {
          this.get('toRemove').addObject(obj);
        }
      },

      save: function save(cb) {
        var _this2 = this;

        if (this.validate()) {
          (function () {
            _this2.set('errors', []);
            var remove = _this2.get('toRemove');
            var cur = _this2.get('ary');

            var changes = [];

            // Remove
            remove.forEach(function (cat) {
              changes.push(_this2.removeCatalogs(cat));
            });

            // Add/update
            cur.forEach(function (cat) {
              cat.set('name', (cat.get('name') || '').trim());
              cat.set('url', (cat.get('url') || '').trim());
              cat.set('branch', (cat.get('branch') || '').trim() || _uiUtilsConstants['default'].CATALOG.DEFAULT_BRANCH);

              if (cat.uiId) {
                // Update maybe
                var orig = _this2.get('old').findBy('uiId', cat.uiId);
                if (orig) {
                  if (JSON.stringify(orig) === JSON.stringify(cat)) {
                    // Do nothing, nothing changed
                  } else {
                      // Update
                      changes.push(cat.save());
                    }
                } else {
                  // This shouldn't happen, but add anyway
                  changes.push(_this2.addCatalogs(cat));
                }
              } else {
                // Add
                changes.push(_this2.addCatalogs(cat));
              }
            });

            _ember['default'].RSVP.allSettled(changes).then(function () {
              return new _ember['default'].RSVP.Promise(function (resolve) {
                setTimeout(resolve, 1);
              }).then(function () {
                return _this2.get('catalog').refresh()['finally'](function () {
                  _ember['default'].run.later(function () {
                    // @TODO ugh...
                    window.l('route:catalog-tab').send('refresh');
                    _this2.sendAction('cancel');
                  }, 500);
                });
              });
            })['catch'](function (err) {
              _this2.set('errors', err);
              cb(false);
            });
          })();
        } else {
          cb(false);
        }
      }
    },

    validate: function validate() {
      var errors = [];
      var global = this.get('global');
      var ary = this.get('ary');

      ary.forEach(function (cat) {
        if ((cat.name || '').trim().length === 0) {
          errors.push('Name is required on each catalog');
        }

        if ((cat.url || '').trim().length === 0) {
          errors.push('URL is required on each catalog');
        }

        if (global.filter(function (x) {
          return (x.name || '').trim().toLowerCase() === cat.name.toLowerCase();
        }).length > 1 || ary.filter(function (x) {
          return (x.name || '').trim().toLowerCase() === cat.name.toLowerCase();
        }).length > 1) {
          errors.push('Each catalog must have a unique name');
        }
      });

      if (errors.length) {
        this.set('errors', errors.uniq());
        return false;
      } else {
        this.set('errors', null);
      }

      return true;
    },

    addCatalogs: function addCatalogs(catalogs) {
      return this.get('store').request({
        url: this.get('app.catalogEndpoint') + '/catalogs',
        method: 'POST',
        headers: _defineProperty({}, _uiUtilsConstants['default'].HEADER.PROJECT_ID, this.get('project.id')),
        body: JSON.stringify(catalogs)
      });
    },

    removeCatalogs: function removeCatalogs(catalogs) {
      return this.get('store').request({
        url: this.get('app.catalogEndpoint') + '/catalogs/' + catalogs.name,
        method: 'DELETE',
        headers: _defineProperty({}, _uiUtilsConstants['default'].HEADER.PROJECT_ID, this.get('project.id')),
        body: JSON.stringify(catalogs)
      });
    }
  });
});