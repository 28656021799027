define('ui/k8s-tab/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    projects: _ember['default'].inject.service(),
    'tab-session': _ember['default'].inject.service(),

    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      return this.get('projects').updateOrchestrationState();
    },

    model: function model() {
      var auth = this.modelFor('authenticated');
      return this.get('store').findAll('container').then(function () {
        return auth;
      });
    }
  });
});