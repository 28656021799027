define('ui/utils/sort', ['exports', 'ember'], function (exports, _ember) {
  exports.insensitiveCompare = insensitiveCompare;
  exports.sortInsensitiveBy = sortInsensitiveBy;
  var get = _ember['default'].get;

  var LOCALE_SAFE = /^[\w-.\s,]*$/;

  function lcStr(x) {
    if (typeof x === 'string') {
      return x.toLowerCase();
    } else {
      return String(x).toLowerCase();
    }
  }

  function insensitiveCompare(a, b) {
    if (typeof a === 'string' || typeof b === 'string') {
      a = lcStr(a);
      b = lcStr(b);
    } else {
      return a > b ? 1 : a < b ? -1 : 0;
    }

    if (LOCALE_SAFE.test(a + b)) {
      return a > b ? 1 : a < b ? -1 : 0;
    } else {
      return a.localeCompare(b, { sensitivity: 'base' });
    }
  }

  function sortInsensitiveBy(ary) {
    for (var _len = arguments.length, fields = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      fields[_key - 1] = arguments[_key];
    }

    // sortInsensitiveBy(ary, field1, field2) or sortInsensitiveBy(ary, [field1, field2])
    if (fields.length === 1 && _ember['default'].isArray(fields[0])) {
      fields = fields[0];
    }

    return ary.sort(function (a, b) {
      var out = 0;
      for (var i = 0; i < fields.length; i++) {
        var aa = get(a, fields[i]);
        var bb = get(b, fields[i]);

        out = insensitiveCompare(aa, bb);
        if (out !== 0) {
          break;
        }
      }

      return out;
    });
  }
});