define('ui/k8s-tab/kubectl/route', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Route.extend({
    access: _ember['default'].inject.service(),
    cookies: _ember['default'].inject.service(),

    model: function model() {
      var _this = this;

      return this.get('store').findAll('container').then(function (containers) {
        var inst = null;
        var command = ['/bin/bash', '-l', '-c', 'echo "# Run kubectl commands inside here\n# e.g. kubectl get rc\n"; TERM=xterm-256color /bin/bash'];

        for (var i = 0; i < containers.get('length'); i++) {
          var container = containers.objectAt(i);
          if (container.get('state') !== 'running') {
            continue;
          }

          var labels = container.get('labels') || {};

          // The kubectl container to use will have this label
          if (labels[_uiUtilsConstants['default'].LABEL.K8S_KUBECTL] + '' === 'true') {
            inst = container;

            // For 1.6 with RBAC, the command is different
            if (labels[_uiUtilsConstants['default'].LABEL.K8S_TOKEN] + '' === 'true') {
              command = ['kubectl-shell.sh', _this.get('cookies').get(_uiUtilsConstants['default'].COOKIE.TOKEN) || 'unauthorized'];
            }

            break;
          }
        }

        if (inst) {
          return _ember['default'].Object.create({
            command: command,
            instance: inst
          });
        } else {
          return _ember['default'].RSVP.reject('Unable to find an active kubectld container');
        }
      });
    },

    resetController: function resetController(controller, isExiting /*, transition*/) {
      if (isExiting) {
        controller.set('step', 1);
        controller.set('kubeconfig', null);
      }
    }
  });
});