define('ui/services/session', ['exports', 'ember', 'ui/utils/browser-storage', 'ui/utils/constants'], function (exports, _ember, _uiUtilsBrowserStorage, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Service.extend(_uiUtilsBrowserStorage['default'], {
    backing: window.localStorage,

    // Multiple browser windows to the same URL will send 'storage' events
    // between each other when a setting changes.
    init: function init() {
      var _this = this;

      this._super();
      $(window).on('storage', function (event) {
        var key = event.originalEvent.key;
        var old = event.originalEvent.oldValue;
        var neu = event.originalEvent.newValue;

        if (old !== neu) {
          _this.notifyPropertyChange(key);

          if (key === _uiUtilsConstants['default'].SESSION.ACCOUNT_ID && old && neu && old !== neu) {
            // If the active user changes, flee
            try {
              window.lc('application').send('logout');
            } catch (e) {}
          }
        }
      });
    }
  });
});