define('ui/components/input-identity/component', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend({
    access: _ember['default'].inject.service(),
    intl: _ember['default'].inject.service(),
    allowTeams: true,
    checking: false,
    addInput: '',
    allIdentities: null,
    showDropdown: (function () {
      return this.get('access.provider') !== 'localauthconfig';
    }).property('access.provider'),

    init: function init() {
      this.set('allIdentities', this.get('userStore').all('identity'));
      this.get('userStore').findAll('identity');
      this._super();
    },

    actions: {
      add: function add() {
        var _this = this;

        if (this.get('checking')) {
          return;
        }

        this.set('checking', true);
        var input = this.get('addInput').trim();

        this.get('userStore').find('identity', null, { filter: { name: input } }).then(function (info) {
          var obj = info.objectAt(0);
          if (obj) {
            _this.set('addInput', '');
            _this.send('addObject', obj);
          } else {
            _this.sendAction('onError', 'Identity not found: ' + input);
          }
        })['catch'](function () {
          _this.sendAction('onError', 'Identity not found: ' + input);
        })['finally'](function () {
          _this.set('checking', false);
        });
      },

      addObject: function addObject(info) {
        this.sendAction('action', info);
      }
    },

    addDisabled: (function () {
      return this.get('checking') || this.get('addInput').trim().length === 0;
    }).property('addInput', 'checking'),

    dropdownChoices: (function () {
      var allowTeams = this.get('allowTeams');
      return this.get('allIdentities').filter(function (identity) {
        var type = identity.get('externalIdType');
        var logicalType = identity.get('logicalType');

        // Don't show people
        if (logicalType === _uiUtilsConstants['default'].PROJECT.PERSON) {
          return false;
        }

        // Don't show teams if disabled
        if (!allowTeams && type === _uiUtilsConstants['default'].PROJECT.TYPE_GITHUB_TEAM) {
          return false;
        }

        return true;
      }).sortBy('logicalTypeSort', 'profileUrl', 'name');
    }).property('allIdentities.@each.{logicalType,externalIdType}', 'allowTeams'),

    dropdownLabel: (function () {
      var out = '';
      var intl = this.get('intl');
      if (this.get('access.provider') === 'githubconfig') {
        out = intl.findTranslationByKey('inputIdentity.dropdownLabel.teams');
      } else {
        out = intl.findTranslationByKey('inputIdentity.dropdownLabel.groups');
      }
      return intl.formatMessage(out);
    }).property('access.provider', 'intl._locale')
  });
});