define("ui/admin-tab/machine/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          var child0 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 12
                  },
                  "end": {
                    "line": 26,
                    "column": 12
                  }
                },
                "moduleName": "ui/admin-tab/machine/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "badge-state", [], ["model", ["subexpr", "@mut", [["get", "driver", ["loc", [null, [25, 34], [25, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "pull-left"], ["loc", [null, [25, 14], [25, 60]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child1 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 26,
                    "column": 12
                  },
                  "end": {
                    "line": 30,
                    "column": 12
                  }
                },
                "moduleName": "ui/admin-tab/machine/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("span");
                dom.setAttribute(el1, "class", "state badge text-danger bg-danger pull-left");
                var el2 = dom.createTextNode("\n                ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("i");
                dom.setAttribute(el2, "class", "icon icon-circle");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode(" ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n              ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 3, 3);
                return morphs;
              },
              statements: [["inline", "t", ["machinePage.inactive"], [], ["loc", [null, [28, 49], [28, 77]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child2 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 33,
                      "column": 16
                    },
                    "end": {
                      "line": 37,
                      "column": 16
                    }
                  },
                  "moduleName": "ui/admin-tab/machine/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1, "class", "btn btn-default btn-xs");
                  var el2 = dom.createTextNode("\n                    ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("i");
                  dom.setAttribute(el2, "class", "icon-fw icon icon-play");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var element4 = dom.childAt(element3, [1]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element3, 'aria-label');
                  morphs[1] = dom.createElementMorph(element3);
                  morphs[2] = dom.createAttrMorph(element4, 'alt');
                  return morphs;
                },
                statements: [["attribute", "aria-label", ["subexpr", "t", ["generic.activate"], [], ["loc", [null, [null, null], [34, 92]]], 0, 0], 0, 0, 0, 0], ["element", "action", ["addCatalogDriver", ["get", "driver", ["loc", [null, [34, 121], [34, 127]]], 0, 0, 0, 0]], [], ["loc", [null, [34, 93], [34, 129]]], 0, 0], ["attribute", "alt", ["subexpr", "t", ["generic.activate"], [], ["loc", [null, [null, null], [35, 82]]], 0, 0], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 31,
                    "column": 12
                  },
                  "end": {
                    "line": 39,
                    "column": 12
                  }
                },
                "moduleName": "ui/admin-tab/machine/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", " btn-group resource-actions action-menu pull-right");
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                return morphs;
              },
              statements: [["block", "tooltip-element", [], ["inlineBlock", true, "type", "tooltip-basic", "tooltipTemplate", "tooltip-static", "model", ["subexpr", "t", ["generic.activate"], [], ["loc", [null, [33, 112], [33, 134]]], 0, 0], "tagName", "span", "tooltipFor", "catalogMachine"], 0, null, ["loc", [null, [33, 16], [37, 37]]]]],
              locals: [],
              templates: [child0]
            };
          })();
          var child3 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 39,
                    "column": 12
                  },
                  "end": {
                    "line": 41,
                    "column": 12
                  }
                },
                "moduleName": "ui/admin-tab/machine/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("              ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "action-menu", [], ["model", ["subexpr", "@mut", [["get", "driver", ["loc", [null, [40, 34], [40, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "class", "pull-right"], ["loc", [null, [40, 14], [40, 61]]], 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child4 = (function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 44,
                    "column": 8
                  },
                  "end": {
                    "line": 46,
                    "column": 8
                  }
                },
                "moduleName": "ui/admin-tab/machine/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "class", "catalog-icon r-mb10");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(1);
                morphs[0] = dom.createAttrMorph(element2, 'style');
                return morphs;
              },
              statements: [["attribute", "style", ["concat", ["background-image:url(", ["get", "driver.catalogTemplateIcon", ["loc", [null, [45, 73], [45, 99]]], 0, 0, 0, 0], ");"], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          })();
          var child5 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 46,
                      "column": 8
                    },
                    "end": {
                      "line": 48,
                      "column": 8
                    }
                  },
                  "moduleName": "ui/admin-tab/machine/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "catalog-icon r-mb10");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element1, 'style');
                  return morphs;
                },
                statements: [["attribute", "style", ["concat", ["background-image:url(", ["get", "driver.machineHasIcon", ["loc", [null, [47, 73], [47, 94]]], 0, 0, 0, 0], ");"], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 48,
                      "column": 8
                    },
                    "end": {
                      "line": 50,
                      "column": 8
                    }
                  },
                  "moduleName": "ui/admin-tab/machine/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n        ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1]);
                  var morphs = new Array(1);
                  morphs[0] = dom.createAttrMorph(element0, 'class');
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["catalog-icon r-mb10 ", ["get", "driver.iconMapFromConstants", ["loc", [null, [49, 44], [49, 71]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                locals: [],
                templates: []
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 46,
                    "column": 8
                  },
                  "end": {
                    "line": 50,
                    "column": 8
                  }
                },
                "moduleName": "ui/admin-tab/machine/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "driver.machineHasIcon", ["loc", [null, [46, 18], [46, 39]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [46, 8], [50, 8]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 6
                },
                "end": {
                  "line": 51,
                  "column": 6
                }
              },
              "moduleName": "ui/admin-tab/machine/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "catalog-header r-mt5");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "class", "clearfix r-mb5 r-pl10 r-pr10");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element5 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(3);
              morphs[0] = dom.createMorphAt(element5, 1, 1);
              morphs[1] = dom.createMorphAt(element5, 2, 2);
              morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "driver.state", ["loc", [null, [24, 18], [24, 30]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [24, 12], [30, 19]]]], ["block", "if", [["get", "driver.catalogId", ["loc", [null, [31, 18], [31, 34]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [31, 12], [41, 19]]]], ["block", "if", [["get", "driver.externalId", ["loc", [null, [44, 14], [44, 31]]], 0, 0, 0, 0]], [], 4, 5, ["loc", [null, [44, 8], [50, 15]]]]],
            locals: [],
            templates: [child0, child1, child2, child3, child4, child5]
          };
        })();
        var child1 = (function () {
          var child0 = (function () {
            var child0 = (function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 52,
                      "column": 8
                    },
                    "end": {
                      "line": 54,
                      "column": 8
                    }
                  },
                  "moduleName": "ui/admin-tab/machine/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "upgrade-dropdown", [], ["model", ["subexpr", "@mut", [["get", "driver", ["loc", [null, [53, 35], [53, 41]]], 0, 0, 0, 0]], [], [], 0, 0], "btnClass", "btn-sm", "currentId", ["subexpr", "@mut", [["get", "driver.externalId", ["loc", [null, [53, 70], [53, 87]]], 0, 0, 0, 0]], [], [], 0, 0], "upgradeOnly", false, "changeVersion", ["subexpr", "action", ["upgradeDriver", ["get", "driver", ["loc", [null, [53, 144], [53, 150]]], 0, 0, 0, 0]], [], ["loc", [null, [53, 120], [53, 151]]], 0, 0]], ["loc", [null, [53, 10], [53, 153]]], 0, 0]],
                locals: [],
                templates: []
              };
            })();
            var child1 = (function () {
              var child0 = (function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 54,
                        "column": 8
                      },
                      "end": {
                        "line": 56,
                        "column": 8
                      }
                    },
                    "moduleName": "ui/admin-tab/machine/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("          ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "text-muted");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n        ");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["inline", "t", ["machinePage.builtin"], [], ["loc", [null, [55, 35], [55, 62]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              })();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 54,
                      "column": 8
                    },
                    "end": {
                      "line": 56,
                      "column": 8
                    }
                  },
                  "moduleName": "ui/admin-tab/machine/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "driver.builtin", ["loc", [null, [54, 18], [54, 32]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [54, 8], [56, 8]]]]],
                locals: [],
                templates: [child0]
              };
            })();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 51,
                    "column": 6
                  },
                  "end": {
                    "line": 57,
                    "column": 6
                  }
                },
                "moduleName": "ui/admin-tab/machine/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "if", [["subexpr", "and", [["get", "driver.externalId", ["loc", [null, [52, 19], [52, 36]]], 0, 0, 0, 0], ["subexpr", "not-eq", [["get", "driver.state", ["loc", [null, [52, 45], [52, 57]]], 0, 0, 0, 0], "inactive"], [], ["loc", [null, [52, 37], [52, 69]]], 0, 0]], [], ["loc", [null, [52, 14], [52, 70]]], 0, 0]], [], 0, 1, ["loc", [null, [52, 8], [56, 15]]]]],
              locals: [],
              templates: [child0, child1]
            };
          })();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 6
                },
                "end": {
                  "line": 57,
                  "column": 6
                }
              },
              "moduleName": "ui/admin-tab/machine/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["subexpr", "eq", [["get", "section", ["loc", [null, [51, 20], [51, 27]]], 0, 0, 0, 0], "footer"], [], ["loc", [null, [51, 16], [51, 37]]], 0, 0]], [], 0, null, ["loc", [null, [51, 6], [57, 6]]]]],
            locals: [],
            templates: [child0]
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 13,
                "column": 4
              },
              "end": {
                "line": 58,
                "column": 4
              }
            },
            "moduleName": "ui/admin-tab/machine/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "eq", [["get", "section", ["loc", [null, [21, 16], [21, 23]]], 0, 0, 0, 0], "header"], [], ["loc", [null, [21, 12], [21, 33]]], 0, 0]], [], 0, 1, ["loc", [null, [21, 6], [57, 13]]]]],
          locals: ["section"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 2
            },
            "end": {
              "line": 59,
              "column": 2
            }
          },
          "moduleName": "ui/admin-tab/machine/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "catalog-box", [], ["model", ["subexpr", "@mut", [["get", "driver", ["loc", [null, [14, 12], [14, 18]]], 0, 0, 0, 0]], [], [], 0, 0], "active", ["subexpr", "not", [["subexpr", "or", [["subexpr", "eq", [["get", "driver.state", ["loc", [null, [15, 26], [15, 38]]], 0, 0, 0, 0], "inactive"], [], ["loc", [null, [15, 22], [15, 50]]], 0, 0], ["subexpr", "eq", [["get", "driver.type", ["loc", [null, [15, 55], [15, 66]]], 0, 0, 0, 0], "template"], [], ["loc", [null, [15, 51], [15, 78]]], 0, 0]], [], ["loc", [null, [15, 18], [15, 79]]], 0, 0]], [], ["loc", [null, [15, 13], [15, 80]]], 0, 0], "classNames", "machine", "showIcon", false, "showDescription", false], 0, null, ["loc", [null, [13, 4], [58, 20]]]]],
        locals: ["driver"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 61,
            "column": 0
          }
        },
        "moduleName": "ui/admin-tab/machine/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "header clearfix");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("button");
        dom.setAttribute(el2, "class", "btn btn-sm btn-primary right-divider-btn");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("hr");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("section");
        dom.setAttribute(el1, "class", "r-plr15 clearfix");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element6 = dom.childAt(fragment, [0]);
        var element7 = dom.childAt(element6, [3]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(dom.childAt(element6, [1]), 0, 0);
        morphs[1] = dom.createElementMorph(element7);
        morphs[2] = dom.createMorphAt(element7, 0, 0);
        morphs[3] = dom.createMorphAt(dom.childAt(fragment, [2, 1]), 0, 0);
        morphs[4] = dom.createMorphAt(dom.childAt(fragment, [4]), 1, 1);
        return morphs;
      },
      statements: [["inline", "t", ["machinePage.header"], [], ["loc", [null, [2, 6], [2, 32]]], 0, 0], ["element", "action", ["addNewDriver"], [], ["loc", [null, [3, 59], [3, 84]]], 0, 0], ["inline", "t", ["machinePage.add"], [], ["loc", [null, [3, 85], [3, 108]]], 0, 0], ["inline", "format-html-message", ["machinePage.subtext"], [], ["loc", [null, [7, 6], [7, 51]]], 0, 0], ["block", "each", [["get", "arranged", ["loc", [null, [12, 10], [12, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 2], [59, 11]]]]],
      locals: [],
      templates: [child0]
    };
  })());
});