define('ui/components/edit-aliasservice/component', ['exports', 'ember', 'lacsso/components/modal-base', 'ui/mixins/new-service-alias'], function (exports, _ember, _lacssoComponentsModalBase, _uiMixinsNewServiceAlias) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewServiceAlias['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    editing: true,
    existing: _ember['default'].computed.alias('originalModel'),

    actions: {
      done: function done() {
        this.send('cancel');
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.set('service', this.get('originalModel').clone());
    },

    doneSaving: function doneSaving() {
      this.send('cancel');
    }
  });
});