define('ui/components/machine/driver-packet/component', ['exports', 'ember', 'ui/mixins/driver', 'ui/utils/packet-choices'], function (exports, _ember, _uiMixinsDriver, _uiUtilsPacketChoices) {

  var osChoices = _uiUtilsPacketChoices.PacketOs.map(function (os) {
    os.enabled = (os.slug || '').toLowerCase() === 'ubuntu_14_04';
    return os;
  });

  var planChoices = _uiUtilsPacketChoices.PacketPlans.filter(function (plan) {
    return (plan.line || '').toLowerCase() === 'baremetal';
  }).map(function (plan) {
    plan.enabled = (plan.slug || '').toLowerCase() !== 'baremetal_2';
    return plan;
  });

  exports['default'] = _ember['default'].Component.extend(_uiMixinsDriver['default'], {
    driverName: 'packet',
    packetConfig: _ember['default'].computed.alias('model.packetConfig'),

    facilityChoices: _uiUtilsPacketChoices.PacketFacilities,
    planChoices: planChoices,
    osChoices: osChoices,

    bootstrap: function bootstrap() {
      var store = this.get('store');
      var config = store.createRecord({
        type: 'packetConfig',
        apiKey: '',
        projectId: '',
        os: 'ubuntu_14_04',
        facilityCode: 'ewr1',
        plan: 'baremetal_0',
        billingCycle: 'hourly'
      });

      this.set('model', store.createRecord({
        type: 'host',
        packetConfig: config
      }));
    },

    validate: function validate() {
      this._super();
      var errors = this.get('errors') || [];

      if (!this.get('packetConfig.projectId')) {
        errors.push('Project ID is required');
      }

      if (!this.get('packetConfig.apiKey')) {
        errors.push('API Key is requried');
      }

      if (errors.length) {
        this.set('errors', errors.uniq());
        return false;
      }

      return true;
    }
  });
});