define('ui/certificates/new/controller', ['exports', 'ember', 'ui/mixins/new-or-edit'], function (exports, _ember, _uiMixinsNewOrEdit) {
  exports['default'] = _ember['default'].Controller.extend(_uiMixinsNewOrEdit['default'], {
    isEncrypted: (function () {
      var key = this.get('model.key') || '';
      return key.match(/^Proc-Type: 4,ENCRYPTED$/m) || key.match(/^-----BEGIN ENCRYPTED PRIVATE KEY-----$/m);
    }).property('model.key'),

    actions: {
      cancel: function cancel() {
        this.transitionToRoute('certificates');
      }
    },

    validate: function validate() {
      this._super();
      var errors = this.get('errors', errors) || [];

      if (this.get('isEncrypted')) {
        errors.push('The private key cannot be password-protected.');
      }

      this.set('errors', errors);
      return this.get('errors.length') === 0;
    },

    doneSaving: function doneSaving() {
      this.transitionToRoute('certificates');
    }
  });
});