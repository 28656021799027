define('ui/mixins/new-or-edit', ['exports', 'ember', 'ember-api-store/models/resource', 'ui/utils/errors'], function (exports, _ember, _emberApiStoreModelsResource, _uiUtilsErrors) {
  exports['default'] = _ember['default'].Mixin.create({
    originalModel: null,
    errors: null,
    saving: false,
    editing: true,
    primaryResource: _ember['default'].computed.alias('model'),
    originalPrimaryResource: _ember['default'].computed.alias('originalModel'),

    initFields: function initFields() {
      this._super();
      this.set('errors', null);
      this.set('saving', false);
    },

    didReceiveAttrs: function didReceiveAttrs() {
      this._super();
      this.set('errors', null);
      this.set('saving', false);
    },

    validate: function validate() {
      var model = this.get('primaryResource');
      var errors = model.validationErrors();
      if (errors.get('length')) {
        this.set('errors', errors);
        return false;
      }

      this.set('errors', null);
      return true;
    },

    actions: {
      error: function error(err) {
        if (err) {
          var body = _uiUtilsErrors['default'].stringify(err);
          this.set('errors', [body]);
        } else {
          this.set('errors', null);
        }
      },

      save: function save(cb) {
        var _this = this;

        // Will save can return true/false or a promise
        _ember['default'].RSVP.resolve(this.willSave()).then(function (ok) {
          if (!ok) {
            // Validation or something else said not to save
            if (cb) {
              cb();
            }
            return;
          }

          _this.doSave().then(_this.didSave.bind(_this)).then(_this.doneSaving.bind(_this))['catch'](function (err) {
            _this.send('error', err);
            _this.errorSaving(err);
          })['finally'](function () {
            try {
              _this.set('saving', false);

              if (cb) {
                cb();
              }
            } catch (e) {}
          });
        });
      }
    },

    // willSave happens before save and can stop the save from happening
    willSave: function willSave() {
      this.set('errors', null);
      var ok = this.validate();
      if (!ok) {
        // Validation failed
        return false;
      }

      if (this.get('saving')) {
        // Already saving
        return false;
      }

      this.set('saving', true);
      return true;
    },

    doSave: function doSave(opt) {
      var _this2 = this;

      return this.get('primaryResource').save(opt).then(function (newData) {
        return _this2.mergeResult(newData);
      });
    },

    mergeResult: function mergeResult(newData) {
      var original = this.get('originalPrimaryResource');
      if (original) {
        if (_emberApiStoreModelsResource['default'].detectInstance(original)) {
          original.merge(newData);
          return original;
        }
      }

      return newData;
    },

    // didSave can be used to do additional saving of dependent resources
    didSave: function didSave(neu) {
      return neu;
    },

    // doneSaving happens after didSave
    doneSaving: function doneSaving(neu) {
      return neu || this.get('originalPrimaryResource') || this.get('primaryResource');
    },

    // errorSaving can be used to do additional cleanup of dependent resources on failure
    errorSaving: function errorSaving() /*err*/{}
  });
});