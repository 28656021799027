define("ui/components/new-select/template", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    var child0 = (function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "ui/components/new-select/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element3 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element3, 'selected');
          morphs[1] = dom.createMorphAt(element3, 1, 1);
          return morphs;
        },
        statements: [["attribute", "selected", ["subexpr", "not", [["get", "selection", ["loc", [null, [2, 25], [2, 34]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [2, 36]]], 0, 0], 0, 0, 0, 0], ["inline", "t", [["get", "prompt", ["loc", [null, [3, 8], [3, 14]]], 0, 0, 0, 0]], [], ["loc", [null, [3, 4], [3, 16]]], 0, 0]],
        locals: [],
        templates: []
      };
    })();
    var child1 = (function () {
      var child0 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 4
              },
              "end": {
                "line": 11,
                "column": 4
              }
            },
            "moduleName": "ui/components/new-select/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "t", [["subexpr", "get", [["get", "item", ["loc", [null, [10, 15], [10, 19]]], 0, 0, 0, 0], ["get", "optionLabelPath", ["loc", [null, [10, 20], [10, 35]]], 0, 0, 0, 0]], [], ["loc", [null, [10, 10], [10, 36]]], 0, 0]], [], ["loc", [null, [10, 6], [10, 38]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      var child1 = (function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 4
              },
              "end": {
                "line": 13,
                "column": 4
              }
            },
            "moduleName": "ui/components/new-select/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "get", [["get", "item", ["loc", [null, [12, 12], [12, 16]]], 0, 0, 0, 0], ["get", "optionLabelPath", ["loc", [null, [12, 17], [12, 32]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 6], [12, 34]]], 0, 0]],
          locals: [],
          templates: []
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 7,
              "column": 0
            },
            "end": {
              "line": 15,
              "column": 0
            }
          },
          "moduleName": "ui/components/new-select/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("option");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element2 = dom.childAt(fragment, [1]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element2, 'value');
          morphs[1] = dom.createAttrMorph(element2, 'selected');
          morphs[2] = dom.createAttrMorph(element2, 'disabled');
          morphs[3] = dom.createMorphAt(element2, 1, 1);
          return morphs;
        },
        statements: [["attribute", "value", ["concat", [["subexpr", "get", [["get", "item", ["loc", [null, [8, 23], [8, 27]]], 0, 0, 0, 0], ["get", "optionValuePath", ["loc", [null, [8, 28], [8, 43]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 17], [8, 45]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "selected", ["subexpr", "eq", [["get", "value", ["loc", [null, [8, 61], [8, 66]]], 0, 0, 0, 0], ["subexpr", "get", [["get", "item", ["loc", [null, [8, 72], [8, 76]]], 0, 0, 0, 0], ["get", "optionValuePath", ["loc", [null, [8, 77], [8, 92]]], 0, 0, 0, 0]], [], ["loc", [null, [8, 67], [8, 93]]], 0, 0]], [], ["loc", [null, [null, null], [8, 95]]], 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["subexpr", "get", [["get", "item", ["loc", [null, [8, 111], [8, 115]]], 0, 0, 0, 0], ["get", "optionDisabledPath", ["loc", [null, [8, 116], [8, 134]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [8, 136]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "localizedLabel", ["loc", [null, [9, 10], [9, 24]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [9, 4], [13, 11]]]]],
        locals: ["item"],
        templates: [child0, child1]
      };
    })();
    var child2 = (function () {
      var child0 = (function () {
        var child0 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 8
                },
                "end": {
                  "line": 23,
                  "column": 8
                }
              },
              "moduleName": "ui/components/new-select/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "t", [["subexpr", "get", [["get", "item", ["loc", [null, [22, 19], [22, 23]]], 0, 0, 0, 0], ["get", "optionLabelPath", ["loc", [null, [22, 24], [22, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [22, 14], [22, 40]]], 0, 0]], [], ["loc", [null, [22, 10], [22, 42]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        var child1 = (function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 8
                },
                "end": {
                  "line": 25,
                  "column": 8
                }
              },
              "moduleName": "ui/components/new-select/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "get", [["get", "item", ["loc", [null, [24, 16], [24, 20]]], 0, 0, 0, 0], ["get", "optionLabelPath", ["loc", [null, [24, 21], [24, 36]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 10], [24, 38]]], 0, 0]],
            locals: [],
            templates: []
          };
        })();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 19,
                "column": 4
              },
              "end": {
                "line": 27,
                "column": 4
              }
            },
            "moduleName": "ui/components/new-select/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("option");
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element0, 'value');
            morphs[1] = dom.createAttrMorph(element0, 'selected');
            morphs[2] = dom.createAttrMorph(element0, 'disabled');
            morphs[3] = dom.createMorphAt(element0, 1, 1);
            return morphs;
          },
          statements: [["attribute", "value", ["concat", [["subexpr", "get", [["get", "item", ["loc", [null, [20, 27], [20, 31]]], 0, 0, 0, 0], ["get", "optionValuePath", ["loc", [null, [20, 32], [20, 47]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 21], [20, 49]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "selected", ["subexpr", "eq", [["get", "value", ["loc", [null, [20, 65], [20, 70]]], 0, 0, 0, 0], ["subexpr", "get", [["get", "item", ["loc", [null, [20, 76], [20, 80]]], 0, 0, 0, 0], ["get", "optionValuePath", ["loc", [null, [20, 81], [20, 96]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 71], [20, 97]]], 0, 0]], [], ["loc", [null, [null, null], [20, 99]]], 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["subexpr", "get", [["get", "item", ["loc", [null, [20, 115], [20, 119]]], 0, 0, 0, 0], ["get", "optionDisabledPath", ["loc", [null, [20, 120], [20, 138]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [20, 140]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "localizedLabel", ["loc", [null, [21, 14], [21, 28]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [21, 8], [25, 15]]]]],
          locals: ["item"],
          templates: [child0, child1]
        };
      })();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 17,
              "column": 0
            },
            "end": {
              "line": 29,
              "column": 0
            }
          },
          "moduleName": "ui/components/new-select/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("optgroup");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var morphs = new Array(2);
          morphs[0] = dom.createAttrMorph(element1, 'label');
          morphs[1] = dom.createMorphAt(element1, 1, 1);
          return morphs;
        },
        statements: [["attribute", "label", ["concat", [["get", "group.group", ["loc", [null, [18, 21], [18, 32]]], 0, 0, 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "each", [["get", "group.options", ["loc", [null, [19, 12], [19, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [19, 4], [27, 13]]]]],
        locals: ["group"],
        templates: [child0]
      };
    })();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 30,
            "column": 0
          }
        },
        "moduleName": "ui/components/new-select/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "prompt", ["loc", [null, [1, 6], [1, 12]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [1, 0], [5, 7]]]], ["block", "each", [["get", "ungroupedContent", ["loc", [null, [7, 8], [7, 24]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [7, 0], [15, 9]]]], ["block", "each", [["get", "groupedContent", ["loc", [null, [17, 8], [17, 22]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [17, 0], [29, 9]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  })());
});