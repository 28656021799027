define('ui/host/index/route', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Route.extend({
    redirect: function redirect() {
      this.replaceWith('host.containers');
    },

    model: function model() {
      return this.modelFor('host').get('host');
    }
  });
});