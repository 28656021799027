define('ui/mixins/polled-model', ['exports', 'ember'], function (exports, _ember) {
  exports['default'] = _ember['default'].Mixin.create({
    pollInterval: 2000,

    growl: _ember['default'].inject.service(),

    pollTimer: null,

    setupController: function setupController() {
      this._super.apply(this, arguments);
      this.scheduleTimer();
    },

    deactivate: function deactivate() {
      this.cancelTimer();
    },

    scheduleTimer: function scheduleTimer() {
      var _this = this;

      _ember['default'].run.cancel(this.get('pollTimer'));
      this.set('pollTimer', _ember['default'].run.later(function () {
        var controller = _this.controller;
        var qp = {};
        (controller.get('queryParams') || []).forEach(function (param) {
          qp[param] = controller.get(param);
        });
        _this.model(qp).then(function (model) {
          _this.controller.set('model', model);

          if (_this.get('pollTimer')) {
            _this.scheduleTimer();
          }
        })['catch'](function (err) {
          _this.get('growl').fromError(err);
        });
      }, this.get('pollInterval')));
    },

    cancelTimer: function cancelTimer() {
      _ember['default'].run.cancel(this.get('pollTimer'));
      // This prevents scheduleTimer from rescheduling if deactivate happened at just the wrong time.
      this.set('pollTimer', null);
    }
  });
});