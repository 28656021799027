define('ui/components/edit-account/component', ['exports', 'ember', 'ui/mixins/new-or-edit', 'ui/utils/constants', 'lacsso/components/modal-base'], function (exports, _ember, _uiMixinsNewOrEdit, _uiUtilsConstants, _lacssoComponentsModalBase) {
  exports['default'] = _lacssoComponentsModalBase['default'].extend(_uiMixinsNewOrEdit['default'], {
    classNames: ['lacsso', 'modal-container', 'large-modal'],
    access: _ember['default'].inject.service(),
    primaryResource: _ember['default'].computed.alias('model.account'),
    settings: _ember['default'].inject.service(),

    originalModel: _ember['default'].computed.alias('modalService.modalOpts'),
    account: null,
    credential: null,

    oldPassword: '',
    newPassword: '',
    newPassword2: '',

    validateDescription: _ember['default'].computed(function () {
      return this.get('settings').get(_uiUtilsConstants['default'].SETTING.AUTH_LOCAL_VALIDATE_DESC) || null;
    }),

    accountTypeChoices: [{ label: 'editAccount.form.kind.user', value: 'user' }, { label: 'editAccount.form.kind.admin', value: 'admin' }],

    isLocalAuth: (function () {
      return this.get('access.provider') === 'localauthconfig';
    }).property('access.provider'),

    isAdmin: _ember['default'].computed.alias('access.admin'),
    generated: false,
    needOld: _ember['default'].computed.not('isAdmin'),
    showConfirm: _ember['default'].computed.not('generated'),

    init: function init() {
      this._super.apply(this, arguments);
      var accountClone = this.get('originalModel').clone();
      var credential = this.get('originalModel.passwordCredential');
      var credentialClone = credential ? credential.clone() : null;

      this.set('model', _ember['default'].Object.create({
        account: accountClone,
        credential: credentialClone
      }));
    },

    validate: function validate() {
      var errors = [];
      var old = this.get('oldPassword');
      var neu = this.get('newPassword');
      var neu2 = this.get('newPassword2');

      if (neu || neu2) {
        if (this.get('needOld') && !old) {
          errors.push('Current password is required');
        }

        if (this.get('showConfirm') && neu !== neu2) {
          errors.push('New passwords do not match');
        }
      }

      if (errors.length) {
        this.set('errors', errors);
        return false;
      }

      return true;
    },

    doSave: function doSave() {
      // Users can't update the account
      if (this.get('isAdmin')) {
        return this._super();
      } else {
        return _ember['default'].RSVP.resolve();
      }
    },

    didSave: function didSave() {
      var old = this.get('oldPassword');
      var neu = this.get('newPassword');

      if (neu) {
        return this.get('model.credential').doAction('changesecret', {
          newSecret: neu,
          oldSecret: old
        }, { catchGrowl: false });
      }
    },

    doneSaving: function doneSaving() {
      this.send('cancel');

      // If you edit yourself and make yourself a user, drop the admin bit.
      if (this.get('model.account.id') === this.get('session.' + _uiUtilsConstants['default'].SESSION.ACCOUNT_ID) && this.get('model.account.kind') !== 'admin') {
        this.set('access.admin', false);
        this.set('session.' + _uiUtilsConstants['default'].SESSION.USER_TYPE, this.get('model.account.kind'));
      }

      return _ember['default'].RSVP.resolve();
    },

    actions: {
      error: function error(err) {
        if (err.get('code') === 'InvalidOldPassword') {
          this.set('errors', ['Current password is incorrect']);
        } else {
          this._super(err);
        }
      },

      generated: function generated() {
        this.set('generated', true);
      }
    }
  });
});