define('ui/models/snapshot', ['exports', 'ember', 'ember-api-store/models/resource', 'ember-api-store/utils/denormalize'], function (exports, _ember, _emberApiStoreModelsResource, _emberApiStoreUtilsDenormalize) {
  exports['default'] = _emberApiStoreModelsResource['default'].extend({
    type: 'snapshot',
    modalService: _ember['default'].inject.service('modal'),

    volume: (0, _emberApiStoreUtilsDenormalize.denormalizeId)('volumeId'),

    hasBackups: _ember['default'].computed.notEmpty('backupTargetId'),
    backupEnabled: _ember['default'].computed.empty('backupTargetId'),

    actions: {
      backup: function backup() {
        var _this = this;

        this.get('store').findAll('backuptarget').then(function (backupTargets) {
          _this.get('modalService').toggleModal('modal-edit-backup', {
            originalModel: _this,
            backupTargets: backupTargets
          });
        });
      },

      revertToSnapshot: function revertToSnapshot() {
        this.get('volume').doAction('reverttosnapshot', {
          snapshotId: this.get('id')
        });
      },

      //restoreFromBackup() {
      //this.get('volume').doAction('restorefrombackup', {
      //backupId: this.get('latestCompleteBackup.id'),
      //});
      //},

      deleteBackup: function deleteBackup() {
        this.doAction('removebackup');
      }
    },

    availableActions: (function () {
      var a = this.get('actionLinks');
      var volA = this.get('volume.actionLinks');

      var created = this.get('state') === 'snapshotted';

      return [{ label: 'action.remove', icon: 'icon icon-trash', action: 'promptDelete', enabled: !!a.remove, altAction: 'delete' }, { divider: true }, { label: 'action.revertToSnapshot', icon: 'icon icon-history', action: 'revertToSnapshot', enabled: created && volA && !!volA.reverttosnapshot }, { label: 'action.restoreFromBackup', icon: 'icon icon-history', action: 'restoreFromBackup', enabled: created && volA && this.get('hasBackups') && !!volA.restorefrombackup }, { label: 'action.backup', icon: 'icon icon-hdd', action: 'backup', enabled: created && this.get('backupEnabled') }, { label: 'action.deleteBackup', icon: 'icon icon-hdd', action: 'deleteBackup', enabled: this.get('hasBackups') }, { label: 'action.viewInApi', icon: 'icon icon-external-link', action: 'goToApi', enabled: true }];
    }).property('actionLinks.remove', 'backupEnabled', 'hasBackups', 'volume.actionLinks.reverttosnapshot', 'state', 'volume.state')
  });
});