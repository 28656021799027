define('ui/authenticated/project/help/controller', ['exports', 'ember', 'ui/utils/constants'], function (exports, _ember, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Controller.extend({
    settings: _ember['default'].inject.service(),

    modelError: false,
    modelResolved: false,
    hasHosts: true,
    forumsLink: _uiUtilsConstants['default'].EXT_REFERENCES.FORUM,
    companyLink: _uiUtilsConstants['default'].EXT_REFERENCES.COMPANY,
    githubLink: _uiUtilsConstants['default'].EXT_REFERENCES.GITHUB,
    docsLink: _ember['default'].computed.alias('settings.docsBase'),

    latestAnnouncement: _ember['default'].computed('model.announcements', function () {
      if (this.get('model.announcements.topics')) {
        var sorted = this.get('model.announcements.topics').sortBy('id');
        var announcement = sorted[sorted.length - 1];
        return {
          title: announcement.title,
          link: this.get('forumsLink') + '/t/' + announcement.slug,
          created: announcement.created_at
        };
      }
    }),

    modelObserver: (function () {
      if (this.get('model.resolved')) {

        // @@TODO@@ - need to add some error handling
        this.set('modelResolved', true);
      }

      if (this.get('model.error')) {

        this.set('modelError', true);
      }
    }).observes('model')

  });
});