define('ui/components/form-networking/component', ['exports', 'ember', 'ui/mixins/container-choices', 'ui/mixins/manage-labels', 'ui/utils/constants'], function (exports, _ember, _uiMixinsContainerChoices, _uiMixinsManageLabels, _uiUtilsConstants) {
  exports['default'] = _ember['default'].Component.extend(_uiMixinsManageLabels['default'], _uiMixinsContainerChoices['default'], {
    intl: _ember['default'].inject.service(),
    projects: _ember['default'].inject.service(),
    settings: _ember['default'].inject.service(),

    //Inputs
    instance: null,
    isService: null,
    allHosts: null,
    errors: null,
    initialLabels: null,
    isUpgrade: false,
    retainWasSetOnInit: false,
    editing: true,

    classNameBindings: ['editing:component-editing:component-static'],

    init: function init() {
      this._super.apply(this, arguments);
      this.initLabels(this.get('initialLabels'), null, [_uiUtilsConstants['default'].LABEL.DNS, _uiUtilsConstants['default'].LABEL.HOSTNAME_OVERRIDE, _uiUtilsConstants['default'].LABEL.REQUESTED_IP]);
      this.initNetwork();
      this.initRequestedIp();
      this.initHostname();
      this.initDnsDiscovery();
      this.initDnsResolver();
      this.initDnsSearch();
      if (this.get('service.retainIp')) {
        this.set('retainWasSetOnInit', this.get('service.retainIp'));
      }
    },

    actions: {
      addDnsResolver: function addDnsResolver() {
        this.get('dnsResolverArray').pushObject({ value: '' });
      },
      removeDnsResolver: function removeDnsResolver(obj) {
        this.get('dnsResolverArray').removeObject(obj);
      },

      addDnsSearch: function addDnsSearch() {
        this.get('dnsSearchArray').pushObject({ value: '' });
      },
      removeDnsSearch: function removeDnsSearch(obj) {
        this.get('dnsSearchArray').removeObject(obj);
      }
    },

    disableRetainIp: _ember['default'].computed('isUpgrade', 'service.retainIp', 'retainWasSetOnInit', function () {
      var isUpgrade = this.get('isUpgrade');
      var wasSet = this.get('retainWasSetOnInit');
      if (isUpgrade && wasSet) {
        return true;
      } else {
        return false;
      }
    }),

    updateLabels: function updateLabels(labels) {
      this.sendAction('setLabels', labels);
    },

    // ----------------------------------
    // Network
    // ----------------------------------
    networkChoices: null,
    isContainerNetwork: _ember['default'].computed.equal('instance.networkMode', 'container'),
    initNetwork: function initNetwork() {
      var isService = this.get('isService') || false;
      var mode = this.get('instance.networkMode');
      var intl = this.get('intl');

      var choices = ['bridge', 'container', 'host', 'managed', 'none'];
      if (this.get('projects.current.isWindows')) {
        choices = ['nat', 'transparent'];
        if (mode === 'managed') {
          this.set('instance.networkMode', 'transparent');
        }
      }

      var out = [];
      choices.forEach(function (option) {
        if (isService && option === 'container') {
          return;
        }

        out.push({ label: intl.t('formNetwork.networkMode.' + option), value: option });
      });

      if (!out.findBy('value', mode) && !this.get('projects.current.isWindows')) {
        out.push({ label: mode, value: mode });
      }

      this.set('networkChoices', out);
    },

    networkModeChanged: (function () {
      if (this.get('instance.networkMode') !== 'container') {
        this.set('instance.networkContainerId', null);
      }
    }).observes('instance.networkMode'),

    isManagedNetwork: _ember['default'].computed.equal('instance.networkMode', 'managed'),
    isHostNetwork: _ember['default'].computed.equal('instance.networkMode', 'host'),

    // ----------------------------------
    // Requested IP
    // ----------------------------------
    requestedIp: null,
    initRequestedIp: function initRequestedIp() {
      var ip = this.getLabel(_uiUtilsConstants['default'].LABEL.REQUESTED_IP) || '';
      if (ip && ip.length && this.get('isManagedNetwork')) {
        this.set('requestedIp', ip);
      } else {
        this.set('requestedIp', '');
      }
    },

    requestedIpDidChange: (function () {
      var val = this.get('requestedIp');
      if (val && val.length && this.get('isManagedNetwork')) {
        this.setLabel(_uiUtilsConstants['default'].LABEL.REQUESTED_IP, val);
      } else {
        this.removeLabel(_uiUtilsConstants['default'].LABEL.REQUESTED_IP);
      }
    }).observes('requestedIp', 'isManagedNetwork'),

    // ----------------------------------
    // Hostname
    // ----------------------------------
    hostname: null,
    initHostname: function initHostname() {
      var override = this.getLabel(_uiUtilsConstants['default'].LABEL.HOSTNAME_OVERRIDE) === _uiUtilsConstants['default'].LABEL.HOSTNAME_OVERRIDE_VALUE;
      var hostname = this.get('instance.hostname') || '';

      if (this.get('isService')) {
        if (override) {
          this.set('hostname', 'override');
          this.set('instance.hostname', '');
        } else if (hostname) {
          this.set('hostname', 'custom');
        } else {
          this.set('hostname', 'default');
        }
      } else {
        this.set('hostname', 'custom');
      }
    },

    hostnameDidChange: (function () {
      var val = this.get('hostname');
      if (val === 'override') {
        this.setLabel(_uiUtilsConstants['default'].LABEL.HOSTNAME_OVERRIDE, _uiUtilsConstants['default'].LABEL.HOSTNAME_OVERRIDE_VALUE);
        this.set('instance.hostname', null);
      } else if (val === 'default') {
        this.removeLabel(_uiUtilsConstants['default'].LABEL.HOSTNAME_OVERRIDE);
        this.set('instance.hostname', null);
      } else {
        this.removeLabel(_uiUtilsConstants['default'].LABEL.HOSTNAME_OVERRIDE);
      }
    }).observes('hostname', 'instance.hostname'),

    // ----------------------------------
    // DNS Discovery
    // ----------------------------------
    dnsDiscovery: null,
    initDnsDiscovery: function initDnsDiscovery() {
      var on = this.getLabel(_uiUtilsConstants['default'].LABEL.DNS) === 'true';
      this.set('dnsDiscovery', on);
    },

    dnsDiscoveryDidChange: (function () {
      var on = this.get('dnsDiscovery') && this.get('isHostNetwork');
      if (on) {
        this.setLabel(_uiUtilsConstants['default'].LABEL.DNS, 'true');
      } else {
        this.removeLabel(_uiUtilsConstants['default'].LABEL.DNS);
        this.set('dnsDiscovery', false);
      }
    }).observes('dnsDiscovery', 'isHostNetwork'),

    // ----------------------------------
    // DNS Resolver
    // ----------------------------------
    dnsResolverArray: null,
    initDnsResolver: function initDnsResolver() {
      var ary = this.get('instance.dns');
      if (!ary) {
        ary = [];
        this.set('instance.dns', ary);
      }

      this.set('dnsResolverArray', ary.map(function (entry) {
        return { value: entry };
      }));
    },

    dnsDidChange: (function () {
      var out = this.get('instance.dns');
      out.beginPropertyChanges();
      out.clear();
      this.get('dnsResolverArray').forEach(function (row) {
        if (row.value) {
          out.push(row.value);
        }
      });
      out.endPropertyChanges();
    }).observes('dnsResolverArray.@each.value'),

    // ----------------------------------
    // DNS Search
    // ----------------------------------
    dnsSearchArray: null,
    initDnsSearch: function initDnsSearch() {
      var ary = this.get('instance.dnsSearch');
      if (!ary) {
        ary = [];
        this.set('instance.dnsSearch', ary);
      }

      this.set('dnsSearchArray', ary.map(function (entry) {
        return { value: entry };
      }));
    },
    dnsSearchDidChange: (function () {
      var out = this.get('instance.dnsSearch');
      out.beginPropertyChanges();
      out.clear();
      this.get('dnsSearchArray').forEach(function (row) {
        if (row.value) {
          out.push(row.value);
        }
      });
      out.endPropertyChanges();
    }).observes('dnsSearchArray.@each.value')
  });
});